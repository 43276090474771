import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DogHeand } from 'src/assets/images/dog-heand-icon.svg';
import { ReactComponent as DogPinkHeand } from 'src/assets/images/pink-heand-icon.svg';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import '../matching.scss';

const MatchingContent = ({
  title,
  description,
  onPawIconClick,
  isActivePawIcon,
}) => {
  const isActivePawIconRef = useRef(false);
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const [isActive, setIsActive] = useState(false);

  // const handleClick = () => {
  //   if (profileData?.isPaid === false) {
  //     setIsActive(false);
  //   } else {
  //     setIsActive(!isActive);
  //   }
  // };

  return (
    <Box className=' dog-content-sec'>
      <Box className='dog-details-content'>
        <Typography
          variant='h3'
          className='font-r-12 sub-head-txt'
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Typography
          variant='h4'
          className='details-txt s-bold'
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Box>
      <Box
        className='pointer dog-paw-icon'
        onClick={() => {
          onPawIconClick();
          // handleClick();
          isActivePawIconRef.current = !isActivePawIconRef.current;
        }}
      >
        {isActivePawIcon && isActivePawIconRef.current ? (
          <DogPinkHeand />
        ) : (
          <DogHeand />
        )}
      </Box>
    </Box>
  );
};

export default MatchingContent;
