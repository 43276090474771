import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from '../../../../assets/images/icon-plus.svg';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { CustomSelect } from '../../UI/select';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/down-icon.svg';
import { ReactComponent as Dogsgrp } from '../../../../assets/images/icons/dogsgrp.svg';

import IOSSwitch from '../../UI/IOSSwitch';
import { identifiers } from 'src/helpers/constants/identifier';

const ProfileCompletionDogDetails = ({
  localesData,
  handleNext,
  dispatch,
  questions,
  answars,
}) => {
  const reduxProfileData = useSelector((state) => state?.profileData);
  // const dogDetails = questions?.[1]?.Question?.find((o) => o?.q_order === 3);
  // const [selectedDate, setSelectedDate] = useState(null);

  const [breedOptions, setBreedOptions] = useState([]);
  const [otherValue, setOtherValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  // const [age, setAge] = useState({ year: '', month: '' });
  const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  useEffect(() => {
    setSelectedValue(
      reduxProfileData?.dog_breed ? reduxProfileData?.dog_breed : ''
    );
    setOtherValue(
      reduxProfileData?.dog_breed_other ? reduxProfileData?.dog_breed_other : ''
    );
  }, [reduxProfileData]);

  useEffect(() => {
    if (answars?.length !== 0) {
      setBreedOptions(
        answars?.find((o) => o?.id === 55)?.Option?.map((item) => item?.title)
      );
    }
  }, [answars]);

  const selectRef = useRef(null);

  const handleBreedBlur = () => {
    if (otherValue !== '') {
      const newValues = [otherValue, ...breedOptions];
      setBreedOptions(newValues);
      setSelectedValue(otherValue);
      dispatch(
        addProfileData({
          key: 'dog_breed_other',
          value: otherValue,
        })
      );
    }
  };

  return (
    <Box className='pb-6 select-sec'>
      <Typography variant='h3' className='t-center pb-4 s-bold'>
        {reduxProfileData?.dog_name}
        {/* {dogDetails?.title} */}
      </Typography>
      <Typography variant='h4' className='t-center pb-6'>
        {localesData?.profile_completion_dog_details?.DESC}{' '}
        <span className='s-bold'>{reduxProfileData?.dog_name}.</span>
      </Typography>
      <Formik
        initialValues={{
          dog_breed: reduxProfileData?.dog_breed
            ? reduxProfileData?.dog_breed
            : '',
          dog_gender: reduxProfileData?.dog_gender
            ? reduxProfileData?.dog_gender
            : '',
          dog_age: {
            year: reduxProfileData?.dog_year ? reduxProfileData?.dog_year : '',
            month: reduxProfileData?.dog_month
              ? reduxProfileData?.dog_month
              : '',
          },
          dog_mitch_number: reduxProfileData?.dog_mitch_number
            ? reduxProfileData?.dog_mitch_number
            : '',
          deceased: reduxProfileData?.deceased
            ? reduxProfileData?.deceased
            : false,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          dog_breed: Yup.string().required(localesData?.validation?.REQUIRED),

          dog_gender: Yup.string().required(localesData?.validation?.REQUIRED),
          dog_age: Yup.object().shape({
            year: Yup.string().required(localesData?.validation?.REQUIRED),
            month: Yup.string().required(localesData?.validation?.REQUIRED),
          }),
          // .max(new Date(), localesData?.validation?.INVALID_AGE),
        })}
        onSubmit={(req) => {
          dispatch(addProfileData({ key: 'dog_breed', value: req?.dog_breed }));
          dispatch(
            addProfileData({ key: 'dog_gender', value: req?.dog_gender })
          );
          const dogAge = `${req?.dog_age?.year} ${req?.dog_age?.month}`;
          dispatch(addProfileData({ key: 'dog_age', value: dogAge }));
          dispatch(
            addProfileData({ key: 'dog_year', value: req?.dog_age?.year })
          );
          dispatch(
            addProfileData({ key: 'dog_month', value: req?.dog_age?.month })
          );
          // dispatch(addProfileData({ key: 'dog_age', value: req?.dog_age }));
          dispatch(
            addProfileData({
              key: 'dog_mitch_number',
              value: req?.dog_mitch_number,
            })
          );
          dispatch(
            addProfileData({
              key: 'deceased',
              value: req?.deceased,
            })
          );
          handleNext();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            {/* {/ Dog Breed /} */}
            <Box className='mb-2'>
              {selectedValue === 'other' ? (
                <>
                  <FormHelperText className='custom-field-helper'>
                    {localesData?.ADD_OTHER}
                  </FormHelperText>
                  <CustomTextField
                    error={Boolean(touched.dog_breed && errors.dog_breed)}
                    fullWidth
                    variant='standard'
                    className='custom-textfield'
                    name='dog_breed'
                    // value={inputValue}
                    // onChange={handleInputfieldChange}
                    onBlur={handleBreedBlur}
                    value={otherValue}
                    onChange={(e) => {
                      handleChange(e);
                      setOtherValue(e.target.value);
                    }}
                  />
                </>
              ) : (
                <FormControl variant='standard' fullWidth>
                  <Select
                    id='dog_breed'
                    name='dog_breed'
                    ref={selectRef}
                    fullWidth
                    labelId='my-dropdown-label'
                    className='custom-select'
                    IconComponent={DropdownIcon}
                    input={<CustomSelect />}
                    // value={values.selectedValue || ''}
                    // onChange={(e, value) => {
                    //   if (e.target.value === '__input__') {
                    //     setShowInput(true);
                    //     handleChange(e);
                    //   } else {
                    //     handleInputChange(e);
                    //     handleChange(e);
                    //   }
                    // }}

                    value={selectedValue || ''}
                    onChange={(e) => {
                      if (e.target.value === 'other') {
                        setOtherValue('');
                      }
                      handleChange(e);
                      setSelectedValue(e.target.value);
                    }}
                  >
                    <MenuItem value='other'>
                      <PlusIcon />
                      <span style={{ marginLeft: '10px' }}>
                        {localesData?.ADD_OTHER}
                      </span>
                    </MenuItem>
                    {breedOptions?.map((opt, i) => (
                      <MenuItem key={i} value={opt} className='select-options'>
                        {opt}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.dog_breed && errors.dog_breed && (
                    <FormHelperText
                      sx={{ marginTop: '4px', fontSize: '10px' }}
                      className='color-error'
                    >
                      {errors.dog_breed}
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              <FormHelperText className='custom-field-helper'>
                {localesData?.profile_completion_dog_details?.DOG_BREED_DESC}
              </FormHelperText>
            </Box>
            {/* {/ Dog Gender /} */}
            <Box className='mb-2'>
              <Select
                fullWidth
                variant='standard'
                error={Boolean(touched.dog_gender && errors.dog_gender)}
                className='custom-select'
                name='dog_gender'
                input={<CustomSelect />}
                IconComponent={DropdownIcon}
                value={values.dog_gender}
                onChange={handleChange}
                onBlur={handleBlur}
                displayEmpty
              >
                {genderList?.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item?.value}
                    className='select-options'
                  >
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.dog_gender && errors.dog_gender && (
                <FormHelperText
                  sx={{ marginTop: '4px', fontSize: '10px' }}
                  className='color-error'
                >
                  {errors.dog_gender}
                </FormHelperText>
              )}
              <FormHelperText className='custom-field-helper'>
                {localesData?.profile_completion_dog_details?.DOG_GENDER_DESC}
              </FormHelperText>
            </Box>
            {/* {/ Dog Age /} */}
            <Box className='dog-age-block-wrap'>
              <Box>
                <FormControl>
                  <Select
                    fullWidth
                    variant='standard'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={values.dog_age.year}
                    name='dog_age.year'
                    input={<CustomSelect />}
                    className='dog-month custom-select'
                    IconComponent={DropdownIcon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {identifiers?.Years.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.dog_age?.year && touched.dog_age?.year && (
                  <FormHelperText className='color-error'>
                    {errors.dog_age.year}
                  </FormHelperText>
                )}
              </Box>

              <Box>
                <FormControl>
                  <Select
                    fullWidth
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={values.dog_age.month}
                    name='dog_age.month'
                    className='dog-month custom-select'
                    input={<CustomSelect />}
                    IconComponent={DropdownIcon}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant='standard'
                  >
                    {identifiers?.Months.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.dog_age?.month && touched.dog_age?.month && (
                  <FormHelperText className='color-error'>
                    {errors.dog_age.month}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <FormHelperText className='custom-field-helper pb-2'>
              {localesData?.profile_completion_dog_details?.DOG_AGE_DESC}
            </FormHelperText>
            <Box className='mb-7'>
              <CustomTextField
                error={Boolean(
                  touched.dog_mitch_number && errors.dog_mitch_number
                )}
                helperText={touched.dog_mitch_number && errors.dog_mitch_number}
                fullWidth
                variant='standard'
                className='custom-textfield'
                name='dog_mitch_number'
                value={values.dog_mitch_number || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={(e) => {
                  if (/[^0-9]/g.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <FormHelperText className='custom-field-helper'>
                {localesData?.profile_completion_dog_details?.DOG_NUMBER}
              </FormHelperText>
            </Box>

            <Box className='d-flex pb-4' sx={{ justifyContent: 'center' }}>
              <FormGroup sx={{ marginRight: '8px' }}>
                <FormControlLabel
                  control={<IOSSwitch />}
                  name='deceased'
                  checked={values.deceased}
                  onChange={handleChange}
                />
              </FormGroup>
              <Typography variant='h5' className='s-bold'>
                {localesData?.profile_completion_dog_details?.DOG_IS_DECEASED}
              </Typography>
            </Box>

            <Box>
              <CustomButton
                variant='contained'
                title={localesData?.profile_completion_dog_details?.DONE}
                background='#59D09D'
                fontWeight='600'
                type='submit'
                // onClick={() => redirectrulespage()}
              />
            </Box>
          </form>
        )}
      </Formik>
      <Dogsgrp />
    </Box>
  );
};

export default ProfileCompletionDogDetails;
