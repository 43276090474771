import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 0,
};

export const navigateSlice = createSlice({
  name: 'navigatePage',
  initialState,
  reducers: {
    handleNext: (state, action) => {
      state.value = action?.payload;
    },
    handlePrevious: (state, action) => {
      state.value = action?.payload;
    },
  },
});

export const { handleNext, handlePrevious } = navigateSlice.actions;

export default navigateSlice.reducer;
