import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from '../../components/ScrollTop';

import './landinglayout.scss';

const LandingLayout = () => {
  return (
    <>
      <ToastContainer style={{ padding: '5px' }} limit={1} />
      <ScrollToTop />
      <Box className='landing-screen-bg'>
        <Outlet />
      </Box>
    </>
  );
};

export default LandingLayout;
