import { createSlice } from '@reduxjs/toolkit';

export const notificationCountSlice = createSlice({
  name: 'notificationCount',
  initialState: { count: 0 },
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setCount } = notificationCountSlice.actions;

export default notificationCountSlice.reducer;
