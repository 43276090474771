import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

const RangeSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#F54D71' : '#F54D71',
  height: 15,
  padding: '0',
  '& .MuiSlider-thumb': {
    // height: 15,
    // width: 15,
    // backgroundColor: '#F54D71',
    // borderRadius: '0',

    display: 'none',

    '&:before': {
      borderRadius: '50% 0',
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 20,
    fontWeight: '600',
    fontFamily: 'Open Sans, sans-serif',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: '#E0E0E0',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#E0E0E0',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));
export default RangeSlider;
