import React from "react";
import Page from "../../../components/Page";
import WelcomePage from "../../component/auth/Welcome";

const WelcomeView = () => {
  return (
    <Page title="Welcome">
      <WelcomePage />
    </Page>
  );
};

export default WelcomeView;
