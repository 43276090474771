import React, { useState, useEffect, useRef } from "react";
import { Box, Link, Typography } from "@mui/material";
import "./dashboard.scss";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import PlaceDetails from "./PlaceDetails";
import CustomButton from "src/views/component/UI/Button/CustomButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as Benefits } from "src/assets/images/icons/benefits.svg";
import { useLocation } from "react-router-dom";
import PreLoader from "src/components/Loader";
import { directoryData } from "src/helpers/store/actions/directory";
import { useDispatch, useSelector } from "react-redux";
import { Config } from "src/helpers/context";

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};
// const AnyReactComponent = ({ img_src }) => <div><img src={img_src} className="YOUR-CLASS-NAME" style={{}} /></div>;

const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultOptions={defaultMapOptions}
      defaultCenter={{ lat: -33.8392792, lng: 151.2055562 }}
    >
      {props?.lat_long_array &&
        props?.lat_long_array.length > 0 &&
        props?.lat_long_array.map((mdata) => {
          // if (props?.showMarkerDetails === mdata?.id) {
          //   return (
          //     <>
          //       <Marker
          //         position={{ lat: mdata?.latitude, lng: mdata?.longitude }}
          //         onClick={() => props.onToggleOpen(mdata?.id, mdata?.place_id, mdata)}
          //       >
          //         <InfoBox
          //           options={{ closeBoxURL: ``, enableEventPropagation: true }}
          //         >
          //           <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `5px` }}>
          //             <div style={{ fontSize: `6px`, fontColor: `#08233B` }}>
          //               {mdata?.place_address + '1111'}
          //             </div>
          //           </div>
          //         </InfoBox>
          //       </Marker>
          //     </>
          //   )
          // }
          // else {
          // return (
          //   <>
          //     <AnyReactComponent
          //       lat={mdata?.latitude}
          //       lng={mdata?.longitude}
          //       img_src={Config.mediaURL + mdata?.markerImage}
          //     />          </>
          // )
          // return (<ReactSVG src={Config.mediaURL + mdata?.markerImage} />)
          // <Avatar src={Config.mediaURL + mdata?.markerImage} />
          return (
            <Marker
              position={{ lat: mdata?.latitude, lng: mdata?.longitude }}
              onClick={() => {
                props.onToggleOpen(mdata?.id, mdata?.place_id, mdata);
              }}
            />
          );
          // }
        })}
      {props?.banefits_array &&
        props?.banefits_array.length > 0 &&
        props?.banefits_array.map((bdata) => {
          return (
            <Marker
              position={{ lat: bdata?.latitude, lng: bdata?.longitude }}
              onClick={() => {
                props.onToggleOpen(bdata?.id, bdata?.place_id, bdata, true);
              }}
            />
          );
        })}
    </GoogleMap>
  ))
);
const Dashboard = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [showMarkerDetails, setShowMarkerDetails] = useState();
  const [showPlaceDetails, setShowPlaceDetails] = useState();
  const [showPlaceInfo, setShowPlaceInfo] = useState();
  const [loader, setLoader] = useState(false);
  const ref = useRef(null);
  const refMap = useRef(null);
  const location = useLocation();
  const reduxDirectory = useSelector((state) => state?.directoryData);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    region: null,
    isLoadMap: false,
    // current_lat: 0,
    // current_long: 0,
    // bhavnagar
    // current_lat: 21.772800,
    // current_long: 72.153557,
    // australia
    current_lat: -33.8392792,
    current_long: 151.2055562,
    // latitudeDelta: 0,
    // longitudeDelta: 0,
    isGPSOff: false,
    loadAllGooglePins: [],
    loading: false,
    benefit: false,
    lat_long_array: [],
    banefits_array: [],
    areaRadius: Math.round(0.0922 * 111),
  });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const dispatch = useDispatch();
  //GET CATEGORY
  const GetCategory = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.GET_CATEGORY);
      if (status === 200) {
        // setApiMessage('success', data?.message);
        const categoryData = data?.data;
        const updatedCategoryData = categoryData?.map((element) => ({
          ...element,
          selected: false,
        }));
        setCategoryData(updatedCategoryData);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };
  // const loadPinsFromSponseredLocations = async (lat_long_list) => {
  //   var param_data = {
  //     currentLatitude: -33.8392792,
  //     currentLongitude: 151.2055562,
  //     categorySelected: [],
  //     area: state.areaRadius,
  //     benefit: true,
  //   };
  //   // commment
  //   const { status, data } = await axiosInstance.post(
  //     URLS.GET_LOCATION_BY_CATEGORY,
  //     param_data
  //   );

  //   if (status === 200) {
  //     let result = data?.resultData;
  //     // result = await this.callDetialAPIForUpdateRating(result);
  //     if (result && result != null) {
  //       let location_list = state?.banefits_array;
  //       // result?.map((results) => {
  //       //   location_list.push({
  //       //     id: results?.id,
  //       //     place_id: results.placeId,
  //       //     latitude: results.lat,
  //       //     longitude: results.long,
  //       //     place_name: results.name,
  //       //     place_rating: results.place_rating,
  //       //     place_total_review: results.place_total_review,
  //       //     place_status: results.place_status,
  //       //     place_type: results.place_type,
  //       //     place_address: results.address,
  //       //     photo_reference: results.imageUrl,
  //       //     markerImage: results.markerPinImage,
  //       //     isSponsered: results?.directoryType === 'sponsor' ? true : false,
  //       //     dataType: 'place',
  //       //     benefits: results.benefit
  //       //   });
  //       // });
  //       result?.map((results) => {
  //         location_list.push({
  //           id: results.id,
  //           place_id: results.placeId,
  //           latitude: results.lat,
  //           longitude: results.long,
  //           place_name: results.name.toString(),
  //           place_rating: results.place_rating,
  //           place_total_review: results.place_total_review,
  //           place_status: results.place_status ? results.place_status : '',
  //           place_type: results.place_type,
  //           place_address: results.address.toString(),
  //           photo_reference: results.imageUrl,
  //           phoneNumber: results.phoneNumber,
  //           sponsorId: results.sponsorId,
  //           webSite: results.sponsorWebsite,
  //           sponsorName: results.sponsorName,
  //           sponsorImage: results.sponsorImage,
  //           benefitId: results.benefitId,
  //           benefits: results.benefit,
  //           is_fav: '',
  //           fav_id: '',
  //           markerImage: results.markerPinImage,
  //           dataType: 'benefit',
  //           isSponsered: results?.directoryType === 'sponsor' ? true : false,
  //         });
  //       });
  //       setState({
  //         ...state,
  //         banefits_array: location_list
  //       });

  //     } else {
  //     }
  //   }
  // }
  const callStatusApi = async (place_detail) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        URLS.GET_FAVOURITE_STATUS + place_detail?.place_id
      );
      if (status === 200) {
        setState({
          ...state,
          loading: false,
          place_detail: place_detail,
          is_fav: data?.data?.isLike === 0 ? false : true,
          fav_id: data?.data?.fevouriteId,
          isDirectoryExist: data?.data?.isDirectoryExist === 1 ? true : false,
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };

  //GET CATEGORY
  const callSearchApi = async (categoryId, isBenefits, statedata) => {
    setLoader(true);
    try {
      let param_data = {
        currentLatitude: state && state?.current_lat,
        currentLongitude: state && state?.current_long,
        categorySelected: categoryId,
        // area: isTestLocation ? 1000 : state?.areaRadius,
        area: state && state?.areaRadius,
        benefit: false,
      };

      const { status, data } = await axiosInstance.post(
        URLS.GET_LOCATION_BY_CATEGORY,
        param_data
      );

      if (status === 200) {
        let result = data?.resultData;
        // result = await this.callDetialAPIForUpdateRating(result);
        if (result && result != null) {
          setLoader(false);
          let location_list = [];
          result?.map((results) => {
            location_list.push({
              id: results?.id,
              place_id: results.placeId,
              latitude: results.lat,
              longitude: results.long,
              place_name: results.name,
              place_rating: results.place_rating,
              place_total_review: results.place_total_review,
              place_status: results.place_status,
              place_type: results.place_type,
              place_address: results.address,
              webSite: results.sponsorWebsite,
              sponsorName: results.sponsorName,
              photo_reference: results.imageUrl,
              markerImage: results.markerPinImage,
              isSponsered: false,
              dataType: "place",
            });
            return true;
          });
          if (isBenefits === "IsRedirected") {
            setState({
              ...statedata,
              lat_long_array: location_list,
            });
          } else if (isBenefits) {
            setState({
              ...state,
              lat_long_array: location_list,
              banefits_array: [],
              benefit: false,
            });
            dispatch(
              directoryData({
                ...reduxDirectory,
                benefit: false,
              })
            );
          } else {
            setState({
              ...state,
              lat_long_array: location_list,
            });
          }
        } else {
        }
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const callDetialAPIForUpdateRating = (place_id) => {
    fetch(
      `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}8&fields=place_id,rating,user_ratings_total,business_status&key=AIzaSyDixxINEkPifkjbJoHnLudtdOsRD2Vtcig`
    )
      .then((response) => response.json())
      .then((data) => {
        const placeDetails = data?.result;
        setShowPlaceDetails(placeDetails);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        // Handle any errors that occurred during the request
      });
  };
  const onToggleOpen = (mid, place_id, place_Info, isBen) => {
    setLoader(true);
    if (showMarkerDetails && showMarkerDetails === mid) {
      setShowMarkerDetails();
    } else {
      setShowMarkerDetails(mid);
      callDetialAPIForUpdateRating(place_id);
      setShowPlaceInfo(place_Info);
      !isBen && callStatusApi(place_Info);
      isBen &&
        setState({
          ...state,
          is_fav:
            place_Info?.benefits &&
            place_Info?.benefits[0] &&
            place_Info?.benefits[0]?.is_fav
              ? place_Info?.benefits[0]?.is_fav
              : false,
          place_detail: place_Info,
        });
      setOpen(true);
    }
    setTimeout(() => {
      setLoader(false);
    }, 300);
  };
  const callBenefitIntoFav = async (fav, fid) => {
    setLoader(true);
    var param_data = {
      currentLatitude: -33.8392792,
      currentLongitude: 151.2055562,
      categorySelected: [],
      area: state && state?.areaRadius,
      benefit: true,
    };
    const { status, data } = await axiosInstance.post(
      URLS.GET_LOCATION_BY_CATEGORY,
      param_data
    );

    if (status === 200) {
      let result = data?.resultData;
      // result = await this.callDetialAPIForUpdateRating(result);
      if (result && result != null) {
        // let location_list = state && state?.banefits_array;
        setLoader(false);
        let location_list = [];
        result?.map((results) => {
          location_list.push({
            id: results?.id,
            place_id: results.placeId,
            latitude: results.lat,
            longitude: results.long,
            place_name: results.name,
            place_rating: results.place_rating,
            place_total_review: results.place_total_review,
            place_status: results.place_status,
            place_type: results.place_type,
            place_address: results.address,
            photo_reference: results.imageUrl,
            markerImage: results.markerPinImage,
            webSite: results.sponsorWebsite,
            sponsorName: results.sponsorName,
            isSponsered: results?.directoryType === "sponsor" ? true : false,
            dataType: "benefit",
            benefits: results.benefit,
            is_fav:
              results?.benefit &&
              results?.benefit[0] &&
              results?.benefit[0]?.is_fav
                ? results?.benefit[0]?.is_fav
                : false,
          });
          return true;
        });
        var placeD = location_list.find((d) => d?.id === fid);
        setState({
          ...state,
          benefit: true,
          banefits_array: location_list,
          is_fav: fav,
          place_detail: placeD,
        });
        dispatch(
          directoryData({
            ...reduxDirectory,
            benefit: true,
          })
        );
        // setState({
        //   ...state,
        //   is_fav: fav,
        //   place_detail:
        // })
      } else {
      }
    }
  };
  const searchData = (id) => {
    const currentIndex = selectedCategory.indexOf(id);
    const newChecked = [...selectedCategory];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedCategory(newChecked);
    dispatch(
      directoryData({
        ...reduxDirectory,
        selectedCategory: newChecked,
      })
    );
    callSearchApi(newChecked);
  };
  const handleBenefitClick = async () => {
    setLoader(true);
    var param_data = {
      currentLatitude: -33.8392792,
      currentLongitude: 151.2055562,
      categorySelected: [],
      area: state && state?.areaRadius,
      benefit: true,
    };
    const { status, data } = await axiosInstance.post(
      URLS.GET_LOCATION_BY_CATEGORY,
      param_data
    );

    if (status === 200) {
      let result = data?.resultData;
      // result = await this.callDetialAPIForUpdateRating(result);
      if (result && result != null) {
        // let location_list = state && state?.banefits_array;
        setLoader(false);
        let location_list = [];
        result?.map((results) => {
          location_list.push({
            id: results?.id,
            place_id: results.placeId,
            latitude: results.lat,
            longitude: results.long,
            place_name: results.name,
            place_rating: results.place_rating,
            place_total_review: results.place_total_review,
            place_status: results.place_status,
            place_type: results.place_type,
            place_address: results.address,
            photo_reference: results.imageUrl,
            markerImage: results.markerPinImage,
            webSite: results.sponsorWebsite,
            sponsorName: results.sponsorName,
            isSponsered: results?.directoryType === "sponsor" ? true : false,
            dataType: "benefit",
            benefits: results.benefit,
            is_fav:
              results?.benefit &&
              results?.benefit[0] &&
              results?.benefit[0]?.is_fav
                ? results?.benefit[0]?.is_fav
                : false,
          });
          return true;
        });
        setState({
          ...state,
          benefit: true,
          banefits_array: location_list,
        });
        dispatch(
          directoryData({
            ...reduxDirectory,
            benefit: true,
          })
        );
        if (
          reduxDirectory &&
          reduxDirectory?.benefit &&
          reduxDirectory?.selectedCategory
        ) {
          var storedStateBen = {
            ...state,
            benefit: true,
            banefits_array: location_list,
          };
          setSelectedCategory(reduxDirectory?.selectedCategory);
          dispatch(
            directoryData({
              ...reduxDirectory,
              selectedCategory: reduxDirectory?.selectedCategory,
              benefit: true,
            })
          );
          setTimeout(() => {
            callSearchApi(
              reduxDirectory?.selectedCategory,
              "IsRedirected",
              storedStateBen
            );
          }, 900);
        } else if (
          location &&
          location?.state &&
          location?.state?.placeDetails &&
          location?.state?.selectedCategory
        ) {
          var selectedBen = location_list?.find(
            (d) => d?.id === location?.state?.placeDetails?.id
          );
          setShowMarkerDetails(selectedBen?.id);
          callDetialAPIForUpdateRating(selectedBen?.place_id);
          setShowPlaceInfo(selectedBen);
          var storedState = {
            ...state,
            is_fav:
              selectedBen?.benefits &&
              selectedBen?.benefits[0] &&
              selectedBen?.benefits[0]?.is_fav
                ? selectedBen?.benefits[0]?.is_fav
                : false,
            place_detail: selectedBen,
            benefit: true,
            banefits_array: location_list,
          };
          setState(storedState);
          setSelectedCategory(location?.state?.selectedCategory);
          dispatch(
            directoryData({
              ...reduxDirectory,
              selectedCategory: location?.state?.selectedCategory,
              benefit: true,
            })
          );
          setTimeout(() => {
            callSearchApi(
              location?.state?.selectedCategory,
              "IsRedirected",
              storedState
            );
          }, 900);
          setOpen(true);
        }
      } else {
      }
    }
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (
      reduxDirectory &&
      reduxDirectory?.benefit !== undefined &&
      reduxDirectory?.selectedCategory !== undefined &&
      !location?.state?.placeDetails &&
      !location?.state?.selectedCategory
    ) {
      GetCategory();
      setSelectedCategory(reduxDirectory?.selectedCategory);
      !reduxDirectory?.benefit &&
        reduxDirectory?.selectedCategory &&
        reduxDirectory?.selectedCategory.length > 0 &&
        callSearchApi(reduxDirectory?.selectedCategory);
      reduxDirectory?.benefit && handleBenefitClick();
    } else {
      GetCategory();
      handleBenefitClick();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box className="directory-map-sec">
        {loader && <PreLoader />}
        <Box className="category-wrap">
          {categoryData?.map((item, index) => {
            return (
              <Link
                onClick={() => searchData(item?.id)}
                key={item?.name}
                className={
                  selectedCategory.indexOf(item?.id) === -1
                    ? "cat-inner"
                    : "cat-inner active-cat"
                }
              >
                {item?.icon && (
                  <img
                    key={index}
                    src={`${Config.mediaURL + item?.icon}`}
                    alt="Icon"
                    className="icon"
                  />
                )}
                <Typography variant="h6" className="font-r-10">
                  {item?.name}
                </Typography>
              </Link>
            );
          })}
        </Box>
        {/* <Button
        onClick={handleBenefitClick}
        className='heand-icon'
      // startIcon={isActive ? <DogPinkHeand /> : <DogHeand />}
      /> */}
        {/* <Button className={state?.benefit ? 'benefit-btn pink-outline-btn color-pink benefit-selected-btn' : 'benefit-btn pink-outline-btn color-pink'}
        onClick={() => {
          if (state && state?.benefit) {
            callSearchApi(selectedCategory, true)
          } else {
            handleBenefitClick()
          }
        }}>
        {"banefit"}
      </Button> */}
        <CustomButton
          variant="text"
          title={"Benefits"}
          classname={
            state?.benefit
              ? "benefit-button pink-button benefit-selected-btn mr-9"
              : "benefit-button pink-button mr-9"
          }
          leftIcon={<Benefits />}
          onClick={() => {
            if (state && state?.benefit) {
              callSearchApi(selectedCategory, true);
            } else {
              handleBenefitClick();
            }
          }}
        />
        <MapComponent
          ref={refMap}
          // defaultZoom={13}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config?.placeAPIKey}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `60vh` }} />}
          containerElement={<div style={{ height: `60vh` }} />}
          mapElement={<div style={{ height: `60vh` }} />}
          lat_long_array={state?.lat_long_array}
          banefits_array={state?.banefits_array}
          onToggleOpen={onToggleOpen}
          showMarkerDetails={showMarkerDetails}
        />
        {open && (
          <ClickAwayListener
            onClickAway={() => {
              handleClickAway();
            }}
            mouseEvent="onMouseDown"
          >
            <Box ref={ref}>
              {showPlaceInfo &&
                showMarkerDetails &&
                showMarkerDetails === showPlaceInfo?.id && (
                  <PlaceDetails
                    selectedCategory={selectedCategory}
                    handleBenefitClick={handleBenefitClick}
                    mainloader={loader}
                    state={state}
                    callBenefitIntoFav={callBenefitIntoFav}
                    callStatusApi={callStatusApi}
                    showPlaceDetails={showPlaceDetails}
                    showPlaceInfo={showPlaceInfo}
                  />
                )}
            </Box>
          </ClickAwayListener>
        )}
        {/* <GoogleMapReact
        // bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps?.center}
        defaultZoom={defaultProps?.zoom}
        onGoogleApiLoaded={({ map, maps }) => }
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact> */}
        {/* <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
         You can add markers or other components here 
          <Marker position={{ lat: -34.397, lng: 150.644 }} />
        </GoogleMapReact>
      </div> */}
        {/* <LocationSearchModal /> */}
      </Box>
    </>
  );
};

export default Dashboard;
