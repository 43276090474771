import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import defaultDogImg from 'src/assets/images/dog-img-upload.svg';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import './editDogProfile.scss';

const Reorderable = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dogs, setDogs] = useState([]);
  const [dogDetails, setDogDetails] = useState([]);

  useEffect(() => {
    setDogs(dogDetails);
  }, [dogDetails]);

  const getDogDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.DOG);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setDogDetails(data?.dogs);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getDogDetails();
  }, []);

  const getDogDetailsupdate = async (obj, dogId) => {
    const sendData = {
      dogIndex: obj?.dogIndex,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.put(
        URLS.DOG + `/${dogId}`,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        // getDogDetails();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const handleDragStart = (e, obj) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({ obj }));
  };
  const handleDrop = (e, id) => {
    /*  e.preventDefault();
    const sourceData = JSON.parse(e.dataTransfer.getData('text/plain'))?.obj;
    const targetValue = dogs.find((obj) => obj?.id === id);

    const newValue = dogs?.map((obj) => {
      if (obj?.id === sourceData?.id) {
        return { ...obj, name: targetValue?.name };
      } else if (obj?.id === id) {
        return { ...obj, name: sourceData?.name };
      } else {
        return obj;
      }
    });

    setDogs(newValue); */

    // dogs.map((item, index) => {
    //   if (index - 1 === index - 1) {

    // } else {
    // getDogDetailsupdate(item, index, true);
    //   }
    // });

    e.preventDefault();
    const sourceData = JSON.parse(e.dataTransfer.getData('text/plain'))?.obj;

    const targetIndex = dogs.findIndex((obj) => obj?.id === id);

    const sourceIndex = dogs.findIndex((obj) => obj?.id === sourceData?.id);

    const updatedDogList = [...dogs];
    const draggedDog = updatedDogList[sourceIndex];

    // Reorder the dogs in the array based on the drag and drop operation
    updatedDogList.splice(sourceIndex, 1);
    updatedDogList.splice(targetIndex, 0, draggedDog);

    // Update the dogIndex property based on the new order
    updatedDogList.forEach((dog, index) => {
      dog.dogIndex = index;
    });

    updatedDogList?.map((obj) => {
      return getDogDetailsupdate(obj, obj?.id);
    });
    const updatedDogs = [...dogs];
    const [removed] = updatedDogs.splice(sourceIndex, 1);
    updatedDogs.splice(targetIndex, 0, removed);

    setDogs(updatedDogs);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {loader && <PreLoader />}
      {dogs &&
        dogs?.length > 0 &&
        dogs?.map((obj, i) => {
          return (
            <Box key={i} className='dog-accordian-content-sec'>
              <Box
                onClick={() => navigate('/dog-profile/edit', { state: obj })}
                className='dog-accordian-tab-sec d-flex'
                key={obj?.id}
                draggable
                onDragStart={(e) => handleDragStart(e, obj)}
                onDrop={(e) => handleDrop(e, obj?.id)}
                onDragOver={handleDragOver}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
                handleDragOver={handleDragOver}
              >
                <Box className='d-flex space-between align-center'>
                  <img
                    src={
                      obj?.images?.filter(
                        (item) =>
                          item?.media_type === 'image' ||
                          item?.url?.substr(item?.url?.length - 3) === 'jpg'
                      )?.[0]?.url
                        ? obj?.images?.filter(
                            (item) =>
                              item?.media_type === 'image' ||
                              item?.url?.substr(item?.url?.length - 3) === 'jpg'
                          )?.[0]?.url
                        : defaultDogImg
                    }
                    className='dog-list-img'
                    alt=''
                  />
                  <Typography variant='h6' className='font-r-12'>
                    {obj?.name}
                  </Typography>
                </Box>
                <Box className='content-errow'>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default Reorderable;
