import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import { addAuth } from '../../../../helpers/store/actions/auth';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { saveToStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';

import { ReactComponent as Visibility } from '../../../../assets/images/icons/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/images/icons/visibility-off.svg';

// import { clearProfileData } from 'src/helpers/store/actions/profileSetup';

const AlreadyAccount = ({ localesData, setLoader, profileData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showEnterPassword, setShowCurrentPassword] = useState(false);

  const redirectForgot = () => {
    dispatch(handleNext(0));
    // dispatch(clearProfileData());
    navigate('/forgot-password');
  };

  // useEffect(() => {
  //   if (!profileData?.first_name) {
  //     navigate('/sign-in');
  //   }
  // }, [navigate, profileData]);

  //GET ALL ANSWER
  const getAllAnswars = async (token) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;

        var selectedCount = 0;
        all_ans?.map((itm) => {
          if (itm?.id === 74) {
            itm?.Option?.map((item) => {
              if (
                item?.answer === true ||
                item?.answer === '1' ||
                item?.answer === 1
              ) {
                selectedCount++;
              }
              return true;
            });
          }
          return true;
        });
        if (selectedCount === 0) {
          redirectNow(token, true);
        } else {
          redirectNow(token, false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const redirectNow = async (token, userType) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE, {
        headers: {
          Authorization: `Bearer ${token?.access_token}`,
          'Content-Type': 'application/json',
        },
      });
      if (status === 200) {
        setTimeout(() => {
          // if (userType) {
          //   setTimeout(() => {
          //     navigate('/profile-setup');
          //     dispatch(handleNext(0));
          //   }, 100);
          // } else
          if (!data?.images || data?.images.length === 0) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(1));
            }, 100);
          } else if (
            data?.dob === '' ||
            data?.dob === null ||
            data?.dob === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(2));
            }, 100);
          } else if (
            data?.gender === '' ||
            data?.gender === null ||
            data?.gender === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(3));
            }, 100);
          } else if (
            data?.looking_for === '' ||
            data?.looking_for === null ||
            data?.looking_for === 'null'
          ) {
            setTimeout(() => {
              navigate('/profile-setup');
              dispatch(handleNext(4));
            }, 100);
          } else {
            setTimeout(() => {
              dispatch(handleNext(0));
              navigate('/');
            }, 100);
          }
        }, 500);
        setLoader(false);
        saveToStorage(identifiers?.AUTH_DATA, token);
        dispatch(addAuth(token));
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          password: '',
        }}
        // enableReinitialize={true}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(localesData?.validation?.REQUIRED),
        })}
        onSubmit={async (requestData) => {
          let sendData = {
            countryCode: profileData?.phone_country,
          };
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
              URLS.SIGN_IN,
              sendData,
              {
                auth: {
                  username: profileData?.phone_number,
                  password: requestData?.password,
                },
              }
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              if (
                profileData?.isVerify === 1 &&
                !profileData?.last_name &&
                !profileData?.first_name
              ) {
                dispatch(handleNext(0));
                saveToStorage(identifiers?.AUTH_DATA, data);
                navigate('/profile-setup');
              } else {
                saveToStorage(identifiers?.VERIFIED, true);
                getAllAnswars(data);
              }
              // redirectNow(data);
              // saveToStorage(identifiers?.AUTH_DATA, data);
              // dispatch(addAuth(data));
              // navigate('/');
              setLoader(false);
            }
          } catch (error) {
            setLoader(false);
            // setApiMessage('error', localesData?.validation?.WRONG);
            if (error.response.data.message) {
              if (error.response.data.message === 'Wrong Password en') {
                setApiMessage('error', localesData?.validation?.WRONG);
              } else {
                setApiMessage('error', error.response.data.message);
              }
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center mb-10'>
              <Typography variant='h3' className='s-bold mb-2'>
                {localesData?.HI} {profileData?.first_name}{' '}
                {profileData?.last_name},
              </Typography>
              <Typography
                variant='h4'
                dangerouslySetInnerHTML={{
                  __html: localesData?.login?.PASSWORD_DESC1,
                }}
              />
            </Box>
            <Box className='mb-6'>
              <Box className='mb-4'>
                <Box className='field-content'>
                  <CustomTextField
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    variant='standard'
                    className='custom-textfield'
                    name='password'
                    value={values.password || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={showEnterPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            disableRipple
                            onClick={() =>
                              setShowCurrentPassword(!showEnterPassword)
                            }
                          >
                            {showEnterPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Box className='mb-4'>
                <CustomButton
                  variant='contained'
                  title={localesData?.LOG_IN}
                  background='#F54D71'
                  fontWeight='600'
                  type='submit'
                />
              </Box>
              {/* <CustomButton
                classname='capitalize'
                variant='text'
                title={localesData?.FORGOT_PASSWORD}
                color='#000000'
                fontWeight='600'
                onClick={() => redirectForgot()}
              /> */}
            </Box>
            <Typography
              variant='h6'
              className='t-center pointer pb-4'
              onClick={() => redirectForgot()}
            >
              {localesData?.FORGOT_PASSWORD}
            </Typography>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AlreadyAccount;
