import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import UpgradePlan from '../UpgradePlan';
// import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
// import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';

const AboutVices = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const [vicesData, setVicesData] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [isPrivate, setIsPrivate] = useState('');

  // const [drinkingData, setDrinkingData] = useState('');
  // const [smokingData, setSmokingData] = useState('');
  // const [marijuanaData, setMarijuanaData] = useState('');
  // const [LFData, setLFData] = useState('');
  const [drinkingChips, setDrinkingChips] = useState([]);
  const [smokingChips, setSmokingChips] = useState([]);
  const [marijuanaChips, setMarijuanaChips] = useState([]);
  const [LFChips, setLFChips] = useState([]);

  useEffect(() => {
    if (answers?.length !== 0) {
      const options = answers?.find((o) => o?.id === 63)?.Option;
      setVicesData(answers?.find((o) => o?.id === 63)?.Option);

      setSelectedChips(
        answers
          ?.find((o) => o?.id === 63)
          ?.Option?.filter((item) => item?.selected === 1)
      );

      const drenkingResult = answers
        ?.find((o) => o?.id === 63)
        ?.Option?.filter((item) => item?.selected === 1)
        ?.find((obj) => [113, 114, 115, 116].includes(obj?.id));

      const smokingResult = answers
        ?.find((o) => o?.id === 63)
        ?.Option?.filter((item) => item?.selected === 1)
        ?.find((obj) => [117, 118, 119, 120].includes(obj?.id));

      const marijuanaResult = answers
        ?.find((o) => o?.id === 63)
        ?.Option?.filter((item) => item?.selected === 1)
        ?.find((obj) => [121, 122, 123, 124].includes(obj?.id));

      const LFResult = answers
        ?.find((o) => o?.id === 63)
        ?.Option?.filter((item) => item?.selected === 1)
        ?.find((obj) => [125, 126, 127, 128].includes(obj?.id));

      setDrinkingChips([drenkingResult]);
      setSmokingChips([smokingResult]);
      setMarijuanaChips([marijuanaResult]);
      setLFChips([LFResult]);

      setIsPrivate({
        is_must_drink: options?.[0]?.isMust === 1 ? true : false,
        is_must_smoking: options?.[4]?.isMust === 1 ? true : false,
        is_must_marijuana: options?.[8]?.isMust === 1 ? true : false,
        is_must_LF: options?.[12]?.isMust === 1 ? true : false,
      });
    }
  }, [answers]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip, ...selectedChips]);
    } else {
      setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
    }

    if (
      chip?.id === 113 ||
      chip?.id === 114 ||
      chip?.id === 115 ||
      chip?.id === 116
    ) {
      if (!drinkingChips?.includes(chip)) {
        setDrinkingChips([chip]);
      }
    }
    if (
      chip?.id === 117 ||
      chip?.id === 118 ||
      chip?.id === 119 ||
      chip?.id === 120
    ) {
      if (!smokingChips?.includes(chip)) {
        setSmokingChips([chip]);
      }
    }
    if (
      chip?.id === 121 ||
      chip?.id === 122 ||
      chip?.id === 123 ||
      chip?.id === 124
    ) {
      if (!marijuanaChips?.includes(chip)) {
        setMarijuanaChips([chip]);
      }
    }
    if (
      chip?.id === 125 ||
      chip?.id === 126 ||
      chip?.id === 127 ||
      chip?.id === 128
    ) {
      if (!LFChips?.includes(chip)) {
        setLFChips([chip]);
      }
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    vicesData?.slice(0, 4)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: drinkingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
      });
      return true;
    });
    vicesData?.slice(4, 8)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
      });
      return true;
    });
    vicesData?.slice(8, 12)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
      });
      return true;
    });
    vicesData?.slice(12, 16)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: LFChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
      });
      return true;
    });
    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveInfo();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const saveInfo = async () => {
    var opt = [];
    vicesData?.slice(0, 4)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: drinkingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
        isMust: isPrivate?.is_must_drink ? true : false,
      });
      return true;
    });
    vicesData?.slice(4, 8)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
        isMust: isPrivate?.is_must_smoking ? true : false,
      });
      return true;
    });
    vicesData?.slice(8, 12)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
        isMust: isPrivate?.is_must_marijuana ? true : false,
      });
      return true;
    });
    vicesData?.slice(12, 16)?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: LFChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
        isMust: isPrivate?.is_must_LF ? true : false,
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };

    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Box className=''>
        {/* <Typography variant='h6' className='s-font-20 color-pink mt-2 mb-8'>
          {localesData?.profile?.WHAT_ARE_YOU_LOOKING_FOR}
        </Typography> */}
        <Box className='chip-container-box'>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
          <Typography variant='h3' className='mb-5 t-center s-bold'>
            {localesData?.profile?.VICES}
          </Typography>
        </Box>
        <Box className='looking-for-chip'>
          <Box className=''>
            <Typography
              sx={{ textAlign: 'left' }}
              variant='h4'
              className='mb-2'
            >
              {localesData?.profile?.DRINKING}
            </Typography>

            <Box
              sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
              className='chip-container  pb-4'
            >
              {/* <Box
            sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
            className='chip-container pb-4'
          > */}
              {vicesData?.slice(0, 4)?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    drinkingChips?.map((obj) => obj?.id)?.includes(item?.id)
                      ? 'chip-active'
                      : ''
                  } `}
                  style={{ width: '100%' }}
                  key={i}
                  label={item?.title?.replace('Drinking-', '')}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            </Box>
            <Box className='d-flex pb-4 justify-center'>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='is_must_drink'
                  checked={isPrivate?.is_must_drink}
                  onChange={(e) =>
                    setIsPrivate({
                      ...isPrivate,
                      is_must_drink: e.target.checked,
                    })
                  }
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>

            <Typography
              sx={{ textAlign: 'left' }}
              variant='h4'
              className='mb-2'
            >
              {localesData?.profile?.SMOKING}
            </Typography>
            <Box
              sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
              className='chip-container pb-4'
            >
              {vicesData?.slice(4, 8)?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    smokingChips?.map((obj) => obj?.id)?.includes(item?.id)
                      ? 'chip-active'
                      : ''
                  } `}
                  style={{ width: '100%' }}
                  key={i}
                  label={item?.title?.replace('Smoking-', '')}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            </Box>
            <Box className='d-flex pb-4 justify-center'>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='is_must_smoking'
                  checked={isPrivate?.is_must_smoking}
                  onChange={(e) =>
                    setIsPrivate({
                      ...isPrivate,
                      is_must_smoking: e.target.checked,
                    })
                  }
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>

            <Typography
              sx={{ textAlign: 'left' }}
              variant='h4'
              className='mb-2'
            >
              {localesData?.profile?.MARIJUANA}
            </Typography>
            <Box
              sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
              className='chip-container pb-4'
            >
              {vicesData?.slice(8, 12)?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    marijuanaChips?.map((obj) => obj?.id)?.includes(item?.id)
                      ? 'chip-active'
                      : ''
                  } `}
                  style={{ width: '100%' }}
                  key={i}
                  label={item?.title?.replace('Marijuana-', '')}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            </Box>
            <Box className='d-flex pb-4 justify-center'>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='is_must_marijuana'
                  checked={isPrivate?.is_must_marijuana}
                  onChange={(e) =>
                    setIsPrivate({
                      ...isPrivate,
                      is_must_marijuana: e.target.checked,
                    })
                  }
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>

            <Typography
              sx={{ textAlign: 'left' }}
              variant='h4'
              className='mb-2'
            >
              {localesData?.profile?.LIFESTYLE_DRUGS}
            </Typography>
            <Box
              sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}
              className='chip-container pb-4'
            >
              {vicesData?.slice(12, 16)?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    LFChips?.map((obj) => obj?.id)?.includes(item?.id)
                      ? 'chip-active'
                      : ''
                  } `}
                  style={{ width: '100%' }}
                  key={i}
                  label={item?.title?.replace('Drugs-', '')}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            </Box>
          </Box>

          <Box className='edit-your-profile-box'>
            <Box className='d-flex pb-4 justify-center'>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='is_must_LF'
                  checked={isPrivate?.is_must_LF}
                  onChange={(e) =>
                    setIsPrivate({
                      ...isPrivate,
                      is_must_LF: e.target.checked,
                    })
                  }
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>
            <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
              <CustomButton
                classname='uppercase'
                variant='contained'
                title={localesData?.DONE}
                background='#59D09D'
                fontWeight='600'
                onClick={handleOnSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutVices;
