import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomButton from '../../UI/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import SubDialogbox from '../../UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from '../../UI/Subscriptionpopup/SubscriptionDialog';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const ProfileCreation = ({ localesData, handleNext }) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);

  return (
    <Box className='t-center pb-6'>
      <Box className='pb-20'>
        <Typography
          variant='h3'
          className='mb-10'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_creation?.PROFILE_DESC_SEC,
          }}
        />
        <Typography
          variant='h4'
          className='pb-3'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_creation?.DESC1,
          }}
        />
        <Typography
          variant='h4'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_creation?.DESC2,
          }}
        />
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.profile_creation?.GET_START}
          background='#6A42ED'
          fontWeight='600'
          onClick={() => handleNext()}
        />
        <CustomButton
          variant='contained'
          title={localesData?.profile_creation?.WINDOW_SHOPPING}
          background='#59D09D'
          fontWeight='600'
          // onClick={() => navigate('/')}
          onClick={() => {
            profileData?.isPaid === false
              ? setToogleDialog(!toogleDialog)
              : navigate('/');
          }}
        />
      </Box>
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => {
          setToogleDialog(!toogleDialog);
          navigate('/');
        }}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default ProfileCreation;
