import React from 'react';
import Page from '../../../../components/Page';
import EditProfile from 'src/views/component/application/ManageProfile/ProfileEditing/EditProfile';

const EditYourProfilePage = () => {
  return (
    <Page title='Edit Your Profile'>
      <EditProfile />
    </Page>
  );
};

export default EditYourProfilePage;
