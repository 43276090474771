import React, { useContext, useEffect, useState } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IntlContext } from 'src/App';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as Payment1 } from 'src/assets/images/icons/payment-icon-1.svg';
import { ReactComponent as Payment2 } from 'src/assets/images/icons/payment-icon-2.svg';
import { ReactComponent as Payment3 } from 'src/assets/images/icons/payment-icon-3.svg';
import CustomButton from 'src/views/component//UI/Button/CustomButton';
import { ReactComponent as Cvvimage } from 'src/assets/images/icons/cvvimage.svg';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import PreLoader from 'src/components/Loader';
import { setApiMessage } from 'src/helpers/commonFunctions';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from 'src/views/component/UI/Subscriptionpopup/SubscriptionDialog';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import './subscription.scss';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import SubscriptionAlert from 'src/views/component/UI/Subscriptionpopup/SubscriptionAlert';

const SubscriptionPlan = () => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const navigate = useNavigate();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [toggleDialog, setToggleDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cardData, setCardData] = useState('');
  const [cardUpdate, setCardUpdate] = useState({
    card_holder_name: '',
    card_token: '',
    card_exp: '',
    cvv: '',
  });
  const [subToggle, setSubToggle] = useState(false);
  const [cancelPlanStatus, setCancelPlanStatus] = useState(false);
  // GET CARD DETAILS
  const updateCardDetails = async (fieldName, value) => {
    const cardName =
      fieldName === 'cardname' ? value : cardUpdate?.card_holder_name;
    const cardNumber =
      fieldName === 'cardNumber' ? value : cardUpdate?.card_token;
    const cardExp =
      fieldName === 'expirationDate' ? value : cardUpdate?.card_exp;
    const cardCVV = fieldName === 'cvv' ? value : cardUpdate?.cvv;

    const [month, year] = cardExp?.split('/');

    const centuryPrefix =
      new Date().getFullYear() - (new Date().getFullYear() % 100) + ''; // Get the current century prefix as a string
    const formattedDate = `${centuryPrefix?.slice(0, 2)}${year}-${month}`;

    setCardUpdate({
      ...cardUpdate,
      card_holder_name: cardName,
      card_token: cardNumber,
      card_exp: cardExp,
      cvv: cardCVV,
    });

    const cardDetails = {
      card_holder_name: cardName,
      card_token: cardNumber?.replace(/\s/g, ''),
      card_exp: formattedDate,
      cvv: cardCVV,
    };

    if (cardName && cardNumber && cardExp && cardCVV) {
      const sendData = {
        card: cardDetails,
      };
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.put(
          URLS.UPDATE_CARD_DETAILS,
          sendData
        );
        if (status === 200) {
          if (data?.status) {
            setCardUpdate({
              card_holder_name: '',
              card_token: '',
              card_exp: '',
              cvv: '',
            });
            setApiMessage('success', data?.message);
          } else {
            setApiMessage('error', data?.message);
            setApiMessage('error', data?.message?.raw?.message);
          }
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    }
  };
  // GET CARD DETAILS
  const getCardDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_SUBSCRIBE_PLAN);
      if (status === 200) {
        // if (Object.keys(data?.data).length !== 0) {
        setCardData(Object.keys(data?.data).length !== 0 ? data?.data : '');
        setLoader(false);
        // } else {
        //   navigate('/');
        // }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // CANCEL PLAN
  const handleCancelPlan = async () => {
    if (profileData?.platform === 'web' || profileData?.platform === null) {
      const sendData = {
        planId: cardData?.planId,
      };
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.delete(
          URLS.CANCEL_SUBSCRIBE_PLAN,
          {
            data: sendData,
          }
        );
        if (status === 200) {
          if (data?.status) {
            setApiMessage('success', data?.message);
            navigate('/matching');
            setSubToggle(!subToggle);
          } else {
            setSubToggle(false);
            setCancelPlanStatus(false);
            setApiMessage('error', data?.message);
          }
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    } else {
      setSubToggle(false);
      setCancelPlanStatus(false);
      setApiMessage(
        'error',
        localesData?.subscription_popup?.CANNOT_CANCEL_IN_WEB
      );
    }
  };

  useEffect(() => {
    getCardDetails();
    // eslint-disable-next-line
  }, [location]);

  const isSubscribtion = profileData?.subscription?.status === 'cancel';

  return (
    <Box className='subscription-plan-sec mt-2'>
      {loader && <PreLoader />}
      <Typography className='header-text'>
        {localesData?.subscription_popup?.my_subscription_plan}
      </Typography>
      <Box className='subscription-plan'>
        <Box className='sub-details'>
          <Typography className='sub-header'>Subscription Type</Typography>
          <Typography className='sub-text'>
            {cardData && '$' + cardData?.cost + '/' + cardData?.duration}
          </Typography>
        </Box>
        <Box className='sub-details'>
          <Typography className='sub-header'>Status</Typography>
          <Typography className='sub-text'>
            {cardData && cardData?.status === 'success'
              ? 'Active'
              : cardData?.status}
          </Typography>
        </Box>
        <Box className='sub-details'>
          <Typography className='sub-header'>Renewal Date</Typography>
          <Typography className='sub-text'>
            {cardData && cardData?.renewal_date}
          </Typography>
        </Box>
        <Box className='sub-details'>
          <Typography className='sub-header'>Initial Amount paid</Typography>
          <Typography className='sub-text'>
            {cardData && '$' + cardData?.initial_amount}
          </Typography>
        </Box>
        <Box className='sub-details payment-method'>
          <Typography className='sub-header'>Payment method</Typography>
          <Box className='card-image'>
            <Payment1 />
            <Payment2 />
            <Payment3 />
          </Box>
        </Box>
        <Formik
          initialValues={{
            cardname: cardData ? cardData?.card?.card_holder_name : '',
            cardNumber: cardData?.card
              ? '**** **** **** ' + cardData?.card?.card_number
              : '',
            expirationDate: cardData ? cardData?.card?.card_expiry : '',
            cvv: cardData ? cardData?.card?.cvv : '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            cardname: Yup.string().required(localesData?.validation?.REQUIRED),
            cardNumber: Yup.string()
              .min(19, 'Card number must be at most 16 characters')
              .max(19, 'Card number must be at most 16 characters')
              .required(localesData?.validation?.error_card_number),
            expirationDate: Yup.string()
              .typeError('Not a valid expiration date. Example: MM/YY')
              .max(5, 'Not a valid expiration date. Example: MM/YY')
              .matches(
                /([0-9]{2})\/([0-9]{2})/,
                'Not a valid expiration date. Example: MM/YY'
              )
              .required(localesData?.validation?.error_expiration_date),
            cvv: Yup.string()
              .min(3)
              .max(3)
              .required(localesData?.validation?.error_ccv_number),
          })}
          // onSubmit={async (requestData) => {
          // }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form
              onSubmit={handleSubmit}
              className='form-content pb-6 all-wrap'
            >
              <Box className='card-form'>
                <Box className='mb-2'>
                  <CustomTextField
                    error={Boolean(touched.cardNumber && errors.cardNumber)}
                    helperText={touched.cardNumber && errors.cardNumber}
                    fullWidth
                    variant='standard'
                    className='select-input-field '
                    name='cardNumber'
                    value={values.cardNumber}
                    inputProps={{ maxLength: 19 }}
                    onChange={(e) => {
                      // handleChange(e);
                      setCardUpdate({
                        ...cardUpdate,
                        card_token: e.target.value,
                      });
                      setFieldValue(
                        'cardNumber',
                        e.target.value
                          .replace(/[^\dA-Z*]/g, '')
                          .replace(/(.{4})/g, '$1 ')
                          .trim()
                      );
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9 ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      updateCardDetails(
                        'cardNumber',
                        values?.cardNumber
                          .replace(/[^\dA-Z*]/g, '')
                          .replace(/(.{4})/g, '$1 ')
                          .trim()
                      );
                    }}
                  />
                  <FormHelperText className='custom-field-helper'>
                    {localesData?.subscription_popup?.card_number}
                  </FormHelperText>
                  {/* <Typography
                      variant='h5'
                      className='d-flex text-field-text'
                      dangerouslySetInnerHTML={{
                        __html: localesData?.subscription_popup?.card_number,
                      }}
                    /> */}
                </Box>
                <Box className='mb-2'>
                  <CustomTextField
                    error={Boolean(
                      touched.expirationDate && errors.expirationDate
                    )}
                    helperText={touched.expirationDate && errors.expirationDate}
                    fullWidth
                    variant='standard'
                    className='select-input-field '
                    name='expirationDate'
                    value={values.expirationDate}
                    onChange={(e) => {
                      setFieldValue(
                        'expirationDate',
                        e.target.value
                          .replace(/[^0-9]/g, '') // To allow only numbers
                          .replace(/^([2-9])$/g, '0$1') // To handle 3 > 03
                          .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2') // 13 > 01/3
                          .replace(/^0{1,}/g, '0') // To handle 00 > 0
                          .replace(
                            /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                            '$1/$2'
                          ) // To handle 113 > 11/3
                      );
                      setCardUpdate({
                        ...cardUpdate,
                        card_exp: e.target.value,
                      });
                    }}
                    inputProps={{ maxLength: 5 }}
                    onBlur={(e) => {
                      handleBlur(e);
                      updateCardDetails(
                        'expirationDate',
                        values?.expirationDate
                      );
                    }}
                  />
                  <FormHelperText className='custom-field-helper'>
                    {localesData?.subscription_popup?.expiry_date}
                    <Typography variant='span' className='text-field-text-gray'>
                      {localesData?.subscription_popup?.expiry_date_eg}
                    </Typography>
                  </FormHelperText>
                  {/* <Typography variant='h5' className='custom-field-helper'>
                      {localesData?.subscription_popup?.expiry_date}
                      <Typography
                        variant='span'
                        className='text-field-text-gray'
                      >
                        {localesData?.subscription_popup?.expiry_date_eg}
                      </Typography>
                    </Typography> */}
                </Box>
                <Box className='mb-2'>
                  <CustomTextField
                    error={Boolean(touched.cvv && errors.cvv)}
                    helperText={touched.cvv && errors.cvv}
                    fullWidth
                    variant='standard'
                    className='select-input-field'
                    name='cvv'
                    value={values.cvv}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChange(e);
                      }
                      setCardUpdate({
                        ...cardUpdate,
                        cvv: e.target.value,
                      });
                    }}
                    inputProps={{ maxLength: 3 }}
                    onBlur={(e) => {
                      handleBlur(e);
                      updateCardDetails('cvv', values?.cvv);
                    }}
                  />
                  <Box className='d-flex '>
                    <Typography
                      variant='h5'
                      className='custom-field-helper'
                      dangerouslySetInnerHTML={{
                        __html: localesData?.subscription_popup?.CVV,
                      }}
                    />
                    <Cvvimage className='cvv-icon' />
                  </Box>
                </Box>
                <Box className=''>
                  <CustomTextField
                    error={Boolean(touched.cardname && errors.cardname)}
                    helperText={touched.cardname && errors.cardname}
                    fullWidth
                    variant='standard'
                    className='select-input-field '
                    name='cardname'
                    value={values.cardname}
                    onChange={(e) => {
                      handleChange(e);
                      setCardUpdate({
                        ...cardUpdate,
                        card_holder_name: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      updateCardDetails('cardname', values?.cardname);
                    }}
                  />
                  <FormHelperText className='custom-field-helper'>
                    {localesData?.subscription_popup?.name_on_card}
                  </FormHelperText>
                  {/* <Typography
                      variant='h5'
                      className='d-flex text-field-text'
                      dangerouslySetInnerHTML={{
                        __html: localesData?.subscription_popup?.name_on_card,
                      }}
                    /> */}
                </Box>
              </Box>
              <Box className='subscription-btn-sec'>
                <CustomButton
                  variant='contained'
                  title={localesData?.subscription_popup?.update_my_plan}
                  background='#6A42ED'
                  classname='update-btn custom-btn'
                  // onClick={() => setToggleDialog(!toggleDialog)}
                  onClick={() => {
                    if (profileData?.isPaid && !isSubscribtion) {
                      setCancelPlanStatus(false);
                      setSubToggle(!subToggle);
                    } else {
                      setToggleDialog(!toggleDialog);
                    }
                  }}
                />
                <CustomButton
                  variant='contained'
                  title={localesData?.subscription_popup?.cancel_subscription}
                  background='#FF0000'
                  classname='cancel-btn custom-btn'
                  // onClick={() => handleCancelPlan()}
                  onClick={() => {
                    setCancelPlanStatus(true);
                    setSubToggle(!subToggle);
                  }}
                />
              </Box>
            </form>
          )}
        </Formik>
      </Box>

      <SubDialogbox
        open={toggleDialog}
        handleClose={() => setToggleDialog(!toggleDialog)}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToggleDialog(!toggleDialog)}
            navigate={navigate}
          />
        }
      />
      {/* ALERT MODAL */}
      <DialogBox
        open={subToggle}
        handleClose={() => {
          setSubToggle(!subToggle);
        }}
        title=''
        className='subscription-modal'
        content={
          <SubscriptionAlert
            alertText={
              cancelPlanStatus
                ? localesData?.subscription_popup
                    ?.CANCEL_SUBSCRIPTION_CONFIRMATION
                : localesData?.subscription_popup?.UPDATE_TO_FIRST_CANCEL
            }
            setSubToggle={setSubToggle}
            handleSubPlan={() =>
              !cancelPlanStatus ? setCancelPlanStatus(true) : handleCancelPlan()
            }
            acceptBtn={
              cancelPlanStatus
                ? localesData?.subscription_popup?.CANCEL_SUBSCRIPTION
                : localesData?.subscription_popup?.CANCEL_ACTIVE_SUBSCRIPTION
            }
            declineBtn={localesData?.subscription_popup?.NOT_NOW}
          />
        }
      />
    </Box>
  );
};

export default SubscriptionPlan;
