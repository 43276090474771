import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      dark: "#000000",
      light: "#ffffff",
      gray: "#fafafb",
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      main: "#191919",
    },
    secondary: {
      main: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#a4a4a4",
      white: "#ffffff",
    },
  },
  shadows,
  typography,
});

export default theme;
