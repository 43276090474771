import React, { useContext, useState, useEffect } from 'react';
import { IntlContext } from 'src/App';
import { Box, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './thrownBones.scss';
import { ReactComponent as CollectBones } from 'src/assets/images/icons/collect-bones.svg';
import { ReactComponent as ThrowBoneActive } from 'src/assets/images/icons/throw-bones-active.svg';
import { ReactComponent as CollectBoneActive } from 'src/assets/images/icons/collect-bone-active.svg';
import { ReactComponent as MatchesActive } from 'src/assets/images/icons/kennel-matches-active.svg';
import { ReactComponent as MsgActive } from 'src/assets/images/icons/msg-active.svg';
import { ReactComponent as MsgBone } from 'src/assets/images/icons/msg-bones.svg';
import { ReactComponent as Match } from 'src/assets/images/icons/match-tab.svg';
import { ReactComponent as Bone } from 'src/assets/images/icons/bone-tab.svg';
import ThrownBonesTab from './ThrownBones';
import MessageTab from './Message';
import CollectBoneTab from './CollectBones';
import MatchesTab from './Matches';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useDispatch } from 'react-redux';
import { setCount } from 'src/helpers/store/actions/notificationCount';
import { socket } from 'src/helpers/context/socket';
import { fetchFromStorage } from 'src/helpers/context';
import { useLocation } from 'react-router-dom';

const ThrownBones = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [notificationCount, setNotificationCount] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [selecteTab, setSelectTab] = useState(true);
  const dispatch = useDispatch();
  const storageUserData = fetchFromStorage('userData');
  const location = useLocation();
  const is_mounted = true;
  const [state, setState] = useState({
    messages_data: [],
    massageCount: 0,
    message_id_record: [],
    selected_button: 0,
    clicked_chat_id: '',
  });

  useEffect(() => {
    setActiveTab('1');
  }, []);

  const checkAvailable = (message_id_record, both_id) => {
    return message_id_record.some((item) => item === both_id);
  };

  // listen socket
  const listenSocket = () => {
    if (socket) {
      // alert('done');
      const user_id = storageUserData?.id;
      socket.on('DeleteMessageSocket', (data) => {
        if (data && is_mounted) {
          const compare_id = data.ReceiverID + '_' + user_id;
          const messages_data = [...state.messages_data];
          for (let i = 0; i < messages_data.length; i++) {
            const old_last_message_data = messages_data[i]?.lastMessase;
            if (
              (messages_data[i]?.unique_id_1 === compare_id ||
                messages_data[i]?.unique_id_2 === compare_id) &&
              old_last_message_data?.MessageID === data._id
            ) {
              old_last_message_data.MessageDeleted = 1;
              old_last_message_data.userId = data?.UserID;
              setState({ ...state, messages_data });
            }
          }
        }
      });
      socket.on('listGroup', (data) => {
        // alert('list');

        // setLatestData(data);
        const {
          massageCount,
          message_id_record,
          messages_data,
          selected_button,
          clicked_chat_id,
        } = state;
        let total_count = massageCount;
        let total_records = [...message_id_record];
        if (data && data.user?._id && is_mounted) {
          const sender_receiver_id = data.ReceiverID + '_' + data.UserID;
          if (message_id_record?.length === 0) {
            total_count = massageCount + 1;
            total_records.push(sender_receiver_id);
          } else {
            const status = checkAvailable(
              message_id_record,
              sender_receiver_id
            );
            if (!status) {
              total_count = massageCount + 1;
              total_records.push(sender_receiver_id);
            }
          }

          setState({
            ...state,
            message_id_record: total_records,
            massageCount: total_count,
          });
          if (
            messages_data !== null &&
            messages_data.length !== 0 &&
            selected_button === 3
          ) {
            const compare_id = data.ReceiverID + '_' + user_id;
            for (let i = 0; i < messages_data.length; i++) {
              if (
                messages_data[i]?.unique_id_1 === compare_id ||
                messages_data[i]?.unique_id_2 === compare_id
              ) {
                let old_last_message_data = messages_data[i]?.lastMessase;
                const whole_message =
                  old_last_message_data?.MessageJSON?.message;
                if (whole_message) {
                  const updatedWholeMessage = {
                    ...whole_message,
                    type: data?.type,
                    content: data?.text,
                  };
                  old_last_message_data.MessageJSON.message = updatedWholeMessage;
                }
                old_last_message_data.MessageCreated = data?.createdAt;
                old_last_message_data.MessageDeleted = 0;
                old_last_message_data.MessageID = data?._id;
                old_last_message_data.userId = data?.user?._id;
                if (
                  !old_last_message_data.SeenByDataStatus &&
                  data?.user?._id !== user_id &&
                  clicked_chat_id !== data?.ReceiverID
                ) {
                  old_last_message_data.SeenByDataStatus = true;
                  // Decrease the total count by 1 when a user message is shown
                  setState({
                    ...state,
                    messages_data,
                    massageCount: total_count - 1,
                  });
                } else {
                  setState({ ...state, messages_data });
                }
              }
            }
          }
        }
      });
    }
  };

  const getNotificationCount = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        URLS.NOTIFICATION_COUNT + '/all'
      );
      if (status === 200) {
        setNotificationCount(data);
        const count =
          data?.count?.unreadMessageCount +
          data?.count?.collectedcount +
          data?.count?.matchescount +
          data?.count?.throwncount;

        dispatch(setCount(count));

        // ReadNotification();
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const ReadNotification = async (button) => {
    try {
      let url;

      if (button === 1) {
        url = URLS.NOTIFICATION_READ + '/thrown';
      } else if (button === 2) {
        url = URLS.NOTIFICATION_READ + '/collected';
      } else if (button === 3) {
        url = URLS.NOTIFICATION_READ + '/matches';
      } else if (button === 4) {
        url = URLS.NOTIFICATION_READ + '/matches';
      }

      const { status, data } = await axiosInstance.get(url);

      if (status === 200) {
        setApiMessage('success', data?.message);
        // setNotificationCount(data);
        getNotificationCount();
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getNotificationCount();
    listenSocket();
  }, []);

  useEffect(() => {
    if (location?.state?.iscollected) {
      setActiveTab('2');
    } else if (location?.state?.Matches) {
      setActiveTab('4');
    } else if (location?.state?.Thrown) {
      setActiveTab('1');
    } else if (location?.state?.message) {
      setActiveTab('3');
    }
  }, [location?.state]);

  const tabsData = [
    {
      value: '1',
      label: localesData?.thrown_bones?.THROWN_BONES,
      icon: activeTab === '1' ? <ThrowBoneActive /> : <Bone />,
      count: notificationCount?.count?.throwncount,
    },
    {
      value: '2',
      label: localesData?.thrown_bones?.COLLECTED_BONES,
      icon: activeTab === '2' ? <CollectBoneActive /> : <CollectBones />,
      count: notificationCount?.count?.collectedcount,
    },
    {
      value: '3',
      label: localesData?.thrown_bones?.MESSAGES,
      icon: activeTab === '3' ? <MsgActive /> : <MsgBone />,
      count: notificationCount?.count?.unreadMessageCount,
    },
    {
      value: '4',
      label: localesData?.thrown_bones?.MATCHES,
      icon: activeTab === '4' ? <MatchesActive /> : <Match />,
      count: notificationCount?.count?.matchescount,
    },
  ];

  return (
    <>
      <Box className='kennel-tab-sec'>
        <TabContext
          value={activeTab}
          className='kennel-context-sec d-flex justify-center'
        >
          <Box className='kennel-tab-box'>
            <TabList className='kennel-list-sec'>
              {tabsData?.map((tab, index) => {
                return (
                  <Box className='badge-sec' key={index}>
                    {tab?.count !== 0 && (
                      <Typography
                        variant='span'
                        className={activeTab !== tab?.value ? 'badge' : 'hide'}
                      >
                        {tab?.count > 50 ? '50+' : tab?.count}
                      </Typography>
                    )}
                    <Tab
                      key={index}
                      label={tab?.label}
                      value={tab?.value}
                      className={
                        activeTab === tab?.value
                          ? 'kennel-tab kennel-tab-active '
                          : 'kennel-tab'
                      }
                      iconPosition='start'
                      icon={tab?.icon}
                      onClick={() => {
                        setActiveTab(tab?.value);
                        setSelectTab(!selecteTab);
                        ReadNotification(index + 1);
                      }}
                    />
                  </Box>
                );
              })}
            </TabList>
          </Box>

          <TabPanel value='1' className='kennel-content-sec '>
            <ThrownBonesTab
              activeTab={activeTab}
              localesData={localesData}
              selecteTab={selecteTab}
              onButtonClick={() => ReadNotification(1)}
            />
          </TabPanel>
          <TabPanel value='2' className='kennel-content-sec '>
            <CollectBoneTab
              localesData={localesData}
              activeTab={activeTab}
              selecteTab={selecteTab}
              onButtonClick={() => ReadNotification(2)}
            />
          </TabPanel>
          <TabPanel value='3' className='kennel-content-sec '>
            <MessageTab
              localesData={localesData}
              selecteTab={selecteTab}
              location={location}
              onButtonClick={() => ReadNotification(3)}
            />
          </TabPanel>
          <TabPanel value='4' className='kennel-content-sec '>
            <MatchesTab
              activeTab={activeTab}
              localesData={localesData}
              selecteTab={selecteTab}
              onButtonClick={() => ReadNotification(4)}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default ThrownBones;
