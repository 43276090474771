import React from 'react';
import Page from '../../../components/Page';
import ThrownBones from 'src/views/component/application/KennelThrownBones';

const ThrownBonesView = () => {
  return (
    <Page title='Kennel-Thrown Bones'>
      <ThrownBones />
    </Page>
  );
};

export default ThrownBonesView;
