import React from 'react';
// import { useDropzone } from 'react-dropzone';
import { Avatar, Card, Box, IconButton } from '@mui/material';
import { ReactComponent as PencilIcon } from '../../../../assets/images/pencil.svg';
import './imageUploader.scss';

const UserProfileEditor = ({ src, handlePencilIconClick }) => {
  // const thumb = {
  //   roundedSize: 13,
  // };

  // const [files, setFiles] = useState([]);
  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: {
  //     'image/*': [],
  //   },
  //   onDrop: (acceptedFiles) => {
  //     setFiles(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  // });

  // const thumbs = files.map((file) => (
  //   <div style={thumb} key={file.name} className='dummy-img-sec'>
  //     <div className='dummy-img'>
  //       <img
  //         src={file.preview}
  //         className='circle-img-dummy'
  //         onLoad={() => {
  //           URL.revokeObjectURL(file.preview);
  //         }}
  //         alt='not found'
  //       />
  //     </div>
  //   </div>
  // ));

  // useEffect(() => {
  //   return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  // });

  // const handlePencilIconClick = () => {
  //   // Add your logic here to handle the click event
  //   console.log('Pencil Icon clicked!');
  // };

  return (
    <Box className='img-upload-head-sec'>
      <Box>
        <Box className='img'>
          <Box>
            {/* <Stack direction='row'> */}
            <Avatar
              variant='rounded'
              alt='not found'
              src={src}
              className='img-upload-camera'
            />{' '}
            {/* {thumbs} */}
            {/* </Stack> */}
          </Box>
          <Box
            // {...getRootProps({
            //   className: 'img-upload-sec',
            // })}
            className='img-upload-sec'
          >
            {' '}
            <Card className='img-card-edit-btn'>
              {/* <input {...getInputProps()} /> */}
              <IconButton
                onClick={handlePencilIconClick}
                className='upload-img'
              >
                <PencilIcon />
              </IconButton>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfileEditor;
