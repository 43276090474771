import React from 'react';
import Page from '../../../../components/Page';
import EditYourProfile from 'src/views/component/application/ManageProfile/EditYourProfile';

const EditProfilePage = () => {
  return (
    <Page title='Edit Your Profile'>
      <EditYourProfile />
    </Page>
  );
};

export default EditProfilePage;
