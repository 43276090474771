import { EventEmitter } from 'src/helpers/EventEmitter';
import { Config } from 'src/helpers/context/config';
import OneSignal from 'react-onesignal';

export const initOneSignalNotification = async () => {
  try {
    await OneSignal.init({
      appId: Config?.OnesignalAppID,
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',
    });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserId = async () => {
  try {
    return new Promise((resolve, reject) => {
      OneSignal.getUserId((userId) => {
        resolve(userId);
      });
    });
  } catch (error) {
    console.error('Error in getUserId:', error);
    return null;
  }
};
// export const getUserId = () => {
//   var user_Id;
//   OneSignal.push(function () {
//     user_Id = OneSignal.getUserId(function (userId) {
//       return userId;
//     });
//   });
//   return user_Id;
// };
export function addListenerForNotificationOpened() {
  OneSignal.on('notificationDisplay', function (event) {
    console.log('notificationDisplayevent:', event);
    EventEmitter.dispatch('notificationDisplay', event);
  });

  //   function notifListen() {
  //     OneSignal.push([
  //       'addListenerForNotificationOpened',
  //       (data) => {
  //         console.log('Received NotificationOpened:', data);
  //         EventEmitter.dispatch('handlenotificationredirect', data);
  //         notifListen();
  //       },
  //     ]);
  //   }
  //   notifListen();
}

export const isPushNotificationsEnabled = async () => {
  try {
    const isEnabled = await new Promise((resolve, reject) => {
      OneSignal.isPushNotificationsEnabled((isEnabled) => {
        resolve(isEnabled);
      });
    });
    return isEnabled;
  } catch (error) {
    console.error('Error in isPushNotificationsEnabled:', error);

    return null;
  }
};
// export function isPushNotificationsEnabled() {
//   var flag;
//   OneSignal.push(function () {
//     flag = OneSignal.isPushNotificationsEnabled(function (isEnabled) {
//       return isEnabled;
//     });
//   });
//   return flag;
// }
