import React from 'react';
import { Box, Dialog, Grow, DialogContent } from '@mui/material';
import { ReactComponent as CloseWhiteIcon } from 'src/assets/images/icons/close-white.svg';
import './mediaDialog.scss';
import ReactPlayer from 'react-player';

const MediaDialogBox = ({ open, handleClose, media_type, media_url }) => {
  return (
    <>
      <Dialog
        className='media-dialog-box'
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}
        scroll={'body'}
      >
        <CloseWhiteIcon className='close pointer' onClick={handleClose} />
        <DialogContent className='media-sub-content'>
          <Box className='media-modal'>
            {media_type === 'video' ? (
              <video src={media_url} alt='' className='media-modal-img' />
            ) : media_type === 'audio' ? (
              <ReactPlayer
                className='react-player-audio'
                url={media_url}
                playing={false}
                width='100%'
                height='50%'
                controls={true}
                config={{
                  file: { attributes: { controlsList: 'nodownload' } },
                }}
              />
            ) : (
              <img src={media_url} alt='' className='media-modal-img' />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MediaDialogBox;
