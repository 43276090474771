import React, { useState, useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { ReactComponent as CameraIcon } from '../../../../assets/images/icons/camera.svg';
import { ReactComponent as PhotoVideoIcon } from '../../../../assets/images/icons/photo-video.svg';
import { ReactComponent as InstagramIcon } from '../../../../assets/images/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/images/icons/facebook-pink.svg';
import DropZone from '../../UI/DropZone';
import './styles.scss';

import SocialMediaImages from 'src/views/component/application/ProfileCompletion/SocialMediaImages';
import Gallery from 'src/views/component/UI/DogGallery';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import DialogBox from '../PremiumPopup/PremiumDialogBox';
import CropImage from '../CropImage';

// var uploadOption = [
// 	{ id: 1, name: "Camera" },
// 	{ id: 2, name: "Photo & Video Library" },
// ];
var allUploadOption = [
  // { id: 1, name: 'Camera', icon: <CameraIcon /> },
  { id: 2, name: 'Photo & Video Library', icon: <PhotoVideoIcon /> },
  // { id: 3, name: "Instagram", icon: <InstagramIcon /> },
  // { id: 4, name: "Facebook", icon: <FacebookIcon /> },
];

function ImageUploadOptions({
  onClose,
  handleFileUpload,
  localesData,
  getSelectedMedia,
  openCamera,
  accept,
  fromChat,
}) {
  const [open, setOpen] = useState(false);
  const [mediaArray, setMediaArray] = useState([]);
  const [socialType, setSociaType] = useState('');
  const [cameraExists, setCameraExists] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);
  // const [cropFile, setCropFile] = useState('');
  const [MultipleFile, setMultipleFile] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videoFile, setVideoFile] = useState([]);
  const handleOnDrop = (file) => {
    if (fromChat) {
      handleFileUpload(file);
    } else {
      // if (file?.length !== 0) {
      //   setCropOpen(true);
      //   const files = [];
      //   for (let i = 0; i < file.length; i++) {
      //     files[i] = Object.assign(file?.[i], {
      //       preview: URL.createObjectURL(file?.[i]),
      //     });
      //   }
      //   setMultipleFile(files);
      //   setCurrentFile(
      //     Object.assign(file?.[0], {
      //       preview: URL.createObjectURL(file?.[0]),
      //     })
      //   );
      // }

      const videoFiles = file.filter((f) => f.type.startsWith('video/'));
      const nonVideoFiles = file.filter((f) => !f.type.startsWith('video/'));
      setVideoFile(videoFiles);

      if (nonVideoFiles.length > 0) {
        setCropOpen(true);
        const files = [];
        for (let i = 0; i < nonVideoFiles.length; i++) {
          files[i] = Object.assign(nonVideoFiles[i], {
            preview: URL.createObjectURL(nonVideoFiles[i]),
          });
        }
        setMultipleFile(files);
        setCurrentFile(
          Object.assign(nonVideoFiles[0], {
            preview: URL.createObjectURL(nonVideoFiles[0]),
          })
        );
      }
    }

    // handleFileUpload(file);
  };
  useEffect(() => {
    if (currentIndex !== 0) {
      setCropOpen(true);
    }
  }, [currentIndex]);

  useEffect(() => {
    checkCamera();
  }, []);

  const checkCamera = async () => {
    try {
      const mediaDevices =
        navigator.mediaDevices || navigator.webkitMediaDevices;
      const devices = await mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === 'videoinput');
      // setCameraExists(cameras.length > 0);
      cameras.length > 0 ? setCameraExists(true) : setCameraExists(false);
    } catch (error) {
      console.error('Error checking camera:', error);
    }
  };

  const handleCropclose = () => {
    setCropOpen(false);
    getCropData(MultipleFile);
    // setCropFile('');
  };

  const getCropData = async (files) => {
    // var baseText = cropper.getCroppedCanvas().toDataURL();
    // const blob = await (await fetch(baseText)).blob();
    // const files = new File([blob], cropFile?.preview, {
    //   type: cropFile?.type,
    //   lastModified: new Date(),
    // });
    // const formData = new FormData();
    // for (let i = 0; i < MultipleFile.length; i++) {
    //   formData.append('data', files[i]);
    // }

    // const config = {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // };
    // setLoader(true);
    const newData = [...videoFile, ...files];
    // newData.push(files);
    handleFileUpload(newData);
    setCropOpen(false);
    onClose();

    // try {
    //   const { status, data } = await axiosInstance.post(
    //     URLS.fileUpload,
    //     formData
    //     // config
    //   );
    //   if (status === 200) {
    //     setApiMessage('success', data?.message);
    //     // setCommentImageName({
    //     //   ['commentImage-' + singleObj?._id]: data?.data?.name,
    //     // });
    //     // setCommentImagePreview({
    //     //   ['commentImage-' + singleObj?._id]: data?.data?.link,
    //     // });
    //     setLoader(false);
    //     handleCropclose();
    //   }
    // } catch (err) {
    //   setLoader(false);
    // }
  };

  const socialImagesCrop = async (images) => {
    try {
      const fetchedFiles = await Promise.all(
        images?.map(async (image) => {
          const response = await fetch(image?.media_url);
          const blob = await response.blob();
          return new File([blob], 'image.jpg', { type: 'image/jpeg' });
        })
      );
      setCropOpen(true);
      const files = [];
      for (let i = 0; i < fetchedFiles.length; i++) {
        files[i] = Object.assign(fetchedFiles?.[i], {
          preview: URL.createObjectURL(fetchedFiles?.[i]),
        });
      }
      setMultipleFile(files);
      setCurrentFile(
        Object.assign(fetchedFiles?.[0], {
          preview: URL.createObjectURL(fetchedFiles?.[0]),
        })
      );
    } catch (error) {
      console.error('Error fetching files:', error);
    }
    // getSelectedMedia(images)
  };
  return (
    <>
      {/* <ClickAwayListener onClickAway={() => onClose()}> */}
      <Grid item xs={12} md={6} className='upload-options pointer'>
        <List>
          {cameraExists && (
            <ListItem>
              <ListItemIcon>
                <CameraIcon />
              </ListItemIcon>
              <ListItemText primary='Camera' onClick={openCamera} />
            </ListItem>
          )}
          {allUploadOption?.map((item, index) => {
            return (
              <DropZone
                key={index}
                text={item?.name}
                icon={item?.icon}
                accept={accept}
                // label={item?.name}
                multiple
                onDrop={handleOnDrop}
              />
            );
          })}
          {fetchFromStorage(identifiers?.instagramImages) !== null &&
            fetchFromStorage(identifiers?.instagramImages) !== undefined && (
              <ListItem
                onClick={() => {
                  setMediaArray(fetchFromStorage(identifiers?.instagramImages));
                  setOpen(true);
                  setSociaType('instagram');
                }}
              >
                <ListItemIcon>
                  <InstagramIcon />
                </ListItemIcon>
                <ListItemText primary={localesData?.INSTAGRAM} />

                {/* <DropZone
									text="Instagram"
									//  accept,
									// label={item?.name}
									multiple
									onDrop={handleOnDrop}
								/> */}
              </ListItem>
            )}
          {fetchFromStorage(identifiers?.facebookImages) !== null &&
            fetchFromStorage(identifiers?.facebookImages) !== undefined && (
              <ListItem
                onClick={() => {
                  setMediaArray(fetchFromStorage(identifiers?.facebookImages));
                  setOpen(true);
                  setSociaType('facebook');
                }}
              >
                <ListItemIcon>
                  <FacebookIcon />{' '}
                </ListItemIcon>
                <ListItemText primary={localesData?.FACEBOOK} />

                {/* <DropZone
									text="Facebook"
									//  accept,
									// label={item?.name}
									multiple
									onDrop={handleOnDrop}
								/> */}
              </ListItem>
            )}
          {/* <InstagramLogin
						clientId="382889262779980"
						buttonText="Login"
						onSuccess={responseInstagram}
						onFailure={responseInstagram}
						// redirectUri="https://puppilovers.com/login"
						redirectUri="https://f159-2405-201-2033-c009-3dea-1549-adcc-d9a2.ngrok-free.app/profile-completion"
						scope={["user_profile", "user_media"]}
					/> */}
        </List>
        {/* <ListItem> */}
        <Button onClick={() => onClose()} className='cancel-btn'>
          {localesData?.profile?.CANCEL_BTN}{' '}
        </Button>
        {/* </ListItem> */}
      </Grid>
      {/* <Button onClick={() => onClose()}>
				{localesData?.profile?.CANCEL_BTN}{" "}
			</Button> */}
      {/* </ClickAwayListener> */}
      <Gallery
        handleClose={() => setOpen(!open)}
        open={open}
        title={
          socialType === 'facebook'
            ? localesData?.FACEBOOK
            : socialType === 'instagram'
            ? localesData?.INSTAGRAM
            : ''
        }
        content={
          <SocialMediaImages
            getSelectedMedia={socialImagesCrop}
            mediaArray={mediaArray}
            handleClose={() => setOpen(!open)}
          />
        }
      />

      <DialogBox
        handleClose={() => handleCropclose()}
        open={cropOpen}
        title=''
        className='crop-dialog-box'
        content={
          <>
            <CropImage
              localesData={localesData}
              // file={cropFile}
              MultipleFile={MultipleFile}
              getCropData={getCropData}
              handleCropclose={handleCropclose}
              currentFile={currentFile}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              setCurrentFile={setCurrentFile}
            />
          </>
        }
      />
    </>
  );
}

export default ImageUploadOptions;
