import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import { useNavigate } from 'react-router-dom';
import JobSelectedYes from './JobSelectedYes';
import UpgradePlan from '../UpgradePlan';
import '../../ProfileCompletionAdvance/UserWork/userWork.scss';

const JobSelected = ({
  handleNext,
  questions,
  setLoader,
  dispatch,
  answars,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [yesNo, setYesNo] = useState(false);
  const [yesNoValue, setYesNoValue] = useState('');
  const [yesBtnColor, setYesBtnColor] = useState('#FFFFFF');
  const [noBtnColor, setNoBtnColor] = useState('#FFFFFF');
  const [empData, setEmpData] = useState('');

  const [empLivingData, setEmpLivingData] = useState('');
  const [doYouWork, setDoYouWork] = useState('');
  const [doYouWorkThere, setDoYouWorkThere] = useState('');

  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');

  // let test = questions
  //   ?.find((o) => o?.id === 2)
  //   ?.Question?.find((item) => {
  //     return item?.q_order === 6;
  //   });
  // setEmpData({
  //   title: test?.title,
  //   id: test?.id,
  //   options: test?.Option,
  // });
  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 6) {
  //         setEmpData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //         });
  //       }
  //       if (item.q_order === 7) {
  //         setEmpLivingData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 8) {
  //         setDoYouWork({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 9) {
  //         setDoYouWorkThere({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       return true;
  //     });
  // }, [questions]);

  // useEffect(() => {
  //   const question = questions?.find((o) => o?.id === 2);

  useEffect(() => {
    const handleQuestionItem = (item, setData) => {
      setData({
        title: item.title,
        id: item.id,
        options: item.Option,
        private: item?.private,
      });
      // setYear(item?.Option?.[0].answer);
      // setMonth(item?.Option?.[0].answer);
      if (item.id === 12) {
        const answerYearMonth =
          item?.Option[0]?.answer && item?.Option[0]?.answer?.split(' ');
        if (answerYearMonth?.length >= 4) {
          const year = answerYearMonth[0] + ' ' + answerYearMonth[1];
          const month = answerYearMonth[2] + ' ' + answerYearMonth[3];
          setYear(year);
          setMonth(month);
        } else {
          setYear('');
          setMonth('');
        }
      }
    };

    let yesNoval = answars?.find((o) => o?.id === 9);

    setYesNoValue(
      yesNoval?.Option && yesNoval?.Option?.[0]?.answer === '1'
        ? 'yes'
        : yesNoval?.Option?.[0]?.answer === '0'
        ? 'no'
        : ''
    );
    answars?.forEach((item) => {
      switch (item.id) {
        case 9:
          handleQuestionItem(item, setEmpData);
          break;
        case 10:
          handleQuestionItem(item, setEmpLivingData);
          break;
        case 11:
          handleQuestionItem(item, setDoYouWork);
          break;
        case 12:
          handleQuestionItem(item, setDoYouWorkThere);
          break;
        default:
          break;
      }
    });
  }, [answars]);
  useEffect(() => {
    // setYesNoValue(
    //   empData && empData?.options?.[0]?.answer === '1'
    //     ? 'yes'
    //     : empData?.options?.[0]?.answer === '0'
    //     ? 'no'
    //     : ''
    // );
    setYesNo(empData && empData?.options?.[0]?.answer === '1');
    setNoBtnColor(
      empData?.options?.[0]?.answer === '0' ? '#F6D6C9' : '#FFFFFF'
    );
    setYesBtnColor(
      empData && empData?.options?.[0]?.answer === '1' ? '#F6D6C9' : '#FFFFFF'
    );
  }, [empData]);

  const redirectrulespageNo = () => {
    setYesNoValue('no');
    setYesNo(false);
    setNoBtnColor('#F6D6C9');
    setYesBtnColor('#FFFFFF');
  };
  const redirectrulespageYes = () => {
    setYesNoValue('yes');
    setYesNo(true);
    setYesBtnColor('#F6D6C9');
    setNoBtnColor('#FFFFFF');
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'empLivingData') {
      setEmpLivingData({
        ...empLivingData,
        options: [
          {
            ...empLivingData.options[0],
            answer: value,
          },
          ...empLivingData.options.slice(1),
        ],
      });
    }
    if (name === 'empLivingDataSwitch') {
      setEmpLivingData({ ...empLivingData, private: checked });
    }
    if (name === 'doYouWork') {
      setDoYouWork({
        ...doYouWork,
        options: [
          {
            ...doYouWork.options[0],
            answer: value,
          },
          ...doYouWork.options.slice(1),
        ],
      });
    }
    if (name === 'doYouWorkSwitch') {
      setDoYouWork({ ...doYouWork, private: checked });
    }
    if (name === 'year') {
      setYear(value);
    }
    if (name === 'month') {
      setMonth(value);
    }

    if (name === 'doYouWorkThereSwitch') {
      setDoYouWorkThere({ ...doYouWorkThere, private: checked });
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];
    opt.push({
      optionId: empData?.options[0]?.id,
      answer: yesNoValue === 'yes' ? '1' : '0',
      selected: yesNoValue === 'yes' ? true : false,
      private: false,
    });
    opt.push({
      optionId: empData?.options[1]?.id,
      answer: yesNoValue === 'no' ? '1' : '0',
      selected: yesNoValue === 'no' ? true : false,
      private: false,
    });

    // if (yesNo) {
    opt.push({
      optionId: empLivingData?.options?.[0]?.id,
      answer:
        yesNoValue === 'yes'
          ? empLivingData?.options?.[0]?.answer?.trim() === ''
            ? null
            : empLivingData?.options?.[0]?.answer
          : yesNoValue === 'no'
          ? ''
          : empLivingData?.options?.[0]?.answer,
      selected: false,
      private: empLivingData?.private ? empLivingData?.private : false,
    });
    opt.push({
      optionId: doYouWork?.options?.[0]?.id,
      answer:
        yesNoValue === 'yes'
          ? doYouWork?.options?.[0]?.answer?.trim() === ''
            ? null
            : doYouWork?.options?.[0]?.answer
          : yesNoValue === 'no'
          ? ''
          : doYouWork?.options?.[0]?.answer,
      selected: false,
      private: doYouWork?.private ? doYouWork?.private : false,
    });
    opt.push({
      optionId: doYouWorkThere?.options?.[0]?.id,
      // answer: year && month ? year + ' ' + month : null,
      answer:
        yesNoValue === 'yes'
          ? !year && !month
            ? null
            : year + ' ' + month
          : yesNoValue === 'no'
          ? ''
          : year + ' ' + month,
      selected: false,
      private: doYouWorkThere?.private ? doYouWorkThere?.private : false,
    });
    // }

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <UpgradePlan title={localesData?.profile?.ABOUT_YOUR_JOB} />
      <Box className='t-center pb-6'>
        <Box className='pb-6'>
          <Divider className='divider' />
        </Box>
        <Typography variant='h3' className='s-bold pb-4'>
          {empData?.title}
        </Typography>

        <Box className='d-flex yes-no-sec'>
          <CustomButton
            variant='contained'
            title={empData?.options?.[0]?.title}
            background={yesBtnColor}
            fontWeight='400'
            type='submit'
            color='#000000'
            classname='capitalize yes-no-btn'
            onClick={redirectrulespageYes}
          />
          <CustomButton
            variant='contained'
            title={empData?.options?.[1]?.title}
            background={noBtnColor}
            fontWeight='400'
            type='submit'
            color='#000000'
            classname='capitalize yes-no-btn'
            onClick={redirectrulespageNo}
          />
        </Box>
        {yesNo && (
          <JobSelectedYes
            questions={questions}
            handleChange={handleChange}
            empLivingData={empLivingData}
            doYouWork={doYouWork}
            doYouWorkThere={doYouWorkThere}
            yearData={year}
            monthData={month}
          />
        )}
        <Box className={!yesNo ? 'keep-going-sec ' : ''}>
          <Box className='done-btn-sec'>
            <CustomButton
              variant='contained'
              title={localesData?.DONE}
              background={'#59D09D'}
              fontWeight='600'
              classname='done-btn s-bold'
              // onClick={handleNext}
              onClick={() => handleOnSubmit()}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default JobSelected;
