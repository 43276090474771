import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Refresh } from 'src/assets/images/swipe-refresh-icon.svg';

import DummyImg from 'src/assets/images/logo-black.png';
import DogCard from '../DogCard';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import PreLoader from 'src/components/Loader';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import '../thrownBones.scss';

const ThrownBonesTab = ({ activeTab, localesData, selecteTab }) => {
  const [loader, setLoader] = useState();
  const [selectedBox, setSelectedBox] = useState(null);
  const [thrownBonesData, setthrownBonesData] = useState([]);

  const [startX, setStartX] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isId, setIsID] = useState(false);
  const location = useLocation();

  //THROWNBONES DATA
  const getThrownBonesdata = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.THROWN_BONES);
      if (status === 200) {
        setApiMessage('success', data?.message);
        const allThrownData = data?.users?.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
        );

        setthrownBonesData(allThrownData);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getThrownBonesdata();
  }, [location, selecteTab]);

  const handleBoxClick = (boxId) => {
    setSelectedBox((prevSelectedBox) =>
      prevSelectedBox === boxId ? null : boxId
    );
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e, index) => {
    if (!isSwiping) return;

    const currentX = e.touches[0].clientX;
    const diffX = currentX - startX;
    if (diffX < 0) {
      setTranslateX(diffX);
      setIsID(index);
    } else {
      setTranslateX(0);
      setIsSwiping(false);
    }
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsSwiping(true);
  };

  const handleMouseMove = (e, index) => {
    if (!isSwiping) return;

    const currentX = e.clientX;
    const diffX = currentX - startX;
    if (diffX < 0 && diffX >= -130) {
      setTranslateX(diffX);
      setIsID(index);
    } else {
      setTranslateX(0);
      setIsSwiping(false);
    }
  };

  const handleMouseUp = () => {
    if (translateX < -100) {
      setIsSwiping(false);
    } else {
      setTranslateX(0);
    }
  };

  return (
    <>
      {loader && <PreLoader />}
      {thrownBonesData?.length === 0 ? (
        <Box className='kennel-page-head-sec'>
          <Box className='dummy-img t-center'>
            <img
              alt='not found'
              className='no-data-dummy-img mb-4 opacity'
              src={DummyImg}
            />
          </Box>
          <Typography
            variant='h3'
            className='t-center'
            dangerouslySetInnerHTML={{
              __html: localesData?.kennel_tab_no_data_found?.THROWN_BONES_TAB,
            }}
          />
        </Box>
      ) : (
        <Box className='pb-18'>
          {thrownBonesData &&
            thrownBonesData?.length > 0 &&
            thrownBonesData?.map((content, index) => {
              //AGE
              let age = '';
              if (content?.dob !== '' && content?.dob !== null) {
                const d = moment(content?.dob).format('MMM DD, YYYY');
                const msDiff = new Date().getTime() - new Date(d).getTime();
                const daysTill30June2035 = Math.floor(
                  msDiff / (1000 * 60 * 60 * 24)
                );
                age = Math.floor(daysTill30June2035 / 365);
              }

              return (
                <Box key={content}>
                  <Box className='swipe-sec'>
                    {isId === index && isSwiping === true && (
                      <Refresh className='swipe-container' />
                    )}
                  </Box>

                  <DogCard
                    thrownid={content}
                    activeTab={activeTab}
                    content={content}
                    dogs={content?.dogs}
                    selectedBox={selectedBox}
                    handleBoxClick={handleBoxClick}
                    firstImg={content?.images?.[0]?.url}
                    secondImg={content?.dogs?.images?.[0]?.url}
                    star={content?.star}
                    firstDogName={content?.firstname}
                    secondDogName={content?.lastname}
                    dogsName={content?.dogs?.name}
                    deceaseDog={content?.true}
                    firstDogBirthDate={age}
                    secondDogBirthDate={content?.dogs?.age}
                    firstDogHeight={content?.height}
                    firstDogLocationName={content?.location}
                    secondDogGenderIcon={content?.secondDogGenderIcon}
                    secondDogGender={content?.dogs?.gender}
                    secondDogType={content?.dogs?.breed}
                    getThrownBonesdata={getThrownBonesdata}
                  />
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default ThrownBonesTab;
