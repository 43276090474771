import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import './button.scss';

const CustomButton = ({
  variant,
  title,
  onClick,
  leftIcon,
  rightIcon,
  background,
  color,
  fontWeight,
  classname,
  border,
  ...props
}) => {
  return (
    <Button
      className={clsx('custom-button', classname)}
      fullWidth
      variant={variant}
      onClick={onClick}
      sx={{
        background: background,
        color: color,
        fontWeight: fontWeight,
        border: border,
        '&:hover': {
          background: background,
        },
      }}
      {...props}
    >
      {leftIcon && leftIcon}
      <span className='button-text'>{title}</span>
      {rightIcon && rightIcon}
    </Button>
  );
};

export default CustomButton;
