import React, { useEffect } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import differenceInYears from 'date-fns/differenceInYears';
import { MobileDatePicker } from '@mui/x-date-pickers';
import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';
import { fetchFromStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { setSVGFile } from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/dob.svg';
import dayjs from 'dayjs';

const SetGender = ({
  localesData,
  handleNext,
  setLoader,
  dispatch,
  questions,
  getProfile,
}) => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);

  const dobData = questions?.[0]?.Question?.find((o) => o?.q_order === 1);

  const updateUserDetails = async (req) => {
    let sendData = { dob: dayjs(req?.DOB) };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, sendData);
      if (status === 200) {
        getProfile();
        // handleNext();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  const currentDate = dayjs();
  const eighteenYearsAgo = currentDate.subtract(18, 'year');

  return (
    <>
      <Formik
        initialValues={{
          DOB: profileData?.dob ? dayjs(profileData?.dob) : '',
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          DOB: Yup.date()
            .nullable()
            .required(localesData?.validation?.REQUIRED)
            .min(new Date('01/01/1900'), localesData?.validation?.INVALID_DOB)
            .test(
              'DOB',
              localesData?.validation?.INVALID_FUTURE_DOB_DATE,
              function (value) {
                return differenceInYears(new Date(), new Date(value)) >= 13;
              }
            ),
        })}
        onSubmit={async (requestData) => {
          var d = dayjs(requestData?.DOB).format('MMM DD, YYYY');
          var msDiff = new Date().getTime() - new Date(d).getTime();
          var daysTill30June2035 = Math.floor(msDiff / (1000 * 60 * 60 * 24));
          var _age = Math.floor(daysTill30June2035 / 365);

          var opt = [];
          opt.push({
            optionId: 83,
            answer: _age?.toString() + '-' + _age?.toString() + '-false',
            startRange: _age,
            endRange: _age,
          });
          let sendData = {
            private: false,
            optionIds: opt,
          };

          try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
              URLS.SET_ANSWERS,
              sendData
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              updateUserDetails(requestData);
              dispatch(
                addProfileData({
                  key: 'DOB',
                  value: dayjs(requestData?.DOB)?.format('DD/MM/YYYY'),
                })
              );
              setLoader(false);
            }
          } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
          }
        }}
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center '>
              <Typography variant='h3' className='s-bold mb-4'>
                {localesData?.dateofbirth?.AWESOME}{' '}
                {profileData?.firstname && profileData?.firstname + ','}
                {/* {profileData?.lastname}, */}
              </Typography>
              <Typography
                variant='h4'
                className='mb-2'
                dangerouslySetInnerHTML={{
                  __html: localesData?.dateofbirth?.FIND_RIGHT_PEOPLE,
                }}
              />

              <Typography variant='h3' className='s-bold mb-4'>
                {dobData?.title}
              </Typography>
            </Box>
            <Box className='field-content custom-date-picker'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  onBlur={handleBlur}
                  name='DOB'
                  value={values.DOB || null}
                  className='custom-textfield'
                  format='DD/MM/YYYY'
                  maxDate={eighteenYearsAgo}
                  disableFuture
                  onChange={(date) => setFieldValue('DOB', date)}
                  renderInput={(params) => (
                    <CustomTextField variant='standard' fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
              {errors.DOB && (
                <FormHelperText className='color-error'>
                  {errors.DOB}
                </FormHelperText>
              )}

              <FormHelperText className='font-r-13 pb-20'>
                {localesData?.dateofbirth?.DESC}
              </FormHelperText>
            </Box>
            <CustomButton
              classname='uppercase'
              variant='contained'
              title={localesData?.NEXT}
              background='#000000'
              fontWeight='600'
              type='submit'
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default SetGender;
