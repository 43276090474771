import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../App';
import { Box, Typography } from '@mui/material';
import './helpCenter.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentPage from './ContentPage';
import HelpcenterCategory from './helpcenterCategory';
import { useNavigate, useLocation } from 'react-router-dom';
import UpgradePlan from '../ManageProfile/UpgradePlan';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';

const HelpCenter = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const location = useLocation();
  const [helpcenter, setHelpCenter] = useState([]);
  const [loader, setLoader] = useState(false);

  const getHelpCenter = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(
        URLS.HELP_CENTRE_CATEGORIES
      );

      if (status === 200) {
        const catData = data?.data?.map((item) => {
          return { cat_title: item?.category, articles: item?.HelpCentres };
        });
        // const catFinalData = catData?.filter((catitem) => {
        //   return catitem?.articles?.length > 0;
        // });
        setHelpCenter(catData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getHelpCenter();
  }, []);

  return (
    <>
      {loader ? (
        <PreLoader />
      ) : (
        <Box className='help-center'>
          {location.pathname === '/help-center/category' ? (
            <HelpcenterCategory localesData={localesData} />
          ) : location.pathname === '/help-center/category/details' ? (
            <ContentPage localesData={localesData} />
          ) : (
            <>
              <Box className='help-center-upgrade-btn'>
                <UpgradePlan
                  title={localesData?.help_center?.HELP_CENTER}
                  localesData={localesData}
                />
              </Box>
              <Box className='accordian-box-sec'>
                {helpcenter && helpcenter?.length > 0 ? (
                  helpcenter.map((content, id) => (
                    <Box className='content-head-sec' key={id}>
                      <Box className='content-sec'>
                        <Box
                          onClick={() => {
                            navigate('/help-center/category', {
                              state: content,
                            });
                          }}
                          className='tab-sec space-between'
                        >
                          <Box>
                            <Typography className='subject-title'>
                              {content.cat_title}
                            </Typography>
                            {content?.description && (
                              <Typography className='font-r-13'>
                                {content.description}
                              </Typography>
                            )}
                          </Box>
                          <Box className='content-errow'>
                            <ChevronRightIcon />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography className='pb-2'>
                    No help centers are available for now. We will get back to
                    you soon.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default HelpCenter;
