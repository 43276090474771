import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { IntlContext } from 'src/App';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { ReactComponent as PinkDogIcon } from 'src/assets/images/icons/pink-dog-icon.svg';
import PreLoader from 'src/components/Loader';
import DogImageSlider from '../../Home/Matching/DogImageSlider';
import InfoAboutUs from '../../Home/Matching/InfoAboutUs/InfoAboutUs';
import MatchingVideo from '../../Home/Matching/MatchingVideo';
import MatchingContent from '../../Home/Matching/MatchingContent';
import MatchingImage from '../../Home/Matching/MatchingImage';
import BackToTop from '../../Home/BackToTop';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import MatchToSend from '../../Home/Matching/MatchToSend';
import '../../Home/Matching/matching.scss';
import { useNavigate, useParams } from 'react-router-dom';
import InfoKennel from '../info';

const SelectedKennlMatches = () => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState('');
  const [firstDogsData, setFirstDogsData] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);
  const [currentUserID, setCurrentUserID] = useState('');
  const [allUserID, setAllUserID] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [firstApperUserID, setFirstApperUserID] = useState('');
  const [dogMustFilter] = useState([]);

  const pageTopRef = useRef(null);
  const { id } = useParams();

  const scrollToTop = () => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getMatchingDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        const userIds = data?.users;
        if (userIds?.length !== 0) {
          getUserDetailsNow(userIds?.[0], false);
          setAllUserID(userIds);
          setFirstApperUserID(userIds?.[0]);
        } else {
          setLoader(false);
        }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkDogFilter = (mainDogData) => {
    if (
      dogMustFilter &&
      dogMustFilter !== null &&
      dogMustFilter.length !== 0 &&
      dogMustFilter.includes(mainDogData?.mainDog?.breed)
    ) {
      return true;
    } else if (
      !dogMustFilter ||
      dogMustFilter === undefined ||
      dogMustFilter === null ||
      dogMustFilter.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getUserDetailsNow = async (current_user_id, isScroll) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.GET_PROFILE + '/' + id
      );
      if (status === 200) {
        let filter_status = checkDogFilter(data?.userDetails);
        if (filter_status) {
          setUserData(data?.userDetails);
          setFirstDogsData(data?.userDetails?.dogs?.[0]);
          setCurrentUserID(current_user_id);
          setLoader(false);

          if (isScroll) {
            scrollToTop();
            if (firstApperUserID === currentUserID) {
              setToogleDialog(!toogleDialog);
            }
          }
        } else {
          removeUserAndCallApi();
        }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getMatchingDetails();
  }, []);

  const onPawIconClick = async () => {
    if (profileData?.isPaid === false) {
      setToogleDialog(true);
    } else {
      sendBoneAPIForFreeUsers();
    }
  };
  const sendBoneAPIForFreeUsers = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.SEND_BONE + '/' + currentUserID
      );
      if (status === 200) {
        setLoader(false);
        if (!showAnimation) {
          setShowAnimation(true);

          setTimeout(() => {
            let all_user_id = allUserID;
            if (all_user_id?.length === 1) {
              setCurrentUserID('');
              setLoader(false);
              setAllUserID([]);
              setShowAnimation(false);
            } else {
              setShowAnimation(false);
              removeUserAndCallApi();
            }
          }, 2000);
        }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const removeUserAndCallApi = async () => {
    let all_user_id = allUserID;
    const updatedUserAfterRemoveID = all_user_id?.filter(
      (item) => item !== currentUserID
    );
    if (updatedUserAfterRemoveID.length !== 0) {
      setAllUserID(updatedUserAfterRemoveID);

      fetchNextUserData(updatedUserAfterRemoveID);
      if (firstApperUserID === currentUserID) {
        setFirstApperUserID(updatedUserAfterRemoveID[0]);
      }
    } else {
      currentUserID('');
      setAllUserID([]);
      setLoader(false);
    }
  };

  const fetchNextUserData = async (all_user_id) => {
    if (all_user_id.length !== 0) {
      getUserDetailsNow(all_user_id[0], false);
    } else {
      setLoader(false);
    }
  };

  return (
    <Box className=' collect-bones' ref={pageTopRef}>
      {loader && <PreLoader />}
      {userData === '' ? (
        ''
      ) : (
        <Box className='match-bg'>
          <>
            {/* ONWER/DOG/FAV ICON */}
            {userData && (
              <Box className='mt-2 mb-2 d-flex space-between align-center'>
                {firstDogsData ? (
                  <Typography variant='h6' className='color-pink'>
                    {userData?.firstname} <PinkDogIcon /> {firstDogsData?.name}
                  </Typography>
                ) : (
                  <Typography variant='h6' className='color-pink'>
                    {userData?.firstname}
                  </Typography>
                )}
                {/* <Box>
                  <FavSelectedIcon
                    className='pointer'
                    onClick={() => {
                      if (
                        userData?.isLike === 1 ||
                        userData?.isLike === '1' ||
                        userData?.isLike === true
                      ) {
                        removeFavouriteAPI(userData?.fevouriteId);
                      } else {
                        makeFavouriteAPI(userData?.id);
                      }
                    }}
                  />
                </Box> */}
              </Box>
            )}
            {!firstDogsData?.video && userData?.dogs?.[0]?.images ? (
              <MatchingImage
                userData={userData?.dogs?.[0]}
                imgIndex={0}
                onPawIconClick={onPawIconClick}
              />
            ) : null}
            {/* DOG VIDEO */}
            {firstDogsData?.video ? (
              <MatchingVideo
                videoLink={firstDogsData?.video}
                onPawIconClick={onPawIconClick}
              />
            ) : null}
            {/* ONWER/DOG INFO */}
            {userData && <InfoKennel userData={userData} />}
            {/* 1st ONWER PIC */}
            {userData &&
              userData?.images?.[0] !== '' &&
              userData?.images?.[0] !== undefined && (
                <MatchingImage
                  userData={userData}
                  imgIndex={0}
                  onPawIconClick={onPawIconClick}
                />
              )}
            {/* 1st DOG */}
            {userData &&
              userData?.dogs?.[0] !== '' &&
              userData?.dogs?.[0] !== undefined && (
                <DogImageSlider dogsData={userData?.dogs?.[0]} />
              )}
            {/* 2nd ONWER PIC */}
            {userData && userData?.images?.[1] && (
              <MatchingImage
                userData={userData}
                imgIndex={1}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* JOB DESCRIPTION */}
            {userData && userData?.job_designation !== '' && (
              <MatchingContent
                title={localesData?.matching?.MY_JOB}
                description={
                  'My Job: ' +
                  userData?.job_designation +
                  '<br/>Working at: ' +
                  userData?.job_place +
                  '<br/>Worked there for: ' +
                  userData?.job_since
                }
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* 3rd ONWER PIC */}
            {userData && userData?.images?.[2] && (
              <MatchingImage
                userData={userData}
                imgIndex={2}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* EDUCATION DESCRIPTION */}
            {userData && userData?.education_institute !== '' && (
              <MatchingContent
                title={localesData?.matching?.MY_EDUCATION}
                description={
                  userData?.education_institute +
                  ' in ' +
                  userData?.education_complete_year
                }
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* 4th ONWER PIC */}
            {userData && userData?.images?.[3] && (
              <MatchingImage
                userData={userData}
                imgIndex={3}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* HOBBIES DESCRIPTION */}

            {userData &&
              (userData?.hobbies !== '' ||
                (userData?.manual_hobbies !== '[]' &&
                  userData?.manual_hobbies !== '')) && (
                <MatchingContent
                  title={localesData?.matching?.MY_HOBBIES}
                  description={[
                    ...(userData?.hobbies || []),
                    ...(userData?.manual_hobbies
                      ? JSON.parse(userData.manual_hobbies)?.map(
                          (obj) => obj?.title
                        ) || []
                      : []),
                  ].join(' | ')}
                  onPawIconClick={onPawIconClick}
                />
              )}
            {/* 5th ONWER PIC */}
            {userData && userData?.images?.[4] && (
              <MatchingImage
                userData={userData}
                imgIndex={4}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* 2nd DOG */}
            {userData &&
              userData?.dogs?.[1] !== '' &&
              userData?.dogs?.[1] !== undefined && (
                <DogImageSlider dogsData={userData?.dogs?.[1]} />
              )}
            {/* RELIGION DESCRIPTION */}
            {userData &&
              (userData?.religion !== '' ||
                (userData?.manual_religion !== '[]' &&
                  userData?.manual_religion !== '')) && (
                <MatchingContent
                  title={localesData?.matching?.MY_RELIGION}
                  // description={[
                  //   ...userData?.religion,
                  //   ...JSON.parse(
                  //     userData?.manual_religion
                  //       ? userData?.manual_religion
                  //       : "[]"
                  //   )?.map((obj) => obj?.title),
                  // ]?.join(" | ")}
                  description={[
                    ...(userData?.religion || []),
                    ...(userData?.manual_religion
                      ? JSON.parse(userData.manual_religion)?.map(
                          (obj) => obj?.title
                        ) || []
                      : []),
                  ].join(' | ')}
                  onPawIconClick={onPawIconClick}
                />
              )}
            {/* 6th ONWER PIC */}
            {userData && userData?.images?.[5] && (
              <MatchingImage
                userData={userData}
                imgIndex={5}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* POLITICS DESCRIPTION */}
            {userData &&
              (userData?.politics !== '' ||
                (userData?.manual_politics !== '[]' &&
                  userData?.manual_politics !== '')) && (
                <MatchingContent
                  title={localesData?.matching?.MY_POLITIC}
                  // description={[
                  //   ...userData?.politics,
                  //   ...JSON.parse(
                  //     userData?.manual_politics
                  //       ? userData?.manual_politics
                  //       : "[]"
                  //   )?.map((obj) => obj?.title),
                  // ]?.join(" | ")}
                  description={[
                    ...(userData?.politics || []),
                    ...(userData?.manual_politics
                      ? JSON.parse(userData.manual_politics)?.map(
                          (obj) => obj?.title
                        ) || []
                      : []),
                  ].join(' | ')}
                  onPawIconClick={onPawIconClick}
                />
              )}
            {/* 7th ONWER PIC */}
            {userData && userData?.images?.[6] && (
              <MatchingImage
                userData={userData}
                imgIndex={6}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* KIDS DESCRIPTION */}
            {userData && userData?.kids !== '' && (
              <MatchingContent
                title={localesData?.matching?.MY_KIDS}
                description={userData?.kids}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* 8th ONWER PIC */}
            {userData && userData?.images?.[7] && (
              <MatchingImage
                userData={userData}
                imgIndex={7}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* 3rd DOG */}
            {userData &&
              userData?.dogs?.[2] !== '' &&
              userData?.dogs?.[2] !== undefined && (
                <DogImageSlider dogsData={userData?.dogs?.[2]} />
              )}
            {/* FAMILY BACKGROUND DESCRIPTION */}
            {userData &&
              (userData?.ethnicity !== '' ||
                (userData?.manual_ethnicity !== '[]' &&
                  userData?.manual_ethnicity !== '')) && (
                <MatchingContent
                  title={localesData?.matching?.FAMILY_BACKGROUND}
                  // description={[
                  //   ...userData?.ethnicity,
                  //   ...JSON.parse(
                  //     userData?.manual_ethnicity
                  //       ? userData?.manual_ethnicity
                  //       : "[]"
                  //   )?.map((obj) => obj?.title),
                  // ]?.join(" | ")}
                  description={[
                    ...(userData?.ethnicity || []),
                    ...(userData?.manual_ethnicity
                      ? JSON.parse(userData.manual_ethnicity)?.map(
                          (obj) => obj?.title
                        ) || []
                      : []),
                  ].join(' | ')}
                  onPawIconClick={onPawIconClick}
                />
              )}
            {/* 9th ONWER PIC */}
            {userData && userData?.images?.[8] && (
              <MatchingImage
                userData={userData}
                imgIndex={8}
                onPawIconClick={onPawIconClick}
              />
            )}
            {/* FAMILY PLAN DESCRIPTION */}
            {userData && userData?.family_plan !== '' && (
              <MatchingContent
                title={localesData?.matching?.FAMILY_PLAN}
                description={userData?.family_plan}
                onPawIconClick={onPawIconClick}
              />
            )}
            {userData && <BackToTop scrollToTop={scrollToTop} />}
          </>
        </Box>
      )}
      {/* {userData &&
        (!rejectLoading ? (
          <CloseIcon
            className='reject-close-icon pointer'
            onClick={rejectUserAPI}
          />
        ) : (
          <Box className='reject-close-icon'>
            <Circle />
          </Box>
        ))} */}
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup handleClose={() => setToogleDialog(!toogleDialog)} />
        }
      />
      <DialogBox
        open={showAnimation}
        handleClose={() => setShowAnimation(!showAnimation)}
        title=''
        closeIconShow={false}
        content={
          <MatchToSend
            handleClose={() => setShowAnimation(!showAnimation)}
            gender={userData && userData?.gender}
          />
        }
      />
      <DialogBox
        open={showLocation}
        handleClose={() => setShowLocation(!showLocation)}
        title=''
        closeIconShow={false}
        content={
          <MatchToSend
            handleClose={() => setShowLocation(!showLocation)}
            gender={userData && userData?.gender}
          />
        }
      />
    </Box>
  );
};

export default SelectedKennlMatches;
