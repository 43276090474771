export const URLS = {
  // AUTH MODULE
  CHECK_USER: '/user/check',
  COUNTRY_LIST: 'countryList',
  SIGN_IN: '/user/login',
  FORGOT_PASSWORD: '/user/forgot-password',
  VERIFY_OTP: '/user/verify',
  RESET_PASSWORD: '/user/reset-password',
  RESEND_PASSWORD: '/user/resend',
  SIGN_UP: '/user/register',
  GET_PROFILE: '/user/profile',
  QUESTIONS: '/question/type',
  SET_ANSWERS: '/question/answer',
  ALL_ANSWERS: '/question',
  USER: '/user',
  REPORT_USER: 'report',
  USEREXPORT: '/user/export',
  DOG: '/dog',
  DIRECTORY: 'directory',
  BENEFIT: 'benefit',
  GET_CHARITY: 'user/getAllCharities',
  MATCHES: 'user/matches',
  BLANK_QUESTIONS: '/question?notAnswer=true',
  GET_CATEGORY: 'directory/getDirectoryCategory',
  GET_LOCATION_BY_CATEGORY: 'directory/getLocationByCategory',
  GEOCODE_API: 'https://maps.googleapis.com/maps/api/geocode/json?',
  PLACE_DETAIL_API: 'https://maps.googleapis.com/maps/api/place/details/json?',
  GET_FAVOURITE_STATUS: 'directory/checkFavourite/',
  REVERSE_GEOCODE_API:
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  IMAGE_UPLOAD: '/uploadImage',
  NOTIFICATION: '/notification',
  HELP_CENTRE_CATEGORIES: 'helpCenter/getArticles',
  FAVOURITE: '/fevourite',
  SEND_BONE: '/user/sendBone',
  REJECT_BONE: '/user/reject',
  SUBSCRIBE: '/subscription/subscribe',
  NOTIFICATION_COUNT: 'user/notificationCount',
  THROWN_BONES: 'user/thrownBones',
  SIMPLE_UNLNK: 'user/unlinkProfile',
  LINK: 'user/linkProfile/',
  UNLINK: 'user/unlinkProfile/',
  SEND_LINK_REQUEST: '?linkStatus=RequestSend',
  ACCEPT_LINK_REQUEST: '?linkStatus=RequestAccept',
  REJECT_LINK_REQUEST: '?linkStatus=RequestReject',
  UNLINK_LINK_REQUEST: '?linkStatus=RequestUnlink',
  UNLINK_LINK_CANCEL: '?linkStatus=RequestCancel',
  REQUEST_UNLNK: 'RequestUnlink',
  ACCEPT_BONE: 'user/acceptBone',
  REJECT_CALL: '/twilio/endCall',
  MISSED_CALL: '/twilio/missedCall',
  GET_TWILLIO_TOKEN: '/twilio/token',
  REGISTER_ONE_SIGNAL_TOKEN: '/user/onesignal',
  COLLECTED_BONES: 'user/collectedBones',
  CHAT_LIST: 'user/chatUser',
  MATCHING_TAB_LIST: '/question/matchProfile',
  CHAT_HISTORY: '/user/chats/',
  GET_EMOJI_LIST: '/emoji',
  NOTIFICATION_READ: 'user/readNotification',
  SUBSCRIPTION_PLAN_LIST: '/subscription/plan-list',
  SUBSCRIBE_PLAN: '/subscription/subscribe',
  GET_SUBSCRIBE_PLAN: '/subscription/get-purchased-plan',
  UPGRADE_SUBSCRIBE_PLAN: '/subscription/upgrade-plan',
  UPDATE_CARD_DETAILS: '/subscription/update-card',
  CANCEL_SUBSCRIBE_PLAN: '/subscription/cancel-plan',
  LOGOUT: '/user/logout',
  APPLE_LOGIN: '/user/apple',
};
