import React, { useState } from "react";
import {
  Box,
  FormHelperText,
  Typography,
  DialogContent,
  Dialog,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomButton from "src/views/component/UI/Button/CustomButton";
import { CustomTextField } from "src/views/component/UI/textfield";
import { setApiMessage } from "src/helpers/commonFunctions";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { fetchFromStorage } from "src/helpers/context";
import { identifiers } from "src/helpers/constants/identifier";
import { manageAccountData } from "src/helpers/store/actions/manageaccount";
import { useSelector } from "react-redux";

const EmailEdit = ({ localesData, setActiveStep, setNewUser, dispatch }) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const EmailMobileData = useSelector((state) => state?.manageAccountData);
  const [deleteEmail, setDelete] = useState(false);

  const EmailDelete = async () => {
    const sendData = {
      isEmailDeleted: true,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.put(URLS.USER, sendData);
      if (status === 200) {
        setLoader(false);
        dispatch(manageAccountData());
        navigate("/account-details");
        setApiMessage("success", data?.message);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage("error", error?.response?.data?.message);
    }
  };

  return (
    <Box className="manage-account-edit-page">
      <Typography
        variant="h6"
        className="s-font-20 color-pink heading"
        dangerouslySetInnerHTML={{
          __html: localesData?.otp?.manage_email_address,
        }}
      />
      <Formik
        initialValues={{
          email: EmailMobileData?.newUser
            ? EmailMobileData?.newUser
            : profileData?.email
            ? profileData?.email
            : "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(localesData?.validation?.REQUIRED)
            .email(localesData?.validation?.VALID_EMAIL)
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              localesData?.validation?.VALID_EMAIL
            ),
        })}
        onSubmit={async (requestData) => {
          let sendData = {
            email: requestData.email,
          };
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.put(
              URLS.USER,
              sendData
            );
            if (status === 200) {
              dispatch(manageAccountData({ editedEmail: true }));
              setNewUser(requestData?.email);
              setActiveStep(1);
            }
          } catch (error) {
            setLoader(false);
            setApiMessage("error", error?.response?.data?.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="form-content">
            {/* <Box className='t-center mb-1'>
                            <Typography
                                variant='h4'
                                className='mb-2'
                                dangerouslySetInnerHTML={{
                                    __html: localesData?.profile?.EMAIL_DESC1,
                                }}
                            /> 
                        </Box> */}
            <Box className="manage-account-conntet">
              <Box className="field-content mb-2">
                <CustomTextField
                  fullWidth
                  error={isSubmit && Boolean(touched.email && errors.email)}
                  helperText={isSubmit && touched.email && errors.email}
                  variant="standard"
                  className={
                    isSubmit
                      ? "custom-textfield"
                      : "custom-textfield not-submitted-textfield"
                  }
                  name="email"
                  value={values.email || ""}
                  onChange={(e) => {
                    handleChange(e);
                    dispatch(manageAccountData({ ModifyEmail: true }));
                    setIsSubmit(false);
                  }}
                  onBlur={handleBlur}
                />
                <FormHelperText className="font-r-13">
                  {localesData?.EMAIL}
                </FormHelperText>
              </Box>
              <Box className="manage-account-button">
                <CustomButton
                  variant="contained"
                  title={localesData?.otp?.save}
                  background="#59D09D"
                  fontWeight="600"
                  type="submit"
                  disabled={
                    values.email && profileData?.email !== values.email
                      ? false
                      : true
                  }
                  classname={
                    values.email && profileData?.email !== values.email
                      ? "save-button"
                      : "save-button disable-button"
                  }
                  onClick={() => {
                    setIsSubmit(true);
                  }}
                />
                {profileData?.email && (
                  <CustomButton
                    variant="contained"
                    title={localesData?.otp?.delete_email_address}
                    background="#FF0000"
                    fontWeight="600"
                    classname="delete-email-button"
                    onClick={() => {
                      if (profileData?.provider !== "basic") {
                        profileData?.isPassword
                          ? setApiMessage(
                              "error",
                              localesData?.otp?.sso_edit_error_with_password
                            )
                          : setApiMessage(
                              "error",
                              localesData?.otp?.sso_edit_error
                            );
                      } else {
                        setDelete(true);
                      }
                    }}
                  />
                )}
                <Dialog
                  open={deleteEmail}
                  onClose={() => {
                    setDelete(false);
                  }}
                  className="delete-dialog-box"
                >
                  <DialogContent>
                    <Box className="delete-head-sec">
                      <Box className="favourites-delete-sec">
                        <Typography className="dailog-head-text pb-1">
                          {" "}
                          {localesData?.otp?.delete_email_address + "?"}
                        </Typography>
                        <Typography className="font-r-13 t-center pb-1">
                          {" "}
                          {localesData?.otp?.delete_email}
                        </Typography>
                      </Box>
                      <Box className="permission-btns">
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={6} className="cancel-btn">
                            <CustomButton
                              variant="text"
                              title={localesData?.favourites_delete?.CANCEL}
                              fontWeight="600"
                              type="submit"
                              classname="favourites-cancel-btn favourites-btn"
                              onClick={() => {
                                setDelete(false);
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CustomButton
                              variant="text"
                              title={localesData?.otp?.delete}
                              fontWeight="600"
                              type="submit"
                              classname="favourites-yes-btn favourites-btn"
                              onClick={() => {
                                setDelete(false);
                                EmailDelete();
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EmailEdit;
