import React from "react";
import Page from "../../../components/Page";
import LoginPage from "../../component/auth/Login";

const LoginView = () => {
  return (
    <Page title="Sign In">
      <LoginPage />
    </Page>
  );
};

export default LoginView;
