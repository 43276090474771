import { Box, Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../UI/Button/CustomButton';
import { useNavigate } from 'react-router-dom';

const ProfileCompletionRules = ({ localesData, handleNext }) => {
  const navigate = useNavigate();

  return (
    <Box className='t-center pb-6'>
      <Box className='mb-8'>
        <Typography
          variant='h3'
          className='pb-6'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.PROFILE_DESC_SEC,
          }}
        />
        <Typography
          variant='h5'
          className='pb-3'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.DESC1,
          }}
        />
        <Typography
          variant='h5'
          className='pb-3'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.DESC2,
          }}
        />
        <Typography
          variant='h5'
          className='pb-3'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.DESC3,
          }}
        />
        <Typography
          variant='h5'
          className='pb-3'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.DESC4,
          }}
        />
        <Typography
          variant='h5'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_rules?.DESC5,
          }}
        />
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.profile_completion_rules?.HAPPY_WITH_THIS}
          background='#59D09D'
          fontWeight='600'
          onClick={() => handleNext()}
        />
        <CustomButton
          variant='contained'
          title={localesData?.profile_completion_rules?.NOT_READY_TO_COMMIT}
          background='#FF0000'
          fontWeight='600'
          onClick={() => navigate('/')}
        />
      </Box>
    </Box>
  );
};
export default ProfileCompletionRules;
