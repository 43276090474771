import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
// import { useNavigate } from 'react-router-dom';
import { CustomTextField } from '../../../UI/textfield';
import IOSSwitch from '../../../UI/IOSSwitch';
import CustomButton from '../../../UI/Button/CustomButton';
import ProgressBar from '../../../UI/ProgressBar';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { Config } from '../../../../../helpers/context';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import '../UserWork/userWork.scss';

Geocode.setApiKey(Config?.placeAPIKey);

const UserLocation = ({
  localesData,
  questions,
  setLoader,
  dispatch,
  handleNext,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  // const navigate = useNavigate();
  const [addressData, setAddressData] = useState('');
  const [locationData, setLocationData] = useState('');
  const [locationSwitchData, setLocationSwitchData] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationToDisplay, setLocationToDisplay] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const question = questions?.find((o) => o?.id === 2);
    const questionAnswersAddress = answars?.find((o) => o?.id === 33);
    const answersAddressIsPrivate = answars?.find((o) => o?.id === 51);
    if (
      questionAnswersAddress?.Option[0]?.answer === '' ||
      questionAnswersAddress?.Option[0]?.answer === null ||
      questionAnswersAddress?.Option[0]?.answer === 'null'
    ) {
    } else {
      setAddressData(questionAnswersAddress?.Option[0]?.answer);
      setLocationToDisplay(questionAnswersAddress?.Option[0]?.answer);
    }

    question?.Question?.forEach((item) => {
      if (item?.q_order === 30) {
        setLocationData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private:
            questionAnswersAddress?.Option[0]?.private === 1 ||
            item.Option[0]?.private === '1'
              ? true
              : false,
        });
      }
      if (item?.q_order === 31) {
        setLocationSwitchData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private:
            answersAddressIsPrivate?.Option[0]?.private === 1 ||
            item.Option[0]?.private === '1'
              ? true
              : false,
        });
      }
    });
  }, [questions, answars]);
  const handleChange = (address) => {
    setAddressData(address);
    // setLocationToDisplay(address);
  };

  const handleSelect = async (address) => {
    try {
      setAddressData(address);
      setLocationToDisplay(address);
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng?.lat);
      setLongitude(latLng?.lng);
      setError('');
    } catch (error) {
      setError(error);
    }
  };

  // const handleSelect = (address) => {
  //   setAddressData(address);
  //   setLocationToDisplay(address);
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => {
  //       setLatitude(latLng?.lat);
  //       setLongitude(latLng?.lng);
  //       setError('');
  //     })
  //     .catch((error) => setError(error));
  // };

  const fetchLocation = () => {
    if (Config?.placeAPIKey !== '') {
      if (navigator.geolocation) {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              getLocationString(
                position.coords.latitude,
                position.coords.longitude
              );
              setError('');
            },
            (error) => {
              setError(error.message);
            }
          );
        } catch (error) {
          setError(error);
        }
      } else {
        setError('Geolocation is not supported by your browser.');
      }
    }
  };

  // const fetchLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //         getLocationString(
  //           position.coords.latitude,
  //           position.coords.longitude
  //         );
  //         setError('');
  //       },
  //       (error) => {
  //         setError(error.message);
  //       }
  //     );
  //   } else {
  //     setError('Geolocation is not supported by your browser.');
  //   }
  // };

  const handleLocationChange = (e) => {
    if (e.target.checked) {
      fetchLocation();
    }
  };

  // useEffect(() => {
  //   if (locationSwitchData?.private === true) {
  //     fetchLocation();
  //   }
  //   // eslint-disable-next-line
  // }, [locationSwitchData]);

  const getLocationString = async (lat, lng) => {
    if (Config?.placeAPIKey !== '') {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          if (response != null) {
            var country_state_name = '';
            var country_name = '';
            var country_city_name = '';
            var full_adrs = '';

            response?.results[0]?.address_components?.map((item) => {
              if (item.types[0] === 'locality') {
                country_city_name = item?.long_name;
              }
              if (item.types[0] === 'administrative_area_level_1') {
                country_state_name = item?.long_name;
              }
              if (item.types[0] === 'country') {
                country_name = item?.long_name;
              }
              full_adrs =
                country_city_name +
                ', ' +
                country_state_name +
                ', ' +
                country_name;
              return true;
            });

            if (response.status === 'OK') {
              if (full_adrs === '') {
                setLocationToDisplay(response.results[0].formatted_address);
                setAddressData(response.results[0].formatted_address);
              } else {
                setLocationToDisplay(full_adrs);
                setAddressData(full_adrs);
              }
            } else {
              setError('something_went_wrong', response.status);
            }
          } else {
            setError('something_went_wrong', response);
          }
        },
        (error) => {
          setError(error);
        }
      );
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];
    // if (locationToDisplay) {
    const locationVal = addressData?.length === 0;
    const latlong = locationData?.options?.[0];

    opt.push({
      optionId: locationData?.options?.[0]?.id,
      answer: locationVal ? null : locationToDisplay ? locationToDisplay : null,
      selected: true,
      private: false,
      lat: locationVal
        ? null
        : locationToDisplay && latitude
        ? latitude?.toString()
        : latlong?.lat
        ? latlong?.lat
        : '',
      long: locationVal
        ? null
        : locationToDisplay && longitude
        ? longitude?.toString()
        : latlong?.long
        ? latlong?.long
        : '',
      startRange: '0',
      endRange: '100',
    });
    // }
    opt.push({
      optionId: locationSwitchData?.options?.[0]?.id,
      answer: '',
      selected: false,
      private: locationSwitchData?.private
        ? locationSwitchData?.private
        : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'location',
            value: opt,
          })
        );
        if (isEdit) {
          checkForBlankData();
        } else {
          redirectHomePage();
        }
        // navigate('/');
        // dispatch(handleNext(0));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box className='t-center pb-6'>
        <Typography variant='h6' className='pb-2 '>
          {localesData?.userwork?.profile_completion}
        </Typography>
        <Box className='mb-3'>
          <Typography variant='h4' className='pb-1'>
            {/* {reduxProgress}% */}
            {progressWeightage}%
          </Typography>
          <ProgressBar processData={progressWeightage} />
        </Box>
        <Typography variant='h3' className='s-bold pb-2'>
          {localesData?.user_hobbies?.now_its_all_about_you}
        </Typography>
        <Typography
          variant='h4'
          className='pb-4'
          dangerouslySetInnerHTML={{
            __html: localesData?.user_family_plan?.SPEND_TIME,
          }}
        />
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex  pb-4'>
          <Typography
            variant='h4'
            className='pb-4 find-text'
            dangerouslySetInnerHTML={{
              __html: localesData?.user_family_plan?.FIND_LOCATION,
            }}
          />
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='locationSwitchData'
              checked={locationSwitchData?.private}
              onChange={(e) => {
                setLocationSwitchData({
                  ...locationSwitchData,
                  private: e.target.checked,
                });
                handleLocationChange(e);
              }}
            />
          </FormGroup>
        </Box>

        <Box className='pb-20'>
          <PlacesAutocomplete
            value={addressData}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <CustomTextField
                  variant='standard'
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'custom-textfield location-search-input',
                  })}
                  disabled={locationSwitchData?.private}
                />
                <FormHelperText className='custom-field-helper'>
                  {localesData?.user_family_plan?.DESC}
                </FormHelperText>
                <div className='autocomplete-dropdown-container'>
                  {loading && <div className='t-center'>Loading...</div>}
                  {suggestions?.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion?.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {error && <FormHelperText error>{error && error}</FormHelperText>}
        </Box>
        <CustomButton
          variant='contained'
          title={localesData?.user_family_plan?.FINALLY}
          background='#59D09D'
          fontWeight='400'
          onClick={handleOnSubmit}
        />
      </Box>
    </>
  );
};

export default UserLocation;
