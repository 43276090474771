import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import UpgradePlan from '../UpgradePlan';
// import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
// import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';

const AboutEmployment = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const [selectedChips, setSelectedChips] = useState([]);
  const [employmentData, setEmploymentData] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);

  useEffect(() => {
    if (answers?.length !== 0) {
      setEmploymentData(
        answers?.find((o) => o?.id === 56)?.Option?.map((item) => item)
      );
      setIsPrivate(
        answers?.find((o) => o?.id === 56)?.Option?.[0]?.isMust === 1
          ? true
          : false
      );
      setSelectedChips(
        answers
          ?.find((o) => o?.id === 56)
          ?.Option?.filter((item) => item?.selected === 1)
      );
    }
  }, [answers]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip]);
    } else {
      setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    employmentData?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveInfo();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const saveInfo = async () => {
    var opt = [];

    employmentData?.map((item) => {
      opt.push({
        optionId: item.id,
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        lookingFor: true,
        isMust: isPrivate ? true : false,
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Box className=''>
        {/* <Typography variant='h6' className='s-font-20 color-pink mt-2 mb-8'>
          {localesData?.profile?.WHAT_ARE_YOU_LOOKING_FOR}
        </Typography> */}
        <Box className='chip-container-box '>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
          <Typography variant='h3' className='mb-5 t-center s-bold'>
            {localesData?.profile?.EMPLOYMENT}
          </Typography>
        </Box>

        {/* <Box className='pb-6 t-center chip-container-box'>
          <Box className='chip-container mb-2'> */}
        <Box className='looking-for-chip'>
          <Box className='chip-container mb-2 pb-6'>
            {employmentData?.map((item, i) => (
              <Chip
                className={`border-chip-inner font-r-11 ${
                  selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                    ? 'chip-active'
                    : ''
                } `}
                key={i}
                label={item?.title}
                onClick={() => handleChipClick(item)}
              />
            ))}
          </Box>

          <Box className='edit-your-profile-box'>
            <Box className='d-flex pb-4 justify-center'>
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='isPrivate'
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
              </Typography>
              {/* {isPrivate ? <UnLockIcon /> : <LockIcon />} */}
            </Box>
            <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
              <CustomButton
                classname='uppercase'
                variant='contained'
                title={localesData?.DONE}
                background='#59D09D'
                fontWeight='600'
                onClick={handleOnSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutEmployment;
