import React, { useContext, useRef, useState, useEffect } from 'react';
import { IntlContext } from 'src/App';
import {
  Box,
  Typography,
  FormHelperText,
  Select,
  FormControl,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import defaultDogImg from 'src/assets/images/dog-img-upload.svg';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { ReactComponent as PlusIcon } from 'src/assets/images/icon-plus.svg';
import UserProfileEditor from 'src/views/component/UI/UserProfileEditor';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { CustomSelect } from 'src/views/component/UI/select';
import { identifiers } from 'src/helpers/constants/identifier';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
import { addProfileData } from 'src/helpers/store/actions/profileSetup';
import './editDogProfile.scss';

const AddEditDogDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxDogData = useSelector((state) => state?.profileData);
  const [breedOptions, setBreedOptions] = useState([]);
  const [otherValue, setOtherValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const intlContext = useContext(IntlContext);
  const [dogMain, setDogMain] = useState(false);
  const [dogDeceased, setDogDeceased] = useState(false);
  const [showNameField, setShowNameField] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dogDetails, setDogDetails] = useState('');
  const [dogProfilePic, setDogProfilePic] = useState('');
  // const [allImages, setAllImages] = useState([]);
  // const [dogAvatar, setDogAvatar] = useState([]);
  // const [avatarIndex, setAvatarIndex] = useState(0);
  const [otherErrors, setOtherErrors] = useState('');
  const isEdit = location?.pathname?.includes('/edit');

  const localesData = intlContext?.messages;

  const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];
  const selectRef = useRef(null);

  const handleBreedBlur = () => {
    if (otherValue !== '') {
      const newValues = [otherValue, ...breedOptions];
      setBreedOptions(newValues);
      setSelectedValue(otherValue);
    }
  };

  const formRef = useRef();

  // const validations = () => {
  //   let error = {};
  //   let flag = true;
  //   const dogImg = reduxDogData?.dog_images
  //     ? reduxDogData?.dog_images
  //     : location?.state?.images;

  //   if (dogName === '') {
  //     error.dogName = localesData?.validation?.REQUIRED;
  //     flag = false;
  //   }
  //   if (dogImg === undefined || dogImg?.length === 0) {
  //     error.images = localesData?.validation?.UPLOAD_AT_LIST_ONE_IMG_VIDEO;
  //     flag = false;
  //   }
  //   setOtherErrors(error);
  //   return flag;
  // };

  const validations = () => {
    const dogImg = reduxDogData?.dog_images
      ? reduxDogData?.dog_images
      : location?.state?.images;

    if (!dogImg) {
      setOtherErrors(localesData?.validation?.UPLOAD_AT_LIST_ONE_IMG_VIDEO);
    } else if (dogImg && dogImg.length === 0) {
      setOtherErrors(localesData?.validation?.UPLOAD_AT_LIST_ONE_IMG_VIDEO);
    }
  };

  const handleChangeValue = (e) => {
    const { name, checked } = e.target;
    if (name === 'dogDeceased') {
      setDogDeceased(checked);
    }
    if (name === 'dogMain') {
      setDogMain(checked);
    }
  };

  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        let newBreed = [...breedOptions];
        newBreed = [
          ...newBreed,
          ...data?.questions
            ?.find((o) => o?.id === 55)
            ?.Option?.map((item) => item?.title),
        ];

        setBreedOptions([location?.state?.breed, ...newBreed]);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllAnswars();
    const dogImg = reduxDogData?.dog_images
      ? reduxDogData?.dog_images
      : location?.state?.images;

    // const oldImages = dogImg?.map((item, i) => {
    //   return {
    //     displayOrder: i + 1,
    //     url: item?.url,
    //     media_type: item?.media_type,
    //   };
    // });

    // dispatch(
    //   addProfileData({
    //     key: 'dog_images',
    //     value: oldImages,
    //   })
    // );
    const imageObject = dogImg?.filter(
      (item) =>
        item?.media_type?.includes('image') ||
        item?.url?.substr(item?.url?.length - 3) === 'jpg'
    )?.[0]?.url;
    setDogProfilePic(imageObject);

    if (location?.state !== null) {
      setTimeout(() => {
        setDogDetails(location?.state);
        setDogMain(location?.state?.mainDog);
        setDogDeceased(location?.state?.deceased);
        setOtherValue(location?.state?.breed);
        setSelectedValue(location?.state?.breed);
      }, 200);
    } else {
      if (isEdit) {
        navigate('/dog-profile');
        dispatch(
          addProfileData({
            key: 'dog_images',
            value: [],
          })
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  const ProfileView = () => {
    navigate('/dog-picture', { state: location?.state });
  };

  // const saveInfo = async (breedName) => {
  //   var opt = [];
  //   var test = [];
  //   test.push({ title: breedName, selected: true });

  //   opt.push({
  //     optionId: 1745,
  //     selected: false,
  //     answer: JSON.stringify(test),
  //     private: true,
  //   });
  //   let sendData = {
  //     private: false,
  //     optionIds: opt,
  //   };
  //   console.log('sendData', sendData);
  //   try {
  //     setLoader(true);
  //     const { status, data } = await axiosInstance.post(
  //       URLS.SET_ANSWERS,
  //       sendData
  //     );
  //     if (status === 200) {
  //       setApiMessage('success', data?.message);
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     setApiMessage('error', error?.response?.data?.message);
  //   }
  // };

  const handleSubmitData = () => {
    // if (validations()) {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
    // }
  };
  return (
    <Box className='dog-profile-detail-sec'>
      {loader && <PreLoader />}
      <Typography
        className='header-txt pb-16'
        dangerouslySetInnerHTML={{
          __html: localesData?.dog_profile_details?.DOG_PROFILE,
        }}
      />

      <Box className='dog-profile-detail mb-4'>
        <UserProfileEditor
          src={dogProfilePic ? dogProfilePic : defaultDogImg}
          handlePencilIconClick={() => ProfileView()}
        />

        <Formik
          innerRef={formRef}
          validate={validations}
          initialValues={{
            dog_name: dogDetails ? dogDetails?.name : '',
            dog_breed: dogDetails ? dogDetails?.breed : '',
            dog_gender: dogDetails ? dogDetails?.gender : '',
            dog_age: {
              year: dogDetails
                ? dogDetails?.age?.split(' ')?.[0] +
                  ' ' +
                  dogDetails?.age?.split(' ')?.[1]
                : '',

              month: dogDetails
                ? dogDetails?.age?.split(' ')?.[2] +
                  ' ' +
                  dogDetails?.age?.split(' ')?.[3]
                : '',
            },
            dog_mitch_number: dogDetails ? dogDetails?.mitch : '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            dog_name: Yup.string().required(localesData?.validation?.REQUIRED),
            dog_breed: Yup.string().required(localesData?.validation?.REQUIRED),

            dog_gender: Yup.string().required(
              localesData?.validation?.REQUIRED
            ),
            dog_age: Yup.object().shape({
              year: Yup.string().required(localesData?.validation?.REQUIRED),
              month: Yup.string().required(localesData?.validation?.REQUIRED),
            }),
            // .max(new Date(), localesData?.validation?.INVALID_AGE),
          })}
          onSubmit={async (req) => {
            const all_images = reduxDogData?.dog_images
              ? reduxDogData?.dog_images
              : location?.state?.images;

            const _imgs = [];
            all_images?.forEach((item) => {
              let _url = '';
              let media_type = '';

              if (item?.url) {
                _url = item?.url?.split('/')[3];
                media_type = item?.media_type?.includes('video')
                  ? 'video'
                  : item?.media_type?.includes('image')
                  ? 'image'
                  : '';
              }
              if (_url && _url !== '') {
                _imgs.push({
                  url: _url,
                  media_type: media_type,
                });
              }
            });
            const videoObject = all_images?.filter((item) =>
              item?.media_type?.includes('video')
            );
            const dogAge = `${req?.dog_age?.year} ${req?.dog_age?.month}`;
            const sendData = {
              name: req.dog_name,
              breed: req.dog_breed,
              gender: req.dog_gender,
              age: dogAge,
              mitch: req.dog_mitch_number,
              mainDog: dogMain,
              deceased: dogDeceased,
              avatarIndex: 0,
              dogIndex: dogDetails?.dogIndex ? dogDetails?.dogIndex : 0,
              images: _imgs,
              video:
                videoObject && videoObject?.length > 0
                  ? videoObject[0]?.url
                  : '',
            };

            // if (otherValue !== '') {
            //   saveInfo(req.dog_breed);
            // }
            if (!isEdit) {
              try {
                setLoader(true);
                const { status, data } = await axiosInstance.post(
                  URLS.DOG,
                  sendData
                );
                if (status === 200) {
                  setApiMessage('success', data?.message);
                  navigate('/dog-profile');
                  dispatch(
                    addProfileData({
                      key: 'dog_images',
                      value: [],
                    })
                  );
                  setLoader(false);
                }
              } catch (error) {
                setLoader(false);
                setApiMessage('error', error?.response?.data?.message);
              }
            } else {
              try {
                setLoader(true);
                const { status, data } = await axiosInstance.put(
                  URLS.DOG + `/${location?.state?.id}`,
                  sendData
                );
                if (status === 200) {
                  setApiMessage('success', data?.message);
                  navigate('/dog-profile');
                  dispatch(
                    addProfileData({
                      key: 'dog_images',
                      value: [],
                    })
                  );
                  setLoader(false);
                }
              } catch (error) {
                setLoader(false);
                setApiMessage('error', error?.response?.data?.message);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} className='form-content'>
              <Box>
                <Box className='t-center dog-name-edit dog-breed-select mb-4'>
                  {!showNameField ? (
                    <Typography
                      variant='h6'
                      className='font-r-12 pointer'
                      onClick={() => setShowNameField(true)}
                    >
                      {values?.dog_name ? values?.dog_name : '[Dog Name]'}
                    </Typography>
                  ) : (
                    <CustomTextField
                      fullWidth
                      variant='standard'
                      className='custom-textfield'
                      name='dog_name'
                      value={values.dog_name}
                      onChange={(e) => handleChange(e)}
                      onBlur={() => setShowNameField(false)}
                    />
                  )}
                  {errors.dog_name && (
                    <FormHelperText
                      sx={{ marginTop: '4px', fontSize: '10px' }}
                      className='color-error'
                    >
                      {errors.dog_name}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
              {/* Dog Breed */}
              <Box className='mb-4'>
                {selectedValue === 'other' ? (
                  <>
                    <FormHelperText className='custom-field-helper'>
                      {localesData?.ADD_OTHER}
                    </FormHelperText>
                    <CustomTextField
                      error={Boolean(touched.dog_breed && errors.dog_breed)}
                      fullWidth
                      variant='standard'
                      className='custom-textfield'
                      name='dog_breed'
                      // value={inputValue}
                      // onChange={handleInputfieldChange}
                      onBlur={handleBreedBlur}
                      value={otherValue}
                      onChange={(e) => {
                        handleChange(e);
                        setOtherValue(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <FormControl variant='standard' fullWidth>
                    <Select
                      id='dog_breed'
                      name='dog_breed'
                      ref={selectRef}
                      fullWidth
                      labelId='my-dropdown-label'
                      // IconComponent={KeyboardArrowDownOutlinedIcon}
                      IconComponent={DropdownIcon}
                      // value={values.selectedValue || ''}
                      // onChange={(e, value) => {
                      //   if (e.target.value === '__input__') {
                      //     setShowInput(true);
                      //     handleChange(e);
                      //   } else {
                      //     handleInputChange(e);
                      //     handleChange(e);
                      //   }
                      // }}

                      value={selectedValue || ''}
                      onChange={(e) => {
                        // if (e.target.value === 'other') {
                        //   setOtherValue('');
                        // }
                        handleChange(e);
                        setSelectedValue(e.target.value);
                        setOtherValue('');
                      }}
                    >
                      <MenuItem value='other'>
                        <PlusIcon />
                        <span style={{ marginLeft: '10px' }}>
                          {localesData?.ADD_OTHER}
                        </span>
                      </MenuItem>
                      {breedOptions?.map((opt, i) => (
                        <MenuItem
                          key={i}
                          value={opt}
                          className='select-options'
                        >
                          {opt}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.dog_breed && errors.dog_breed && (
                      <FormHelperText
                        sx={{ marginTop: '4px', fontSize: '10px' }}
                        className='color-error'
                      >
                        {errors.dog_breed}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}

                <FormHelperText className='custom-field-helper'>
                  {localesData?.profile_completion_dog_details?.DOG_BREED_DESC}
                </FormHelperText>
              </Box>
              {/* {/ Dog Gender /} */}
              <Box className='mb-4'>
                <Select
                  fullWidth
                  variant='standard'
                  error={Boolean(touched.dog_gender && errors.dog_gender)}
                  className='custom-select'
                  name='dog_gender'
                  input={<CustomSelect />}
                  IconComponent={DropdownIcon}
                  value={values.dog_gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  displayEmpty
                >
                  {genderList?.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item?.value}
                      className='select-options'
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.dog_gender && errors.dog_gender && (
                  <FormHelperText
                    sx={{ marginTop: '4px', fontSize: '10px' }}
                    className='color-error'
                  >
                    {errors.dog_gender}
                  </FormHelperText>
                )}
                <FormHelperText className='custom-field-helper'>
                  {localesData?.profile_completion_dog_details?.DOG_GENDER_DESC}
                </FormHelperText>
              </Box>
              {/* {/ Dog Age /} */}
              <Box className='mb-4'>
                <Box className='dog-age-block-wrap'>
                  <Box>
                    <FormControl>
                      <Select
                        fullWidth
                        variant='standard'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={values.dog_age.year}
                        name='dog_age.year'
                        input={<CustomSelect />}
                        className='dog-month custom-select'
                        IconComponent={DropdownIcon}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {identifiers?.Years.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.dog_age?.year && touched.dog_age?.year && (
                      <FormHelperText className='color-error'>
                        {errors.dog_age.year}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box>
                    <FormControl>
                      <Select
                        fullWidth
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={values.dog_age.month}
                        name='dog_age.month'
                        className='dog-month custom-select'
                        input={<CustomSelect value={values.dog_age.month} />}
                        IconComponent={DropdownIcon}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='standard'
                      >
                        {identifiers?.Months.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.dog_age?.month && touched.dog_age?.month && (
                      <FormHelperText className='color-error'>
                        {errors.dog_age.month}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
                <FormHelperText className='custom-field-helper'>
                  {localesData?.profile_completion_dog_details?.DOG_AGE_DESC}
                </FormHelperText>
              </Box>
              <Box className='mb-7'>
                <CustomTextField
                  error={Boolean(
                    touched.dog_mitch_number && errors.dog_mitch_number
                  )}
                  helperText={
                    touched.dog_mitch_number && errors.dog_mitch_number
                  }
                  fullWidth
                  variant='standard'
                  className='custom-textfield'
                  name='dog_mitch_number'
                  value={values.dog_mitch_number || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    if (/[^0-9]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <FormHelperText className='custom-field-helper'>
                  {localesData?.profile_completion_dog_details?.DOG_NUMBER}
                </FormHelperText>
              </Box>
            </form>
          )}
        </Formik>
      </Box>

      <Box className='d-flex pb-4 ' sx={{ justifyContent: 'center' }}>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch className='ios-switch' />}
            name='dogMain'
            checked={dogMain}
            onChange={(e) => handleChangeValue(e)}
          />
        </FormGroup>
        <Typography
          variant='h5'
          className='s-bold'
          dangerouslySetInnerHTML={{
            __html: localesData?.dog_profile_details?.MAKE_MY_DOMAIN,
          }}
        />
      </Box>

      <Box className='d-flex pb-4 ' sx={{ justifyContent: 'center' }}>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch className='ios-switch' />}
            name='dogDeceased'
            checked={dogDeceased}
            onChange={(e) => handleChangeValue(e)}
          />
        </FormGroup>
        <Typography variant='h5' className='s-bold'>
          {localesData?.profile_completion_dog_details?.DOG_IS_DECEASED}
        </Typography>
      </Box>

      {otherErrors && (
        <FormHelperText
          sx={{ marginTop: '4px', fontSize: '10px' }}
          className='color-error mb-2'
        >
          {otherErrors}
        </FormHelperText>
      )}
      <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
        <CustomButton
          variant='contained'
          title={localesData?.DONE}
          background='#59D09D'
          fontWeight='600'
          classname=''
          onClick={handleSubmitData}
        />
      </Box>
    </Box>
  );
};

export default AddEditDogDetails;
