import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../App';
import './benefits.scss';
import { Box, Typography } from '@mui/material';
// import { ReactComponent as PinkStar } from '../../../../assets/images/icons/pink-star.svg';
// import { ReactComponent as Petbarn } from '../../../../assets/images/icons/petbarn.svg';
import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-active.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import DialogBox from '../../UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from '../../UI/PremiumPopup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const BenefitDetails = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const location = useParams();
  const locationPath = useLocation();
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [benefit, setBenefit] = useState([]);
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [loader, setLoader] = useState(false);
  // const [benefitLoader, setbenefitLoader] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);

  useEffect(() => {
    EventEmitter.dispatch('updateHeader', benefit?.petStore);
    const updateHeader = (text) => {
      setSelectedBenefit((prevBenefit) => ({ ...prevBenefit, petStore: text }));
    };
    EventEmitter.subscribe('updateHeader', updateHeader);

    return () => {
      EventEmitter.unsubscribe('updateHeader', updateHeader);
    };
  }, [benefit, setSelectedBenefit]);

  const getBenefitData = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BENEFIT);
      if (status === 200) {
        setBenefit(
          data?.benefits.filter((item) => item?.id == location?.id)?.[0]
        );
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (profileData?.isPaid === false) {
      navigate('/benefits');
    } else {
      getBenefitData(1);
    }
  }, []);

  const makeFavouriteAPI = async (itemID) => {
    const sendData = {
      following: itemID.toString(),
      type: 'benefits',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        sendData
      );
      if (status === 200) {
        getBenefitData();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const removeFavouriteAPI = async (itemID) => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + itemID
      );
      if (status === 200) {
        getBenefitData();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      {!loader ? (
        <Box className='benefit-detail-page-sec'>
          <Box className='benefits-detail-sec benefit-detail-page pb-4'>
            <Box className='benefits-box-sec'>
              <Box className='white-Petbarn-box'>
                <Box className='Petbarn-box-head d-flex'>
                  <Typography className='d-flex'>
                    {benefit?.image ? (
                      <img
                        src={benefit?.image}
                        alt={benefit?.image}
                        className='benefit-image'
                      />
                    ) : (
                      <span className='blank-image-placeholder'> </span>
                    )}
                    {/* Display the image */}
                  </Typography>
                  {benefit?.isLike === 1 ||
                  benefit?.isLike === '1' ||
                  benefit?.isLike === true ? (
                    <FavSelectedIcon
                      onClick={() =>
                        profileData?.isPaid === false
                          ? setToogleDialog(true)
                          : removeFavouriteAPI(benefit?.fevouriteId)
                      }
                      className='pointer'
                      height={22}
                      width={23}
                    />
                  ) : (
                    <FavIcon
                      onClick={() =>
                        profileData?.isPaid === false
                          ? setToogleDialog(true)
                          : makeFavouriteAPI(benefit?.id)
                      }
                      className='pointer'
                      height={22}
                      width={23}
                    />
                  )}
                </Box>
              </Box>
              <Box className='Petbarn-box'>
                <Box className='store-off-content'>
                  <Box className='d-flex'>
                    <Typography className='pet-store-head-txt'>
                      {benefit?.petStore}
                    </Typography>
                    <Typography className='pet-store-txt'>
                      {'-'}
                      {benefit?.petStoreType}
                    </Typography>
                  </Box>
                  <Typography className='font-r-12'>
                    {benefit?.benefistName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              variant='h4'
              className='pb-2'
              dangerouslySetInnerHTML={{
                __html: localesData?.benefits_details?.DESCRIPTION,
              }}
            />
            <Typography
              className='description-text pb-1'
              dangerouslySetInnerHTML={{
                __html: `${benefit?.description?.replace(
                  /<a([^>]*)>/g,
                  '<a$1 class="web-link">'
                )}`,
              }}
            />
          </Box>
          <Box>
            <Box className='mt-10 mb-10 barcode-sec'>
              <img
                src={benefit?.barCode}
                alt={benefit?.barCode}
                width={345}
                height={84}
              />
            </Box>
          </Box>
          <Box className='mt-4'>
            {benefit?.thingsYouShouldKnow && (
              <Typography
                className='description-text pb-2'
                dangerouslySetInnerHTML={{
                  __html: localesData?.benefits_details?.THINGS_YOU_KNOW,
                }}
              />
            )}
            <Typography
              className='description-text pb-1'
              dangerouslySetInnerHTML={{
                __html: benefit?.thingsYouShouldKnow,
              }}
            />
          </Box>
          <DialogBox
            open={toogleDialog}
            handleClose={() => setToogleDialog(!toogleDialog)}
            title=''
            content={
              <PremiumPopup
                handleClose={() => setToogleDialog(!toogleDialog)}
                // navigate={navigate}
              />
            }
          />
        </Box>
      ) : (
        <> </>
      )}
    </>
  );
};

export default BenefitDetails;
