import React, { useState } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { CustomTextField } from '../../../UI/textfield';
import { ReactComponent as PlusIcon } from '../../../../../assets/images/icons/plus.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/images/icons/check.svg';
import ProgressBar from '../../../UI/ProgressBar';
import '../UserWork/userWork.scss';

const ManuallyReligion = ({
  localesData,
  reduxProgress,
  setManualData,
  progressWeightage,
}) => {
  const [religion, setReligion] = useState(['']);
  const [numFields, setNumFields] = useState(1);
  const [errors, setErrors] = useState('');

  const handleBlur = (index, value) => {
    if (numFields === index + 1 && value !== '') {
      setReligion([...religion, '']);
      setNumFields(numFields + 1);
    } else {
      setErrors(localesData?.validation?.ITS_CANT_BE_BLANK);
    }
  };

  const handleReligionChange = (index, value) => {
    const newReligion = [...religion];
    newReligion[index] = value;
    setReligion(newReligion);
    setManualData(newReligion);
    setErrors('');
  };

  return (
    <Box className='mb-10'>
      <Typography
        className='mb-2 font-r-12'
        dangerouslySetInnerHTML={{
          __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
        }}
      />
      <Box className='mb-3'>
        <Typography variant='h4' className='pb-1'>
          {/* {reduxProgress}% */}
          {progressWeightage}%
        </Typography>
        <ProgressBar processData={progressWeightage} />
      </Box>
      <Typography variant='h3' className='s-bold pb-2 t-center'>
        {localesData?.user_hobbies?.now_its_all_about_you}
      </Typography>
      <Typography
        variant='h4'
        className='pb-4 t-center'
        dangerouslySetInnerHTML={{
          __html: localesData?.user_hobbies?.do_you_have_religion,
        }}
      ></Typography>
      <Typography
        variant='h4'
        className='pb-4 t-center'
        dangerouslySetInnerHTML={{
          __html:
            localesData?.user_hobbies?.didnt_find_what_you_were_looking_for,
        }}
      ></Typography>
      <Box className='field-profile-sec'>
        {religion?.map((hobby, index) => (
          <Box key={index}>
            <CustomTextField
              fullWidth
              variant='standard'
              className='custom-textfield pb-2'
              name={`hobby_${index}`}
              InputProps={{
                endAdornment:
                  index === numFields - 1 ? (
                    <PlusIcon
                      className='pointer'
                      onClick={() => handleBlur(index, religion[index])}
                    />
                  ) : (
                    <CheckIcon />
                  ),
              }}
              value={hobby}
              onChange={(event) =>
                handleReligionChange(index, event.target.value)
              }
              // onBlur={(event) => handleBlur(index, event.target.value)}
            />
          </Box>
        ))}
        {errors && (
          <FormHelperText className='color-error'>{errors}</FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default ManuallyReligion;
