import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IntlContext } from '../../../../App';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import ForgotWithNumber from './ForgotWithNumber';

import ForgotWithEmail from './ForgotWithEmail';
import ForgotPasswordOTP from './ForgotPasswordOTP';
import ConfirmPassword from '../Register/ConfirmPassword';

import PreLoader from '../../../../components/Loader';
import '../Login/login.scss';

const Index = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const profileData = useSelector((state) => state?.profileData);

  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [sendType, setSendType] = useState('email');

  useEffect(() => {
    if (!profileData?.first_name) {
      navigate('/sign-in');
    }
  }, [navigate, profileData]);

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const changeComponent = (type) => {
    setSendType(type);
  };

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return sendType === 'phone' ? (
          <ForgotWithNumber
            localesData={localesData}
            handleNext={handleNexts}
            profileData={profileData}
            changeComponent={changeComponent}
            setLoader={setLoader}
          />
        ) : (
          <ForgotWithEmail
            localesData={localesData}
            handleNext={handleNexts}
            profileData={profileData}
            changeComponent={changeComponent}
            setLoader={setLoader}
          />
        );
      case 1:
        return (
          <ForgotPasswordOTP
            localesData={localesData}
            handleNext={handleNexts}
            profileData={profileData}
            changeComponent={changeComponent}
            setLoader={setLoader}
          />
        );
      case 2:
        return (
          <ConfirmPassword
            isForgot={true}
            handleNext={handleNexts}
            setLoader={setLoader}
          />
        );
      default:
        return (
          <ForgotWithNumber
            localesData={localesData}
            handleNext={handleNexts}
            profileData={profileData}
            changeComponent={changeComponent}
            setLoader={setLoader}
          />
        );
    }
  };

  return (
    <>
      <Box className='page-content-wrap'>
        {loader && <PreLoader />}
        {renderComponent()}
      </Box>
    </>
  );
};

export default Index;
