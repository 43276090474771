import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import dogIcon from 'src/assets/images/icons/black-dog-icon.svg';
import noImage from 'src/assets/images/no_image.png';
import MediaDialogBox from 'src/views/component/UI/MediaDialog/MediaDialogBox';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../matching.scss';

const DogImageSlider = ({ dogsData, isRemoveImg }) => {
  const sliderRef = useRef(null);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [modalMedia, setModalMedia] = useState('');

  const settings = {
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const openMediaModal = (media) => {
    setModalMedia(media);
    setToogleDialog(!toogleDialog);
  };

  // useEffect(() => {
  //   const video = document.getElementById('matchingVideo');

  //   // Disable Picture-in-Picture functionality
  //   video.addEventListener('loadedmetadata', () => {
  //     video.disablePictureInPicture = true;
  //   });
  // }, []);

  useEffect(() => {
    const videos = document.querySelectorAll('.matchingVideo');

    videos.forEach((video) => {
      video.addEventListener('loadedmetadata', () => {
        video.disablePictureInPicture = true;
      });
    });
  }, []);

  return (
    <Box>
      <Box className='d-flex mt-4'>
        <img src={dogIcon} alt='dog icon' style={{ marginRight: '12px' }} />
        <Typography className='font-r-12'>{dogsData?.name}</Typography>
      </Box>
      <Box className='b-bottom mt-2 mb-2' />
      <Slider ref={sliderRef} {...settings} className='dog-slider'>
        {dogsData &&
          dogsData?.images?.length > 0 &&
          dogsData?.images?.map((obj, i) => {
            return (
              <Box className='image-slider' key={i}>
                {obj?.url?.substr(obj?.url?.length - 3) === 'mp4' ? (
                  <video
                    className='dog-slider-img matchingVideo'
                    src={obj?.url}
                    controls
                    id='matchingVideo'
                    controlsList='nodownload nofullscreen noremoteplayback noplaybackrate noseeking'
                  />
                ) : (
                  <LazyLoadImage
                    src={!isRemoveImg ? obj?.url : noImage}
                    alt=''
                    className='dog-slider-img pointer'
                    effect='blur'
                    width='130px'
                    onClick={() => openMediaModal(obj?.url)}
                  />
                )}
                {/* <img
                    alt=''
                    className='dog-slider-img pointer'
                    loading='lazy'
                    src={obj?.url}
                    onClick={() => openMediaModal(obj?.url)}
                  /> */}
              </Box>
            );
          })}
      </Slider>
      {/* <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <Box className='media-modal'>
            <img src={modalMedia} alt='' className='media-modal-img' />
          </Box>
        }
      /> */}
      <MediaDialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        media_type='image'
        media_url={modalMedia}
      />
    </Box>
  );
};

export default DogImageSlider;
