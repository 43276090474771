import React from 'react';
import { Box } from '@mui/material';
import './index.scss';
// import axiosInstance from '../../../../helpers/axios/axiosInstance';
// import { URLS } from '../../../../helpers/constants/urls';
// import { useDispatch } from 'react-redux';
// import { addProgressBar } from '../../../../helpers/store/actions/progressBarSlice';
// import PreLoader from '../../../../components/Loader';
const ProgressBar = ({ processData }) => {
  // const dispatch = useDispatch();
  // const [processData, setProcessData] = useState(0);
  // const [loader, setLoader] = useState(false);
  // const getAllQuestions = async () => {
  //   var black_data = 0;
  //   var job = true;
  //   var education = true;
  //   var height_weight = true;
  //   var vices = true;
  //   try {
  //     setLoader(true);
  //     const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
  //     if (status === 200) {
  //       // setApiMessage('success', data?.message);
  //       // setQuestions(data?.types);
  //       data?.questions?.map((item) => {
  //         if (item.id === 8) {
  //           black_data++; //looking for
  //         }
  //         if (
  //           item.id === 9 ||
  //           item.id === 10 ||
  //           item.id === 11 ||
  //           item.id === 12
  //         ) {
  //           if (job) {
  //             black_data++; //job
  //             job = false;
  //           }
  //         }
  //         if (item.id === 13 || item.id === 14 || item.id === 15) {
  //           if (education) {
  //             black_data++; //education
  //             education = false;
  //           }
  //         }
  //         if (item.id === 16) {
  //           black_data++; //hobbies
  //         }
  //         if (item.id === 17) {
  //           black_data++; //religion
  //         }
  //         if (item.id === 18) {
  //           black_data++; //politics
  //         }
  //         if (item.id === 19) {
  //           black_data++; //children
  //         }
  //         if (item.id === 20) {
  //           black_data++; //how many kids
  //         }
  //         if (item.id === 23 || item.id === 24) {
  //           if (height_weight) {
  //             black_data++; //height weight
  //             height_weight = false;
  //           }
  //         }
  //         if (item.id === 25) {
  //           black_data++; //ethnicity
  //         }
  //         if (item.id === 26) {
  //           black_data++; //family plan
  //         }
  //         if (
  //           item.id === 28 ||
  //           item.id === 29 ||
  //           item.id === 30 ||
  //           item.id === 31
  //         ) {
  //           if (vices) {
  //             black_data++; //vices
  //             vices = false;
  //           }
  //         }
  //         if (item.id === 33) {
  //           black_data++; //location
  //         }
  //         if (item.id === 2) {
  //           black_data++; //about gender
  //         }
  //         if (item.id === 54) {
  //           black_data++; //about age
  //         }
  //         if (item.id === 55) {
  //           black_data++; //about dog breed
  //         }
  //         if (item.id === 56) {
  //           black_data++; //about job
  //         }
  //         if (item.id === 57) {
  //           black_data++; //about education
  //         }
  //         if (item.id === 58) {
  //           black_data++; //about hobbies
  //         }
  //         if (item.id === 59) {
  //           black_data++; //about religion
  //         }
  //         if (item.id === 60) {
  //           black_data++; //about politics
  //         }
  //         if (item.id === 61) {
  //           black_data++; //about height weight
  //         }
  //         if (item.id === 62) {
  //           black_data++; //about ethnicity
  //         }
  //         if (item.id === 63) {
  //           black_data++; //about vices
  //         }
  //         if (item.id === 64) {
  //           black_data++; //about location
  //         }
  //         return true;
  //       });
  //       setTimeout(() => {
  //         setProcessData(black_data);
  //         // dispatch(addProgressBar((25 - black_data) * 6));
  //         setLoader(false);
  //       }, 100);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     // setApiMessage('error', error?.response?.data?.message);
  //   }
  // };
  // useEffect(() => {
  //   getAllQuestions();
  // }, []);

  const activeDashes = Math.ceil((processData / 100) * 6);
  //  Math.ceil((processData / 100) * 4);
  //  Math.ceil((processData / 100) * 6);

  const dashes = [];
  for (let i = 0; i < 6; i++) {
    const isActive = i < activeDashes;
    const dashColor = isActive ? 'active-dash' : 'inactive-dash';
    dashes.push(<Box key={i} className={`dash ${dashColor}`} />);
  }

  return (
    <Box className='progress-bar'>
      {/* {loader && <PreLoader />}  */}
      {dashes}
    </Box>
  );
};

export default ProgressBar;
