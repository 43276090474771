import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProfileData,
  // clearProfileData,
} from '../../../../helpers/store/actions/profileSetup';
import { setSVGFile } from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/fname-dog.svg';

const SetFirstName = ({ localesData, handleNext }) => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileData);

  useEffect(() => {
    // dispatch(clearProfileData());
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        first_name: profileData?.first_name ? profileData?.first_name : '',
      }}
      // enableReinitialize={true}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required(localesData?.validation?.REQUIRED),
      })}
      onSubmit={(requestData, { resetForm }) => {
        // let sendData = {
        //   first_name: requestData.first_name,
        // };
        handleNext();
        dispatch(
          addProfileData({ key: 'first_name', value: requestData.first_name })
        );
        // dispatch(clearProfileData());
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className='form-content'>
          <Box className='t-center mb-10'>
            <Typography variant='h3' className='s-bold mb-2'>
              {localesData?.HI_THERE},
            </Typography>
            <Typography
              variant='h4'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.FNAME_DESC,
              }}
            />
            <Typography
              variant='h3'
              className='s-bold'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.YOUR_FIRST_NAME,
              }}
            />
          </Box>

          <Box className='field-content mb-8'>
            <CustomTextField
              fullWidth
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
              variant='standard'
              className='custom-textfield'
              name='first_name'
              value={values.first_name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={localesData?.NEXT}
              background='#000000'
              fontWeight='600'
              type='submit'
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SetFirstName;
