import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Typography,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import RangeSlider from 'src/views/component/UI/RangeSlider';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import UpgradePlan from '../UpgradePlan';
// import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
// import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';

const valuetext = (value) => {
  return `${value}`;
};

const AboutHeightWeight = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const minDistance = 1;
  const [heightData, setHeightData] = useState('');
  const [weightData, setWeightData] = useState('');
  const [dontCareHeight, setDontCareHeight] = useState([]);
  const [dontCareWeight, setDontCareWeight] = useState([]);
  const [heightSlider, setHeightSlider] = useState([]);
  const [weightSlider, setWeightSlider] = useState([]);
  const [isHeightPrivate, setIsHeightPrivate] = useState(false);
  const [isWeightPrivate, setIsWeightPrivate] = useState(false);

  const handleChangeHeight = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setHeightSlider([
        Math.min(newValue[0], heightSlider[1] - minDistance),
        heightSlider[1],
      ]);
    } else {
      setHeightSlider([
        heightSlider[0],
        Math.max(newValue[1], heightSlider[0] + minDistance),
      ]);
    }
  };

  const handleChangeWeight = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setWeightSlider([
        Math.min(newValue[0], weightSlider[1] - minDistance),
        weightSlider[1],
      ]);
    } else {
      setWeightSlider([
        weightSlider[0],
        Math.max(newValue[1], weightSlider[0] + minDistance),
      ]);
    }
  };

  useEffect(() => {
    setDontCareHeight([{ id: 1, title: 'Don’t Care' }]);
    setDontCareWeight([{ id: 1, title: 'Don’t Care' }]);
    answers?.map((item) => {
      if (item.id === 61) {
        if (item.Option[0]?.answer === null || item.Option[0]?.answer === '') {
          setHeightData({
            opt_id: item.Option[0]?.id,
            dont_care: false,
          });
          setHeightSlider([130, 250]);
        } else {
          var ansHeight = item.Option[0]?.answer;
          setHeightData({
            dont_care: ansHeight?.split('-')[2] === 'true' ? true : false,
            opt_id: item.Option[0]?.id,
            is_must: item.Option[0].isMust === 1 ? true : false,
          });
          setHeightSlider([
            Number(ansHeight?.split('-')[0]),
            Number(ansHeight?.split('-')[1]),
          ]);
        }
        if (item.Option[1]?.answer === null || item.Option[1]?.answer === '') {
          setWeightData({
            opt_id: item.Option[1]?.id,
            dont_care: false,
          });
          setWeightSlider([30, 200]);
        } else {
          var ansWeight = item.Option[1]?.answer;
          setWeightData({
            dont_care: ansWeight?.split('-')[2] === 'true' ? true : false,
            opt_id: item.Option[1]?.id,
            is_must: item.Option[1].isMust === 1 ? true : false,
          });
          setWeightSlider([
            Number(ansWeight?.split('-')[0]),
            Number(ansWeight?.split('-')[1]),
          ]);
        }
        setIsHeightPrivate(
          answers?.find((o) => o?.id === 61)?.Option?.[0]?.isMust === 1
            ? true
            : false
        );
        setIsWeightPrivate(
          answers?.find((o) => o?.id === 61)?.Option?.[1]?.isMust === 1
            ? true
            : false
        );
      }
      return true;
    });
  }, [answers]);

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: heightData?.opt_id,
      answer:
        heightSlider?.[0]?.toString() +
        '-' +
        heightSlider?.[1]?.toString() +
        '-' +
        heightData?.dont_care?.toString(),
      startRange: heightData?.dont_care ? 130 : heightSlider?.[0],
      endRange: heightData?.dont_care ? 250 : heightSlider?.[1],
    });

    opt.push({
      optionId: weightData?.opt_id,
      answer:
        weightSlider?.[0]?.toString() +
        '-' +
        weightSlider?.[1]?.toString() +
        '-' +
        weightData?.dont_care?.toString(),
      startRange: weightData?.dont_care ? 30 : weightSlider?.[0],
      endRange: weightData?.dont_care ? 200 : weightSlider?.[1],
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveInfo();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const saveInfo = async () => {
    var opt = [];

    opt.push({
      optionId: heightData?.opt_id,
      answer:
        heightSlider?.[0]?.toString() +
        '-' +
        heightSlider?.[1]?.toString() +
        '-' +
        heightData?.dont_care?.toString(),
      lookingFor: true,
      startRange: heightData?.dont_care ? 130 : heightSlider?.[0],
      endRange: heightData?.dont_care ? 250 : heightSlider?.[1],
      isMust: isHeightPrivate,
      selected: true,
    });

    opt.push({
      optionId: weightData?.opt_id,
      answer:
        weightSlider?.[0]?.toString() +
        '-' +
        weightSlider?.[1]?.toString() +
        '-' +
        weightData?.dont_care?.toString(),
      lookingFor: true,
      startRange: weightData?.dont_care ? 30 : weightSlider?.[0],
      endRange: weightData?.dont_care ? 200 : weightSlider?.[1],
      isMust: isWeightPrivate,
      selected: true,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        navigate('/edit-profile', {
          state: 'looking-for',
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChipHeightClick = () => {
    setHeightData({
      ...heightData,
      dont_care: !heightData?.dont_care,
    });
  };

  const handleChipWeightClick = () => {
    setWeightData({
      ...weightData,
      dont_care: !weightData?.dont_care,
    });
  };

  return (
    <>
      <Box>
        {/*className='' about-location */}
        {/* <Typography variant='h6' className='s-font-20 color-pink mt-2 mb-8'>
          {localesData?.profile?.WHAT_ARE_YOU_LOOKING_FOR}
        </Typography> */}
        <Box className='chip-container-box'>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
          <Typography variant='h3' className='mb-5 t-center s-bold'>
            {localesData?.profile?.HEIGHT}
          </Typography>
        </Box>
        <Box className='mb-9' m='0 45px'>
          <Box className='d-flex space-between'>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
            >
              {heightSlider?.[0]?.toString()}cm
              {/* 130cm */}
            </Typography>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
            >
              {heightSlider?.[1]?.toString()}cm
              {/* 250cm */}
            </Typography>
          </Box>
          <RangeSlider
            min={130}
            max={250}
            getAriaLabel={() => 'Minimum distance'}
            value={heightSlider}
            onChange={handleChangeHeight}
            valueLabelDisplay='on'
            getAriaValueText={valuetext}
            disableSwap
          />
        </Box>
        <Box className='looking-for-chip'>
          <Box className='chip-container t-center pb-4'>
            {dontCareHeight?.map((item, i) => (
              <Chip
                className={`border-chip-inner border font-r-11 ${
                  heightData?.dont_care ? 'chip-active' : ''
                } `}
                style={{ width: '144px' }}
                key={i}
                label={item?.title}
                onClick={() => handleChipHeightClick()}
              />
            ))}
          </Box>
        </Box>
        <Box className='d-flex mb-10 justify-center'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch className='ios-switch' />}
              name='isHeightPrivate'
              checked={isHeightPrivate}
              onChange={(e) => setIsHeightPrivate(e.target.checked)}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
          </Typography>
          {/* {isHeightPrivate ? <UnLockIcon /> : <LockIcon />} */}
        </Box>

        <Box className='mb-9' m='0 45px'>
          <Box className='d-flex space-between'>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
            >
              {/* 30kg */}
              {weightSlider?.[0]?.toString()}kg
            </Typography>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
              kg
            >
              {/* 200kg */}
              {weightSlider?.[1]?.toString()}kg
            </Typography>
          </Box>
          <RangeSlider
            min={30}
            max={200}
            getAriaLabel={() => 'Minimum distance'}
            value={weightSlider}
            onChange={handleChangeWeight}
            valueLabelDisplay='on'
            getAriaValueText={valuetext}
            disableSwap
          />
        </Box>
        <Box className='looking-for-chip'>
          <Box className='chip-container t-center pb-4'>
            {dontCareWeight?.map((item, i) => (
              <Chip
                className={`border-chip-inner border font-r-11 ${
                  weightData?.dont_care ? 'chip-active' : ''
                } `}
                style={{ width: '144px' }}
                key={i}
                label={item?.title}
                onClick={() => handleChipWeightClick()}
              />
            ))}
          </Box>

          <Box className='d-flex mb-10 justify-center'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch className='ios-switch' />}
                name='isWeightPrivate'
                checked={isWeightPrivate}
                onChange={(e) => setIsWeightPrivate(e.target.checked)}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.THIS_IS_A_MUST_TO_MATCH}
            </Typography>
            {/* {isWeightPrivate ? <UnLockIcon /> : <LockIcon />} */}
          </Box>

          <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
            <CustomButton
              classname='uppercase'
              variant='contained'
              title={localesData?.DONE}
              background='#59D09D'
              fontWeight='600'
              onClick={handleOnSubmit}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutHeightWeight;
