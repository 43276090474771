const typography = {
  h1: {
    fontWeight: 400,
    fontSize: 35,
    lineHeight: '42px',
    fontFamily: 'Open Sans, sans-serif',
  },
  h2: {
    fontWeight: 400,
    fontSize: 29,
    lineHeight: '35px',
    fontFamily: 'Open Sans, sans-serif',
  },
  h3: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '30px',
    fontFamily: 'Open Sans, sans-serif',
  },
  h4: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '24px',
    fontFamily: 'Open Sans, sans-serif',
  },
  h5: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: 'Open Sans, sans-serif',
  },
  h6: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    fontFamily: 'Open Sans, sans-serif',
  },
  overline: {
    fontWeight: 400,
  },
};

export default typography;
