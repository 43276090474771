import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

export const CustomTextField = withStyles({
  root: {
    '& .MuiInput-root': {
      backgroundColor: 'transparent',
      height: '32px',

      '&:before': {
        borderBottom: '1.5px solid #000000 !important',
      },
      '&:after': {
        borderBottom: '1.5px solid #000000 !important',
      },
    },
    '& .MuiInputBase-input': {
      fontWeight: '600',
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
})(TextField);
