import React from 'react';
import Page from '../../../components/Page';
import LandingScreen from 'src/views/component/auth/LandingScreen';

const LandingView = () => {
  return (
    <Page title='Giveaway'>
      <LandingScreen />
    </Page>
  );
};

export default LandingView;
