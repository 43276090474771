import React from 'react';
import Page from '../../../components/Page';
import ProfilePreview from 'src/views/component/application/ManageProfile/AccountDetails/ProfilePreview';

const ProfilePreviewpage = () => {
  return (
    <Page title='Profile Preview'>
      <ProfilePreview />
    </Page>
  );
};

export default ProfilePreviewpage;
