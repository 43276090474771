import React from 'react';
import Page from '../../../components/Page';
import SelectedCollectBones from 'src/views/component/application/KennelThrownBones/CollectBones/SelecteCollectBones';

const SelectedCollectBonesView = () => {
  return (
    <Page title=''>
      <SelectedCollectBones />
    </Page>
  );
};

export default SelectedCollectBonesView;
