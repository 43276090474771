import React from 'react';
import { Box, Typography } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';

const SubscriptionAlert = ({
  alertText,
  handleSubPlan,
  setSubToggle,
  acceptBtn,
  declineBtn,
}) => {
  return (
    <Box className='subscription-alert-modal'>
      <Box className='pb-7'>
        <Typography className='t-center sub-alert-text' variant='h6'>
          {alertText}
        </Typography>
      </Box>

      <Box className='btn-sec'>
        <CustomButton
          variant='contained'
          title={acceptBtn}
          background='#F54D71'
          fontWeight='400'
          onClick={() => handleSubPlan()}
        />
        <CustomButton
          variant='contained'
          title={declineBtn}
          background='#ffffff'
          color='#F54D71'
          border='1px solid #F54D71'
          fontWeight='400'
          onClick={() => setSubToggle(false)}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionAlert;
