import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
// import DummyImg from 'src/assets/images/matching-dummy-img.png';
import DummyImg from 'src/assets/images/logo-black.png';
import { IntlContext } from 'src/App';
import '../matching.scss';

const NoDataFound = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className='matching-no-data-found'>
      <Box className='dummy-img d-flex'>
        <img
          alt='not found'
          className='no-data-dummy-img mb-2'
          src={DummyImg}
        />
      </Box>
      <Typography
        className='t-center header-text'
        variant='h4'
        dangerouslySetInnerHTML={{
          __html: localesData?.matching_no_data_found?.NO_DATA_FOUND,
        }}
      />
    </Box>
  );
};

export default NoDataFound;
