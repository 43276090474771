import React, { useState } from 'react';
import { Avatar, Box, Typography, Stack, Rating } from '@mui/material';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-white.svg';
import { useNavigate } from 'react-router-dom';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import PreLoader from 'src/components/Loader';

const FavPlaceDetails = ({ showPlaceInfo, callStatusApi }) => {
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const localAuth = fetchFromStorage(identifiers.AUTH_DATA);
  const userData = fetchFromStorage(identifiers.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const AddToFavNow = async (ID, place_detail) => {
    setLoader(true);
    const param_data = {
      following: ID.toString(),
      type: 'place',
    };
    const { status, data } = await axiosInstance.post(
      URLS.FAVOURITE,
      param_data
    );
    if (status === 200) {
      callStatusApi(place_detail);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const GetDirectoryList = async (placeID, place_detail) => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.DIRECTORY);
      if (status === 200) {
        var favID = '';
        data?.results?.map((item) => {
          if (placeID === item?.placeId) {
            favID = item?.id;
          }
        });
        favID && AddToFavNow(favID, place_detail);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const callFavouriteAPI = async () => {
    setLoader(true);
    let place_detail = showPlaceInfo;
    const param_data = {
      name:
        place_detail?.place_type !== ''
          ? place_detail?.name.toString() + ' - ' + place_detail?.place_type
          : place_detail?.name.toString(),
      address:
        place_detail?.address !== '' || place_detail?.address !== undefined
          ? place_detail?.address.toString()
          : '',
      lat: place_detail?.lat.toString(),
      long: place_detail?.long.toString(),
      imageUrl: place_detail?.photo_reference
        ? place_detail?.photo_reference?.toString()
        : null,
      placeId: place_detail?.placeId.toString(),
      place_rating: place_detail?.place_rating,
      place_total_review: place_detail?.place_total_review,
      place_status: place_detail?.place_status,
      place_type: place_detail?.place_type,
      phoneNumber: place_detail?.sponsorId ? place_detail?.phoneNumber : null,
      sponsorName: place_detail?.sponsorId ? place_detail?.sponsorName : null,
      sponsorImage: place_detail?.sponsorId ? place_detail?.sponsorImage : null,
      sponsorWebsite: place_detail?.sponsorId
        ? place_detail?.sponsorWebsite
        : null,
      benefitId: place_detail?.sponsorId ? place_detail?.benefitId : null,
      directoryType: place_detail?.sponsorId ? 'sponsor' : 'normal',
    };
    const { status, data } = await axiosInstance.post(
      URLS.DIRECTORY,
      param_data
    );
    if (status === 200) {
      GetDirectoryList(place_detail?.placeId.toString(), place_detail);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const callRemoveFavouriteAPI = async (FavID, placeDetails) => {
    setLoader(true);
    try {
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + FavID
      );
      if (status === 200) {
        callStatusApi(placeDetails);
        setLoader(false);
        navigate('/favourites');
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const onFavPress = async () => {
    if (!reduxAuth && !localAuth) {
      // log out
      navigate('/');
    } else {
      // if (state.isConnected) {
      if (showPlaceInfo?.isLike) {
        callRemoveFavouriteAPI(showPlaceInfo?.fav_id, showPlaceInfo);
      } else {
        if (userData?.isPaid) {
          if (showPlaceInfo?.isDirectoryExist) {
            GetDirectoryList(showPlaceInfo?.placeId.toString(), showPlaceInfo);
          } else {
            callFavouriteAPI();
          }
        } else {
          setToogleDialog(true);
        }
      }
      // }
      // else {
      //     //   Toast.show(ScreenText.NET_CONNECTION_ERROR_MESSAGE, Toast.LONG);
      //     setApiMessage('error', 'Please check your internet connection and try again.')
      // }
    }
  };

  return (
    <Box className='map-place-details'>
      {loader && <PreLoader />}
      <Box className='map-place-details-sec'>
        <Box className='map-places'>
          <Box className='place-details-left'>
            <Avatar
              variant='square'
              className='place-image'
              src={showPlaceInfo?.imageUrl ? showPlaceInfo?.imageUrl : ''}
            />
          </Box>
          <Box className='place-details-right'>
            <Typography className='place-name'>
              {showPlaceInfo?.name}
            </Typography>
            {showPlaceInfo && (
              <Box className='rating-details'>
                <Stack spacing={1}>
                  <Rating
                    name='half-rating'
                    className='rating-sec'
                    readOnly
                    defaultValue={showPlaceInfo?.place_rating}
                    precision={0.5}
                  />
                </Stack>
                {showPlaceInfo?.place_total_review && (
                  <Typography className='rate-number'>
                    {'(' + showPlaceInfo?.place_total_review + ')'}
                  </Typography>
                )}
              </Box>
            )}

            <Typography className='place-address'>
              {showPlaceInfo?.address}
            </Typography>
            {showPlaceInfo?.place_type && (
              <Typography variant='span' className='place-status'>
                {showPlaceInfo?.place_type + ' •'}{' '}
              </Typography>
            )}
            {showPlaceInfo?.place_status === 'OPERATIONAL' && (
              <Typography variant='span' className='place-status color-green'>
                {' '}
                Open
              </Typography>
            )}
            {/* <Box>
                            {
                                state?.place_detail?.webSite && <Link className='sponsored-name visit-link' href={state?.place_detail?.webSite} target='_blank'>{"Visit Website"}</Link>
                            }
                        </Box> */}
          </Box>
        </Box>
        <Box className='map-places mt-2'>
          {/* <Box className='place-details-left'>
                        <Avatar variant='square' className='place-image' src={showPlaceInfo?.icon} />
                    </Box> */}
          <Box className='place-details-right'>
            {/* <Typography className='sponsored-name'>Sponsored By</Typography>
                        <Typography className='sponsored-name f-bold'>{showPlaceInfo?.place_name}</Typography> */}
            <Box className={'fav-benefit-button-sec'}>
              {
                <CustomButton
                  variant='text'
                  title={'Favourite'}
                  classname={
                    showPlaceInfo?.isLike
                      ? 'fav-bene-button pink-button mr-9'
                      : 'fav-bene-button mr-9'
                  }
                  leftIcon={
                    showPlaceInfo?.isLike ? <FavSelectedIcon /> : <FavIcon />
                  }
                  onClick={() => {
                    // if (checkIsBenefit(showPlaceInfo)) {
                    //     if (userData?.isPaid) {
                    //         if (showPlaceInfo?.benefits[0]?.is_fav) {
                    //             callRemoveBenefitFromFav(showPlaceInfo, 0, false)
                    //         } else {
                    //             callAddBenefitIntoFav(showPlaceInfo, 0, false)
                    //         }
                    //     } else {
                    //         alert('')
                    //     }

                    // } else {
                    onFavPress();
                    // }
                  }}
                />
              }
              {/* {
                                state?.place_detail?.isSponsered && state?.place_detail?.benefits &&
                                state?.place_detail?.benefits !== null &&
                                state?.place_detail?.benefits?.length !== 0 && (
                                    <CustomButton
                                        variant='text'
                                        title={'Benefits'}
                                        classname='fav-bene-button'
                                        leftIcon={<Benefits />}
                                        onClick={() => {
                                            if (userData?.isPaid) {
                                                if (
                                                    state?.place_detail?.benefits &&
                                                    state?.place_detail?.benefits !== null &&
                                                    state?.place_detail?.benefits.length !== 0
                                                ) {

                                                    // navigate(`/benefits/${state?.place_detail?.id}`, { state: { placeDetails: state?.placeDetails } })
                                                    state?.place_detail && state?.place_detail?.benefits && state?.place_detail?.benefits[0] && navigate(`/benefits/fav-benefit/${state?.place_detail?.benefits[0]?.id}`, { state: { placeDetails: state?.place_detail } })
                                                } else {
                                                    setApiMessage('error', 'Benefits are not available');
                                                }
                                            } else {
                                                alert('isPaid :', userData?.isPaid)
                                            }
                                        }}
                                    />
                                )
                            } */}
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};
export default FavPlaceDetails;
