import React from 'react';
import { Box } from '@mui/material';
// import FemaleImg from 'src/assets/images/match-female.png';
// import MaleImg from 'src/assets/images/match-male.png';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import ManAnimation from 'src/assets/animation/throwman.mp4';
import WomanAnimation from 'src/assets/animation/throwwoman.mp4';
import '../matching.scss';

const MatchToSend = () => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);

  return (
    <Box className='match-to-send'>
      {/* {profileData?.gender === 2 ? (
        <Box className='img-sec'>
          <img alt='' variant='square' className='animation-video' src={MaleImg} />
        </Box>
      ) : (
        <Box className='img-sec'>
          <img alt='' variant='square' className='animation-video' src={FemaleImg} />
        </Box>
      )} */}

      {profileData?.gender === 0 ? (
        <Box className='img-sec'>
          <video
            alt=''
            className='animation-video'
            src={ManAnimation}
            autoPlay
          />
        </Box>
      ) : (
        <Box className='img-sec'>
          <video
            alt=''
            className='animation-video'
            src={WomanAnimation}
            autoPlay
          />
        </Box>
      )}
    </Box>
  );
};

export default MatchToSend;
