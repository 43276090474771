import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    manageAccount: '',
};

export const manageaccountSlice = createSlice({
    name: 'manageaccount',
    initialState,
    reducers: {
        manageAccountData: (state, action) => {
            return (action.payload)
        },
    },
});
export const { manageAccountData } = manageaccountSlice.actions;

export default manageaccountSlice.reducer;