import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFromStorage, saveToStorage } from '../../helpers/context';
import { identifiers } from '../../helpers/constants/identifier';
import { handleNext } from '../../helpers/store/actions/navigateSlice';
import ScrollToTop from '../../components/ScrollTop';
import axiosInstance from '../../helpers/axios/axiosInstance';
import { URLS } from '../../helpers/constants/urls';
import { setApiMessage } from '../../helpers/commonFunctions';
import PreLoader from '../../components/Loader';
import TopBar from './TopBar';
import { clearSvgData } from '../../helpers/store/actions/progressImage';
import BottomBar from 'src/views/component/UI/MenuListItem';
import '../layout.scss';

const ProfileLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const reduxAuth = useSelector((state) => state?.auth_data?.auth);
  const localAuth = fetchFromStorage(identifiers?.AUTH_DATA);
  const localUserData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(false);

  const auth = reduxAuth ? reduxAuth : localAuth;

  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;

        var selectedCount = 0;
        all_ans?.map((itm) => {
          if (itm?.id === 74) {
            itm?.Option?.map((item) => {
              if (
                item?.answer === true ||
                item?.answer === '1' ||
                item?.answer === 1
              ) {
                selectedCount++;
              }
              return true;
            });
          }
          return true;
        });
        if (selectedCount === 0) {
          getProfile(true);
        } else {
          getProfile(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getProfile = async (userType) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveToStorage(identifiers?.USER_DATA, data);
        // if (userType) {
        //   navigate('/profile-setup');
        //   dispatch(handleNext(0));
        // } else
        if (!data?.images || data?.images.length === 0) {
          navigate('/profile-setup');
          dispatch(handleNext(1));
        } else if (
          data?.dob === '' ||
          data?.dob === null ||
          data?.dob === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(2));
        } else if (
          data?.gender === '' ||
          data?.gender === null ||
          data?.gender === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(3));
        } else if (
          data?.looking_for === '' ||
          data?.looking_for === null ||
          data?.looking_for === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(4));
        } else {
          if (location?.pathname === '/profile-setup') {
            // if (reduxStep === 4) {
            //   navigate('/profile-setup');
            //   dispatch(handleNext(4));
            // }
            if (reduxStep === 5) {
              navigate('/profile-setup');
              dispatch(handleNext(5));
            } else {
              navigate('/');
            }
          }
          dispatch(handleNext(0));
          dispatch(clearSvgData());
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // const pageTopRef = useRef(null);

  // const scrollToTop = () => {
  //   if (pageTopRef.current) {
  //     pageTopRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
  //   }
  // };

  // useEffect(() => {
  //   scrollToTop();
  // }, [reduxStep]);

  useEffect(() => {
    // getProfile();
    getAllAnswars();
    // eslint-disable-next-line
  }, []);

  const showFooter =
    location?.pathname === '/matching' ||
    location?.pathname === '/kennel' ||
    location?.pathname === '/directory' ||
    location?.pathname === '/benefits' ||
    location?.pathname === '/benefits/BenefitDetails' ||
    location?.pathname === '/favourites' ||
    location?.pathname.includes('/favourites/benefit') ||
    location?.pathname.includes('/favourites/matches');

  return (
    <Box className='body-image'>
      <Box className='main-layout-wrapper'>
        {loader && <PreLoader />}
        <ToastContainer style={{ padding: '5px' }} limit={1} />
        <ScrollToTop />
        <TopBar auth={auth} navigate={navigate} localUserData={localUserData} />
        <Box>
          <Box
            className={
              location?.pathname === '/contact'
                ? 'layout-wrapper profile-layout-wrapper o-hidden m-0'
                : 'layout-wrapper profile-layout-wrapper ' ||
                  location?.pathname === '/favourites'
                ? 'layout-wrapper profile-layout-wrapper '
                : 'layout-wrapper profile-layout-wrapper o-hidden'
            }
          >
            <Outlet />

            {showFooter && <BottomBar />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileLayout;
