import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Typography,
  FormControl,
  Select,
} from '@mui/material';
import { IntlContext } from '../../../../../App';
import { CustomTextField } from '../../../UI/textfield';
import IOSSwitch from '../../../UI/IOSSwitch';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import { CustomSelect } from 'src/views/component/UI/select';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage } from 'src/helpers/context';

const Employed = ({
  handleChange,
  empLivingData,
  doYouWork,
  doYouWorkThere,
  yearData,
  monthData,
  isEdit,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);

  const yearSplit =
    doYouWorkThere?.options?.length > 0
      ? doYouWorkThere?.options?.[0]?.answer?.split(' ')?.[0] +
        ' ' +
        doYouWorkThere?.options?.[0]?.answer?.split(' ')?.[1]
      : '';
  const monthSplit =
    doYouWorkThere?.options?.length > 0
      ? doYouWorkThere?.options?.[0]?.answer?.split(' ')?.[2] +
        ' ' +
        doYouWorkThere?.options?.[0]?.answer?.split(' ')?.[3]
      : '';
  useEffect(() => {
    setYear(yearData ? yearData : yearSplit);
    setMonth(monthData ? monthData : monthSplit);
    // eslint-disable-next-line
  }, [yearData, monthData]);

  return (
    <Box sx={{ textAlign: 'left' }}>
      <Box className='field-content'>
        <CustomTextField
          fullWidth
          variant='standard'
          className='custom-textfield pb-2'
          name='empLivingData'
          value={empLivingData?.options?.[0]?.answer}
          onChange={(e) => handleChange(e)}
        />
      </Box>
      <Box>
        <Typography variant='h5' className='s-bold pb-2'>
          {/* {localesData?.userwork?.what_do_you_do_for_a_living} */}
          {empLivingData?.title}
        </Typography>
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch />}
              name='empLivingDataSwitch'
              checked={empLivingData?.private}
              onChange={(e) => {
                if (isEdit) {
                  profileData?.isPaid === false
                    ? setToogleDialog(true)
                    : handleChange(e);
                } else {
                  handleChange(e);
                }
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {empLivingData?.private ? <LockIcon /> : <UnLockIcon />}
        </Box>
      </Box>
      <Box className='field-content'>
        <CustomTextField
          fullWidth
          variant='standard'
          className='custom-textfield pb-2'
          name='doYouWork'
          value={doYouWork?.options?.[0]?.answer}
          onChange={(e) => handleChange(e)}
        />
      </Box>
      <Box>
        <Typography variant='h5' className='s-bold pb-2'>
          {/* {localesData?.userwork?.where_do_you_work} */}
          {doYouWork?.title}
        </Typography>
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch />}
              name='doYouWorkSwitch'
              checked={doYouWork?.private}
              // onChange={(e) => {
              //   profileData?.isPaid === false
              //     ? setToogleDialog(true)
              //     : handleChange(e);
              // }}
              onChange={(e) => {
                if (isEdit) {
                  profileData?.isPaid === false
                    ? setToogleDialog(true)
                    : handleChange(e);
                } else {
                  handleChange(e);
                }
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {doYouWork?.private ? <LockIcon /> : <UnLockIcon />}
        </Box>
      </Box>
      <Box className='field-content'>
        {/* <CustomTextField
          fullWidth
          variant='standard'
          className='custom-textfield pb-2'
          name='doYouWorkThere'
          onChange={(e) => handleChange(e)}
        /> */}
        <Box className='dog-age-block-wrap mb-2'>
          <Box>
            <FormControl>
              <Select
                fullWidth
                labelId='year-select-label'
                id='year-select'
                value={year}
                name='year'
                variant='standard'
                input={<CustomSelect />}
                className='dog-month custom-select'
                IconComponent={DropdownIcon}
                onChange={(e) => {
                  handleChange(e);
                  setYear(e.target.value);
                }}
              >
                {identifiers?.Years.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <Select
                fullWidth
                labelId='month-select-label'
                id='month-select'
                value={month}
                variant='standard'
                name='month'
                className='dog-month custom-select'
                input={<CustomSelect />}
                IconComponent={DropdownIcon}
                onChange={(e) => {
                  handleChange(e);
                  setMonth(e.target.value);
                }}
              >
                {identifiers?.Months.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item?.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box className='pb-8'>
        <Typography variant='h5' className='s-bold pb-2'>
          {/* {localesData?.userwork?.how_long_have_you_worked_there} */}
          {doYouWorkThere?.title}
        </Typography>
        <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={<IOSSwitch />}
              name='doYouWorkThereSwitch'
              checked={doYouWorkThere?.private}
              // onChange={(e) => {
              //   profileData?.isPaid === false
              //     ? setToogleDialog(true)
              //     : handleChange(e);
              // }}
              onChange={(e) => {
                if (isEdit) {
                  profileData?.isPaid === false
                    ? setToogleDialog(true)
                    : handleChange(e);
                } else {
                  handleChange(e);
                }
              }}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {doYouWorkThere?.private ? <LockIcon /> : <UnLockIcon />}
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default Employed;
