import React, { Component } from 'react';
import { Menu, MenuItem, Typography, Box } from '@mui/material';
import moment from 'moment';
import ViewMessage from './ViewMessage';
import { MSG_TYPE } from './MsgType';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { socket } from 'src/helpers/context/socket';
import BlockIcon from '@mui/icons-material/Block';
// import ViewLocationMessage from './ViewLocationMessage';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MediaDialogBox from 'src/views/component/UI/MediaDialog/MediaDialogBox';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { ReactComponent as AudioIcon } from 'src/assets/images/icons/audioIcon.svg';
import { ReactComponent as SeenIcon } from 'src/assets/images/icons/seenicon.svg';
import { EventEmitter } from 'src/helpers/EventEmitter';
import AudioMessage from './Audio';

class ChatMessagesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      toogleDialog: false,
      imageUrl: '',
      AudioUrl: '',
      IsShowAudio: false,
    };
    this.profileData = fetchFromStorage(identifiers?.USER_DATA);
  }
  handleContextMenu = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onDeletePress = (current_message) => {
    const url = window.location.pathname;
    const spliturl = url.split('/');
    const chatId = spliturl[spliturl.length - 1];
    let delete_obj = {
      UserID: this.profileData?.id,
      MessageID: current_message?._id,
      MessageDeleted: '1',
      to: chatId,
    };
    socket.emit('delete-message', delete_obj);
    this.handleClose();
  };

  renderReplyMessage(message) {
    switch (message?.reply?.messageType) {
      case 'string':
        return (
          <>
            <div
              onClick={() =>
                this.props.handleClickToScroll(message.reply.messageID)
              }
              className='alert-box reply-msg reply-imge-form'
            >
              <div className='message-content'>
                <div className='username sender-name'>
                  {message.reply.messageFromID === this.props.UserID
                    ? 'You'
                    : message.reply.messageFromName}
                  :
                </div>
              </div>
              <span
                className='sender-message'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {message.reply.messageContent}
              </span>
            </div>
          </>
        );
      case 'image':
        const splitdata = message.reply.messageContent.split('.');
        const array = splitdata[splitdata.length - 1];
        const imageType = array === 'gif' ? 'GIF' : 'Image';
        return (
          <>
            <div
              onClick={() =>
                this.props.handleClickToScroll(message.reply.messageID)
              }
              className='alert-box reply-msg'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div className='message-content message-image'>
                  <div className='username sender-name'>
                    {message.reply.messageFromID === this.props.UserID
                      ? 'You'
                      : message.reply.messageFromName}
                    :
                  </div>
                </div>
                <span
                  className='sender-message'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <PhotoCameraIcon style={{ marginRight: '5px' }} /> {imageType}
                </span>
              </div>
              <div className='reply-image'>
                <img src={message.reply.messageContent} alt='' />
              </div>
            </div>
          </>
        );
      case 'video':
        return (
          <>
            <div
              onClick={() =>
                this.props.handleClickToScroll(message.reply.messageID)
              }
              className='alert-box reply-msg reply-imge-form'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div className='message-content'>
                  <div className='username sender-name'>
                    {message.reply.messageFromID === this.props.UserID
                      ? 'You'
                      : message.reply.messageFromName}
                    :
                  </div>
                </div>
                <span
                  className='sender-message'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <PlayCircleFilledIcon style={{ marginRight: '5px' }} />
                  Video
                </span>
              </div>
              <div className='reply-image'>
                <video src={message.reply.messageContent} alt='' />
              </div>
            </div>
          </>
        );
      case 'audio':
        return (
          <>
            <div
              onClick={() =>
                this.props.handleClickToScroll(message.reply.messageID)
              }
              className='alert-box reply-msg reply-imge-form'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div className='message-content'>
                  <div className='username sender-name'>
                    {message.reply.messageFromID === this.props.UserID
                      ? 'You'
                      : message.reply.messageFromName}
                    :
                  </div>
                </div>
                <span
                  className='sender-message'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <AudioIcon style={{ marginRight: '5px' }} /> Audio
                </span>
              </div>
            </div>
          </>
        );
      case 'emoji':
        return (
          <>
            <div
              onClick={() =>
                this.props.handleClickToScroll(message.reply.messageID)
              }
              className='alert-box reply-msg reply-imge-form'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <div className='message-content'>
                  <div className='username sender-name'>
                    {message.reply.messageFromID === this.props.UserID
                      ? 'You'
                      : message.reply.messageFromName}
                    :
                  </div>
                </div>
                <span
                  className='sender-message'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <PhotoCameraIcon style={{ marginRight: '5px' }} /> Sticker
                </span>
              </div>
              <div className='reply-image sticker'>
                <img src={message.reply.messageContent} alt='' />
              </div>
            </div>
          </>
        );
      default:
        return '';
    }
  }

  onReplay(message) {
    var ColorCode =
      'rgb(' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ',' +
      Math.floor(Math.random() * 256) +
      ')';

    this.setState({ messageObj: message, ColorCode: ColorCode });
    // const messageContent = message.type === 'image' ? message.image ? message.type === 'string' ? message.text : message.text
    let messageContent = '';
    if (message.type === 'image') {
      messageContent = message.image;
    } else if (message.type === 'video') {
      messageContent = message.videourl;
    } else if (message.type === 'audio') {
      messageContent = message.audiourl;
    } else if (message.type === 'string') {
      messageContent = message.text;
    } else if (message.type === 'emoji') {
      messageContent = message.emoji;
    }
    let replay_obj = {
      messageID: message._id,
      messageType: message.type,
      messageFromName: message.user.name,
      messageFromID: message.user._id,
      messageContent: messageContent,
      messageImage: message.image,
    };
    this.setState({
      replayTo: replay_obj,
    });
    EventEmitter.dispatch('replayTo', replay_obj);
    EventEmitter.dispatch('isReply', true);

    this.handleClose();
  }
  openImage = (imgUrl) => {
    this.setState({ imageUrl: imgUrl, toogleDialog: true });
  };
  openAudioMsg = (Url) => {
    this.setState({ AudioUrl: Url, IsShowAudio: true });
  };
  closeAudio = () => {
    this.setState({ AudioUrl: '', IsShowAudio: false });
  };
  render() {
    const { message, UserID, itemkey } = this.props;
    const { anchorEl, toogleDialog, imageUrl } = this.state;
    const { user, createdAt } = message;
    const messageFromMe = UserID === user?._id;
    const fromChampy = user?._id === 0;
    // const DateTime = moment.unix(createdAt).format('DD/MM/YYYY, h:mm a');
    const DateTime = moment.unix(createdAt).format('h:mm a');
    const msgSendtime = DateTime.split(',');
    let className = messageFromMe
      ? 'Messages-message currentMember'
      : 'Messages-message' && fromChampy
      ? 'Messages-message'
      : 'Messages-message OtherMember';
    const contactDesign =
      message?.type === 'contact' ? ' contact-share-main' : '';

    return (
      <>
        <li
          key={itemkey}
          ref={this.props?.messageElements[message?._id]}
          className={className + ' groupChatmessage champyChatmain'}
        >
          {/* <Avatar
            className='avatar'
            alt='user image'
            src={
              user?.avatar !== ''
                ? user?.avatar
                : require('../../../../../../assets/images/bone.png')
            }
          /> */}
          <div
            className={'Message-content type-' + message.type}
            onContextMenu={
              message.messageDeleted === 2 ||
              message.messageDeleted === 1 ||
              message.type === MSG_TYPE?.MISSED_CALL
                ? null
                : this.handleContextMenu
            }
          >
            {/* <div className='username'>{user.name}</div> */}
            <div className={'text' + contactDesign}>
              {message.messageDeleted === 2 ||
              message.messageDeleted === 1 ||
              message.type === MSG_TYPE?.MISSED_CALL ? (
                ''
              ) : (
                <div
                  className={
                    messageFromMe ? 'send-date message-text' : 'send-date'
                  }
                >
                  {msgSendtime}
                </div>
              )}
              {/* {typeof message?.reply !== 'undefined' &&
              Object.keys(message?.reply).length > 0 ? (
                <>{this.renderReplyMessage(message)}</>
              ) : (
                ''
              )} */}
              {message.messageDeleted === 2 || message.messageDeleted === 1 ? (
                <div className='Message-content type-delete'>
                  <BlockIcon />
                  Message Deleted
                </div>
              ) : (
                <>
                  {typeof message?.reply !== 'undefined' &&
                  Object.keys(message?.reply).length > 0 ? (
                    <>{this.renderReplyMessage(message)}</>
                  ) : (
                    ''
                  )}
                  <ViewMessage
                    message={message}
                    chatHistory={this.props?.chatHistory}
                    startPrivateChat={this.props?.startPrivateChat}
                    seenbyAll={true}
                    champyChat={this.props?.champyChat}
                    openImage={this.openImage}
                    openAudioMsg={this.openAudioMsg}
                    messageFromMe={messageFromMe}
                  />
                </>
              )}
            </div>

            {/* {message.type === MSG_TYPE?.MISSED_CALL ? (
              ''
            ) : (
              <div className='send-date'>{msgSendtime}</div>
            )} */}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => this.onReplay(message)}>Reply</MenuItem>
              <MenuItem onClick={() => this.onDeletePress(message)}>
                Delete for me
              </MenuItem>
            </Menu>
          </div>
        </li>
        {messageFromMe &&
          message?.SeenByData?.length > 0 &&
          message?.SeenByData[0] &&
          message?.SeenByData[0]?.MessageSeenTime &&
          message?.SeenByData?.[0]?.MessageSeenTime && (
            <Box className='message-seen'>
              <Typography className='seen-text'>Seen</Typography>
              <SeenIcon />
            </Box>
          )}
        <MediaDialogBox
          open={toogleDialog}
          handleClose={() => this.setState({ toogleDialog: !toogleDialog })}
          title=''
          media_type='image'
          media_url={imageUrl}
        />
        {/* AUDIO POPUP */}
        {/* <MediaDialogBox
          open={this.state.IsShowAudio}
          handleClose={() => {
            this.setState({ IsShowAudio: !this.state.IsShowAudio });
            this.closeAudio();
          }}
          title=''
          media_type='audio'
          media_url={this.state.AudioUrl}
        /> */}
        {this.state.IsShowAudio && (
          <AudioMessage
            audiourl={this.state.AudioUrl}
            message={message}
            closeAudio={this.closeAudio}
          />
        )}
      </>
    );
  }
}
export default ChatMessagesItem;
