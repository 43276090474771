import React, { useState } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { manageAccountData } from 'src/helpers/store/actions/manageaccount';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';

const EmailOTP = ({ localesData, newUser, dispatch }) => {
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [loader, setLoader] = useState(false);

    const resendOTP = async () => {
        try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
                URLS.RESEND_PASSWORD + `/${newUser}`
            );
            if (status === 200) {
                setLoader(false);
                setApiMessage('success', data?.message);
            }
        } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
        }
    }
    return (
        <Box className='manage-account-edit-page'>
            <Typography
                variant='h6'
                className='s-font-20 color-pink heading'
                dangerouslySetInnerHTML={{
                    __html: localesData?.otp?.verify_email_address,
                }}
            />
            <Formik
                initialValues={{
                    otp: '',
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().required(localesData?.validation?.REQUIRED),
                })}
                onSubmit={async (requestData) => {
                    try {
                        setLoader(true);
                        const { status, data } = await axiosInstance.get(
                            URLS.VERIFY_OTP + `/${newUser}` + `/${requestData?.otp}`
                        );
                        if (status === 200) {
                            setLoader(false);
                            setApiMessage('success', data?.message);
                            dispatch(manageAccountData());
                            navigate('/account-details');
                        }
                    } catch (error) {
                        setLoader(false);
                        setApiMessage('error', error?.response?.data?.message);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className='form-content'>
                        <Box className='manage-account-conntet'>
                            <Box>
                                <Typography
                                    variant='h4'
                                    className='email-text'
                                    dangerouslySetInnerHTML={{
                                        __html: localesData?.otp?.verification_code_email_text
                                    }}
                                />
                                <Box className='field-content mb-2'>
                                    <CustomTextField
                                        fullWidth
                                        error={isSubmit && Boolean(touched.otp && errors.otp)}
                                        helperText={isSubmit && touched.otp && errors.otp}
                                        variant='standard'
                                        className={isSubmit ? "custom-textfield" : "custom-textfield not-submitted-textfield"}
                                        name='otp'
                                        value={values.otp || ''}
                                        onChange={(e) => { handleChange(e); setIsSubmit(false) }}
                                        onBlur={handleBlur}
                                    />
                                    <FormHelperText className='custom-field-helper'>
                                        {localesData?.otp?.verification_code}
                                    </FormHelperText>
                                </Box>
                                <Typography
                                    variant='span'
                                    className='mt-4'
                                    dangerouslySetInnerHTML={{
                                        __html: localesData?.otp?.didnt_receive_code
                                    }}
                                />
                                <Typography
                                    variant='span'
                                    className='color-pink text-underline'
                                    onClick={() => resendOTP()}
                                    dangerouslySetInnerHTML={{
                                        __html: localesData?.otp?.resend
                                    }}
                                />

                            </Box>
                            <Box className='manage-account-button'>
                                <CustomButton
                                    variant='contained'
                                    title={localesData?.otp?.verify}
                                    background='#59D09D'
                                    fontWeight='600'
                                    type='submit'
                                    classname='save-button'
                                    onClick={() => { setIsSubmit(true) }}
                                />
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default EmailOTP;
