import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { IntlContext } from '../../../../App';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';
import PreLoader from '../../../../components/Loader';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { saveToStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import { addAuth } from '../../../../helpers/store/actions/auth';
import {
    clearProfileData,
} from '../../../../helpers/store/actions/profileSetup';
import {
    clearSvgData,
} from '../../../../helpers/store/actions/progressImage';
import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';

const MobileOtp = () => {
    const intlContext = useContext(IntlContext);
    const localesData = intlContext?.messages;
    const location = useLocation();
    const { type } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxStep = useSelector((state) => state?.page_navigate?.value);
    const profileData = useSelector((state) => state?.profileData);

    const [isSubmit, setIsSubmit] = useState(false);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (!profileData?.phone_number) {
            navigate('/sign-in');
        }
    }, [navigate, profileData]);

    const resendOTP = async () => {
        const username = type === "mobile" ? profileData?.phone_number : profileData?.email;
        let sendData = {
            countryCode: profileData?.phone_country
        }
        try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
                URLS.RESEND_PASSWORD + `/${username}`,
                sendData
            );
            if (status === 200) {
                setLoader(false);
                setApiMessage('success', data?.message);
            }
        } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
        }
    }
    return (
        <Box className='page-content-wrap'>
            {loader && <PreLoader />}
            <Formik
                initialValues={{
                    otp: '',
                }}
                // enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().required(localesData?.validation?.REQUIRED),
                })}
                onSubmit={async (requestData) => {
                    const username = type === "mobile" ? profileData?.phone_number : profileData?.email
                    const url = type === "mobile" ? URLS.VERIFY_OTP + `/${username}` + `/${requestData?.otp}` :
                        URLS.VERIFY_OTP + `/${username}` + `/${requestData?.otp}?loginType=register`
                    try {
                        setLoader(true);
                        const { status, data } = await axiosInstance.get(url);
                        if (status === 200) {
                            setApiMessage('success', data?.message);
                            if (type === "mobile") {
                                saveToStorage(identifiers?.AUTH_DATA, data?.token);
                                dispatch(addAuth(data?.token));
                                saveToStorage(identifiers?.VERIFIED, false);
                                setTimeout(() => {
                                    navigate('/profile-setup');
                                }, 200);

                            } else if (type === 'email') {
                                saveToStorage(identifiers?.VERIFIED, true);
                                dispatch(clearSvgData());
                                dispatch(handleNext(0));
                                navigate('/profile-setup');
                            }
                            setLoader(false);
                        }
                    } catch (error) {
                        setLoader(false);
                        setApiMessage('error', error?.response?.data?.message);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} className='form-content'>
                        <Box className='t-center mb-10'>
                            <Typography variant='h3' className='s-bold mb-2'>
                                {type === "mobile" ? localesData?.otp?.confirmation : localesData?.otp?.confirmation_email}
                            </Typography>
                            <Typography
                                variant='h4'
                                dangerouslySetInnerHTML={{
                                    __html: type === "mobile" ? localesData?.otp?.confirmation_text : localesData?.otp?.confirmation_email_text,
                                }}
                            />
                        </Box>

                        <Box className='field-content mb-8'>
                            <CustomTextField
                                fullWidth
                                error={isSubmit && Boolean(touched.otp && errors.otp)}
                                helperText={isSubmit && touched.otp && errors.otp}
                                variant='standard'
                                className={isSubmit ? "custom-textfield" : "custom-textfield not-submitted-textfield"}
                                name='otp'
                                value={values.otp || ''}
                                onChange={(e) => { handleChange(e); setIsSubmit(false) }}
                                onBlur={handleBlur}
                            />
                            <FormHelperText className="custom-field-helper">
                                {localesData?.forgot?.OTP}
                            </FormHelperText>
                        </Box>
                        <Box>
                            <CustomButton
                                variant='contained'
                                title={type === "mobile" ? localesData?.LET_ME_IN : localesData?.DONE}
                                background={type === "mobile" ? "#F54D71" : '#59D09D'}
                                fontWeight='600'
                                type='submit'
                                onClick={() => { setIsSubmit(true); }}
                            />
                        </Box>
                        <Box className='t-center'>
                            <Typography variant='h4' className='s-bold pointer mt-2' onClick={() => { resendOTP() }}>
                                {localesData?.otp?.send_again}
                            </Typography>
                            {
                                type !== "mobile" && <Typography variant='h4' className='s-bold pointer mt-15' onClick={() => { resendOTP() }}>
                                    {localesData?.otp?.renter_email}
                                </Typography>
                            }
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default MobileOtp;
