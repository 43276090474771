import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  // Chip,
  FormHelperText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import CustomButton from '../../UI/Button/CustomButton';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { URLS } from '../../../../helpers/constants/urls';
// import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { setSVGFile } from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/wanttoseeyou-dog.svg';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useSelector } from 'react-redux';
import { ReactComponent as Checked } from '../../../../assets/images/checked.svg';
import { ReactComponent as UnChecked } from '../../../../assets/images/unchecked.svg';

const SetWantToSee = ({
  localesData,
  handleNext,
  setLoader,
  dispatch,
  questions,
  getProfile,
  answers,
}) => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  // const [selectedChips, setSelectedChips] = useState([]);
  const [errors, setErrors] = useState('');
  const [wantToSeeData, setWantToSeeData] = useState([]);
  const [lookingForVal, setLookingForVal] = useState(null);

  const genderList = [
    { label: localesData?.gender?.MALES, value: 2 },
    { label: localesData?.gender?.FEMALES, value: 3 },
    { label: localesData?.gender?.EVERYBODY, value: 0 },
  ];

  useEffect(() => {
    const wantToSee = answers?.find((o) => o?.id === 2)?.Option;
    setWantToSeeData(wantToSee);

    // let selectedOption = [];
    if (profileData?.looking_for === 0) {
      // selectedOption = genderOption?.filter((item) => item?.id === 2)?.[0];
      setLookingForVal(2);
    } else if (profileData?.looking_for === 1) {
      // selectedOption = genderOption?.filter((item) => item?.id === 3)?.[0];
      setLookingForVal(3);
    } else if (profileData?.looking_for === 2) {
      // selectedOption = genderOption?.filter((item) => item?.id === 0)?.[0];
      setLookingForVal(0);
    }
    // setSelectedChips(selectedOption);
    // eslint-disable-next-line
  }, [answers, questions, reduxStep]);

  const getProfileData = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveToStorage(identifiers?.USER_DATA, data);
        handleNext();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const updateUserDetails = async () => {
    var looking_for = 0;

    if (lookingForVal === 2) {
      looking_for = 0;
    } else if (lookingForVal === 3) {
      looking_for = 1;
    } else {
      looking_for = 2;
    }

    let sendData = {
      looking_for: looking_for,
    };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, sendData);
      if (status === 200) {
        getProfileData();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // const handleChipClick = (chip) => {
  //   chip?.id === selectedChips?.id
  //     ? setSelectedChips()
  //     : setSelectedChips(chip);

  //   setErrors('');
  // };
  const handleChange = (e) => {
    setLookingForVal(Number(e.target.value));
    setErrors('');
  };
  const handleOnSubmit = async () => {
    let opt = [];
    wantToSeeData?.map((obj) => {
      if (lookingForVal === 2) {
        opt.push({
          optionId: obj.id,
          selected: obj.id === 2 ? true : false,
          lookingFor: true,
        });
      } else if (lookingForVal === 3) {
        opt.push({
          optionId: obj.id,
          selected: obj.id === 3 ? true : false,
          lookingFor: true,
        });
      } else {
        opt.push({
          optionId: obj.id,
          selected: obj.id === 3 || obj.id === 2 ? false : true,
          lookingFor: true,
        });
      }
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };

    if (lookingForVal || lookingForVal === 0) {
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.post(
          URLS.SET_ANSWERS,
          sendData
        );
        if (status === 200) {
          setApiMessage('success', data?.message);
          updateUserDetails();
          // dispatch(
          //   addProfileData({
          //     key: 'looking_for',
          //     value: selectedChips?.id,
          //   })
          // );
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    } else {
      setErrors(localesData?.validation?.SELECT_AT_LIST_ONE);
    }
  };

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  return (
    <>
      <Box className='form-content pb-6'>
        <Box className='t-center '>
          <Typography
            variant='h4'
            className='pb-1 mb-4'
            dangerouslySetInnerHTML={{
              __html: localesData?.help_center?.PERFECT,
            }}
          />
          {/* <Typography
            variant='h4'
            className='mb-8'
            dangerouslySetInnerHTML={{
              __html: localesData?.help_center?.QUESTION,
            }}
          /> */}
          <Typography variant='h3' className='s-bold mb-2'>
            {localesData?.edit_profile?.who_you_want_to_see_you}
          </Typography>

          {/* <Typography className='font-r-13 mb-4'>
            {localesData?.help_center?.LOOKING}
          </Typography> */}
        </Box>
        <FormControl className='custom-radio pb-20'>
          <RadioGroup
            name='gender'
            value={lookingForVal}
            onChange={(e) => handleChange(e)}
            // onBlur={handleBlur}
          >
            {genderList?.map((item) => (
              <FormControlLabel
                key={item?.value}
                value={item?.value}
                control={
                  <Radio
                    size='small'
                    icon={<UnChecked />}
                    checkedIcon={<Checked />}
                    disableRipple
                  />
                }
                labelPlacement='start'
                label={<Typography variant='h5'>{item?.label}</Typography>}
              />
            ))}
          </RadioGroup>
          {errors.gender && (
            <FormHelperText sx={{ margin: '0' }} className='color-error'>
              {errors.gender}
            </FormHelperText>
          )}
        </FormControl>

        {/* <Box className='pb-20'>
          <Box className='chip-block mb-2'>
            {genderOption?.map((item, i) => (
              <Chip
                className={`chip-inner font-r-11 ${
                  selectedChips?.id === item?.id && 'chip-active'
                }`}
                key={i}
                label={item?.title}
                onClick={() => handleChipClick(item)}
              />
            ))}
          </Box>
          {errors && (
            <FormHelperText sx={{ margin: '0' }} className='color-error'>
              {errors}
            </FormHelperText>
          )}
        </Box> */}
        <CustomButton
          variant='contained'
          title={localesData?.NEXT}
          background='#000000'
          fontWeight='600'
          onClick={handleOnSubmit}
        />
      </Box>
    </>
  );
};

export default SetWantToSee;
