import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Typography,
  Select,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { CustomTextField } from '../../UI/textfield';
import { CustomSelect } from '../../UI/select';
import CustomButton from '../../UI/Button/CustomButton';

import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/down-icon.svg';

import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { useDispatch } from 'react-redux';
import {
  addProfileData,
  // clearProfileData,
} from '../../../../helpers/store/actions/profileSetup';

const PhoneNumber = ({ localesData, handleNext, setLoader, profileData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toRedirect, setToRedirect] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);

  // GET COUNTRY LIST
  const getCountryList = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.COUNTRY_LIST);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setCountryOptions(data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getCountryList();
    // dispatch(clearProfileData());
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{
        phone_country: profileData?.phone_country
          ? profileData?.phone_country
          : '61',
        phone_number: profileData?.phone_number
          ? profileData?.phone_number
          : '',
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        phone_country: Yup.string().required(localesData?.validation?.REQUIRED),
        phone_number: Yup.string()
          .required(localesData?.validation?.REQUIRED)
          .matches(/^[0-9]{8,15}$/, localesData?.validation?.NUMBER),
      })}
      onSubmit={async (requestData) => {
        dispatch(
          addProfileData({
            key: 'phone_country',
            value: requestData.phone_country,
          })
        );
        dispatch(
          addProfileData({
            key: 'phone_number',
            value: requestData.phone_number,
          })
        );
        dispatch(
          addProfileData({
            key: 'email',
            value: '',
          })
        );
        let type = toRedirect ? 'register' : 'login';
        try {
          setLoader(true);
          const { status, data } = await axiosInstance.get(
            URLS.CHECK_USER +
            `/${requestData.phone_country}/${requestData.phone_number}?type=${type}`
          );
          if (status === 200) {
            if (data?.status) {
              setApiMessage('success', data?.message);
              if (toRedirect) {
                if (data?.isVerify === 0) {
                  navigate('/otp/mobile');
                } else {
                  navigate('/sign-up');
                }
              } else {
                // if (data?.isVerify === 1 && !data?.lastname && !data?.firstname) {
                //   navigate('/profile-setup');
                // }
                dispatch(
                  addProfileData({
                    key: 'first_name',
                    value: data?.firstname,
                  })
                );
                dispatch(
                  addProfileData({
                    key: 'last_name',
                    value: data?.lastname,
                  })
                );
                dispatch(
                  addProfileData({
                    key: 'isVerify',
                    value: data?.isVerify,
                  })
                );
                handleNext();
              }
            } else {
              if (data?.isVerify === 0) {
                navigate('/otp/mobile');
              }
              setApiMessage('error', data?.message);
              setToRedirect(false);
            }
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          if (toRedirect) {
            if (error?.response?.data?.isVerify === 0) {
              navigate('/otp/mobile');
            }
          }
          setApiMessage('error', error?.response?.data?.message);
          setToRedirect(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className='form-content pb-6'>
          <Box className='t-center mb-10'>
            <Typography variant='h3' className='s-bold mb-2'>
              {localesData?.login?.ENTER_YOUR_PHONE_NUMBER}
            </Typography>
            <Typography
              variant='h4'
              dangerouslySetInnerHTML={{
                __html: localesData?.login?.DESC1,
              }}
            />
          </Box>
          <Box>
            <Box className='login-field-content mb-8'>
              <Box className='field-content'>
                <Select
                  fullWidth
                  variant='standard'
                  error={Boolean(touched.phone_country && errors.phone_country)}
                  className='custom-select'
                  name='phone_country'
                  input={<CustomSelect />}
                  IconComponent={DropdownIcon}
                  value={values.phone_country?.replace('+', '') || '61'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  displayEmpty
                >
                  {countryOptions?.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item?.countryCode?.replace('+', '')}
                      className='select-options'
                    >
                      <img
                        src={item?.flagUrl}
                        alt=''
                        className='dropdown-flag-icon'
                      />
                      <span className='dropdown-flag-text'>
                        {/* {item?.countryName} ({item?.countryCode}) */}
                        {item?.countryCode}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
                {errors.phone_country && (
                  <FormHelperText error={Boolean(errors.phone_country)}>
                    {touched.phone_country && errors.phone_country}
                  </FormHelperText>
                )}
              </Box>
              <Box className='field-content'>
                <CustomTextField
                  fullWidth
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  helperText={touched.phone_number && errors.phone_number}
                  variant='standard'
                  className='custom-textfield'
                  name='phone_number'
                  value={values.phone_number || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyPress={(e) => {
                    if (/[^0-9]/g.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Box>
            </Box>
            <Box>
              <CustomButton
                variant='contained'
                title={localesData?.SIGN_IN}
                background='#000000'
                fontWeight='400'
                type='submit'
              />
              <CustomButton
                variant='contained'
                title={localesData?.SIGN_UP}
                background='#F54D71'
                fontWeight='400'
                type='submit'
                // onClick={() => navigate('/sign-up')}
                onClick={() => setToRedirect(true)}
              />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default PhoneNumber;
