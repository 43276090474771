import React, { useEffect, useRef, useState } from 'react';
import '../favourites.scss';
import {
  Box,
  Typography,
  Avatar,
  DialogContent,
  Dialog,
  Grid,
} from '@mui/material';
import { ReactComponent as PinkStar } from 'src/assets/images/icons/pink-star.svg';
import { ReactComponent as NextArrow } from 'src/assets/images/icons/next-pink-arrow.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import '../../Benefits/benefits.scss';
import NoDataFound from './Nodata';
import PreLoader from 'src/components/Loader';
import FavBenefitDetails from './BenefitDetails';
import { EventEmitter } from 'src/helpers/EventEmitter';

const Benefits = ({ localesData, selectedTab }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [benefitData, setBenefitData] = useState([]);
  const [ids, setId] = useState('');
  const [selectedBenefit, setSelectedBenefit] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (profileData?.isPaid === true) {
      setIsDialogOpen(true);
    }
  };

  // GET BENEFITS
  const getBenefitdata = async () => {
    setLoader(true);
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.BENEFIT + '?fevourite=true'
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setBenefitData(data?.benefits);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBenefitdata();
    EventEmitter.dispatch('updateHeader', '');
  }, [location, selectedTab]);

  const handlePopupOpen = () => {
    if (profileData?.isPaid === false) {
      setToogleDialog(true);
    }
  };
  const removeFavouriteAPI = async (id) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + id
      );
      if (status === 200) {
        setIsDialogOpen(false);
        getBenefitdata();
        setId('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const MakeFavouriteAPI = async (id) => {
    const param_data = {
      following: id.toString(),
      type: 'benefits',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        param_data
      );
      if (status === 200) {
        getBenefitdata();
        setId('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <Box>
      {loader && <PreLoader />}
      {benefitData?.length === 0 ? (
        <NoDataFound />
      ) : (
        <Box onClick={handlePopupOpen} className="pointer favourite-tab-content">
          {benefitData &&
            benefitData?.length > 0 &&
            benefitData?.map((content, id) => {
              return (
                <>
                  <Box className="places-card-sec mb-2">
                    <Box className="card-head d-flex">
                      <Box
                        className="w-100"
                        onClick={() => {
                          if (profileData?.isPaid === false) {
                            handlePopupOpen();
                          } else {
                            navigate(`/favourites/benefit/${content?.id}`);
                          }
                        }}
                      >
                        <Typography className="Petbarn-icon d-flex">
                          {content.image ? (
                            <img
                              src={content.image}
                              alt={content.image}
                              className="benefit-img"
                            />
                          ) : (
                            <span className="blank-image-placeholder"> </span>
                          )}
                        </Typography>
                      </Box>
                      <Avatar
                        alt="Remy Sharp"
                        variant="square"
                        className="star-icon pointer"
                        onClick={() => {
                          if (profileData?.isPaid === false) {
                            handlePopupOpen();
                          } else {
                            setIsDialogOpen(true);
                            setId(content);
                          }
                        }}
                      >
                        <PinkStar />
                      </Avatar>{' '}
                      <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        className="delete-dialog-box"
                      >
                        <DialogContent>
                          <Box className="delete-head-sec">
                            <Box className="favourites-delete-sec">
                              <Typography className="dailog-head-text pb-1">
                                {' '}
                                {localesData?.favourites_delete?.ARE_YOU_SURE}
                              </Typography>
                              <Typography className="font-r-13 t-center pb-1">
                                {' '}
                                {localesData?.fav_benefit?.DELETE}
                              </Typography>
                            </Box>
                            <Box className="permission-btns">
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item xs={6} className="cancel-btn">
                                  <CustomButton
                                    variant="text"
                                    title={
                                      localesData?.favourites_delete?.CANCEL
                                    }
                                    fontWeight="600"
                                    type="submit"
                                    classname="favourites-cancel-btn favourites-btn"
                                    onClick={() => {
                                      setId('');
                                      setIsDialogOpen(false);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <CustomButton
                                    variant="text"
                                    title={localesData?.favourites_delete?.YES}
                                    fontWeight="600"
                                    type="submit"
                                    classname="favourites-yes-btn favourites-btn"
                                    onClick={() => {
                                      if (
                                        ids?.isLike === 1 ||
                                        ids?.isLike === '1' ||
                                        ids?.isLike === true
                                      ) {
                                        removeFavouriteAPI(ids?.fevouriteId);
                                      } else {
                                        MakeFavouriteAPI(ids?.id);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </Box>
                    <Box
                      onClick={() => {
                        if (profileData?.isPaid === false) {
                          handlePopupOpen();
                        } else {
                          navigate(`/favourites/benefit/${content?.id}`);
                        }
                      }}
                      className="description-content d-flex pointer space-between align-center"
                    >
                      <Box>
                        <Box className="d-flex">
                          <Typography className="pet-store-head-txt">
                            {' '}
                            <span className="s-bold">
                              {content?.petStore}
                            </span>{' '}
                            {'-'} {content?.petStoreType}
                          </Typography>
                        </Box>
                        <Typography
                          className="font-r-12"
                          dangerouslySetInnerHTML={{
                            __html: content.benefistName,
                          }}
                        />
                      </Box>
                      <Box>
                        <NextArrow />
                      </Box>
                    </Box>
                  </Box>
                  {selectedBenefit &&
                  id === 'BenefitDetails' &&
                  profileData?.isPaid === false ? (
                    <FavBenefitDetails benefit={benefitData} />
                  ) : null}
                </>
              );
            })}
        </Box>
      )}
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=""
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default Benefits;
