import React, { useContext, useState } from 'react';
import { IntlContext } from 'src/App';
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import '../thrownBones.scss';
import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross-icon.svg';
import { ReactComponent as PinkDogHeand } from 'src/assets/images/icons/dog-pink-heand-icon.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';

const RespondCard = ({ eventData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const [isDialogPopupOpen, setIsDialogPopupOpen] = useState(true);
  const [loader, setLoader] = useState(false);

  //Thrown BONES REJECT
  const handleReject2 = async (itemID, isCollected) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        isCollected
          ? URLS.REJECT_BONE + '/' + itemID + '?type=collected'
          : URLS.REJECT_BONE + '/' + itemID
      );

      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        setIsDialogPopupOpen(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  //HandleAccept

  const handleAccept = async (userID) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.ACCEPT_BONE + '/' + userID
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setIsDialogPopupOpen(false);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      {/* {showDialog && (
        <Box>
          {showCardDetails ? ( */}
      <Dialog
        className='dailog-respond'
        open={isDialogPopupOpen}
        onClose={() => setIsDialogPopupOpen(false)}
      >
        <DialogContent>
          {loader && <PreLoader />}
          <Box className='respond-card-sec'>
            <Box className='respond-card-head pb-2'>
              <Typography
                className='t-center head-text'
                dangerouslySetInnerHTML={{
                  __html: localesData?.repsond_card?.RECEIVED_BONE,
                }}
              />
              <Box className='t-center'>
                <Typography variant='span' className='dog-name'>
                  {eventData?.data?.firstname}{' '}
                  <span className='head-text'>And</span>{' '}
                  {eventData?.data?.dogs?.name}
                </Typography>
              </Box>
            </Box>
            <Box className='dog-img-card pb-7'>
              <Grid
                container
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                justifyContent='space-between'
                alignContent='center'
                direction='row'
              >
                <img
                  src={eventData?.data?.images?.[0]?.url}
                  className='dog-img'
                  alt=''
                />
                {/* <img
                  src={eventData?.data?.dogs?.images?.[0]?.url}
                  className='dog-img'
                /> */}
                {eventData?.data?.dogs?.images?.[0] &&
                eventData?.data?.dogs?.images?.[0]?.url?.endsWith('.mp4') ? (
                  <img
                    src={eventData?.data?.dogs?.images?.[1]?.url || ''}
                    className='dog-img'
                    alt=''
                  />
                ) : (
                  <img
                    src={eventData?.data?.dogs?.images?.[1]?.url}
                    className='dog-img'
                    alt=''
                  />
                )}
              </Grid>
            </Box>
            <Box className='content-sec'>
              <Typography
                className='t-center content-text'
                dangerouslySetInnerHTML={{
                  __html: localesData?.repsond_card?.ANIMATION_BONE,
                }}
              />
            </Box>
            <Box className='t-center trainer-permission-content pb-4'>
              <Typography
                variant='h4'
                dangerouslySetInnerHTML={{
                  __html: localesData?.repsond_card?.WANT_TO_RETRIVE,
                }}
              />
              <Typography variant='span' className='dog-trainer-name'>
                {eventData?.data?.dogs?.name}?
              </Typography>
            </Box>
            <Box className='d-flex space-between button-sec pb-3'>
              <Button
                alt='Remy Sharp'
                variant='square'
                className='bones-star-icon close-btn'
                startIcon={<CrossIcon />}
                onClick={() => handleReject2(eventData?.data?.id, true)}
                // onClick={setDialogTogglePopup}
              />
              <Button
                alt='Remy Sharp'
                variant='square'
                className='bones-star-icon agree-btn'
                startIcon={<PinkDogHeand />}
                onClick={() => {
                  handleAccept(eventData?.data?.id);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {/* ) : (
            <Box className='respond-card-sec'>
              <Box className='respond-card-head pb-2'>
                <Box className='t-center'>
                  <Typography variant='span' className='dog-name'>
                    [match name]
                  </Typography>
                  <Typography variant='span' className='head-text'>
                    has
                  </Typography>
                  <Typography variant='span' className='accepted-name'>
                    [accepted]
                  </Typography>
                  <Typography
                    className='head-text'
                    dangerouslySetInnerHTML={{
                      __html: localesData?.repsond_card?.YOUR_BONE,
                    }}
                  />
                </Box>
              </Box>
              <Box className='dog-img-card pb-7'>
                <Grid
                  container
                  xl={10}
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  justifyContent='space-between'
                  alignContent='center'
                  direction='row'
                >
                  <Avatar
                    alt='Remy Sharp'
                    variant='square'
                    className='dog-img'
                  />
                  <Avatar
                    alt='Remy Sharp'
                    variant='square'
                    className='dog-img'
                  />
                </Grid>
              </Box>
              <Box className='content-sec'>
                <Typography
                  className='t-center content-text'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.repsond_card?.ANIMATION_RESPONSE,
                  }}
                />
              </Box>
              <Box className='d-flex justify-center chat-sec pb-3'>
                <Button
                  alt='Remy Sharp'
                  variant='square'
                  className='chat-icon'
                  startIcon={<ChatIcon />}
                />
                <Typography
                  variant='h4'
                  className='chat-text'
                  dangerouslySetInnerHTML={{
                    __html: localesData?.repsond_card?.CHAT,
                  }}
                />
              </Box>
            </Box>
          )}
        </Box> */}
      {/* )} */}
    </>
  );
};

export default RespondCard;
