import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as DogHeand } from 'src/assets/images/dog-heand-icon.svg';
import { ReactComponent as DogPinkHeand } from 'src/assets/images/pink-heand-icon.svg';
// import video from 'src/assets/images/video.mp4';
// import video from '../../../../../../assets/images/video.mp4';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
import '../matching.scss';

const MatchingVideo = ({ videoLink, onPawIconClick, isActivePawIcon }) => {
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const isActivePawIconRef = useRef(false);

  useEffect(() => {
    const video = document.getElementById('matchingVideo');

    // Disable Picture-in-Picture functionality
    video.addEventListener('loadedmetadata', () => {
      video.disablePictureInPicture = true;
    });
  }, []);

  return (
    <Box className='matching-sec'>
      {/* <Box className='video-sec'> */}
      <video
        src={videoLink ? videoLink : ''}
        controls
        className='custom-video'
        id='matchingVideo'
        controlsList='nodownload nofullscreen noremoteplayback noplaybackrate noseeking'
      />
      <Box
        className='pointer dog-paw-icon'
        onClick={() => {
          onPawIconClick();
          // handleClick();
          isActivePawIconRef.current = !isActivePawIconRef.current;
        }}
      >
        {isActivePawIcon && isActivePawIconRef.current ? (
          <DogPinkHeand />
        ) : (
          <DogHeand />
        )}
      </Box>
    </Box>
  );
};

export default MatchingVideo;
