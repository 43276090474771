import React, { useState, useEffect } from 'react';
import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/images/icons/plus.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import IOSSwitch from '../../../UI/IOSSwitch';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import ProgressBar from '../../../UI/ProgressBar';
import ManuallyPolitics from './ManuallyPolitics';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { useNavigate } from 'react-router-dom';
import { CustomTextField } from 'src/views/component/UI/textfield';
import _ from 'lodash';

const Politics = ({
  localesData,
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const [politicsData, setPoliticsData] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [manually, setManually] = useState(false);
  const [manualData, setManualData] = useState([]);
  const [manuallyId, setManuallyId] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'religionPrivate') {
      setPoliticsData({ ...politicsData, private: checked });
      setIsPrivate(checked);
    }
  };

  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 15) {
  //         setPoliticsData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //           private: false,
  //         });
  //       }
  //       if (item?.q_order === 34) {
  //         setManuallyId(item?.Option[0]?.id);
  //       }
  //     });
  // }, [questions]);

  useEffect(() => {
    const question = questions?.find((o) => o?.id === 2);
    setLoader(true);
    if (question) {
      const itemWithOrder15 = question.Question?.find(
        (item) => item?.q_order === 15
      );
      const itemWithOrder34 = question.Question?.find(
        (item) => item?.q_order === 34
      );

      if (itemWithOrder15) {
        setPoliticsData({
          title: itemWithOrder15?.title,
          id: itemWithOrder15?.id,
          options: itemWithOrder15?.Option,
          private:
            answars?.find((o) => o?.id === 18)?.Option[0]?.private === '1' ||
            answars?.find((o) => o?.id === 18)?.Option[0]?.private === 1
              ? true
              : false,
        });
        setIsPrivate(
          answars?.find((o) => o?.id === 18)?.Option[0]?.private === '1' ||
            answars?.find((o) => o?.id === 18)?.Option[0]?.private === 1
            ? true
            : false
        );
      }
      const anslist = answars
        ?.find((o) => o?.id === 18)
        ?.Option?.filter(
          (item) => item?.selected === 1 || item?.selected === true
        );
      const mergedArray = _.merge(anslist, selectedChips);
      setSelectedChips(mergedArray);
      if (itemWithOrder34) {
        setManuallyId(itemWithOrder34?.Option[0]?.id);
      }
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, [questions, answars]);

  // useEffect(() => {
  //   if (answars?.length !== 0) {
  //     setSelectedChips(
  //       answars
  //         ?.find((o) => o?.id === 18)
  //         ?.Option?.filter(
  //           (item) => item?.selected === 1 || item?.selected === true
  //         )
  //     );
  //     setPoliticsData({
  //       ...politicsData,
  //       private:
  //         answars?.find((o) => o?.id === 18)?.Option[0]?.private === '1' ||
  //         answars?.find((o) => o?.id === 18)?.Option[0]?.private === 1
  //           ? true
  //           : false,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [answars]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([...selectedChips, chip]);
    } else {
      setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    // selectedChips?.map((item) => {
    //   opt.push({
    //     optionId: item.id,
    //     selected: true,
    //     private: politicsData?.private,
    //   });
    //   return true;
    // });
    politicsData?.options
      ?.filter((o) => o?.id !== undefined)
      ?.map((item) => {
        opt.push({
          optionId: item.id,
          selected: selectedChips
            ?.map((obj) => obj?.title)
            ?.includes(item?.title)
            ? true
            : false,
          private: politicsData?.private,
        });
        return true;
      });
    if (manually) {
      var test = [];
      manualData?.map((item) => {
        if (item !== '') {
          test.push({ title: item, selected: true });
        }
        return true;
      });

      manualData?.map((item) => {
        opt.push({
          optionId: manuallyId,
          selected: true,
          answer: JSON.stringify(test),
          private: politicsData?.private,
        });
        return true;
      });
    }
    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'religion',
            value: opt,
          })
        );
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 19) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(6));
            } else if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <Box className='t-center'>
      {manually ? (
        <ManuallyPolitics
          localesData={localesData}
          handleNext={handleNext}
          reduxProgress={reduxProgress}
          questions={questions}
          setManualData={setManualData}
          progressWeightage={progressWeightage}
        />
      ) : (
        <>
          <Box className='chip-container-box'>
            <Typography
              className='mb-2 font-r-12'
              dangerouslySetInnerHTML={{
                __html:
                  localesData?.profile_completion_user?.PROFILE_COMPLETION,
              }}
            />
            <Box className='mb-3'>
              <Typography variant='h4' className='pb-1'>
                {/* {reduxProgress}% */}
                {progressWeightage}%
              </Typography>
              <ProgressBar processData={progressWeightage} />
            </Box>
            <Typography variant='h3' className='s-bold pb-2'>
              {localesData?.user_hobbies?.now_its_all_about_you}
            </Typography>
            <Typography
              variant='h4'
              className='pb-4'
              dangerouslySetInnerHTML={{
                __html: localesData?.user_hobbies?.do_you_have_politics,
              }}
            />
            <Typography variant='h4' className='pb-4'>
              {localesData?.user_hobbies?.select_as_many_as_you_like_or_add}
              <span className='plus-icon'>
                <PlusIcon onClick={() => setManually(true)} />
              </span>
            </Typography>
            <CustomTextField
              variant='standard'
              className='custom-textfield pb-2'
              placeholder='search'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>
          <Box sx={{ textAlign: 'left' }} className='chip-container pb-4'>
            {politicsData?.options
              ?.filter((item) =>
                item.title.toLowerCase().includes(searchInput.toLowerCase())
              )
              ?.map((item, i) => (
                <Chip
                  className={`chip-inner font-r-11 ${
                    selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                      ? 'chip-active'
                      : ''
                  } `}
                  key={i}
                  label={item?.title}
                  onClick={() => handleChipClick(item)}
                />
              ))}
            {politicsData &&
              politicsData?.options?.filter((item) =>
                item?.title?.toLowerCase().includes(searchInput?.toLowerCase())
              )?.length === 0 && (
                <Typography variant='h5' className='t-center disable-color'>
                  {localesData?.validation?.NO_SEARCH_RESULT_FOUND}
                </Typography>
              )}
          </Box>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch className='ios-switch' />}
                name='religionPrivate'
                checked={isPrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange(e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  } else {
                    handleChange(e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {isPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </>
      )}

      <Box className=''>
        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='600'
          onClick={() => handleOnSubmit()}
        />

        <Box className='pb-8 mb-4'>
          <DogIcon width='81.32px' height='61.36px' className='mb-2' />

          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default Politics;
