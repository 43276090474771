import React, { useEffect } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addProfileData,
  clearProfileData,
} from '../../../../helpers/store/actions/profileSetup';
import CustomButton from '../../UI/Button/CustomButton';
import { CustomTextField } from '../../UI/textfield';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { saveToStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import { addAuth } from '../../../../helpers/store/actions/auth';
import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import {
  clearSvgData,
  setSVGFile,
} from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/email-dog.svg';

const SetEmail = ({ localesData, setLoader }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state?.profileData);

  const onclickskip = async () => {
    let sendData = {
      // countryCode: profileData.phone_country,
      // username: profileData.phone_number,
      // countryCode: profileData.phone_country,
      // password: profileData.password,
      firstname: profileData?.first_name,
      lastname: profileData?.last_name,
      // platform: 'web',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.put(
        URLS.USER,
        sendData
      );
      if (status === 200) {
        saveToStorage(identifiers?.VERIFIED, true);
        dispatch(clearSvgData());
        dispatch(handleNext(0));
        navigate('/profile-setup');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(clearProfileData());
      dispatch(clearSvgData());
      setApiMessage('error', error?.response?.data?.message);
    }
  }

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required(localesData?.validation?.REQUIRED)
          .email(localesData?.validation?.VALID_EMAIL)
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            localesData?.validation?.VALID_EMAIL
          ),
      })}
      onSubmit={async (requestData) => {
        dispatch(addProfileData({ key: 'email', value: requestData.email }));
        let sendData = {
          // countryCode: profileData.phone_country,
          // username: profileData.phone_number,
          // countryCode: profileData.phone_country,
          // password: profileData.password,
          email: requestData?.email,
          firstname: profileData?.first_name,
          lastname: profileData?.last_name
          // platform: 'web',
        };
        try {
          setLoader(true);
          const { status, data } = await axiosInstance.put(
            URLS.USER,
            sendData
          );
          if (status === 200) {
            // saveToStorage(identifiers?.AUTH_DATA, data?.token);
            // dispatch(addAuth(data?.token));
            navigate('/otp/email');
            // dispatch(clearProfileData());
            // dispatch(clearSvgData());
            // dispatch(handleNext(0));
            setLoader(false);
          }
        } catch (error) {
          setLoader(false);
          setApiMessage('error', error?.response?.data?.message);
        }
      }}

    // onSubmit={(requestData) => {
    //   dispatch(addProfileData({ key: 'email', value: requestData.email }));

    // }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className='form-content'>
          <Box className='t-center mb-1'>
            <Typography variant='h3' className='s-bold'>
              {localesData?.profile?.NICE_TO_MEET_YOU}
            </Typography>
            <Typography variant='h3' className='s-bold mb-2'>
              {profileData?.first_name} {profileData?.last_name},
            </Typography>
            <Typography
              variant='h4'
              className='mb-2'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.EMAIL_DESC1,
              }}
            />
            <Typography
              variant='h3'
              className='s-bold'
              dangerouslySetInnerHTML={{
                __html: localesData?.profile?.WHATS_YOUR_EMAIL_ADDRESS,
              }}
            />
          </Box>

          <Box className='field-content mb-2'>
            <CustomTextField
              fullWidth
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              variant='standard'
              className='custom-textfield'
              name='email'
              value={values.email || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormHelperText className='font-r-13'>
              {localesData?.profile?.EMAIL_HELPER}
            </FormHelperText>
          </Box>
          <Box>
            <CustomButton
              variant='contained'
              title={localesData?.DONE}
              background='#59D09D'
              fontWeight='600'
              type='submit'
            />
          </Box>
          <Box className='t-center'>
            <Typography variant='h4' className='s-bold pointer mt-2' onClick={() => onclickskip()}>
              {localesData?.SKIP}
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default SetEmail;
