import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackArrow } from '../../assets/images/icons/back-arrow.svg';
import blackLogo from 'src/assets/images/logo-black.png';
import { handleNext } from '../../helpers/store/actions/navigateSlice';
import { useLocation } from 'react-router-dom';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { clearStorage } from 'src/helpers/context';
import { setApiMessage } from 'src/helpers/commonFunctions';

// import { clearStorage } from 'src/helpers/context';
// import { ReactComponent as Male } from 'src/assets/images/icons/male.svg';
// import { ReactComponent as Female } from 'src/assets/images/icons/female.svg';
// import { ReactComponent as Other } from 'src/assets/images/icons/other.svg';

const TopBar = ({ auth, className, navigate, localUserData, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxBackStep = useSelector((state) => state?.page_navigate?.value);
  const backStep = reduxBackStep;

  const isProfileSetup = location.pathname === '/profile-setup';

  const handleBack = () => {
    if (isProfileSetup && backStep === 1) {
      accountLogout();
    }
    if (backStep !== 0) {
      dispatch(handleNext(backStep - 1));
    } else if (isProfileSetup) {
      accountLogout();
    } else {
      navigate(-1);
    }
  };

  const accountLogout = async () => {
    const sendData = {};
    try {
      const { status } = await axiosInstance.post(URLS.LOGOUT, sendData);
      if (status === 200) {
        clearStorage();
        navigate('/');
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // const logout = () => {
  //   // clearStorage();
  //   navigate('/profile');
  // };

  // const profileImg =
  //   localUserData?.images?.length === 0 ? '' : localUserData?.images?.[0].url;

  const hideLogo = location?.state === 'isEdit';
  return (
    <AppBar
      className={clsx(className, 'app-bar')}
      elevation={0}
      position='static'
      {...rest}
    >
      <Toolbar>
        <BackArrow className='pointer' onClick={() => handleBack()} />

        {hideLogo && (
          <img src={blackLogo} alt='logo' height='34px' width='74px' />
        )}
        <Box> </Box>
        {/* <Button onClick={logout}>Profile</Button> */}
        {/* <Box className='pointer' onClick={() => navigate('/profile')}>
          <Avatar
            src={
              profileImg === '' ? (
                localUserData?.gender === 2 ? (
                  <Male />
                ) : localUserData?.gender === 3 ? (
                  <Female />
                ) : (
                  <Other />
                )
              ) : (
                profileImg
              )
            }
          />
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
