import React from 'react';
import { Box } from '@mui/material';

import whiteLogo from '../../../../assets/images/logo-white.png';
import blackLogo from '../../../../assets/images/logo-black.png';

import './logoUI.scss';

const LogoUI = ({ color }) => {
  return (
    <Box className='logo-section'>
      <img src={color === 'white' ? whiteLogo : blackLogo} alt='logo' />
    </Box>
  );
};

export default LogoUI;
