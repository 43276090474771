import React, { useContext, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IntlContext } from '../../../../App';
import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { saveToStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import PreLoader from '../../../../components/Loader';
// import SetLookingFor from './SetLookingFor';
import SetPictures from './SetPictures';
import SetDOB from './SetDOB';
import SetGender from './SetGender';
import SetWantToSee from './SetWantToSee';
import SetUpFinish from './SetUpFinish';

const ProfileSetup = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep);
    } else {
      setActiveStep(0);
    }
  }, [reduxStep]);

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
  };

  const getAllQuestions = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setQuestions(data?.types);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  //GET ALL ANSWER
  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;
        setAnswers(all_ans);
        var selectedCount = 0;
        all_ans?.map((itm) => {
          if (itm?.id === 74) {
            itm?.Option?.map((item) => {
              if (
                item?.answer === true ||
                item?.answer === '1' ||
                item?.answer === 1
              ) {
                selectedCount++;
              }
              return true;
            });
          }
          return true;
        });
        if (selectedCount === 0) {
          getProfile(true);
        } else {
          getProfile(false);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  //GET UPDATED ALL ANSWER
  const getUpdatedAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        var all_ans = data?.questions;
        setAnswers(all_ans);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getProfile = async (userType) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveToStorage(identifiers?.USER_DATA, data);
        // if (userType) {
        //   navigate('/profile-setup');
        //   dispatch(handleNext(0));
        // } else
        if (!data?.images || data?.images.length === 0) {
          navigate('/profile-setup');
          dispatch(handleNext(1));
        } else if (
          data?.dob === '' ||
          data?.dob === null ||
          data?.dob === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(2));
        } else if (
          data?.gender === '' ||
          data?.gender === null ||
          data?.gender === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(3));
        } else if (
          data?.looking_for === '' ||
          data?.looking_for === null ||
          data?.looking_for === 'null'
        ) {
          navigate('/profile-setup');
          dispatch(handleNext(4));
        } else {
          // if (reduxStep === 4) {
          //   navigate('/profile-setup');
          //   dispatch(handleNext(4));
          // }
          if (reduxStep === 5) {
            navigate('/profile-setup');
            dispatch(handleNext(5));
          } else {
            navigate('/');
            dispatch(handleNext(0));
          }
          dispatch(handleNext(0));
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllQuestions();
    getAllAnswars();
    // getProfile();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (reduxStep === 0 || reduxStep === 1) {
      getUpdatedAllAnswars();
    }
  }, [reduxStep]);
  const renderComponent = () => {
    switch (
      activeStep //activeStep
    ) {
      // case 0:
      //   return (
      //     <SetLookingFor
      //       localesData={localesData}
      //       handleNext={handleNexts}
      //       setLoader={setLoader}
      //       dispatch={dispatch}
      //       questions={questions}
      //       getProfile={getProfile}
      //       answers={answers}
      //     />
      //   );
      case 1:
        return (
          <SetPictures
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            getProfile={getProfile}
          />
        );
      case 2:
        return (
          <SetDOB
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            getProfile={getProfile}
          />
        );
      case 3:
        return (
          <SetGender
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            getProfile={getProfile}
            getUpdatedAllAnswars={getUpdatedAllAnswars}
            answers={answers}
          />
        );
      case 4:
        return (
          <SetWantToSee
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            getProfile={getProfile}
            answers={answers}
          />
        );
      case 5:
        return (
          <SetUpFinish
            localesData={localesData}
            dispatch={dispatch}
            getProfile={getProfile}
            handleNext={handleNext}
          />
        );
      // case 6:
      //   return navigate('/profile-completion');
      default:
        return (
          <>
            <SetPictures
              localesData={localesData}
              handleNext={handleNexts}
              setLoader={setLoader}
              dispatch={dispatch}
              questions={questions}
              getProfile={getProfile}
            />
            {/* <SetLookingFor
            localesData={localesData}
            handleNext={handleNexts}
            setLoader={setLoader}
            dispatch={dispatch}
            questions={questions}
            getProfile={getProfile}
            answers={answers}
          /> */}
          </>
        );
    }
  };

  return (
    <Box className='page-content-wrap pb-3'>
      {loader && <PreLoader />}
      {renderComponent()}
    </Box>
  );
};

export default ProfileSetup;
