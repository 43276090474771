import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { IntlContext } from 'src/App';
import EmailEdit from './EmailEdit';
import EmailOTP from './EmailOTP';
import MobileEdit from './MobileEdit';
import MobileOTP from './MobileOTP';
import PreLoader from 'src/components/Loader';
import { manageAccountData } from 'src/helpers/store/actions/manageaccount';
import './manageaccount.scss'
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const ManageAccount = () => {
    const intlContext = useContext(IntlContext);
    const localesData = intlContext?.messages;
    const { type } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileData = fetchFromStorage(identifiers?.USER_DATA);
    const EmailMobileData = useSelector((state) => state?.manageAccountData);

    const [activeStep, setActiveStep] = useState(0);
    const [loader, setLoader] = useState(false);
    const [newUser, setNewUser] = useState(false);

    useEffect(() => {
        dispatch(manageAccountData({ ...EmailMobileData, activeStep: activeStep, newUser: newUser }));
    }, [activeStep])

    useEffect(() => {
        if (EmailMobileData && EmailMobileData?.backtab) {
            setActiveStep(0)
        }
    }, [EmailMobileData && EmailMobileData?.backtab])

    const renderComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <MobileEdit dispatch={dispatch} localesData={localesData} setActiveStep={setActiveStep} setNewUser={setNewUser} />
                );
            case 1:
                return (
                    <MobileOTP dispatch={dispatch} localesData={localesData} newUser={newUser} />
                );
            default:
                return (
                    <MobileEdit dispatch={dispatch} localesData={localesData} setActiveStep={setActiveStep} setNewUser={setNewUser} />
                );
        }
    };
    const renderComponentEmail = () => {
        switch (activeStep) {
            case 0:
                return (
                    <EmailEdit dispatch={dispatch} localesData={localesData} setActiveStep={setActiveStep} setNewUser={setNewUser} />
                );
            case 1:
                return (
                    <EmailOTP dispatch={dispatch} localesData={localesData} newUser={newUser} />
                );
            default:
                return (
                    <EmailEdit dispatch={dispatch} localesData={localesData} setActiveStep={setActiveStep} setNewUser={setNewUser} />
                );
        }
    };

    return (
        <Box className='manage-account'>
            {loader && <PreLoader />}
            {type === 'email' ? renderComponentEmail() : renderComponent()}
        </Box>
    );
};

export default ManageAccount;
