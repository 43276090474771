import React from 'react';

import Page from '../../../components/Page';
import ForgotPage from '../../component/auth/Forgot';

const Forgot = () => {
  return (
    <>
      <Page title="Forgot Password">
        <ForgotPage />
      </Page>
    </>
  );
};

export default Forgot;
