import React from "react";
import Page from "../../../components/Page";
import ManageAccount from "src/views/component/application/ManageProfile/AccountDetails/ManageAccount";

const ManageAccountView = () => {
    return (
        <Page title='Terms and Condtion'>
            <ManageAccount />
        </Page>
    );
};

export default ManageAccountView;