import React from 'react';
import Page from 'src/components/Page';
import HomePage from 'src/views/component/application/Home/Matching';

const MatchingView = () => {
  return (
    <Page title='Matching'>
      <HomePage />
    </Page>
  );
};

export default MatchingView;
