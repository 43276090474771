import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Redirect } from "react-router-dom";
import moment from 'moment';
import { IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChatForm from '../components/ChatForm';
// import { setActiveGroupMessageRef } from 'src/helpers/store/actions/chatview.actions';
// import { CratePrivateChat } from '../../../../../services/api/chat';
import { EventEmitter } from 'src/helpers/EventEmitter';
import ChatMessagesItem from '../components/ChatMessagesItem';
// import ButtonComponent from '../../../sharedUI/ButtonComponent/ButtonComponent';
import ViewMessage from '../components/ViewMessage';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { socket } from 'src/helpers/context/socket';
// import { MSG_TYPE } from '../components/MsgType';
import { getFields } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
import _ from 'lodash';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_data: [],
      page_count: 1,
      loader: false,
      hasMoreItems: true,
      isReplay: false,
    };
    this.messageElements = [];
    EventEmitter.subscribe('scrollToMessage', this.handleClickToScroll);
    EventEmitter.subscribe('DeleteMessage', this.deleteMessage);
    EventEmitter.subscribe('isReply', this.handleSetIsReplay);
    // EventEmitter.subscribe('AddMessage', this.addMessage);
    this.profileData = fetchFromStorage(identifiers?.USER_DATA);
  }
  handleSetIsReplay = (data) => {
    this.setState({ isReplay: data });
  };

  // GET CHAT HISTORY
  async callChatDataApi() {
    const url = window.location.pathname;
    const spliturl = url.split('/');
    const chatId = spliturl[spliturl.length - 1];

    try {
      this.setState({ loader: true });
      const { status, data } = await axiosInstance.get(
        URLS.CHAT_HISTORY + chatId + `?page=${this.state.page_count}`
      );
      if (status === 200) {
        let new_data = data.chatHistory;
        let old_data = this.state.list_data;
        if (new_data?.length === 0) {
          this.setState({ hasMoreItems: false });
        }
        let all_data = [...old_data, ...new_data]?.sort(
          (a, b) => a._id - b._id
        );

        const uniqe_data = _.uniqBy(all_data, '_id');
        var otherUserChatData = uniqe_data?.filter(
          (item) => item?.user?._id !== this.profileData?.id
        );
        var unseenMessage = otherUserChatData.filter(
          (item) =>
            item?.SeenByData === undefined || item?.SeenByData?.length === 0
        );
        const emitdata = {
          ReceiverID: Number(chatId),
          MessageID: getFields(unseenMessage, '_id'),
          UserID: this.profileData?.id,
        };
        socket.emit('message-seen', emitdata);
        this.setState({
          list_data: uniqe_data,
          page_count: this.state.page_count + 1,
        });

        this.setState({ loader: false });
      } else {
        this.setState({
          loader: false,
          hasMoreItems: false,
        });
      }
    } catch (e) {
      this.setState({
        loader: false,
        hasMoreItems: false,
      });
    }
  }

  // DELETE MESSAGE
  deleteMessage = (data) => {
    const { list_data } = this.state;
    const url = window.location.pathname;
    const spliturl = url.split('/');
    const chatId = spliturl[spliturl.length - 1];

    if (typeof chatId !== 'undefined' && chatId === data.ReceiverID) {
      var messages = list_data;
      messages = messages?.map((item, i) => {
        return {
          ...item,
          messageDeleted:
            item._id === data._id ? data?.MessageDeleted : item.messageDeleted,
        };
      });
      this.setState({
        list_data: messages,
      });
    }
  };
  checkSeenByMessage = () => {
    const url = window.location.pathname;
    const spliturl = url.split('/');
    const chatId = spliturl[spliturl.length - 1];
    var otherUserChat = this.state.list_data?.filter(
      (item) => item?.user?._id !== this.profileData?.id
    );
    var unseenMessageArray = otherUserChat.filter(
      (item) => item?.SeenByData?.length === 0
    );
    // const emitdata = {
    //   MessageID: getFields(unseenMessageArray, '_id'),
    //   UserID: this.profileData?.id,
    //   SplitGroupID: chatId,
    // };
    const emitdata = {
      ReceiverID: chatId,
      MessageID: getFields(unseenMessageArray, '_id'),
      UserID: this.profileData?.id,
    };
    socket.emit('message-seen', emitdata);
  };
  setReferance() {
    const { findMessage, list_data } = this.state;
    this.messageElements = list_data?.reduce((acc, value) => {
      acc[value._id] = React.createRef();
      if (findMessage?.status === true && value._id === findMessage?.id) {
        this.setState({ findMessage: { ...findMessage, status: 'found' } });
      }
      return acc;
    }, {});
    // this.props.setActiveGroupMessageRef(this.messageElements);
    if (this.state.findMessage?.status === true) {
      setTimeout(() => {
        this.handleClickToScroll(this.state.findMessage?.id);
      }, 100);
    } else if (this.state.findMessage?.status === 'found') {
      this.setState({ findMessage: { id: '', status: false } });
    }
  }

  handleClickToScroll = (id = '') => {
    // var otherUserChat = this.state.list_data?.filter(
    //   (item) => item?.user?._id !== this.profileData?.id
    // );
    // var unseenMessageArray = otherUserChat.filter(
    //   (item) => item?.SeenByData?.length === 0
    // );
    // const url = window.location.pathname;
    // const spliturl = url.split('/');
    // const chatId = spliturl[spliturl.length - 1];

    // // var unseenMessageArray = otherUserChat.slice((otherUserChat.length - item?.total_unread_count), otherUserChat.length)
    // // {
    // //   message_id: getFields(unseenMessageArray, '_id'),
    // //   group_id: chatId,
    // //   user_id: this.profileData?.id,
    // // }
    // const emitdata = {
    //   MessageID: getFields(unseenMessageArray, '_id'),
    //   UserID: this.profileData?.id,
    //   SplitGroupID: chatId,
    // };

    // socket.emit('message-seen', emitdata);
    if (
      id !== '' &&
      (typeof this.messageElements[id] === 'undefined' ||
        this.messageElements[id] === '')
    ) {
      this.setState({ findMessage: { id: id, status: true } }, () => {
        this.props.getMessages();
      });
    }

    if (
      typeof id !== 'undefined' &&
      typeof this.messageElements[id] !== 'undefined' &&
      this.messageElements[id] !== null &&
      typeof this.messageElements[id].current !== 'undefined' &&
      this.messageElements[id].current !== null
    ) {
      this.messageElements[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      this.messageElements[id].current.classList.toggle('message-heighlight');
      setTimeout(
        () =>
          this.messageElements[id].current.classList.toggle(
            'message-heighlight'
          ),
        300
      );
    }
    this.checkSeenByMessage();
  };

  componentDidMount() {
    this.setReferance();
    this.callChatDataApi();
    if (socket) {
      // const url = window.location.pathname;
      // const spliturl = url.split('/');
      // const chatId = spliturl[spliturl.length - 1];
      // socket.on('DeleteMessageSocket', (data) => {
      //   if (data && data?._id) {
      //     let delete_obj = {
      //       _id: data?._id != null ? data?._id : 0,
      //       user_id: this.profileData?.id ? this.profileData?.id : 0,
      //       chat_id: chatId ? chatId : 0,
      //       text: data?.text ? data?.text : '',
      //       videourl: data?.videourl ? data?.videourl : '',
      //       audiourl: data?.audiourl ? data?.audiourl : '',
      //       emoji: data?.emoji ? data?.emoji : '',
      //       image: data?.image ? data?.image : '',
      //       type: data?.type ? data?.type : MSG_TYPE.STRING,
      //       name: data?.name ? data?.name : '',
      //       reply: data?.reply ? data?.reply : {},
      //       messageDeleted: data?.messageDeleted ? data?.messageDeleted : 0,
      //       createdAt: data?.createdAt ? data?.createdAt : 0,
      //       timeUpdated: data?.timeUpdated ? data?.timeUpdated : 0,
      //       user: data?.user ? data?.user : {},
      //       system: false,
      //     };
      //     console.log('data', delete_obj);
      //   }
      // });

      socket.on('listGroup', (data) => {
        const url = window.location.pathname;
        const spliturl = url.split('/');
        const chatId = spliturl[spliturl.length - 1];
        var all_data;
        var listData = this.state.list_data;
        if (Number(data?.ReceiverID) === Number(chatId)) {
          if (Number(data?.user?._id) === Number(chatId)) {
            all_data = [...listData, data];
            this.setState({ list_data: all_data });
            var otherUserChat = all_data?.filter(
              (item) => item?.user?._id !== this.profileData?.id
            );
            var unseenMessageArray = otherUserChat.filter(
              (item) =>
                item?.SeenByData === undefined || item?.SeenByData?.length === 0
            );
            const emitdata = {
              ReceiverID: Number(chatId),
              MessageID: getFields(unseenMessageArray, '_id'),
              UserID: this.profileData?.id,
            };
            socket.emit('message-seen', emitdata);
          }
          if (Number(data?.user?._id) !== Number(chatId)) {
            var seendata = [];
            socket.on('message-seen', (sdata) => {
              var seenUserData = sdata;
              seenUserData = { ...seenUserData, MessageSeenTime: Date() };
              seendata.push(seenUserData);
              data = { ...data, SeenByData: seendata };
              all_data = [...listData, data];
              this.setState({ list_data: all_data });
              return all_data;
            });
            all_data = [...listData, data];
            this.setState({ list_data: all_data });
          }
        } else {
          all_data = [...this.state.list_data];
          this.setState({ list_data: all_data });
        }
      });
      // var otherUserChat = this.state.list_data?.filter(
      //   (item) => item?.user?._id !== this.profileData?.id
      // );
      // var unseenMessageArray = otherUserChat.filter(
      //   (item) => item?.SeenByData?.length === 0
      // );
      // const url = window.location.pathname;
      // const spliturl = url.split('/');
      // const chatId = spliturl[spliturl.length - 1];
      // // var unseenMessageArray = otherUserChat.slice((otherUserChat.length - item?.total_unread_count), otherUserChat.length)

      // socket.on('message-seen', (data) => {
      //   console.log('dataseenseen', data);
      // });
    }
  }

  // addMessage(data) {
  //   const url = window.location.pathname;
  //   const spliturl = url.split('/');
  //   const chatId = spliturl[spliturl.length - 1];
  //   console.log('ssss', data);
  //   if (typeof chatId !== 'undefined') {
  //     // this.setState((state) => ({
  //     //   chatHistory: [...state.chatHistory, data],
  //     // }));
  //     console.log('ssss', data);
  //     let all_data = [...this.state.list_data, data];

  //     this.setState({ list_data: all_data });
  //   }
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.list_data !== prevProps.list_data) {
      this.setReferance();
      if (this.state.page_count === 2) {
        this.scrollToBottom();
      }
    }
    if (typeof this.listRef !== 'undefined' && snapshot !== null) {
      const list = this.listRef.current;
      list.scrollTop = list?.scrollHeight - snapshot;
    }
    // var otherUserChat = this.state.list_data?.filter(
    //   (item) => item?.user?._id !== this.profileData?.id
    // );
    // var unseenMessageArray = otherUserChat.filter(
    //   (item) => item?.SeenByData?.length === 0
    // );
    // const url = window.location.pathname;
    // const spliturl = url.split('/');
    // const chatId = spliturl[spliturl.length - 1];
    // console.log(
    //   unseenMessageArray,
    //   chatId,
    //   this.profileData?.id,
    //   getFields(unseenMessageArray, '_id'),
    //   'unseenMessageArrayunseenMessageArray'
    // );
    // // var unseenMessageArray = otherUserChat.slice((otherUserChat.length - item?.total_unread_count), otherUserChat.length)
    // // {
    // //   message_id: getFields(unseenMessageArray, '_id'),
    // //   group_id: chatId,
    // //   user_id: this.profileData?.id,
    // // }
    // const emitdata = {
    //   MessageID: getFields(unseenMessageArray, '_id'),
    //   UserID: this.profileData?.id,
    //   SplitGroupID: chatId,
    // };
    // socket.emit('message-seen', emitdata);
  }

  scrollToBottom = () => {
    this.el.scrollIntoView();
  };

  render() {
    const { list_data, loader, hasMoreItems, isReplay } = this.state;
    const { groupUserData } = this.props;
    // if (redirect && newGroupId !== "") {
    //   return <Redirect to={"/dashboard/privatechat/" + newGroupId} />;
    // }
    var heightdevied = isReplay ? ' changeHeight ' : '';

    return (
      <>
        {loader && <PreLoader />}
        <div className='Messages-wrap'>
          <div className={'Messages-list with-form' + heightdevied}>
            {hasMoreItems && (
              <CustomButton
                variant='contained'
                title='Load Earlier Message'
                background='#f54d71'
                fontWeight='600'
                classname='inherit load-more-btn'
                onClick={() => this.callChatDataApi()}
              />
            )}
            {list_data?.length > 0 && (
              <ul>
                {list_data?.map((m, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i === 0 && (
                        <ViewMessage
                          type='systemtime'
                          message={{
                            type: 'systemtime',
                            createdAt: m.createdAt,
                          }}
                        />
                      )}
                      {i > 0 &&
                        moment
                          .unix(list_data[i - 1].createdAt)
                          .format('DD/MM/YYYY') !==
                          moment.unix(m.createdAt).format('DD/MM/YYYY') && (
                          <ViewMessage
                            type='systemtime'
                            message={{
                              type: 'systemtime',
                              createdAt: m.createdAt,
                            }}
                          />
                        )}
                      <ChatMessagesItem
                        champyChat={this.props.champyChat}
                        handleClickToScroll={this.handleClickToScroll}
                        messageElements={this.messageElements}
                        key={
                          'champymessageitem' + Math.floor(Math.random() * 100)
                        }
                        itemkey={i}
                        message={m}
                        groupUserData={groupUserData}
                        chatHistory={list_data}
                        UserID={this.profileData?.id}
                      />
                    </React.Fragment>
                  );
                })}
              </ul>
            )}
            <div
              ref={(el) => {
                this.el = el;
              }}
            />
          </div>
        </div>
        <IconButton
          aria-label='scroll'
          size='medium'
          className='scroll-down'
          onClick={this.scrollToBottom}
        >
          <ExpandMoreIcon />
        </IconButton>
        <ChatForm
          {...this.props}
          changeType={this.props.changeType}
          isPrivatChat={this.props.champyChat}
          onTyping={this.props.onTyping}
          list_data={this.state.list_data}
        />
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     activeGroupMessageRef: state.messages.activeGroupMessageRef,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setActiveGroupMessageRef: (activeGroupMessageRef) => {
//       dispatch(setActiveGroupMessageRef(activeGroupMessageRef));
//     },
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Messages);
export default Messages;
