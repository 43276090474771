import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close-white-icon.svg';
import 'react-toastify/dist/ReactToastify.css';

export const setApiMessage = (type, message) => {
  var commonProps = {
    position: 'bottom-center',
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    hideProgressBar: true,
    theme: 'colored',
    style: {
      minHeight: 'unset',
    },
    toastId: 'active',
  };
  switch (type) {
    case 'info':
      toast.info(message, commonProps);
      break;
    case 'success':
      toast.success(message, commonProps);
      break;
    case 'warning':
      toast.warning(message, commonProps);
      break;
    case 'error':
      toast.error(message, {
        icon: <CloseIcon />,
        ...commonProps,
      });
      break;
    default:
      break;
  }
};

export function GetFilePartsFromUrl(url) {
  if (url) {
    let data = { name: '', extension: '' };
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      data.name = m[1];
    }
    const splitdata = url.split('.');
    data.extension = splitdata[splitdata.length - 1];
    return data;
  }
  return '';
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes ? bytes + ' B' : 0 + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes ? bytes.toFixed(dp) + ' ' + units[u] : 0 + ' B';
}
export const getFields = (list, field) => {
  return list.reduce(function (carry, item) {
    if (typeof item === 'object' && field in item) {
      carry.push(item[field]);
    }
    return carry;
  }, []);
};
