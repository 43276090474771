import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  DialogContent,
  Dialog,
  FilledInput,
  FormHelperText,
  FormControl,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackArrow } from 'src/assets/images/icons/back-arrow.svg';
import blackLogo from 'src/assets/images/logo-black.png';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { ReactComponent as Male } from 'src/assets/images/icons/male.svg';
import { ReactComponent as Female } from 'src/assets/images/icons/female.svg';
import { ReactComponent as Other } from 'src/assets/images/icons/other.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import { useLocation, useParams } from 'react-router-dom';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import CallIcon from '@mui/icons-material/Call';
// import VideocamIcon from '@mui/icons-material/Videocam';
import { URLS } from 'src/helpers/constants/urls';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { IntlContext } from 'src/App';
import { manageAccountData } from 'src/helpers/store/actions/manageaccount';
import AudioVideoCalling from 'src/views/component/application/chat/Messages/components/AudioVideoCall/AudioVideoCalling';
import { ReactComponent as Info } from 'src/assets/images/icons/info.svg';
import CloseIcon from '@mui/icons-material/CancelSharp';
import PreLoader from 'src/components/Loader';
import 'src/views/component/UI/ReportCard/reportCard.scss';

const TopBar = ({ auth, className, navigate, localUserData, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { id } = useParams();
  const reduxDogData = useSelector((state) => state?.profileData);
  const reduxBackStep = useSelector((state) => state?.page_navigate?.value);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const EmailMobileData = useSelector((state) => state?.manageAccountData);
  const backStep = reduxBackStep;
  const [headerText, setHeaderText] = useState('');
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [discardchange, setDiscardChange] = useState(false);
  const chatTopBar = location?.pathname.includes('/chat/');
  const [report, setReport] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDialogOpenUnmatch, setisDialogOpenUnmatch] = useState(false);
  // const [isVideoAllowed, setIsVideoAllowed] = useState(false);

  // const localVideoRef = useRef();
  // const socketRef = useRef();
  // const peerRef = useRef();
  // const [modalOpen, setModalOpen] = useState(false);

  // const [room, setRoom] = useState(null);
  // const [participant, setParticipant] = useState(null);

  // const handleModalClose = () => {
  //   setModalOpen(false);
  // };

  useEffect(() => {
    const updateHeader = (text) => {
      setHeaderText(text);
    };
    EventEmitter.subscribe('updateHeader', updateHeader);

    return () => {
      EventEmitter.unsubscribe('updateHeader', updateHeader);
    };
  }, []);
  const EmailDiscard = async () => {
    dispatch(manageAccountData(null));
    const sendData = {
      isDiscarded: true,
      ...(EmailMobileData && EmailMobileData?.newUser && EmailMobileData?.newUser?.countryCode && { countryCode: profileData && profileData?.countryCode ? profileData?.countryCode : "" })
    }
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.put(
        URLS.USER,
        sendData
      );
      if (status === 200) {
        setLoader(false);
        setTimeout(() => {
          navigate('/account-details')
        }, 200);
        setApiMessage('success', data?.message);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  }
  const handleBack = () => {
    EventEmitter.dispatch('updateHeader', '');
    if (location?.pathname.includes('/manage-account') && EmailMobileData && EmailMobileData?.activeStep === 1) {
      dispatch(manageAccountData({ ...EmailMobileData, backtab: true }));
    } else if (location?.pathname.includes('/manage-account') && EmailMobileData && EmailMobileData?.ModifyEmail) {
      setDiscardChange(true);
    } else if (location?.pathname.includes('/manage-account') && EmailMobileData && EmailMobileData?.activeStep === 0 && EmailMobileData?.editedEmail) {
      setDiscardChange(true);
    } else if (backStep !== 0 && !location.pathname.includes('')) {
      dispatch(handleNext(backStep - 1));
    } else if (location?.pathname === '/profile') {
      navigate('/matching');
    } else if (location?.pathname === '/edit-profile') {
      navigate('/profile');
    } else {
      if (location.pathname.includes('/favourites/benefit/')) {
        navigate('/favourites', {
          state: { isBenefit: true },
        });
      } else if (location.pathname.includes('/favourites/matches/')) {
        navigate('/favourites', {
          state: { isMatches: true },
        });
      } else if (location.pathname.includes('/kennel/collectbones/')) {
        navigate('/kennel', {
          state: { iscollected: true },
        });
      } else if (location.pathname.includes('/kennel/thrownbones/')) {
        navigate('/kennel', {
          state: { Thrown: true },
        });
      } else if (location.pathname.includes('/chat/')) {
        navigate('/kennel', {
          state: { message: true },
        });
      } else if (location.pathname.includes('/kennel/matches/')) {
        navigate('/kennel', {
          state: { Matches: true },
        });
      } else if (
        location.pathname.includes('/benefits/') &&
        location?.state?.placeDetails !== null &&
        location?.state?.placeDetails !== undefined
      ) {
        navigate(`/directory`, {
          state: {
            placeDetails: location?.state?.placeDetails,
            selectedCategory: location?.state?.selectedCategory,
          },
        });
      } else {
        navigate(-1);
      }
    }
  };

  const getUserDetailsNow = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.GET_PROFILE + '/' + id
      );
      if (status === 200) {
        setUserData(data?.userDetails);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (chatTopBar && id) {
      getUserDetailsNow();
    }
    // eslint-disable-next-line
  }, [chatTopBar, id]);

  const User_images = reduxDogData?.User_images;
  const final_img = User_images?.[1]?.images?.[0]?.url;
  const profileImg = localUserData?.images?.length === 0 ? '' : final_img;
  const hideBackBtn =
    location?.pathname?.includes('/matching') ||
    location?.pathname === '/benefits' ||
    location?.pathname === '/favourites' ||
    location?.pathname === '/kennel' ||
    location?.pathname === '/directory';
  const hideprofile = location?.pathname.includes('/profile-preview');

  // useEffect(() => {
  //   Video.connect('8d48829f35c15896d1aea7031c573806', {
  //     video: true,
  //     audio: true,
  //     name: 'my-room-name',
  //   })
  //     .then((room) => {
  //       setRoom(room);

  //       const localParticipant = room.localParticipant;
  //       setParticipant(localParticipant);

  //       localParticipant.videoTracks.forEach((publication) => {
  //         const track = publication.track;
  //         const videoElement = localVideoRef.current;
  //         videoElement.appendChild(track.attach());
  //       });

  //       room.on('participantConnected', (participant) => {
  //         setParticipant(participant);
  //         participant.videoTracks.forEach((publication) => {
  //           const track = publication.track;
  //           const videoElement = document.createElement('div');
  //           videoElement.appendChild(track.attach());
  //           document
  //             .getElementById('remote-participants')
  //             .appendChild(videoElement);
  //         });
  //       });

  //       room.on('participantDisconnected', (participant) => {
  //         setParticipant(null);
  //         document.getElementById('remote-participants').innerHTML = '';
  //       });
  //     })
  //     .catch((error) => console.error(error));

  //   return () => {
  //     if (room) {
  //       room.disconnect();
  //     }
  //   };
  // }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event) => {
    // event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const Reportuser = async () => {
    setIsFormSubmitted(true);
    if (title.trim() === '') {
      return;
    }
    let param_data = {
      userId: userData?.id,
      subject: title,
      message: message,
      status: '',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.REPORT_USER,
        param_data
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setTitle('');
        setMessage('');
        setReport(!report);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const handleunmatches = async (itemID, isCollected) => {
    // : URLS.REJECT_BONE + '/' + itemID
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.REJECT_BONE + '/' + itemID + '?type=collected'
      );
      if (status === 200) {
        setLoader(false);
        setisDialogOpenUnmatch(false);
        navigate('/kennel', { state: { message: true } });
        setApiMessage('success', data?.message);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  // const handleunmatches = async (itemID, isCollected) => {
  //   var param_data = {
  //     userId: this.state.chat_id,
  //     subject: title,
  //     message: message,
  //     status: '',
  //   };
  //   try {
  //     setLoader(true);
  //     const { status, data } = await axiosInstance.post(URLS.REPORT_USER, param_data);
  //     if (status === 200) {
  //       setLoader(false);
  //       setisDialogOpenUnmatch(false);
  //       setApiMessage('success', data?.message);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     setApiMessage('error', error?.response?.data?.message);
  //   }
  // };
  useEffect(() => {
    setAnchorEl(null);
  }, [location.pathname]);

  // useEffect(() => {
  //   async function checkVideoPermission() {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: true,
  //       });
  //       // Permission granted if the promise resolves successfully
  //       setIsVideoAllowed(true);
  //       // Don't forget to stop the stream when done with it
  //       stream.getTracks().forEach((track) => track.stop());
  //     } catch (error) {
  //       // Permission denied or an error occurred
  //       setIsVideoAllowed(false);
  //     }
  //   }

  //   checkVideoPermission();
  // }, []);

  return (
    <AppBar
      className={`${className} app-bar profile-app-bar`}
      elevation={0}
      position='static'
      {...rest}
    >
      {loader && <PreLoader />}
      {chatTopBar ? (
        <Toolbar>
          <Box className='d-flex justify-center align-center'>
            <BackArrow className='pointer' onClick={() => handleBack()} />
            <Avatar
              src={userData && userData?.images?.[0]?.url}
              style={{ margin: '0 10px', height: '30px', width: '30px' }}
            />
            <Box>
              <Typography
                variant='h5'
                className='font-r-12'
                style={{ fontWeight: '600', lineHeight: '12px' }}
              >
                {userData && userData?.firstname + ' ' + userData?.lastname}
              </Typography>
              {userData &&
                userData?.dogs &&
                userData?.dogs?.length > 0 &&
                userData?.dogs[0] &&
                userData?.dogs[0]?.name ? (
                <Typography
                  variant='h5'
                  className='font-r-12'
                  style={{ fontWeight: '600' }}
                >
                  {'& ' + userData?.dogs?.[0]?.name}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box className='chat-topbar-icon'>
            <Box className='pointer'>
              {/* <CallIcon /> */}
              <AudioVideoCalling
                callingType='audio_calling'
                reciverData={userData}
              />
            </Box>
            <Box className='pointer'>
              {/* <VideocamIcon /> */}
              <AudioVideoCalling
                // callingType={isVideoAllowed ? 'video_calling' : 'audio_calling'}
                callingType='video_calling'
                reciverData={userData}
              />
            </Box>
            <Box className='pointer' onClick={(e) => handleOpenMenu(e)}>
              <MoreVertIcon />
            </Box>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => navigate(`/profile-view/${userData?.id}`)}>
              See Profile{' '}
            </MenuItem>
            <MenuItem
              onClick={() => setisDialogOpenUnmatch(!isDialogOpenUnmatch)}
            >
              UnMatch
            </MenuItem>
            <MenuItem onClick={() => setReport(!report)}>Report</MenuItem>
          </Menu>
        </Toolbar>
      ) : (
        <Toolbar>
          {!hideBackBtn ? (
            hideprofile ? (
              <CrossIcon
                className='pointer'
                onClick={() => handleBack()}
                height='31px'
              />
            ) : (
              <BackArrow className='pointer' onClick={() => handleBack()} />
            )
          ) : (
            <Box></Box>
          )}
          {/* <div ref={localVideoRef}></div>
        <div id='remote-participants'></div> */}
          <Box>
            {headerText ? (
              <Typography variant='h6'>{headerText}</Typography>
            ) : hideprofile ? (
              <Typography variant='h6' className='mr-10'>
                {'  Profile Preview'}
              </Typography>
            ) : (
              <img src={blackLogo} alt='logo' height='34px' width='74px' />
            )}
          </Box>
          {!hideprofile ? (
            <Box className='pointer' onClick={() => navigate('/profile')}>
              <Avatar
                src={
                  profileImg === '' ? (
                    localUserData?.gender === 2 ? (
                      <Male />
                    ) : localUserData?.gender === 3 ? (
                      <Female />
                    ) : (
                      <Other />
                    )
                  ) : (
                    profileImg || profileData?.images?.[0]?.url
                  )
                }
              />
            </Box>
          ) : (
            ''
          )}
        </Toolbar>
      )}
      {/* <Dialog open={modalOpen} onClose={handleModalClose}>
      <div ref={localVideoRef}></div>
      <div id="remote-participants"></div>
      </Dialog> */}
      <Dialog
        open={isDialogOpenUnmatch}
        onClose={() => setisDialogOpenUnmatch(false)}
        className='delete-dialog-box'
      >
        <DialogContent>
          <Box className='delete-head-sec'>
            <Box className='favourites-delete-sec'>
              <Typography className='dailog-head-text pb-1'>
                {' '}
                {localesData?.favourites_delete?.ARE_YOU_SURE}
              </Typography>
              <Typography className='font-r-13 t-center pb-1'>
                {' '}
                {localesData?.UNMATCH}
              </Typography>
            </Box>
            <Box className='permission-btns'>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={6} className='cancel-btn'>
                  <CustomButton
                    variant='text'
                    title={localesData?.favourites_delete?.CANCEL}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-cancel-btn favourites-btn'
                    onClick={() => {
                      // setId('');
                      setisDialogOpenUnmatch(!isDialogOpenUnmatch);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    variant='text'
                    title={localesData?.favourites_delete?.YES}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-yes-btn favourites-btn'
                    onClick={() => {
                      // handleunmatches(userData?.id, userData?.collectedStatus);
                      handleunmatches(id);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={discardchange}
        onClose={() => { setDiscardChange(false) }}
        className='delete-dialog-box'
      >
        <DialogContent>
          <Box className='delete-head-sec'>
            <Box className='favourites-delete-sec'>
              <Typography className='dailog-head-text pb-1'>
                {' '}
                {localesData?.otp?.discard_changes}
              </Typography>
              <Typography className='font-r-13 t-center pb-1'>
                {' '}
                {location?.pathname.includes('/manage-account/mobile') ? localesData?.otp?.discard_changes_text_mobile : localesData?.otp?.discard_changes_text}
              </Typography>
            </Box>
            <Box className='permission-btns'>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={6} className='cancel-btn'>
                  <CustomButton
                    variant='text'
                    title={localesData?.favourites_delete?.CANCEL}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-cancel-btn favourites-btn'
                    onClick={() => {
                      setDiscardChange(false)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    variant='text'
                    title={localesData?.otp?.discard}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-yes-btn favourites-btn'
                    onClick={() => {
                      setDiscardChange(false);

                      if (EmailMobileData?.ModifyEmail) {
                        navigate('/account-details')
                        setTimeout(() => {
                          dispatch(manageAccountData(null));
                        }, 200);
                      } else {
                        EmailDiscard()
                        dispatch(manageAccountData(null));
                      }

                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={report}
        onClose={() => setReport(false)}
        className='delete-dialog-box'
      >
        <DialogContent>
          <Box className='d-flex justify-end close-icon-report'>
            <CloseIcon onClick={() => setReport(false)} />
          </Box>{' '}
          <Box className='report-card-head-sec'>
            <Box className='d-flex justify-center'>
              {/* <Avatar className='report-card-image' alt='Image'>
                    <UserImg />
                  </Avatar> */}
              <img
                src={userData && userData?.images?.[0]?.url}
                className='report-card-image'
                alt=''
              />
            </Box>
            <Box className='t-center pb-10'>
              <Typography className='card-person-name' variant='span'>
                {/* {matches?.firstname} {matches?.lastname} */}
                {userData && userData?.firstname + ' ' + userData?.lastname}
              </Typography>
            </Box>
            <Box className='input-field-sec'>
              <FormControl
                sx={{ m: 1, width: '25ch' }}
                className='form-control-sec pb-8'
                variant='filled'
                fullWidth={true}
                error={
                  isFormSubmitted && (title.trim() === '' || title.length > 50)
                }
              >
                <FilledInput
                  className='form-input-field'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <FormHelperText className='helper-text'>
                  {localesData?.report_card?.TITLE}
                </FormHelperText>
                <FormHelperText
                  className='helper-text color-error font-r-10 d-flex align-center'
                  error={isFormSubmitted && title.trim() === ''}
                >
                  {' '}
                  {isFormSubmitted && title.trim() === '' ? (
                    <>
                      {' '}
                      <Info className='mr-4' /> please enter title{' '}
                    </>
                  ) : (
                    ''
                  )}
                </FormHelperText>
              </FormControl>
              <FormControl
                sx={{ m: 1, width: '25ch' }}
                className='form-control-sec'
                variant='filled'
                fullWidth={true}
              >
                <FilledInput
                  className='form-input-field'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />

                <FormHelperText className='helper-text'>
                  {localesData?.report_card?.MESSAGE}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box className='send-btn-sec d-flex justify-center'>
              <CustomButton
                variant='contained'
                title={localesData?.report_card?.SEND}
                fontWeight='600'
                type='submit'
                className='send-btn'
                onClick={() => Reportuser()}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
