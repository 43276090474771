import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { IntlContext } from 'src/App';
import Webcam from 'react-webcam';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
// import { setApiMessage } from 'src/helpers/commonFunctions';
import undoPicture from 'src/assets/images/icons/undo.png';
import ImageUploadOptions from 'src/views/component/UI/ImageUploadOptions';
import { Config } from 'src/helpers/context';
import { ReactComponent as ImgUploadPlus } from 'src/assets/images/img-upload-plus.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross.svg';
import ClickPicture from 'src/assets/images/icons/camera.png';
// import { ReactComponent as CameraIcon } from 'src/assets/images/icons/camera.svg';
// import { ReactComponent as PhotoVideoIcon } from 'src/assets/images/icons/photo-video.svg';
// import { ReactComponent as InstagramIcon } from 'src/assets/images/icons/instagram.svg';
// import { ReactComponent as FacebookIcon } from 'src/assets/images/icons/facebook-pink.svg';
import { ReactComponent as VideoCamIcon } from 'src/assets/images/icons/videocam-icon.svg';
// import { identifiers } from 'src/helpers/constants/identifier';
import PreLoader from 'src/components/Loader';
import { addProfileData } from 'src/helpers/store/actions/profileSetup';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import CropImage from 'src/views/component/UI/CropImage/cropimage';

function DogPictures() {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');
  const [openCamera, setOpenCamera] = useState(false);
  const [facingMode, setFacingMode] = useState(null);
  const [cropOpen, setCropOpen] = useState(false);
  const [cropFile, setCropFile] = useState('');
  const [dorder, setDorder] = useState();
  const webcamRef = useRef(null);
  const reduxDogData = useSelector((state) => state?.profileData);
  const [imagesArray, setImagesArray] = useState([
    { displayOrder: 1, url: '', media_type: '' },
    { displayOrder: 2, url: '', media_type: '' },
    { displayOrder: 3, url: '', media_type: '' },
    { displayOrder: 4, url: '', media_type: '' },
    { displayOrder: 5, url: '', media_type: '' },
    { displayOrder: 6, url: '', media_type: '' },
    { displayOrder: 7, url: '', media_type: '' },
    { displayOrder: 8, url: '', media_type: '' },
    { displayOrder: 9, url: '', media_type: '' },
  ]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkForBackCamera = async () => {
      try {
        // Check for mediaDevices availability in the browser
        if (
          !navigator.mediaDevices ||
          !navigator.mediaDevices.enumerateDevices
        ) {
          console.error('MediaDevices API not available in this browser.');
          return;
        }

        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        // If permission is granted, enumerate devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasBackCam = devices.some(
          (device) =>
            device.kind === 'videoinput' &&
            device.label.toLowerCase().includes('back')
        );
        setIsMobile(hasBackCam);
        setFacingMode('user');
        // Release the camera stream
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.error('Error checking camera availability:', error);
      }
    };

    checkForBackCamera();
  }, []);

  const capture = () => {
    const base64Data = webcamRef.current.getScreenshot();
    setCapturedImage(base64Data);
    // const file = dataURLtoFile(base64Data, "image.jpg");
    // handleFileUpload([file]);
  };
  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  const videoConstraints = {
    facingMode: facingMode,
  };
  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    const dogImg = reduxDogData?.dog_images
      ? reduxDogData?.dog_images
      : location?.state?.images;

    const updatedArray = imagesArray?.map((item) => {
      const matchingItem = dogImg?.find(
        (obj, i) => i + 1 === item.displayOrder
      );
      if (matchingItem) {
        return {
          ...item,
          url: matchingItem?.url,
          media_type: matchingItem?.media_type,
        };
      }
      return item;
    });

    setImagesArray(updatedArray);
    // eslint-disable-next-line
  }, []);

  const redirectrulespage = () => {
    if (imagesArray?.filter((obj) => obj?.url !== '')?.length !== 0) {
      navigate(-1);
      dispatch(
        addProfileData({
          key: 'dog_images',
          value: imagesArray,
        })
      );
    } else {
      setErrors(localesData?.validation?.UPLOAD_AT_LIST_ONE_IMG_VIDEO);
    }

    //   updateUserDetails();
  };
  const handleFileUpload = async (file) => {
    const maxFileUploads = 9;

    for (let i = 0; i < file.length; i++) {
      const blankImg = imagesArray?.filter((item) => item?.url === '')?.[0]
        ?.displayOrder;
      if (file?.length === 1) {
        await uploadImage(file[i], blankImg, false);
      } else {
        await uploadImage(file[i], blankImg, true);
      }
      if (file.length >= maxFileUploads) {
        // If the maximum number of file uploads (9) is reached, break out of the loop
        break;
      }
    }
  };
  const uploadImage = async (file, blankImg, multipleImage) => {
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.IMAGE_UPLOAD;
    body.append('image', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 200) {
        setLoading(false);
        setIsOpen(false);
        setErrors('');
        setOpenCamera(false);
        setCapturedImage(null);
        const newData = [];

        const newObj = {
          displayOrder: multipleImage ? blankImg : currentIndex,
          url: data?.url?.location,
          media_type: data?.url?.mimetype,
        };
        newData.push(newObj);

        newData?.forEach((obj) => {
          const index = imagesArray?.findIndex(
            (item) => item?.displayOrder === obj?.displayOrder
          );
          if (index !== -1) {
            imagesArray[index] = obj;
          }
        });

        setImagesArray([...imagesArray]);

        // const updatedImagesArray = imagesArray.map((item) => {
        //   if (item.displayOrder === currentIndex) {
        //     return {
        //       ...item,
        //       url: data?.url?.location,
        //       media_type: data?.url?.mimetype,
        //     };
        //   }
        //   return item;
        // });
        // setImagesArray(updatedImagesArray);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  // const updateUserDetails = async (req) => {

  //   var _imgs = [];

  //   imagesArray?.map((item) => {
  //     var _url = item?.location?.split('/')[3];
  //     if (_url && _url !== '') {
  //       _imgs.push({
  //         url: _url,
  //       });
  //     }
  //     return true;
  //   });
  //   let sendData = { images: _imgs };
  //   if (_imgs?.length !== 0) {
  //     try {
  //       setLoader(true);
  //       const { status } = await axiosInstance.put(URLS.USER, sendData);
  //       if (status === 200) {
  //         getProfile();
  //         setLoader(false);
  //       }
  //     } catch (error) {
  //       setLoader(false);
  //       setApiMessage('error', error?.response?.data?.message);
  //     }
  //   } else {
  //     setErrors(localesData?.validation?.UPLOAD_AT_LIST_ONE);
  //   }
  // };

  const removeImage = (order) => {
    const updatedImagesArray = imagesArray.map((item) => {
      if (item.displayOrder === order) {
        return {
          ...item,
          url: '',
          media_type: '',
        };
      }
      return item;
    });
    setImagesArray(updatedImagesArray);
  };

  const getSelectedMedia = (images) => {
    const url = Config.baseURL + URLS.IMAGE_UPLOAD;
    images.forEach((image) => {
      const imageUrl = image?.media_url;
      setLoading(true);
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append('image', blob, 'image.jpg');

          return fetch(url, {
            method: 'POST',
            body: formData,
          });
        })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);

          const blankImg = imagesArray?.filter(
            (item) => item?.location === ''
          )?.[0]?.displayOrder;

          const newData = [];

          const newObj = {
            displayOrder: images?.length === 1 ? currentIndex : blankImg,
            url: data?.url?.location,
            media_type: data?.url?.mimetype.includes('video')
              ? 'video'
              : 'image',
          };
          newData.push(newObj);

          newData?.forEach((obj) => {
            const index = imagesArray?.findIndex(
              (item) => item?.displayOrder === obj?.displayOrder
            );
            if (index !== -1) {
              imagesArray[index] = obj;
            }
          });
          setImagesArray([...imagesArray]);

          // const updatedImagesArray = imagesArray.map((item) => {
          //   if (item.displayOrder === currentIndex) {
          //     return {
          //       ...item,
          //       url: data?.url?.location,
          //       media_type: data?.url?.mimetype.includes('video')
          //         ? 'video'
          //         : 'image',
          //     };
          //   }
          //   return item;
          // });
          // setImagesArray(updatedImagesArray);
          setIsOpen(false);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  // DRAG AND DROP
  const handleDragStart = (event, imageIndex) => {
    setDraggedIndex(imageIndex);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', imageIndex);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event, targetIndex) => {
    event.preventDefault();
    const sourceIndex = draggedIndex;
    if (sourceIndex === targetIndex) return;
    const reorderedImages = [...imagesArray];
    if (reorderedImages[sourceIndex]?.url) {
      const temp = reorderedImages[targetIndex];
      reorderedImages[targetIndex] = reorderedImages[sourceIndex];
      reorderedImages[sourceIndex] = temp;
    }

    setImagesArray(reorderedImages);
    setDraggedIndex(null);
  };
  const handleTouchStart = (event, imageIndex) => {
    event.preventDefault();
    setDraggedIndex(imageIndex);
  };
  const handleTouchMove = (event, targetIndex) => {
    event.preventDefault();
    if (draggedIndex === null) return;

    const sourceIndex = draggedIndex;
    if (sourceIndex === targetIndex) return;

    const reorderedImages = [...imagesArray];
    const movedImage = reorderedImages[sourceIndex];

    reorderedImages.splice(sourceIndex, 1);
    reorderedImages.splice(targetIndex, 0, movedImage);

    setImagesArray(reorderedImages);
    setDraggedIndex(targetIndex);
  };
  const handleTouchEnd = () => {
    setDraggedIndex(null);
  };
  const handleCrop = (file, indez) => {
    setCropFile(file);
    setDorder(indez + 1);
    setTimeout(() => {
      setCropOpen(true);
    }, 700);
  };
  const handleCropclose = () => {
    setCropOpen(false);
    // setCropFile('');
    if (openCamera) {
      const file = dataURLtoFile(capturedImage, 'image.jpg');
      handleFileUpload([file]);
    }
  };
  const EditUploadImage = async (file) => {
    var baseText = file.getCroppedCanvas().toDataURL();
    const blob = await (await fetch(baseText)).blob();
    const files = new File([blob], cropFile, {
      type: 'image',
      lastModified: new Date(),
    });
    setLoading(true);
    const body = new FormData();
    const method = 'post';
    const url = URLS.IMAGE_UPLOAD;
    body.append('image', files);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    try {
      const { status, data } = await axiosInstance[method](url, body, config);
      if (status === 200) {
        setOpenCamera(false);
        setCapturedImage(null);
        setLoading(false);
        setIsOpen(false);

        const newObj = {
          displayOrder: Number(dorder),
          url: data?.url?.location,
          media_type: data?.url?.mimetype,
        };
        imagesArray[Number(dorder) - 1] = newObj;

        setImagesArray([...imagesArray]);
        setCropOpen(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <Box className='pb-2'>{loading && <PreLoader />}</Box>
      <Box className='t-center pb-4'>
        <Typography variant='h3' className='pb-3 text-left color-pink s-bold'>
          {localesData?.dog?.YOUR_DOG_PICTURES}
        </Typography>
      </Box>

      <div
        className='lot-Images-block pb-4 img-main-block'
        style={{ touchAction: 'manipulation' }}
      >
        {imagesArray?.map((item, index) => (
          <div
            key={index}
            className='file-uploader'
            draggable={item?.url ? true : false}
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, index)}
            onTouchStart={(event) => handleTouchStart(event, index)}
            onTouchMove={(event) => handleTouchMove(event, index)}
            onTouchEnd={handleTouchEnd}
            onContextMenu={(event) => event.preventDefault()}
          >
            <div className='dropzone d-flex justify-content-center align-items-center mx-auto'>
              <Box className='t-center profile-picture'>
                <div>
                  <Box className='picture-upload'>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignContent='center'
                      direction='row'
                      className='file-upload-sec'
                    >
                      <Box className='card'>
                        {item?.url !== '' ? (
                          item?.media_type?.includes('video') ? (
                            <Box style={{ height: '100%' }}>
                              <video className='video-box'>
                                <source src={item?.url} />
                              </video>
                              <VideoCamIcon className='video-cam-icon' />
                              <IconButton
                                disableRipple
                                onClick={() => removeImage(item?.displayOrder)}
                                className='remove-image'
                              >
                                <CrossIcon />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box style={{ height: '100%' }}>
                              <Avatar src={item?.url} />
                              <IconButton
                                disableRipple
                                onClick={() => removeImage(item?.displayOrder)}
                                className='remove-image'
                              >
                                <CrossIcon />
                              </IconButton>
                            </Box>
                          )
                        ) : (
                          <Box
                            className='file-upload-box pointer'
                            onClick={() => {
                              setIsOpen(!isOpen);
                              setCurrentIndex(item?.displayOrder);
                            }}
                          >
                            {/* <input
															type="file"
															id="fileupload"
															className="file-upload-input"
														/> */}
                            {/* <IconButton disableRipple> */}
                            <ImgUploadPlus className='file-upload-img' />
                            {/* </IconButton> */}
                          </Box>
                        )}
                      </Box>
                      {item?.url && (
                        <div
                          className={
                            item?.media_type?.includes('video')
                              ? 'picture-overlay not-clickable'
                              : 'picture-overlay'
                          }
                          onClick={() =>
                            item?.url !== '' &&
                            !item?.media_type?.includes('video') &&
                            handleCrop(item?.url, index)
                          }
                        />
                      )}
                    </Grid>
                  </Box>
                </div>
              </Box>
            </div>
          </div>
        ))}
        {errors && (
          <FormHelperText
            sx={{ fontSize: '10px', marginLeft: '12px' }}
            className='color-error'
          >
            {errors}
          </FormHelperText>
        )}
      </div>
      <Box className='t-center pb-23'>
        <Typography
          variant='h6'
          dangerouslySetInnerHTML={{
            __html: localesData?.edit_dog_profile?.DRAG_RECORDER,
          }}
        />
      </Box>

      <Box className='t-center w-100 pb-4'>
        <CustomButton
          variant='contained'
          title={localesData?.DONE}
          background='#59D09D'
          fontWeight='600'
          onClick={() => {
            // setIsOpen(!isOpen);
            redirectrulespage();
          }}
        />
      </Box>
      {openCamera && (
        <Box className={capturedImage ? 'camera-wrap clicked' : 'camera-wrap'}>
          {!capturedImage && (
            <Webcam
              audio={false}
              screenshotFormat='image/jpeg'
              ref={webcamRef}
              mirrored={facingMode === 'environment' ? false : true}
              videoConstraints={{ ...videoConstraints, facingMode }}
              // onUserMediaError={setOpenCamera(false)}
            />
          )}
          <Box
            className='capture-wrap'
            style={{ width: '100%', display: 'flex' }}
          >
            {!capturedImage && (
              <>
                <Box style={{ marginLeft: '15px', alignSelf: 'center' }}>
                  <img
                    src={undoPicture}
                    onClick={() => {
                      setOpenCamera(false);
                      setCapturedImage(null);
                    }}
                    alt='not found'
                    width={30}
                    className='pointer' //mt-3
                  />
                </Box>

                <Box style={{ width: 'fit-content', margin: '0 auto' }}>
                  <img
                    src={ClickPicture}
                    onClick={capture}
                    alt='not found'
                    width={50}
                    className='pointer'
                  />
                </Box>
                <Box style={{ marginRight: '15px', alignSelf: 'center' }}>
                  {isMobile && (
                    <FlipCameraAndroidIcon
                      className='pointer'
                      onClick={() => handleSwitchCamera()}
                      style={{ fill: '#ffffff', width: '30px', height: '30px' }}
                    />
                  )}
                </Box>
              </>
            )}
            {capturedImage && (
              <>
                <img
                  src={capturedImage}
                  alt='Captured'
                  width={200}
                  height={200}
                />
                <Box className='retake-wrap'>
                  <span
                    onClick={() => {
                      setOpenCamera(true);
                      setCapturedImage(null);
                    }}
                  >
                    Retry
                  </span>
                  <span
                    onClick={() => {
                      // const file = dataURLtoFile(capturedImage, 'image.jpg');
                      // handleFileUpload([file]);
                      handleCrop(capturedImage, currentIndex - 1);
                    }}
                  >
                    Ok
                  </span>
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
      {isOpen && (
        <Box className='select-option-wrap pointer'>
          <ImageUploadOptions
            onClose={() => setIsOpen(false)}
            handleFileUpload={handleFileUpload}
            localesData={localesData}
            openCamera={() => {
              setOpenCamera(true);
              setIsOpen(false);
            }}
            getSelectedMedia={getSelectedMedia}
            accept='both'
          />
        </Box>
      )}
      <DialogBox
        handleClose={() => handleCropclose()}
        open={cropOpen}
        title=''
        className='crop-dialog-box'
        content={
          <>
            <CropImage
              localesData={localesData}
              file={cropFile}
              // MultipleFile={MultipleFile}
              getCropData={EditUploadImage}
              handleCropclose={handleCropclose}
            />
          </>
        }
      />
    </>
  );
}

export default DogPictures;
