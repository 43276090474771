import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { IntlContext } from 'src/App';
// import UpgradePlan from '../UpgradePlan';
import AboutGender from './AboutGender';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
// import { useDispatch } from 'react-redux';
import AboutAge from './AboutAge';
import { useLocation, useNavigate } from 'react-router-dom';
import AboutDogBreed from './AboutDogBreed';
import AboutManuallyDogBreed from './AboutManuallyDogBreed';
import AboutEmployment from './AboutEmployment';
import AboutEducation from './AboutEducation';
import AboutHobbies from './AboutHobbies';
import AboutReligion from './AboutReligion';
import AboutPolitics from './AboutPolitics';
import AboutHeightWeight from './AboutHeightWeight';
import AboutEthnicity from './AboutEthnicity';
import AboutVices from './AboutVices';
import AboutLocation from './AboutLocation';

const LookingFor = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [loader, setLoader] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [activeStep, setActiveStep] = useState('');
  const hideUpgradePlan = location?.state !== 'manuallyDogBreed';

  useEffect(() => {
    if (location?.state) {
      setActiveStep(location?.state);
    } else {
      navigate('/profile');
    }
  }, [location, navigate]);

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.ALL_ANSWERS + '?lookingFor=true'
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswers(data?.questions);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllAnswers();
  }, []);

  const renderComponent = () => {
    switch (activeStep) {
      case '1':
        return (
          <AboutGender
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '2':
        return (
          <AboutAge
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '3':
        return (
          <AboutDogBreed
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case 'manuallyDogBreed':
        return (
          <AboutManuallyDogBreed
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '4':
        return (
          <AboutEmployment
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '5':
        return (
          <AboutEducation
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '6':
        return (
          <AboutHobbies
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '7':
        return (
          <AboutReligion
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '8':
        return (
          <AboutPolitics
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '9':
        return (
          <AboutHeightWeight
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '10':
        return (
          <AboutEthnicity
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '11':
        return (
          <AboutVices
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case '12':
        return (
          <AboutLocation
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      case 'matching_location': // Matching Location
        return (
          <AboutLocation
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
      default:
        return (
          <AboutGender
            localesData={localesData}
            setLoader={setLoader}
            answers={answers}
            navigate={navigate}
            hideUpgradePlan={hideUpgradePlan}
          />
        );
    }
  };

  return (
    <Box className='content-scroll'>
      {' '}
      {/* main-page-content-wrap */}
      {loader && <PreLoader />}
      {/* {hideUpgradePlan && (
        <>
          <Box>
            <UpgradePlan
              title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
            />
            <Box className="b-bottom mt-4 mb-4" />
          </Box>
        </>
      )} */}
      {renderComponent()}
    </Box>
  );
};

export default LookingFor;
