import React from 'react';
import Page from '../../../components/Page';
import HelpCenter from '../../component/application/HelpCenter';

const HelpCenterView = () => {
  return (
    <Page title='Help Center'>
      <HelpCenter />
    </Page>
  );
};

export default HelpCenterView;
