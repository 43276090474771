import React from 'react';
import Page from 'src/components/Page';
import DogProfile from 'src/views/component/application/ManageProfile/DogProfile';

const DogProfileView = () => {
  return (
    <Page title='Dog Profile'>
      <DogProfile />
    </Page>
  );
};

export default DogProfileView;
