import React from 'react';
import Page from 'src/components/Page';
import UserProfile from 'src/views/component/application/ManageProfile/ProfileEditing/UserProfile';

const UserProfileView = () => {
  return (
    <Page title='Profile Picture'>
      <UserProfile />
    </Page>
  );
};

export default UserProfileView;
