import React, { useEffect, useState } from 'react';
import '../keyboard.scss';
import { Box, Tab, TextField, Button, InputAdornment } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import EmojiTabpnel from './EmojiTabPanel';
import AvtarTabpnel from './AvtarTabPanel';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import PreLoader from 'src/components/Loader';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { ReactComponent as Search } from 'src/assets/images/icon-map-search.svg';

const AddEmojiTab = ({
  localesData,
  iconTab,
  setIsOpen,
  activeTab,
  setActiveTab,
  handleSend,
}) => {
  const [selectedTab, setSelectedTab] = useState('1');
  const [loader, setLoader] = useState(false);
  const [bonesList, setBonesList] = useState([]);
  const [dogsList, setDogsList] = useState([]);
  const [heartsList, setHeartsList] = useState([]);
  const [homesList, setHomesList] = useState([]);

  const [searchBonesList, setSearchBonesList] = useState([]);
  const [searchDogsList, setSearchDogsList] = useState([]);
  const [searchHeartsList, setSearchHeartsList] = useState([]);
  const [searchHomesList, setSearchHomesList] = useState([]);

  const [recentList, setRecentList] = useState([]);
  const localRecentList = fetchFromStorage(identifiers?.RECENT_EMOJI);
  const [message, setMessage] = useState('');

  const tabsData = [
    {
      value: '1',
      label: localesData?.web_keyboard?.STICKER,
    },
    {
      value: '2',
      label: localesData?.web_keyboard?.AVTAR_BTN,
    },
  ];

  const getEmojiData = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_EMOJI_LIST);
      if (status === 200) {
        let emoji_list = data?.emojies;
        if (emoji_list !== null && emoji_list.length !== 0) {
          let bones_list = filterEmoji(emoji_list, 'bones');
          let dogs_list = filterEmoji(emoji_list, 'dogs');
          let hearts_list = filterEmoji(emoji_list, 'hearts');
          let homes_list = filterEmoji(emoji_list, 'homes');
          setBonesList(bones_list);
          setDogsList(dogs_list);
          setHeartsList(hearts_list);
          setHomesList(homes_list);

          setSearchBonesList(bones_list);
          setSearchDogsList(dogs_list);
          setSearchHeartsList(hearts_list);
          setSearchHomesList(homes_list);
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getEmojiData();
    setRecentList(localRecentList);
  }, []);

  const filterEmoji = (emoji_list, filter_type) => {
    let final_list = [];
    for (let i = 0; i < emoji_list?.length; i++) {
      if (emoji_list[i]?.type !== null) {
        if (emoji_list[i]?.type?.name === filter_type) {
          final_list?.push(emoji_list[i]);
        }
      }
    }
    return final_list;
  };
  const handleChange = (event) => {
    const { value } = event?.target;
    setMessage(value);
    if (activeTab === '2') {
      const newData = searchBonesList.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = value.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setBonesList(newData);
    } else if (activeTab === '3') {
      const newData = searchDogsList.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = value.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDogsList(newData);
    } else if (activeTab === '4') {
      const newData = searchHeartsList.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = value.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setHeartsList(newData);
    } else if (activeTab === '5') {
      const newData = searchHomesList.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = value.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setHomesList(newData);
    }
  };

  return (
    <Box className='sticker-head-sec'>
      {loader && <PreLoader />}
      <Box className='sticker-tab-sec'>
        {activeTab !== '1' && (
          <Box className='sticker-search-sec'>
            <TextField
              onChange={(e) => handleChange(e)}
              className='sticker-input'
              id='message'
              placeholder='Search'
              margin='normal'
              value={message}
              // onKeyDown={handleKeyUp}
              InputLabelProps={{ shrink: true }}
              // InputAdornment={{ positionEnd: <Search /> }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Button className='done-button'>Done</Button>
          </Box>
        )}
        {activeTab === '1' ? (
          <TabContext value={selectedTab}>
            <TabList className='tab-list-sec'>
              {tabsData?.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    label={tab?.label}
                    value={String(tab?.value)}
                    className={
                      selectedTab === index
                        ? 'sticker-tab selected-tab'
                        : 'sticker-tab'
                    }
                    onClick={() => {
                      setSelectedTab(tab?.value);
                    }}
                  />
                );
              })}
            </TabList>
            <Box
              className='border-sec d-flex justify-center'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {/* <Typography variant='span' className='bottom-border' /> */}
            </Box>
            <TabPanel
              value='1'
              style={{ padding: '9px' }}
              className='main-tab-panel'
            >
              <EmojiTabpnel
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                iconTab={iconTab}
                recentList={recentList}
                bonesList={bonesList}
                dogsList={dogsList}
                heartsList={heartsList}
                homesList={homesList}
                handleSend={handleSend}
              />
            </TabPanel>
            <TabPanel
              value='2'
              style={{ padding: '9px' }}
              className='main-tab-panel'
            >
              <AvtarTabpnel
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                iconTab={iconTab}
              />
            </TabPanel>
          </TabContext>
        ) : (
          <EmojiTabpnel
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            iconTab={iconTab}
            recentList={recentList}
            bonesList={bonesList}
            dogsList={dogsList}
            heartsList={heartsList}
            homesList={homesList}
            handleSend={handleSend}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddEmojiTab;
