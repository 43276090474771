import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
// import { useSelector } from 'react-redux';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import RangeSlider from 'src/views/component/UI/RangeSlider';
import { CustomTextField } from 'src/views/component/UI/textfield';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useLocation } from 'react-router-dom';
import UpgradePlan from '../UpgradePlan';

const valuetext = (value) => {
  return `${value}`;
};

const AboutLocation = ({
  hideUpgradePlan,
  localesData,
  setLoader,
  answers,
  navigate,
}) => {
  const location = useLocation();
  const minDistance = 1;
  const [locationData, setLocationData] = useState('');
  const [locationSlider, setLocationSlider] = useState([]);
  const [addressData, setAddressData] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationToDisplay, setLocationToDisplay] = useState('');
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   answers?.map((item) => {
  //     if (item.id === 64) {
  //       if (item.Option[0]?.answer === null || item.Option[0]?.answer === '') {
  //         setAddressData('');
  //       } else {
  //         setAddressData(item.Option[0]?.answer);
  //       }
  //       if (item.Option[1]?.answer === null || item.Option[1]?.answer === '') {
  //         setLocationData({
  //           location_opt_id: item.Option[0]?.id,
  //           distance_opt_id: item.Option[1]?.id,
  //           dont_care: false,
  //         });
  //         setLocationSlider([0, 100]);
  //       } else {
  //         var ansHeight = item.Option[1]?.answer;
  //         setLocationData({
  //           dont_care: ansHeight?.split('-')[2] === 'true' ? true : false,
  //           location_opt_id: item.Option[0]?.id,
  //           distance_opt_id: item.Option[1]?.id,
  //           is_must: item.Option[1].isMust === 1 ? true : false,
  //         });
  //         setLocationSlider([
  //           Number(ansHeight?.split('-')[0]),
  //           Number(ansHeight?.split('-')[1]),
  //         ]);
  //       }
  //     }
  //     return true;
  //   });
  // }, [answers]);

  useEffect(() => {
    answers?.forEach((item) => {
      if (item.id === 64) {
        if (item.Option[0]?.answer === null || item.Option[0]?.answer === '') {
          setAddressData('');
        } else {
          setAddressData(item.Option[0]?.answer);
          setLocationToDisplay(item?.Option[0]?.answer);
        }

        if (item.Option[1]?.answer === null || item.Option[1]?.answer === '') {
          setLocationData({
            location_opt_id: item.Option[0]?.id,
            distance_opt_id: item.Option[1]?.id,
            lat: item.Option[1]?.lat,
            long: item.Option[1]?.long,
            dont_care: false,
          });
          setLocationSlider([0, 100]);
        } else {
          const ansHeight = item.Option[1]?.answer;
          const dontCare = ansHeight?.split('-')[2] === 'true';

          setLocationData({
            dont_care: dontCare,
            location_opt_id: item.Option[0]?.id,
            distance_opt_id: item.Option[1]?.id,
            lat: item.Option[1]?.lat,
            long: item.Option[1]?.long,
            is_must: item.Option[1]?.isMust === 1,
          });
          setLocationSlider([
            Number(ansHeight?.split('-')[0]),
            Number(ansHeight?.split('-')[1]),
          ]);
        }
      }
    });
  }, [answers]);

  const handleChangeDistance = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setLocationSlider([
        Math.min(newValue[0], locationSlider[1] - minDistance),
        locationSlider[1],
      ]);
    } else {
      setLocationSlider([
        locationSlider[0],
        Math.max(newValue[1], locationSlider[0] + minDistance),
      ]);
    }
  };

  const handleChange = (address) => {
    setAddressData(address);
  };

  // const handleSelect = (address) => {
  //   setAddressData(address);
  //   setLocationToDisplay(address);
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => {
  //       setLatitude(latLng?.lat);
  //       setLongitude(latLng?.lng);
  //     })
  //     .catch((error) => console.error('Error', error));
  // };

  const handleSelect = async (address) => {
    try {
      setAddressData(address);
      setLocationToDisplay(address);
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatitude(latLng?.lat);
      setLongitude(latLng?.lng);
      setError('');
    } catch (error) {
      setError(error);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];
    const locationVal = addressData?.length === 0;
    const latlong = locationData;

    opt.push({
      optionId: locationData?.location_opt_id,
      answer: locationVal ? null : locationToDisplay ? locationToDisplay : null,
    });
    opt.push({
      optionId: locationData?.distance_opt_id,
      answer:
        locationSlider?.[0]?.toString() + '-' + locationSlider?.[1]?.toString(),
      startRange: locationSlider?.[0],
      endRange: locationSlider?.[1],
      lat: locationVal
        ? null
        : locationToDisplay && latitude
        ? latitude.toString()
        : latlong?.lat
        ? latlong?.lat
        : null,
      long: locationVal
        ? null
        : locationToDisplay && latitude
        ? longitude.toString()
        : latlong?.long
        ? latlong?.long
        : null,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        saveInfo();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const saveInfo = async () => {
    var opt = [];

    const locationVal = addressData?.length === 0;
    const latlong = locationData;

    opt.push({
      optionId: locationData?.location_opt_id,
      answer: locationToDisplay,
      lookingFor: true,
    });
    opt.push({
      optionId: locationData?.distance_opt_id,
      answer:
        locationSlider?.[0]?.toString() + '-' + locationSlider?.[1]?.toString(),
      startRange: locationSlider?.[0],
      endRange: locationSlider?.[1],
      lat: locationVal
        ? null
        : locationToDisplay && latitude
        ? latitude.toString()
        : latlong?.lat
        ? latlong?.lat
        : null,
      long: locationVal
        ? null
        : locationToDisplay && latitude
        ? longitude.toString()
        : latlong?.long
        ? latlong?.long
        : null,
      // lat: latitude ? latitude.toString() : '',
      // long: latitude ? longitude.toString() : '',
      lookingFor: true,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        if (location?.state === 'matching_location') {
          navigate('/matching');
        } else {
          navigate('/edit-profile', {
            state: 'looking-for',
          });
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <Box>
        <Box className='chip-container-box'>
          {hideUpgradePlan && (
            <Box>
              <UpgradePlan
                title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR}
              />
              <Box className='b-bottom mt-4 mb-4' />
            </Box>
          )}
        </Box>
        <Typography variant='h3' className='mb-5 t-center s-bold'>
          {localesData?.profile?.LOCATION}
        </Typography>

        <Box className='pb-10'>
          <PlacesAutocomplete
            value={addressData}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <CustomTextField
                  variant='standard'
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'custom-textfield location-search-input',
                  })}
                />
                <FormHelperText className='custom-field-helper'>
                  {localesData?.user_family_plan?.DESC}
                </FormHelperText>
                <div className='autocomplete-dropdown-container'>
                  {loading && <div className='t-center'>Loading...</div>}
                  {suggestions?.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion?.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {error && <FormHelperText error>{error && error}</FormHelperText>}
        </Box>
        <Typography variant='h3' className='t-center s-bold mb-4'>
          {localesData?.profile?.SELECT_DISTANCE}
        </Typography>
        <Box className='mb-9 pb-20' m='0 45px'>
          <Box className='d-flex space-between'>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
            >
              {locationSlider?.[0]?.toString()}km
              {/* 0km */}
            </Typography>
            <Typography
              variant='h6'
              className='font-r-13'
              style={{ fontWeight: 600 }}
            >
              {locationSlider?.[1]?.toString()}km
              {/* 100km */}
            </Typography>
          </Box>
          <RangeSlider
            min={0}
            max={100}
            getAriaLabel={() => 'Minimum distance'}
            value={locationSlider}
            onChange={handleChangeDistance}
            valueLabelDisplay='on'
            getAriaValueText={valuetext}
            disableSwap
          />
        </Box>
        <Box className='d-flex justify-center'>
          <Box
            className='t-center m-auto d-flex justify-center about-location-button'
            sx={{ maxWidth: '134px' }}
          >
            <CustomButton
              classname='uppercase'
              variant='contained'
              title={localesData?.DONE}
              background='#59D09D'
              fontWeight='600'
              onClick={handleOnSubmit}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutLocation;
