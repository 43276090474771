import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { ListItem, ListItemIcon } from '@mui/material';

const ImageDropzone = ({
  accept,
  label,
  multiple,
  onDrop,
  text,
  disabled,
  icon,
}) => {
  var acceptFile =
    accept === 'image'
      ? {
          accept: {
            'image/*': [],
          },
        }
      : accept === 'both'
      ? {
          accept: {
            'image/*': [],
            'video/*': [],
          },
        }
      : {};
  const { getRootProps, acceptedFiles, getInputProps } = useDropzone(
    acceptFile
  );

  useEffect(() => {
    acceptedFiles && onDrop(acceptedFiles);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  return (
    <div className='file-uploader'>
      {label && <p className='mb-1 inputField-label'>{label}</p>}
      <div
        {...getRootProps({
          className:
            'dropzone d-flex justify-content-center align-items-center mx-auto',
          // onDrop: event => event.stopPropagation(),
        })}
      >
        <ListItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <input {...getInputProps()} />
          <p>{text}</p>
        </ListItem>
      </div>
    </div>
  );
};

ImageDropzone.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  // ref: PropTypes.string,
  multiple: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
};

ImageDropzone.defaultProps = {
  accept: '',
  label: '',
  multiple: false,
  text: '',
  // ref: '',
};

export default ImageDropzone;
