import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../UI/Button/CustomButton';
import {
  clearSvgData,
  setSVGFile,
} from '../../../../helpers/store/actions/progressImage';
import svgData from '../../../../assets/images/finish-dog.svg';
import SubDialogbox from '../../UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from '../../UI/Subscriptionpopup/SubscriptionDialog';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const SetUpFinish = ({ localesData, dispatch, handleNext }) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);

  useEffect(() => {
    dispatch(setSVGFile(svgData));
  }, [dispatch]);

  const redirectNow = () => {
    if (profileData?.isPaid === false) {
      setToogleDialog(!toogleDialog);
    } else {
      dispatch(clearSvgData());
      dispatch(handleNext(0));
      navigate('/');
    }
  };
  const handleClose = () => {
    setToogleDialog(!toogleDialog);
    dispatch(clearSvgData());
    dispatch(handleNext(0));
    navigate('/');
  };

  const completaProfile = () => {
    // dispatch(clearSvgData());
    dispatch(handleNext(0));
    navigate('/profile-completion');
  };
  return (
    <>
      <Box className='pb-6'>
        <Box className='t-center'>
          <Typography
            variant='h3'
            className='mb-3'
            dangerouslySetInnerHTML={{
              __html: localesData?.help_center?.FANTASTIC,
            }}
          />
          <Typography
            variant='h4'
            className='mb-5'
            dangerouslySetInnerHTML={{
              __html: localesData?.help_center?.ACCOUNT,
            }}
          />
          <Typography
            variant='h4'
            className='mb-10'
            dangerouslySetInnerHTML={{
              __html: localesData?.help_center?.MATCHES,
            }}
          />
        </Box>

        <CustomButton
          classname='uppercase'
          variant='contained'
          title={localesData?.profile?.COMPLETE_MY_PROFILE}
          background='#F54D71'
          fontWeight='600'
          onClick={() => completaProfile()}
        />
        <CustomButton
          classname='uppercase'
          variant='contained'
          title={localesData?.profile?.GO_WINDOW_SHOPPING}
          background='#59D09D'
          fontWeight='600'
          onClick={() => redirectNow()}
        />
      </Box>
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => handleClose()}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default SetUpFinish;
