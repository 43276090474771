import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  DialogContent,
  Dialog,
  Grid,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// import { ReactComponent as NakedBrew } from 'src/assets/images/nakedbrew.svg';
import { ReactComponent as PinkStar } from 'src/assets/images/icons/pink-star.svg';
// import { ReactComponent as BeachLogo } from 'src/assets/images/beach-logo.svg';
// import { ReactComponent as ParkLogo } from 'src/assets/images/park-logo.svg';
// import { ReactComponent as DuckLogo } from 'src/assets/images/ducklogo.svg';
// import PlaceDummyImg from 'src/assets/images/place-dummy-img.png';
// import DeleteDialogBox from '../DeleteDialogBox';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { Config } from 'src/helpers/context';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import NoDataPlaces from './NoDataPlaces';
import PreLoader from 'src/components/Loader';
import '../favourites.scss';

const Places = ({ localesData, selectedTab }) => {
  // const [popup, setPopup] = useState(false);
  // const [togglePopup, setTogglePopup] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [placesData, setPlacesData] = useState([]);
  const [placeData, setPlaceData] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  // GET PLACES
  const getPlacesdata = async () => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.DIRECTORY);
      if (status === 200) {
        setLoader(false);
        const filteredData = data?.results?.filter(
          (item) =>
            item.isLike === 1 || item.isLike === '1' || item.isLike === true
        );

        setPlacesData(filteredData);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const checkFavFirst = async (placeId) => {
    setLoader(true);
    try {
      const { status } = await axiosInstance.get(
        URLS.GET_FAVOURITE_STATUS + placeId
      );
      if (status === 200) {
        setLoader(false);
        setApiMessage('success', 'Done');
        getPlacesdata();
        removeFavouriteAPI(placeId);
        setIsDialogOpen(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const removeFavouriteAPI = async (id) => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.delete(URLS.FAVOURITE + '/' + id);
      if (status === 200) {
        setIsDialogOpen(false);
        getPlacesdata();
        setPlaceData('');
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getPlacesdata();
  }, [location, selectedTab]);
  return (
    <Box>
      {loader && <PreLoader />}
      {placesData?.length === 0 ? (
        <NoDataPlaces />
      ) : (
        <Box className='favourite-tab-content'>
          {placesData &&
            placesData?.length > 0 &&
            placesData?.map((content, id) => {
              return (
                <>
                  <Box
                    className='places-card-sec mb-2 pointer '
                    onClick={() => {
                      navigate('/place-details', {
                        state: { placeDetails: content },
                      });
                    }}
                  >
                    <Box className='card-head d-flex'>
                      <Typography className='card-icon d-flex'>
                        <img
                          height='70px'
                          width='130px'
                          src={`${Config.mediaURL + content?.markerPinImage}`}
                          alt=''
                        />
                      </Typography>
                      <Avatar
                        alt='like'
                        variant='square'
                        className='star-icon'
                        // src={content?.star}
                        onClick={() => {
                          setIsDialogOpen(true);
                          setPlaceData(content);
                        }}
                      >
                        <PinkStar />
                      </Avatar>
                      <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        className='delete-dialog-box'
                      >
                        <DialogContent>
                          <Box className='delete-head-sec'>
                            <Box className='favourites-delete-sec'>
                              <Typography
                                // dangerouslySetInnerHTML={{
                                //   __html: localesData?.favourites_delete?.ARE_YOU_SURE,
                                // }}

                                className='dailog-head-text pb-1'
                              >
                                {' '}
                                {localesData?.favourites_delete?.ARE_YOU_SURE}
                              </Typography>
                              <Typography
                                // dangerouslySetInnerHTML={{
                                //   __html: localesData?.favourites_delete?.DELETE_DESC,
                                // }}

                                className='font-r-13 t-center pb-1'
                              >
                                {' '}
                                {localesData?.favourites_delete?.DELETE_DESC}
                              </Typography>
                            </Box>
                            <Box className='permission-btns'>
                              <Grid
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                              >
                                <Grid item xs={6} className='cancel-btn'>
                                  <CustomButton
                                    variant='text'
                                    title={
                                      localesData?.favourites_delete?.CANCEL
                                    }
                                    fontWeight='600'
                                    type='submit'
                                    classname='favourites-cancel-btn favourites-btn'
                                    onClick={() => {
                                      // setId('');
                                      setIsDialogOpen(false);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <CustomButton
                                    variant='text'
                                    title={localesData?.favourites_delete?.YES}
                                    fontWeight='600'
                                    type='submit'
                                    classname='favourites-yes-btn favourites-btn'
                                    onClick={() => {
                                      if (
                                        placeData?.isLike === 1 ||
                                        placeData?.isLike === '1' ||
                                        placeData?.isLike === true
                                      ) {
                                        checkFavFirst(placeData?.placeId);
                                      } else {
                                        removeFavouriteAPI(placeData?.id);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </Box>
                    <Box
                      className='p-9'
                      // onClick={() => navigate(`/fav-places/${content?.id}`)}
                    >
                      <Box>
                        <Typography className='card-head-txt font-r-12'>
                          {content?.name}
                        </Typography>
                        <Typography className=' font-r-12'>
                          {content?.address}
                        </Typography>
                      </Box>
                      <Typography className='font-r-12'>
                        {content.description}
                      </Typography>
                    </Box>
                  </Box>
                </>
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default Places;
