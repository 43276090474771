import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import ProgressBar from '../../../UI/ProgressBar';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../UI/IOSSwitch';
// import { CustomTextField } from '../../../UI/textfield';
import { useNavigate } from 'react-router-dom';
import { CustomSelect } from 'src/views/component/UI/select';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import { fetchFromStorage } from 'src/helpers/context';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';

const UserHeightWeight = ({
  localesData,
  handleNext,
  reduxProgress,
  questions,
  setLoader,
  dispatch,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  const [tallData, setTallData] = useState('');
  const [weightData, setWeightData] = useState('');
  const [tall, setTall] = useState('');
  const [tallIsPrivate, setTallIsPrivate] = useState(false);
  const [weight, setWeight] = useState('');
  const [weightIsPrivate, setWeightIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);

  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item.q_order === 20) {
  //         setTallData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //       if (item.q_order === 21) {
  //         setWeightData({
  //           title: item.title,
  //           id: item.id,
  //           options: item.Option,
  //         });
  //       }
  //     });
  // }, [questions]);

  useEffect(() => {
    const question = questions?.find((o) => o?.id === 2);

    question?.Question?.forEach((item) => {
      if (item.q_order === 20) {
        setTallData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private:
            answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private ===
              '1' ||
            answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private === 1
              ? true
              : false,
        });
        setTallIsPrivate(
          answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private === '1' ||
            answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private === 1
            ? true
            : false
        );
      }
      if (item.q_order === 21) {
        setWeightData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private:
            answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private ===
              '1' ||
            answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private === 1
              ? true
              : false,
        });
        setWeightIsPrivate(
          answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private === '1' ||
            answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private === 1
            ? true
            : false
        );
      }
    });

    setTall(answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.answer);
    setWeight(answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.answer);
  }, [questions, answars]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'tallData') {
      setTallData({ ...tallData, answer: value });
    }
    if (name === 'tallPrivate') {
      setTallData({ ...tallData, private: checked });
      setTallIsPrivate(checked);
    }
    if (name === 'weightData') {
      setWeightData({ ...weightData, answer: value });
    }
    if (name === 'weightPrivate') {
      setWeightData({ ...weightData, private: checked });
      setWeightIsPrivate(checked);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: tallData?.options?.[0]?.id,
      answer: tall ? tall : null,
      selected: false,
      private: tallData?.private ? tallData?.private : false,
    });
    opt.push({
      optionId: weightData?.options?.[0]?.id,
      answer: weight ? weight : null,
      selected: false,
      private: weightData?.private ? weightData?.private : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        if (isEdit) {
          // checkForBlankData();
          checkForBlankAnsData();
        } else {
          handleNext();
        }
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Box className='t-center'>
      <Box>
        <Typography
          className='mb-2 font-r-12'
          dangerouslySetInnerHTML={{
            __html: localesData?.profile_completion_user?.PROFILE_COMPLETION,
          }}
        />
        <Box className='mb-3'>
          <Typography variant='h4' className='pb-1'>
            {/* {reduxProgress}% */}
            {progressWeightage}%
          </Typography>
          <ProgressBar processData={progressWeightage} />
        </Box>
        <Typography variant='h3' className='s-bold pb-2'>
          {localesData?.userwork?.Now_its_all_about_you}
        </Typography>
        <Typography variant='h4' className='pb-4'>
          {
            localesData?.user_height_weight
              ?.lets_get_some_physical_attributes_out_of_the_way
          }
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'left' }} className='pb-6'>
        <Box className='field-content'>
          <Select
            fullWidth
            variant='standard'
            className='custom-select'
            name='tall'
            input={<CustomSelect />}
            IconComponent={DropdownIcon}
            value={tall ? tall : ''}
            onChange={(e) => setTall(e.target.value)}
            displayEmpty
          >
            {identifiers?.talldata?.map((item, i) => (
              <MenuItem key={i} value={item?.label} className='select-options'>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Typography variant='h5' className='s-bold pb-2'>
            {tallData?.title}
          </Typography>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='tallPrivate'
                checked={tallIsPrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange(e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  } else {
                    handleChange(e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {tallIsPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </Box>
        <Box className='field-content'>
          {/* <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='weightData'
            onChange={(e) => handleChange(e)}
          /> */}
          <Select
            fullWidth
            variant='standard'
            className='custom-select'
            name='weight'
            input={<CustomSelect />}
            IconComponent={DropdownIcon}
            value={weight ? weight : ''}
            onChange={(e) => setWeight(e.target.value)}
            displayEmpty
          >
            {identifiers?.weight?.map((item, i) => (
              <MenuItem key={i} value={item?.label} className='select-options'>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Typography variant='h5' className='s-bold pb-2'>
            {weightData?.title}
          </Typography>
          <Box sx={{ justifyContent: 'space-between' }} className='d-flex pb-4'>
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch />}
                name='weightPrivate'
                checked={weightIsPrivate}
                // onChange={(e) => {
                //   profileData?.isPaid === false
                //     ? setToogleDialog(true)
                //     : handleChange(e);
                // }}
                onChange={(e) => {
                  if (isEdit) {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  } else {
                    handleChange(e);
                  }
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {weightIsPrivate ? <LockIcon /> : <UnLockIcon />}
          </Box>
        </Box>
      </Box>
      <Box>
        <CustomButton
          variant='contained'
          title={localesData?.userwork?.keep_going}
          background='#6A42ED'
          fontWeight='400'
          // type='submit'
          onClick={() => handleOnSubmit()}
        />

        <Box className='pb-8 mb-4 d-flex justify-center'>
          <DogIcon />
          <Typography
            variant='h5'
            className='pointer'
            // onClick={() => navigate('/')}
            onClick={() => redirectHomePage()}
          >
            {localesData?.userwork?.FINISH}
          </Typography>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default UserHeightWeight;
