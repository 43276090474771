import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as Checked } from '../../../../../assets/images/profile-checked.svg';
import { ReactComponent as UnChecked } from '../../../../../assets/images/unchecked.svg';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import UpgradePlan from '../UpgradePlan';
import { useNavigate } from 'react-router-dom';
// import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

const WhatYouLookingFor = ({
  localesData,
  handleNext,
  setLoader,
  dispatch,
  questions,
  getProfile,
  answars,
}) => {
  const navigate = useNavigate();

  const [checkLooking, setCheckLooking] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);

  // const lookingForOpt = answars?.[0]?.Question?.find(
  //   (o) => o?.q_order === 4
  // )?.Option;

  useEffect(() => {
    // Get the selected chips from the saved data and set them in selectedChips state
    const savedSelectedChips = answars
      ?.find((o) => o?.id === 8)
      ?.Option?.filter((item) => item?.selected === 1);

    const savedSelectedOther = answars
      ?.find((o) => o?.id === 74)
      ?.Option?.filter((item) => item?.selected === 1);
    savedSelectedOther?.filter((item) =>
      item?.id === 275
        ? (item.id = 11)
        : item?.id === 276
        ? (item.id = 12)
        : item?.id === 277
        ? (item.id = 13)
        : item
    );
    const combinedArray = [
      ...(savedSelectedChips || []),
      ...(savedSelectedOther || []),
    ];
    setSelectedChips(combinedArray || []);

    // Set the available options for the checkboxes in checkLooking state
    answars?.map((item) => {
      if (item?.id === 8) {
        setCheckLooking(item?.Option?.filter((option) => option?.id !== 13));
      }
      return true;
    });
  }, [answars]);

  return (
    <Formik
      initialValues={{
        looking_for: selectedChips?.map((chip) => chip.id.toString()),
      }}
      enableReinitialize={true}
      // validationSchema={Yup.object({
      //   looking_for: Yup.array().min(
      //     1,
      //     localesData?.validation?.SELECT_AT_LIST_ONE
      //   ),
      // })}
      onSubmit={async (requestData) => {
        var opt = [];

        opt.push({
          optionId: checkLooking[0]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[0]?.id?.toString()
          ),
          private: false,
        });
        opt.push({
          optionId: checkLooking[1]?.id,
          answer: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          )
            ? '1'
            : '0',
          selected: requestData?.looking_for?.includes(
            checkLooking[1]?.id?.toString()
          ),
          private: false,
        });

        let sendData = {
          private: false,
          optionIds: opt,
        };
        try {
          setLoader(true);
          const { status, data } = await axiosInstance.post(
            URLS.SET_ANSWERS,
            sendData
          );
          if (status === 200) {
            setApiMessage('success', data?.message);
            handleNext();
            setLoader(false);
            navigate('/edit-profile', {
              state: 'profile-edit',
            });
          }
        } catch (error) {
          setLoader(false);
          setApiMessage('error', error?.response?.data?.message);
        }
      }}
    >
      {({ errors, handleChange, handleSubmit, values }) => (
        <form
          onSubmit={handleSubmit}
          style={{ overflowY: 'scroll', height: 'inherit' }}
          className='form-content pb-6'
        >
          <UpgradePlan title={localesData?.profile?.WHAT_YOU_ARE_LOOKING_FOR} />
          <Box className='t-center mb-5'>
            <Box>
              <Divider className='divider' />
            </Box>

            <Typography
              variant='h4'
              className='pb-2 mt-2'
              dangerouslySetInnerHTML={{
                __html: localesData?.edit_profile?.more_information,
              }}
            />

            <Typography variant='h4' className='s-bold'>
              {localesData?.profile?.SELECT_AS_YOU_LIKE}
            </Typography>
          </Box>
          <Box className='field-content pb-20'>
            {checkLooking?.map((option, i) => (
              <Box className='checkbox-container mb-4' key={i}>
                <Typography variant='h5' className='custom-checkbox-text'>
                  {option?.title}
                </Typography>
                <Checkbox
                  className='custom-checkbox'
                  icon={<UnChecked />}
                  checkedIcon={<Checked />}
                  checked={values?.looking_for?.includes(
                    option?.id?.toString()
                  )}
                  disableRipple
                  value={option?.id}
                  name='looking_for'
                  onChange={handleChange}
                />
              </Box>
            ))}
            {errors.looking_for && (
              <FormHelperText className='color-error'>
                {errors.looking_for}
              </FormHelperText>
            )}
          </Box>

          <Box className='keep-going-sec done-btn-sec'>
            <CustomButton
              variant='contained'
              title={localesData?.DONE}
              background={'#59D09D'}
              fontWeight='600'
              classname='done-btn s-bold'
              onClick={() => handleSubmit()}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default WhatYouLookingFor;
