import React, { useContext, useState } from 'react';
import {
  Box,
  Avatar,
  DialogContent,
  Dialog,
  Typography,
  Grid,
  FilledInput,
  FormHelperText,
  FormControl,
  Divider,
} from '@mui/material';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RespondCard from '../RespondCard';
import { ReactComponent as NotiWomen } from 'src/assets/images/icons/noti-women.svg';
import { ReactComponent as NotiMen } from 'src/assets/images/icons/noti-men.svg';
import { ReactComponent as NotiDog } from 'src/assets/images/icons/noti-dog.svg';
import { ReactComponent as Cake } from 'src/assets/images/icons/cake.svg';
import { ReactComponent as Height } from 'src/assets/images/icons/height.svg';
import { ReactComponent as GenderIcon } from 'src/assets/images/icons/gender-icon.svg';
import { ReactComponent as Location } from 'src/assets/images/icons/Icon-location.svg';
import { ReactComponent as DogAvtar } from 'src/assets/images/dog_avatar.svg';
import { ReactComponent as Info } from 'src/assets/images/icons/info.svg';

import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-active.svg';
import { ReactComponent as BoneBox } from 'src/assets/images/icons/bone-box.svg';
import { IntlContext } from 'src/App';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { useNavigate } from 'react-router-dom';
import PreLoader from 'src/components/Loader';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { ReactComponent as UserImg } from 'src/assets/images/Fav-matches-girl.svg';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import 'src/views/component/UI/ReportCard/reportCard.scss';
import CloseIcon from '@mui/icons-material/CancelSharp';
import { ReactComponent as CrossIcon } from 'src/assets/images/icons/cross-icon.svg';
import { ReactComponent as ChatIcon } from 'src/assets/images/icons/chat-icon.svg';
import { ReactComponent as PinkDogHeand } from 'src/assets/images/icons/dog-pink-heand-icon.svg';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const DogCard = ({
  thrownid,
  content,
  selectedBox,
  dogs,
  handleBoxClick,
  firstImg,
  secondImg,
  star,
  dogsName,
  id,
  firstDogName,
  secondDogName,
  firstDogBirthDate,
  secondDogBirthDate,
  firstDogHeight,
  firstDogLocationName,
  secondDogGenderIcon,
  secondDogGender,
  secondDogType,
  activeTab,
  setMatchesData,
  matches,
  getThrownBonesdata,
  collected,
  Cindex,
  getCollecteddata,
  getMatchesdata,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tid, setID] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [toogleDialog, setToogleDialog] = useState(false);
  const [cardBlur, setCardBlur] = useState([false, true]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenUnmatch, setisDialogOpenUnmatch] = useState(false);
  const [isDialogPopupOpen, setIsDialogPopupOpen] = useState(false);
  const [collectedpopup, setCollectedpopup] = useState(false);
  const [unmatchId, setUnmatchId] = useState();
  const [report, setReport] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [titleError, setTitleError] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const handleClickpopup = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Thrown BONES REJECT
  const handleReject = async (itemID, isCollected) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        isCollected
          ? URLS.REJECT_BONE + '/' + itemID + '?type=collected'
          : URLS.REJECT_BONE + '/' + itemID
      );

      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        getThrownBonesdata();
        setCollectedpopup(false);
        setAnchorEl(null);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  //Thrown BONES REJECT
  const handleReject2 = async (itemID, isCollected) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        isCollected
          ? URLS.REJECT_BONE + '/' + itemID + '?type=collected'
          : URLS.REJECT_BONE + '/' + itemID
      );

      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        // setIsDialogPopupOpen(false);
        getCollecteddata();
        setIsDialogOpen(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleclickcollected = (id) => {
    if (id === 0) {
      navigate(`/kennel/collectbones/${collected?.id}`);
    } else {
      setToogleDialog(true);
    }
  };
  const handleCardClick = () => {
    setCardBlur([cardBlur[1], false]);
  };

  const handleDialogClick = () => {
    setIsDialogPopupOpen(true);
  };

  //Handle Unmatches
  const handleunmatches = async (itemID, isCollected) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        isCollected
          ? URLS.REJECT_BONE + '/' + itemID + '?type=collected'
          : URLS.REJECT_BONE + '/' + itemID
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        setisDialogOpenUnmatch(false);
        // getThrownBonesdata();
        getMatchesdata();
        setAnchorEl(null);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  //REMOVE TO FAV
  const removeFav = async (fevouriteId) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + fevouriteId
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setMatchesData((prevThrownBonesData) => {
          return prevThrownBonesData.map((content) => {
            if (content.fevouriteId === fevouriteId) {
              return { ...content, isLike: false };
            }
            return content;
          });
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleAccept = async (userID) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.ACCEPT_BONE + '/' + userID
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setIsDialogPopupOpen(false);
        setLoader(false);
        getCollecteddata();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  //ADD to Fav
  const addToFav = async (ItemId) => {
    const param_data = {
      following: ItemId.toString(),
      type: 'matches',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        param_data
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setMatchesData((prevThrownBonesData) => {
          return prevThrownBonesData.map((content) => {
            if (content.id === ItemId) {
              return { ...content, isLike: true };
            }
            return content;
          });
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  //Report
  const Reportuser = async () => {
    setIsFormSubmitted(true);
    if (title.trim() === '') {
      return;
    }
    setTitleError('');
    let param_data = {
      userId: matches?.id,
      subject: title,
      message: message,
      status: '',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.REPORT_USER,
        param_data
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        setTitle('');
        setMessage('');
        setReport(!report);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      {loader && <PreLoader />}
      {activeTab === '1' ? (
        <Box
          className='bones-card-sec collect-tab mb-2 box-sec'
          key={content?.id}
        >
          <Box
            className='bones-box-sec d-flex pointer'
            sx={{
              backgroundColor:
                content?.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
            }}
            onClick={() => {
              // handleBoxClick(content?.id);
              navigate(`/kennel/thrownbones/${content?.id}`);
            }}
          >
            <img src={firstImg} className='dog-img' />
            <Box className='bone-sec pb-3'>
              <Box className='bones-grid-sec'>
                <Box className=''>
                  <Typography variant='h6' className=' font-r-12 dog-name '>
                    {firstDogName} {secondDogName}
                  </Typography>
                </Box>

                <Box
                  className='d-flex dog-details-sec o-auto'
                  onClick={() => {
                    navigate(`/kennel/thrownbones/${content?.id}`);
                  }}
                >
                  {/* CAKE */}
                  <Box className='d-flex dog-details'>
                    <Box className='detail-content '>
                      <Cake />
                    </Box>
                    <Box className='font-r-12 align-self'>
                      {' '}
                      {firstDogBirthDate !== '' &&
                      firstDogBirthDate !== undefined
                        ? firstDogBirthDate
                        : '-'}
                    </Box>
                  </Box>
                  {/* HEIGHT */}
                  <Box className='d-flex location dog-details'>
                    <Box className='detail-content'>
                      <Height />
                    </Box>
                    <Box className='font-r-12 align-self'>
                      {' '}
                      {firstDogHeight !== '' && firstDogHeight !== undefined
                        ? firstDogHeight
                        : '-'}
                    </Box>
                  </Box>
                  {/* LOCATION */}
                  <Box className='d-flex location dog-details'>
                    <Box className='detail-content'>
                      {' '}
                      <Location />
                    </Box>
                    <Box className='font-r-12 align-self'>
                      {' '}
                      {firstDogLocationName !== '' &&
                      firstDogLocationName !== undefined
                        ? firstDogLocationName
                        : '-'}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className='star-sec'>
            {' '}
            <Avatar
              alt='Remy Sharp'
              variant='square'
              className='bones-star-icon'
              onClick={() => {
                setCollectedpopup(true);
                setID(content);
              }}
            >
              <BoneBox />
            </Avatar>
            <Box className='drop-down-sec-thrown'>
              <Button
                id='demo-positioned-button'
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => {
                  handleClick(event);
                }}
                className='more-btn'
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className='rewind-list'
              >
                <MenuItem onClick={() => handleReject(content?.id, false)}>
                  Rewind
                </MenuItem>
              </Menu>
            </Box>
            <Dialog
              open={collectedpopup}
              onClose={() => setCollectedpopup(false)}
              className='delete-dialog-box'
            >
              <DialogContent>
                <Box className='delete-head-sec'>
                  <Box className='favourites-delete-sec'>
                    <Typography className='dailog-head-text pb-1'>
                      {' '}
                      {localesData?.favourites_delete?.ARE_YOU_SURE}
                    </Typography>
                    <Typography className='font-r-13 t-center pb-1'>
                      {' '}
                      {localesData?.UNMATCH}
                    </Typography>
                  </Box>
                  <Box className='permission-btns'>
                    <Grid
                      container
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <Grid item xs={6} className='cancel-btn'>
                        <CustomButton
                          variant='text'
                          title={localesData?.favourites_delete?.CANCEL}
                          fontWeight='600'
                          type='submit'
                          classname='favourites-cancel-btn favourites-btn'
                          onClick={() => {
                            // setId('');
                            setCollectedpopup(false);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CustomButton
                          variant='text'
                          title={localesData?.favourites_delete?.YES}
                          fontWeight='600'
                          type='submit'
                          classname='favourites-yes-btn favourites-btn'
                          onClick={() => handleReject(content?.id, true)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          {/* SECOND PART OF BOX */}
          {dogs.length === 0 ? (
            <>
              <Typography className='font-r-12 t-center m-4'>
                {localesData?.NO_DOG}
              </Typography>
            </>
          ) : (
            <Box
              className='bones-box-sec d-flex pointer'
              style={{ padding: '19px 0px 10px 17px' }}
              sx={{
                backgroundColor:
                  content.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
              }}
              onClick={() => {
                // handleBoxClick(content?.id);
                navigate(`/kennel/thrownbones/${content?.id}`);
              }}
            >
              {/* <Avatar alt='Remy Sharp' variant='square' className='dog-img'>
              {' '}
              {secondImg}
            </Avatar> */}
              {/* <img src={secondImg} className='dog-img' /> */}
              <div style={{ width: '60px' }}>
                {thrownid?.dogs?.images?.[0] &&
                thrownid?.dogs?.images?.[0]?.url?.endsWith('.mp4') ? (
                  <img
                    src={thrownid?.dogs?.images?.[1]?.url || ''}
                    className='dog-img'
                  />
                ) : (
                  <img
                    src={thrownid?.dogs?.images?.[1]?.url}
                    className='dog-img'
                  />
                )}
              </div>
              <Box className='bone-sec pb-3'>
                <Box className='bones-grid-sec'>
                  <Box className=''>
                    <Box className=' font-r-12 dog-name'>
                      {dogsName !== '' && dogsName !== undefined
                        ? dogsName
                        : '-'}{' '}
                    </Box>
                  </Box>
                  <Box
                    className='d-flex dog-details-sec pointer'
                    onClick={() => {
                      navigate(`/kennel/thrownbones/${content?.id}`);
                    }}
                  >
                    {/* CAKE */}
                    <Box className='d-flex dog-details'>
                      <Box className='detail-content '>
                        <Cake />
                      </Box>

                      <Box className='font-r-12 align-self'>
                        {' '}
                        {secondDogBirthDate !== '' &&
                        secondDogBirthDate !== undefined
                          ? secondDogBirthDate
                          : '-'}{' '}
                      </Box>
                    </Box>
                    {/* DOG */}
                    <Box className='d-flex location dog-details'>
                      <Box className='detail-content'>
                        <DogAvtar />
                      </Box>
                      <Box className='font-r-12 align-self'>
                        {' '}
                        {secondDogType !== '' && secondDogType !== undefined
                          ? secondDogType
                          : '-'}{' '}
                      </Box>
                    </Box>
                    {/* LOCATION */}
                    <Box className='d-flex location dog-details'>
                      <Box className='detail-content'>
                        <GenderIcon />
                      </Box>
                      <Box className='font-r-12 align-self'>
                        {' '}
                        {secondDogGender}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : activeTab === '2' ? (
        // collected.isPaid === true ? (
        <>
          <Box>
            <Box
              className='collect-sec  bones-card-sec mb-2'
              // sx={{
              //   backgroundColor:
              //     content?.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
              // }}
              // onClick={() => handleBoxClick(content?.id)}
              key={collected?.id}
            >
              <Box
                className={
                  profileData.isPaid === true ||
                  (profileData.isPaid === false && Cindex === 0)
                    ? 'bones-box-sec d-flex'
                    : 'blur bones-box-sec d-flex'
                }
                // className={
                //   Cindex == 0
                //     ? ' bones-box-sec d-flex'
                //     : 'blur bones-box-sec d-flex'
                // }

                onClick={() => {
                  profileData.isPaid === true ||
                  (profileData.isPaid === false && Cindex === 0)
                    ? navigate(`/kennel/collectbones/${collected?.id}`)
                    : handleclickcollected(Cindex);
                }}
                // onClick={id ? handleCardClick : () => handleBoxClick(content?.id)}
                sx={{
                  cursor: cardBlur[id] ? 'pointer' : 'default',
                  backgroundColor:
                    collected?.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
                }}
              >
                <img src={collected?.images?.[0]?.url} className='dog-img' />
                <Box className='bone-sec'>
                  <Box className='bones-grid-sec'>
                    <Box className=''>
                      <Typography
                        variant='span'
                        className=' font-r-12 dog-name'
                      >
                        {collected?.firstname} {collected?.lastname}
                      </Typography>
                    </Box>
                    <Box className='d-flex dog-details-sec'>
                      {/* CAKE */}
                      <Box className='d-flex dog-details'>
                        <Typography variant='span' className='detail-content '>
                          <Cake />
                        </Typography>
                        <Typography variant='span' className='font-r-12 '>
                          {firstDogBirthDate}
                        </Typography>
                      </Box>

                      {/* HEIGHT */}
                      <Box className='d-flex location dog-details'>
                        <Typography variant='span' className='detail-content'>
                          <Height />
                        </Typography>
                        <Typography variant='span' className='font-r-12 '>
                          {firstDogHeight}
                        </Typography>
                      </Box>
                      {/* LOCATION */}
                      <Box className='d-flex location dog-details'>
                        <Typography variant='span' className='detail-content'>
                          {' '}
                          <Location />
                        </Typography>
                        <Typography variant='span' className='font-r-12 '>
                          {firstDogLocationName}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box className='star-sec star-bone'>
                <Avatar
                  alt='Remy Sharp'
                  variant='square'
                  className='bones-star-icon'
                  onClick={() => {
                    // handleClickpopup();
                    // setID(content);
                  }}
                >
                  <BoneBox />
                </Avatar>
              </Box> */}
              <Box className='bone-icon-sec'>
                {profileData.isPaid === true ||
                (profileData.isPaid === false && Cindex === 0) ? (
                  <Box
                    className='bone-icon'
                    onClick={() => {
                      setIsDialogPopupOpen(true);
                    }}
                  >
                    {' '}
                    <BoneBox />
                  </Box>
                ) : (
                  <Box
                    className='bone-icon icon-wrap'
                    onClick={() => {
                      setToogleDialog(true);
                    }}
                  >
                    {' '}
                    <Typography
                      variant='h6'
                      className='font-r-12 s-bold font-bold'
                    >
                      {' '}
                      {collected?.firstname}
                    </Typography>
                    <BoneBox />
                  </Box>
                )}{' '}
                <Dialog
                  open={isDialogPopupOpen}
                  className='collect-bones-wrap'
                  onClose={() => setIsDialogPopupOpen(false)}
                >
                  <DialogContent>
                    <Box className='respond-card-sec'>
                      <Box className='respond-card-head pb-2'>
                        <Typography
                          className='t-center head-text'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.repsond_card?.RECEIVED_BONE,
                          }}
                        />
                        <Box className='t-center'>
                          <Typography variant='span' className='dog-name'>
                            {collected?.firstname}{' '}
                            <span className='head-text'> and</span>{' '}
                            {collected?.dogs?.name}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className='dog-img-card pb-6'>
                        <Grid
                          container
                          xl={9}
                          lg={9}
                          md={9}
                          sm={9}
                          xs={9}
                          justifyContent='space-between'
                          alignContent='center'
                          direction='row'
                          className='respond-card-grid'
                        >
                          {/* <Avatar
                              alt='Remy Sharp'
                              variant='square'
                              className='dog-img'
                            /> */}
                          <img
                            src={collected?.images?.[0]?.url}
                            className='dog-img'
                          />
                          {/* <div style={{ width: '60px' }}> */}
                          {collected?.dogs?.images?.[0] &&
                          collected?.dogs?.images?.[0]?.url?.endsWith(
                            '.mp4'
                          ) ? (
                            <img
                              src={collected?.dogs?.images?.[1]?.url || ''}
                              className='dog-img'
                            />
                          ) : (
                            <img
                              src={collected?.dogs?.images?.[0]?.url}
                              className='dog-img'
                            />
                          )}
                          {/* </div> */}
                          {/* <img
                            src={collected?.dogs?.images?.[0]?.url}
                            className='dog-img'
                          /> */}
                        </Grid>
                      </Box>
                      <Box className='content-sec'>
                        {/* <Typography
                            className='t-center content-text'
                            dangerouslySetInnerHTML={{
                              __html: localesData?.repsond_card?.ANIMATION_BONE,
                            }}
                          /> */}
                        <Box className='d-flex align-center justify-center'>
                          {profileData?.gender === 2 ? (
                            <NotiMen className='women-icon' />
                          ) : (
                            <NotiWomen className='women-icon' />
                          )}

                          <NotiDog className='dog-icon' />
                        </Box>
                      </Box>
                      <Box className='t-center trainer-permission-content pb-4'>
                        <Typography
                          variant='h4'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.repsond_card?.WANT_TO_RETRIVE,
                          }}
                        />
                        <Typography variant='span' className='dog-trainer-name'>
                          {collected?.dogs?.name}?
                        </Typography>
                      </Box>
                      <Box className='d-flex space-between icon-sec '>
                        <Button
                          alt='Remy Sharp'
                          variant='square'
                          className='bones-star-icon close-btn'
                          startIcon={<CrossIcon />}
                          onClick={() => {
                            handleReject2(collected?.id, true);
                          }}
                        />
                        <Button
                          alt='Remy Sharp'
                          variant='square'
                          className='bones-star-icon agree-btn'
                          startIcon={<PinkDogHeand />}
                          // onClick={() => {
                          //   setShowCardDetails(false);
                          // }}
                          onClick={() => handleAccept(collected?.id)}
                        />
                      </Box>
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
              {/* SECOND PART OF BOX */}
              {collected?.dogs.length === 0 ? (
                <>
                  <Typography className='font-r-12 t-center m-4'>
                    {localesData?.NO_DOG}
                  </Typography>
                </>
              ) : (
                <Box
                  className={
                    profileData?.isPaid === true ||
                    (profileData?.isPaid === false && Cindex === 0)
                      ? 'bones-box-sec d-flex'
                      : 'blur bones-box-sec d-flex'
                  }
                  // className="bones-box-sec d-flex"
                  // onClick={() => handleBoxClick(content?.id)}
                  onClick={() => {
                    profileData.isPaid === true ||
                    (profileData.isPaid === false && Cindex === 0)
                      ? navigate(`/kennel/collectbones/${collected?.id}`)
                      : handleclickcollected(Cindex);
                  }}
                  style={{ padding: '19px 0px 10px 17px' }}
                >
                  {/* <img
                    src={collected?.dogs?.images?.[0]?.url}
                    className='dog-img'
                  /> */}
                  <div style={{ width: '60px' }}>
                    {collected?.dogs?.images?.[0] &&
                    collected?.dogs?.images?.[0]?.url?.endsWith('.mp4') ? (
                      <img
                        src={collected?.dogs?.images?.[1]?.url || ''}
                        className='dog-img'
                      />
                    ) : (
                      <img
                        src={collected?.dogs?.images?.[0]?.url}
                        className='dog-img'
                      />
                    )}
                  </div>{' '}
                  <Box className='bone-sec'>
                    <Box className='bones-grid-sec'>
                      <Box className=''>
                        <Typography
                          variant='span'
                          className=' font-r-12 dog-name d-flex align-center'
                        >
                          {' '}
                          {collected?.dogs?.name}
                          {collected.dogs.deceased ? (
                            <>
                              <span className='round'></span>
                              {collected.dogs?.deceased ? 'Deceased' : ''}
                            </>
                          ) : null}
                        </Typography>
                      </Box>
                      <Box className='d-flex dog-details-sec'>
                        {/* CAKE */}
                        <Box className='d-flex dog-details'>
                          <Typography
                            variant='span'
                            className='detail-content '
                          >
                            <Cake />
                          </Typography>
                          <Typography variant='span' className='font-r-12 '>
                            {collected?.dogs?.age}
                          </Typography>
                        </Box>
                        {/* DOG */}
                        <Box className='d-flex location dog-details'>
                          <Typography variant='span' className='detail-content'>
                            <DogAvtar />
                          </Typography>
                          <Typography variant='span' className='font-r-12 '>
                            {' '}
                            {collected?.dogs?.breed}
                          </Typography>
                        </Box>
                        {/* LOCATION */}
                        <Box className='d-flex location dog-details'>
                          <Typography variant='span' className='detail-content'>
                            <GenderIcon />
                          </Typography>
                          <Typography variant='span' className='font-r-12 '>
                            {' '}
                            {collected?.dogs?.gender}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <DialogBox
            open={toogleDialog}
            handleClose={() => setToogleDialog(!toogleDialog)}
            title=''
            content={
              <PremiumPopup
                handleClose={() => setToogleDialog(!toogleDialog)}
                // navigate={navigate}
              />
            }
          />
        </>
      ) : activeTab === '4' ? (
        <Box className='bones-card-sec mb-2' key={matches?.id}>
          <Box
            className='bones-box-sec d-flex'
            sx={{
              backgroundColor:
                matches?.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
            }}
            onClick={() => {
              // handleBoxClick(matches?.id);
              navigate(`/kennel/matches/${matches?.id}`);
            }}
          >
            <img src={firstImg} className='dog-img' />
            <Box className='bone-sec pb-3'>
              <Box className='bones-grid-sec'>
                <Box className=''>
                  <Typography variant='h6' className=' font-r-12 dog-name'>
                    {firstDogName} {secondDogName}
                  </Typography>
                </Box>
                <Box className='d-flex dog-details-sec'>
                  {/* CAKE */}
                  <Box className='d-flex dog-details'>
                    <Typography variant='span' className='detail-content '>
                      <Cake />
                    </Typography>
                    <Typography variant='span' className='font-r-12 align-self'>
                      {firstDogBirthDate !== '' &&
                      firstDogBirthDate !== undefined
                        ? firstDogBirthDate
                        : '-'}
                    </Typography>
                  </Box>

                  {/* HEIGHT */}
                  <Box className='d-flex location dog-details'>
                    <Typography variant='span' className='detail-content'>
                      <Height />
                    </Typography>
                    <Typography variant='span' className='font-r-12 align-self'>
                      {firstDogHeight !== '' && firstDogHeight !== undefined
                        ? firstDogHeight
                        : '-'}
                    </Typography>
                  </Box>
                  {/* LOCATION */}
                  <Box className='d-flex location dog-details'>
                    <Typography variant='span' className='detail-content'>
                      {' '}
                      <Location />
                    </Typography>
                    <Typography variant='span' className='font-r-12 align-self'>
                      {firstDogLocationName !== '' &&
                      firstDogLocationName !== undefined
                        ? firstDogLocationName
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className='bone-icon-sec d-flex'>
            {' '}
            <Box className='star-icon'>
              <Avatar
                alt='Remy Sharp'
                variant='square'
                className='matches-star-icons'
                onClick={() =>
                  matches?.isLike == 1 || matches?.isLike == true
                    ? removeFav(matches?.fevouriteId)
                    : addToFav(matches?.id)
                }
              >
                {matches.isLike == 1 || matches.isLike == true ? (
                  <FavSelectedIcon
                    className='pointer star-bones'
                    height={22}
                    width={23}
                  />
                ) : (
                  <FavIcon className='pointer' height={22} width={23} />
                )}
              </Avatar>
            </Box>
            <Box className='drop-down-sec'>
              <Button
                id='demo-positioned-button'
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => {
                  handleClick(event);
                  setUnmatchId(matches);
                }}
                className='more-btn'
              >
                <MoreVertIcon />
              </Button>
              <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={open}
                className='report-box'
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => setisDialogOpenUnmatch(true)}>
                  UnMatch
                </MenuItem>
                <Divider className='divider' />
                <MenuItem onClick={() => setReport(!report)}>Report</MenuItem>
              </Menu>
            </Box>
          </Box>
          <Dialog
            open={isDialogOpenUnmatch}
            onClose={() => setisDialogOpenUnmatch(false)}
            className='delete-dialog-box'
          >
            <DialogContent>
              <Box className='delete-head-sec'>
                <Box className='favourites-delete-sec'>
                  <Typography className='dailog-head-text pb-1'>
                    {' '}
                    {localesData?.favourites_delete?.ARE_YOU_SURE}
                  </Typography>
                  <Typography className='font-r-13 t-center pb-1'>
                    {' '}
                    {localesData?.UNMATCH}
                  </Typography>
                </Box>
                <Box className='permission-btns'>
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid item xs={6} className='cancel-btn'>
                      <CustomButton
                        variant='text'
                        title={localesData?.favourites_delete?.CANCEL}
                        fontWeight='600'
                        type='submit'
                        classname='favourites-cancel-btn favourites-btn'
                        onClick={() => {
                          // setId('');
                          setisDialogOpenUnmatch(!isDialogOpenUnmatch);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomButton
                        variant='text'
                        title={localesData?.favourites_delete?.YES}
                        fontWeight='600'
                        type='submit'
                        classname='favourites-yes-btn favourites-btn'
                        onClick={() =>
                          handleunmatches(matches?.id, matches?.collectedStatus)
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog
            open={report}
            onClose={() => setReport(false)}
            className='delete-dialog-box'
          >
            <DialogContent>
              {' '}
              <Box className='d-flex justify-end close-icon-report'>
                <CloseIcon onClick={() => setReport(false)} />
              </Box>
              <Box className='report-card-head-sec report'>
                <Box className='d-flex justify-center'>
                  {/* <Avatar className='report-card-image' alt='Image'>
                    <UserImg />
                  </Avatar> */}
                  <img
                    src={matches?.images?.[0]?.url}
                    className='report-card-image'
                  />
                </Box>
                <Box className='t-center pb-10'>
                  <Typography className='card-person-name' variant='span'>
                    {matches?.firstname} {matches?.lastname}
                  </Typography>
                </Box>
                <Box className='input-field-sec'>
                  <FormControl
                    sx={{ m: 1, width: '25ch' }}
                    className='form-control-sec pb-8'
                    variant='filled'
                    fullWidth={true}
                    error={
                      isFormSubmitted &&
                      (title.trim() === '' || title.length > 50)
                    }
                  >
                    <FilledInput
                      className='form-input-field'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <FormHelperText className='helper-text'>
                      {localesData?.report_card?.TITLE}
                    </FormHelperText>
                    <FormHelperText
                      className='helper-text color-error font-r-10 d-flex align-center'
                      error={isFormSubmitted && title.trim() === ''}
                    >
                      {' '}
                      {isFormSubmitted && title.trim() === '' ? (
                        <>
                          {' '}
                          <Info className='mr-4' /> please enter title{' '}
                        </>
                      ) : (
                        ''
                      )}
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    sx={{ m: 1, width: '25ch' }}
                    className='form-control-sec'
                    variant='filled'
                    fullWidth={true}
                  >
                    <FilledInput
                      className='form-input-field'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />

                    <FormHelperText className='helper-text'>
                      {localesData?.report_card?.MESSAGE}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className='send-btn-sec d-flex justify-center'>
                  <CustomButton
                    variant='contained'
                    title={localesData?.report_card?.SEND}
                    fontWeight='600'
                    type='submit'
                    className='send-btn'
                    onClick={() => Reportuser()}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
          {matches?.dogs?.length === 0 ? (
            <>
              <Typography className='font-r-12 t-center m-4'>
                {localesData?.NO_DOG}
              </Typography>
            </>
          ) : (
            <>
              {/* SECOND PART OF BOX */}
              <Box
                className='bones-box-sec d-flex'
                style={{ padding: '19px 0px 10px 17px' }}
                sx={{
                  backgroundColor:
                    matches?.id === selectedBox ? '#F0F0F0' : '#FFFFFF',
                }}
                onClick={() => {
                  // handleBoxClick(matches?.id);
                  navigate(`/kennel/matches/${matches?.id}`);
                }}
                // onClick={() => handleBoxClick(matches?.id)}
              >
                {/* <img
                  src={matches?.dogs?.images?.[0]?.url}
                  className='dog-img'
                /> */}
                <div style={{ width: '60px' }}>
                  {matches?.dogs?.images?.[0] &&
                  matches?.dogs?.images?.[0]?.url?.endsWith('.mp4') ? (
                    <img
                      src={matches?.dogs?.images?.[1]?.url || ''}
                      className='dog-img'
                    />
                  ) : (
                    <img
                      src={matches?.dogs?.images?.[0]?.url}
                      className='dog-img'
                    />
                  )}
                </div>
                <Box className='bone-sec pb-3'>
                  <Box className='bones-grid-sec'>
                    <Box className='pb-2'>
                      <Typography
                        variant='span'
                        className=' font-r-12 dog-name d-flex align-center'
                      >
                        {matches?.dogs?.name !== '' &&
                        matches?.dogs?.name !== undefined
                          ? matches?.dogs?.name
                          : '-'}
                        {matches.dogs?.deceased ? (
                          <>
                            <span className='round'></span>
                            {matches.dogs?.deceased ? 'Deceased' : ''}
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                    <Box className='d-flex dog-details-sec'>
                      {/* CAKE */}
                      <Box className='d-flex dog-details'>
                        <Typography variant='span' className='detail-content '>
                          <Cake />
                        </Typography>
                        <Typography
                          variant='span'
                          className='font-r-12 align-self'
                        >
                          {secondDogBirthDate !== '' &&
                          secondDogBirthDate !== undefined
                            ? secondDogBirthDate
                            : '-'}
                        </Typography>
                      </Box>
                      {/* DOG */}
                      <Box className='d-flex location dog-details'>
                        <Typography variant='span' className='detail-content'>
                          <DogAvtar />
                        </Typography>
                        <Typography
                          variant='span'
                          className='font-r-12 align-self'
                        >
                          {' '}
                          {secondDogType !== '' && secondDogType !== undefined
                            ? secondDogType
                            : '-'}
                        </Typography>
                      </Box>
                      {/* LOCATION */}
                      <Box className='d-flex location dog-details'>
                        <Typography variant='span' className='detail-content'>
                          <GenderIcon />
                        </Typography>
                        <Typography
                          variant='span'
                          className='font-r-12 align-self'
                        >
                          {' '}
                          {secondDogGender !== '' &&
                          secondDogGender !== undefined
                            ? secondDogGender
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      ) : (
        ''
      )}
    </>
  );
};

export default DogCard;
