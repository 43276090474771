import { Box } from '@mui/material';
import React, { useContext } from 'react';
import '../Matching/matching.scss';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { IntlContext } from 'src/App';

const BackToTop = ({ scrollToTop }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className='pb-2'>
      <CustomButton
        onClick={scrollToTop}
        variant='contained'
        fontWeight='600'
        type='submit'
        background='#F54D71'
        classname='inherit'
        title={localesData?.matching?.BACK_TO_TOP}
      />
    </Box>
  );
};

export default BackToTop;
