import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  // RadioGroup,
  Typography,
  // Radio,
  Checkbox,
} from '@mui/material';
import { Formik } from 'formik';

import IOSSwitch from '../../../UI/IOSSwitch';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as Checked } from '../../../../../assets/images/profile-checked.svg';
import { ReactComponent as UnChecked } from '../../../../../assets/images/unchecked.svg';
import { ReactComponent as DogIcon } from '../../../../../assets/images/icons/dog.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import ProgressBar from '../../../UI/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const Kids = ({
  localesData,
  handleNextPage,
  questions,
  setLoader,
  dispatch,
  reduxProgress,
  redirectHomePage,
  progressWeightage,
  answars,
  isEdit,
  checkForBlankData,
}) => {
  const navigate = useNavigate();
  // const [kidsData, setKidsData] = useState([]);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [empData, setEmpData] = useState([]);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [youHaveKidsData, setYouHaveKidsData] = useState('');
  const [oldChildData, setOldChildData] = useState('');
  const [otherData, setOtherData] = useState({
    totalKids: [],
  });
  // useEffect(() => {
  //   questions
  //     ?.find((o) => o?.id === 2)
  //     ?.Question?.find((item) => {
  //       if (item?.q_order === 16) {
  //         setKidsData({
  //           title: item?.title,
  //           id: item?.id,
  //           options: item?.Option,
  //         });
  //       }
  //     });
  // }, [questions]);

  useEffect(() => {
    // const question = questions?.find((o) => o?.id === 2);
    // const itemWithOrder16 = question?.Question?.find(
    //   (item) => item?.q_order === 16
    // );

    // if (itemWithOrder16) {
    //   setKidsData({
    //     title: itemWithOrder16?.title,
    //     id: itemWithOrder16?.id,
    //     options: itemWithOrder16?.Option,
    //   });
    // }
    answars?.map((item, i) => {
      if (item.id === 19) {
        setEmpData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
        });
      }
      if (item.id === 20) {
        setYouHaveKidsData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: item?.Option?.[0]?.private === 1 ? true : false,
        });
      }
      if (item.id === 21) {
        setOldChildData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private: item?.Option?.[0]?.private === 1 ? true : false,
        });
        setOtherData({
          totalKids: item?.Option?.[0]?.answer
            ? JSON.parse(item.Option[0].answer)
            : null,
        });
      }
      return true;
    });
  }, [answars]);

  const handleCheckboxChange = (optionId) => {
    setEmpData((prevEmpData) => ({
      ...prevEmpData,
      options: prevEmpData.options.map(
        (option) =>
          option.id === optionId
            ? { ...option, selected: !option.selected } // Toggle the selected state of the clicked option
            : { ...option, selected: false } // Set other options to selected: false
      ),
    }));
  };

  const checkForBlankAnsData = async () => {
    try {
      setLoader(true);
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;
      if (status === 200) {
        data?.questions.map((item) => {
          if (breakIt === false) {
            if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          kidsPrivate:
            empData?.options?.[0]?.private === '1' ||
            empData?.options?.[0]?.private === 1
              ? true
              : false,
        }}
        enableReinitialize={true}
        onSubmit={async (requestData) => {
          // const opt = empData?.options?.map((obj) => ({
          //   answer: obj?.id === Number(requestData?.kids_list) ? '1' : '0',
          //   optionId: obj?.id,
          //   selected: obj?.id === Number(requestData?.kids_list) ? true : false,
          //   private: requestData?.kidsPrivate,
          // }));

          var opt = [];
          empData?.options?.map((obj) => {
            opt.push({
              optionId: obj?.id,
              answer: obj?.selected ? '1' : '0',
              selected: obj?.selected,
              private: requestData?.kidsPrivate,
            });
            return true;
          });
          opt.push({
            optionId: youHaveKidsData?.options[0]?.id,
            answer:
              empData?.options[1]?.selected ||
              empData?.options[2]?.selected ||
              empData?.options[3]?.selected
                ? '0'
                : youHaveKidsData?.options[0]?.answer === ''
                ? null
                : youHaveKidsData?.options[0]?.answer,
            selected: false,
            private: youHaveKidsData?.private
              ? youHaveKidsData?.private
              : false,
          });

          opt.push({
            optionId: oldChildData?.options[0]?.id,
            answer:
              otherData?.totalKids === ''
                ? null
                : empData?.options[1]?.selected ||
                  empData?.options[2]?.selected ||
                  empData?.options[3]?.selected
                ? null
                : JSON.stringify(otherData?.totalKids),
            selected: false,
            private: oldChildData?.private ? oldChildData?.private : false,
          });

          const sendData = {
            private: false,
            optionIds: opt || [],
          };

          try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
              URLS.SET_ANSWERS,
              sendData
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              if (empData?.options?.[0]?.selected) {
                dispatch(handleNext('otherKids'));
              } else {
                if (isEdit) {
                  // checkForBlankData();
                  checkForBlankAnsData();
                } else {
                  handleNextPage();
                }
              }
              dispatch(
                addProfileData({
                  key: 'kids_list',
                  value: opt,
                })
              );

              setLoader(false);
            }
          } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, handleBlur, values }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='t-center'>
              <Typography
                className='mb-2 font-r-12'
                dangerouslySetInnerHTML={{
                  __html:
                    localesData?.profile_completion_user?.PROFILE_COMPLETION,
                }}
              />
              <Box className='mb-3'>
                <Typography variant='h4' className='pb-1'>
                  {/* {reduxProgress}% */}
                  {progressWeightage}%
                </Typography>
                <ProgressBar processData={progressWeightage} />
              </Box>
              <Typography variant='h3' className='s-bold pb-2'>
                {localesData?.user_hobbies?.now_its_all_about_you}
              </Typography>
              <Typography variant='h4' className='pb-4'>
                {
                  localesData?.user_kids
                    ?.do_you_have_any_children_aside_from_your_furry_ones
                }
              </Typography>
              <Typography variant='h4' className='pb-1 s-bold'>
                {localesData?.user_kids?.select_as_many_as_you_ike}
              </Typography>
            </Box>

            <Box className='field-content '>
              <FormControl className='custom-radio pb-4'>
                <FormGroup>
                  {empData?.options?.map((item, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          size='small'
                          icon={<UnChecked />}
                          checkedIcon={<Checked />}
                          checked={item?.selected}
                          onChange={() => {
                            handleCheckboxChange(item?.id);
                          }}
                          disableRipple
                        />
                      }
                      labelPlacement='start'
                      label={
                        <Typography variant='h5' className='mb-2'>
                          {item?.title.replace(/,/g, '')}
                        </Typography>
                      }
                      onClick={() => {
                        if (!item?.selected && i === 0) {
                          handleSubmit();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
                {/* <RadioGroup
                  name='kids_list'
                  value={Number(values.kids_list)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {empData?.options?.map((item, i) => (
                    <FormControlLabel
                      key={i}
                      value={item?.id}
                      control={
                        <Radio
                          size='small'
                          icon={<UnChecked />}
                          checkedIcon={<Checked />}
                          disableRipple
                          checked={item?.selected}
                          onChange={() => {
                            handleCheckboxChange(item?.id);
                          }}
                        />
                      }
                      labelPlacement='start'
                      label={
                        <Typography variant='h5' className='mb-2'>
                          {item?.title}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup> */}
              </FormControl>
            </Box>
            <Box
              sx={{ justifyContent: 'space-between' }}
              className='d-flex pb-10 '
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='kidsPrivate'
                  checked={values?.kidsPrivate}
                  // onChange={(e) => {
                  //   profileData?.isPaid === false
                  //     ? setToogleDialog(true)
                  //     : handleChange(e);
                  // }}
                  onChange={(e) => {
                    if (isEdit) {
                      profileData?.isPaid === false
                        ? setToogleDialog(true)
                        : handleChange(e);
                    } else {
                      handleChange(e);
                    }
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {values?.kidsPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>
            <Box>
              <CustomButton
                variant='contained'
                title={localesData?.userwork?.keep_going}
                background='#6A42ED'
                fontWeight='400'
                type='submit'
                // onClick={() => handleOnSubmit()}
              />

              <Box className='pb-8 mb-4 d-flex justify-center'>
                <DogIcon />
                <Typography
                  variant='h5'
                  className='pointer'
                  // onClick={() => navigate('/')}
                  onClick={() => redirectHomePage()}
                >
                  {localesData?.userwork?.FINISH}
                </Typography>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default Kids;
