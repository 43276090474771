import React from 'react';
import { Dialog, Grow, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/CancelSharp';
import './subscriptionPoppup.scss';

const SubDialogbox = ({
  open,
  handleClose,
  title,
  content,
  contentClass,
  closeIconShow,
  onCloseStatus,
}) => {
  return (
    <>
      <Dialog
        className='dialog-sub-box'
        open={open}
        onClose={onCloseStatus ? handleClose : null}
        TransitionComponent={Grow}
        scroll={'body'}
      >
        {!closeIconShow && (
          <CloseIcon className='close pointer' onClick={handleClose} />
        )}
        {/* <CloseIcon className='close pointer' onClick={handleClose} /> */}
        <DialogTitle className='dialog_title'>{title}</DialogTitle>
        <DialogContent
          className={
            contentClass ? `sub-content ${contentClass}` : 'sub-content'
          }
        >
          {content}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubDialogbox;
