import { Box, Typography, FormHelperText } from '@mui/material';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addProfileData } from '../../../../helpers/store/actions/profileSetup';
import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';
import { ReactComponent as VideoImg } from '../../../../assets/images/video-img.svg';
import { fetchFromStorage } from '../../../../helpers/context';
import { identifiers } from '../../../../helpers/constants/identifier';
import { useSelector } from 'react-redux';
import './profileCompletion.scss';

const ProfileCompletionDogName = ({
  localesData,
  handleNext,
  dispatch,
  questions,
}) => {
  const reduxProfileData = useSelector((state) => state?.profileData);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);

  const whatsDogName = questions?.[1]?.Question?.find((o) => o?.q_order === 1);

  return (
    <Box className='t-center'>
      <Formik
        initialValues={{
          dog_name: reduxProfileData?.dog_name
            ? reduxProfileData?.dog_name
            : '',
        }}
        validationSchema={Yup.object().shape({
          dog_name: Yup.string().required(localesData?.validation?.REQUIRED),
        })}
        onSubmit={(requestData) => {
          handleNext();
          dispatch(
            addProfileData({ key: 'dog_name', value: requestData.dog_name })
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} className='form-content pb-6'>
            <Box className='mb-8'>
              <Typography
                variant='h6'
                className='pb-2'
                dangerouslySetInnerHTML={{
                  __html: localesData?.profile_completion_dog?.PROFILE_DESC_SEC,
                }}
              />
              <Typography variant='h3'>
                {localesData?.profile_completion_dog?.DESC1}{' '}
                {profileData?.firstname}
                {/* {profileData?.lastname} */}.
              </Typography>
              <Typography
                variant='h3'
                dangerouslySetInnerHTML={{
                  __html: localesData?.profile_completion_dog?.DESC2,
                }}
              />
            </Box>
            <Box className='field-content mb-8'>
              <Typography variant='h3' className='s-bold mb-3'>
                {whatsDogName?.title}
              </Typography>
              <CustomTextField
                fullWidth
                error={Boolean(touched.dog_name && errors.dog_name)}
                helperText={touched.dog_name && errors.dog_name}
                variant='standard'
                className='custom-textfield pb-2'
                name='dog_name'
                value={values.dog_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormHelperText className='custom-field-helper'>
                {localesData?.profile_completion_dog?.DOG_NAME_HELPER}
              </FormHelperText>
            </Box>
            <Box className='mb-6'>
              <CustomButton
                variant='contained'
                title={localesData?.NEXT}
                background='#54B5CD'
                fontWeight='600'
                type='submit'
              />
            </Box>
            <VideoImg />
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProfileCompletionDogName;
