import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import PlaceDetails from "./FavPlaceDetails";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useLocation, useNavigate } from "react-router-dom";
import BottomBar from "src/views/component/UI/MenuListItem";
import { Config } from "src/helpers/context";
import "./FavPlaces.scss";

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};
const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultOptions={defaultMapOptions}
      defaultCenter={{ lat: -33.8392792, lng: 151.2055562 }}
    >
      <Marker
        position={{
          lat: props?.showPlaceInfo?.lat,
          lng: props?.showPlaceInfo?.long,
        }}
        onClick={() => {
          props.onMarkerClick();
        }}
      />
    </GoogleMap>
  ))
);
const FavPlaces = () => {
  const navigate = useNavigate();
  const [showPlaceInfo, setShowPlaceInfo] = useState();
  const ref = useRef(null);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [IsFirst, setIsFirst] = useState(false);

  const callStatusApi = async (place_detail) => {
    try {
      const { status, data } = await axiosInstance.get(
        URLS.GET_FAVOURITE_STATUS + place_detail?.placeId
      );
      if (status === 200) {
        setShowPlaceInfo({
          ...place_detail,
          loading: false,
          isLike: data?.data?.isLike === 0 ? false : true,
          fav_id: data?.data?.fevouriteId,
          isDirectoryExist: data?.data?.isDirectoryExist === 1 ? true : false,
        });
      }
    } catch (error) {
      // setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };
  const handleMarkerClick = () => {
    open ? setOpen(false) : setOpen(true);
  };
  const handleClickAway = () => {
    if (!IsFirst) {
      setIsFirst(true);
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (location?.state && location?.state?.placeDetails) {
      // setShowPlaceInfo(location?.state?.placeDetails);
      callStatusApi(location?.state?.placeDetails);
      setOpen(true);
    } else {
      navigate(-1);
    }
  }, [location?.state]);

  return (
    <>
      <Box className="directory-map-sec">
        <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config?.placeAPIKey}&libraries=geometry,drawing,places`} //&sensor=false&callback=myMap
          loadingElement={<div style={{ height: `60vh` }} />}
          containerElement={<div style={{ height: `60vh` }} />}
          mapElement={<div style={{ height: `60vh` }} />}
          onMarkerClick={handleMarkerClick}
          showPlaceInfo={showPlaceInfo}
        />
        {open && (
          <ClickAwayListener
            onClickAway={() => {
              handleClickAway();
            }}
            mouseEvent="onMouseDown"
          >
            <Box ref={ref}>
              {showPlaceInfo && (
                <PlaceDetails
                  callStatusApi={callStatusApi}
                  showPlaceInfo={showPlaceInfo}
                />
              )}
            </Box>
          </ClickAwayListener>
        )}
        <BottomBar />
      </Box>
    </>
  );
};

export default FavPlaces;
