import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import IOSSwitch from '../../../UI/IOSSwitch';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import { useNavigate } from 'react-router-dom';
import UpgradePlan from '../UpgradePlan';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const YourFamilyPlan = ({
  handleNext,
  questions,
  setLoader,
  dispatch,
  answars,
}) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [familyData, setFamilyData] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'isPrivate') {
      setIsPrivate(checked);
    }
  };
  useEffect(() => {
    answars?.map((item) => {
      if (item.id === 26) {
        setFamilyData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: false,
        });
        setSelectedChips(item?.Option?.filter((item) => item?.selected === 1));
        setIsPrivate(
          item?.Option?.[0]?.private === 1 || item?.Option?.[0]?.private === '1'
            ? true
            : false
        );
      }
      return true;
    });
  }, [answars]);

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip]);
    } else {
      setSelectedChips((chips) => chips?.filter((obj) => obj?.id !== chip.id));
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    familyData?.options?.map((item) => {
      opt.push({
        optionId: item.id,
        answer: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? '1'
          : '0',
        selected: selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
          ? true
          : false,
        private: isPrivate,
      });
      return true;
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'family_plans',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <Box className=''>
      {/* <> */}
      <Box className=' chip-container-box'>
        <UpgradePlan title={localesData?.profile?.YOUR_FAMILY_PLAN} />
        <Box className='pb-6'>
          <Divider className='divider' />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{ textAlign: 'left', alignItems: 'center' }}
          className='chip-container  pb-4'
        >
          {/* <Box
          sx={{ textAlign: 'left', alignItems: 'center' }}
          className='chip-container pb-4'
        > */}
          {familyData?.options?.map((item, i) => (
            <Chip
              className={`chip-inner font-r-11 ${
                selectedChips?.map((obj) => obj?.id)?.includes(item?.id)
                  ? 'chip-active'
                  : ''
              } `}
              key={i}
              label={item?.title}
              onClick={() => handleChipClick(item)}
            />
          ))}
        </Box>
        {/* </> */}
        <Box className='edit-your-profile-box'>
          <Box className='keep-going-sec'>
            {/* <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
          <FormGroup>
            <FormControlLabel
              control={
                <IOSSwitch
                  className='ios-switch'
                  onClick={() =>
                    setToogleDialog(
                      familyData.private ? toogleDialog : !toogleDialog
                    )
                  }
                />
              }
              name='hobbiesPrivate'
              onChange={(e) => handleChange(e)}
            />
          </FormGroup>
          <Typography variant='h5'>
            {localesData?.userwork?.thats_private_dont_show_this}
          </Typography>
          {familyData?.private ? <LockIcon /> : <UnLockIcon />}
        </Box> */}
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch className='ios-switch' />}
                  name='isPrivate'
                  checked={isPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {isPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>

            <Box className='done-btn-sec'>
              <CustomButton
                variant='contained'
                title={localesData?.DONE}
                background={'#59D09D'}
                fontWeight='600'
                classname='done-btn s-bold'
                onClick={() => handleOnSubmit()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default YourFamilyPlan;
