import React, { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../../../../App';
import { Box, Typography } from '@mui/material';
import { ReactComponent as RightArrowIcon } from '../../../../../assets/images/icons/arrow-right-icon.svg';
import CustomButton from '../../../UI/Button/CustomButton';
import ProgressBar from '../../../UI/ProgressBar';
import UpgradePlan from '../UpgradePlan';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import BottomFooter from 'src/views/component/UI/BottomFooter';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
// import PremiumPopup from 'src/views/component/UI/PremiumPopup';
// import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from 'src/views/component/UI/Subscriptionpopup/SubscriptionDialog';
import { useDispatch } from 'react-redux';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { clearSvgData } from 'src/helpers/store/actions/progressImage';
import './editProfile.scss';

const EditProfile = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const location = useLocation();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [question, setQuestion] = useState([]);
  const [loader, setLoader] = useState(false);
  // const reduxProgress = useSelector((state) => state?.progressBarData);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [wEditProfile, setWEditProfile] = useState(0);
  const [wWhatAreYouLookingFor, setWWhatAreYouLookingFor] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.state) {
      navigate('/profile');
    }
  }, [navigate, location?.state]);

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        if (location?.state === 'looking-for') {
          const lookingForQuestion = data?.types
            ?.find((item) => item?.id === 4)
            ?.Question?.filter((obj) => obj?.id !== 47) // Filter out question with id 47
            .map((obj) => ({
              ...obj,
              isPaid: [53, 54, 55].includes(obj?.id) || profileData?.isPaid,
            }));

          setQuestion(lookingForQuestion);
        }
        if (location?.state === 'profile-edit') {
          const profileEditQuestion = data?.types
            ?.find((item) => item?.id === 3)
            ?.Question?.filter((obj) => obj?.id !== 35 && obj?.id !== 47) // Filter out question with id 47
            .map((obj) => ({
              ...obj,
              title:
                obj?.title === 'Who Do You Want To See You?'
                  ? 'What are you looking for?'
                  : obj?.title,
              isPaid: true,
            }));
          setQuestion(profileEditQuestion);
        }

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllAnswers();
    // eslint-disable-next-line
  }, []);

  // const isDisabled = location?.state === 'looking-for' ? true : false;

  const checkForBlankDataOnLoad = async () => {
    var edit_profile_black_data = 0;
    var what_are_you_looking_for_black_data = 0;
    var job = true;
    var education = true;
    var height_weight = true;
    var vices = true;

    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      if (status === 200) {
        data?.questions?.map((item) => {
          // if (item.id === 8) {
          //   //looking for
          //   edit_profile_black_data++;
          // }
          if (
            item.id === 9 ||
            item.id === 10 ||
            item.id === 11 ||
            item.id === 12
          ) {
            if (job) {
              //job
              edit_profile_black_data++;
              job = false;
            }
          }
          if (item.id === 13 || item.id === 14 || item.id === 15) {
            if (education) {
              //education
              education = false;
              edit_profile_black_data++;
            }
          }
          if (item.id === 16) {
            //hobbies
            edit_profile_black_data++;
          }
          if (item.id === 17) {
            //religion
            edit_profile_black_data++;
          }
          if (item.id === 18) {
            //politics
            edit_profile_black_data++;
          }
          if (item.id === 19) {
            //children
            edit_profile_black_data++;
          }
          if (item.id === 20) {
            //how many kids
            edit_profile_black_data++;
          }
          if (item.id === 23 || item.id === 24) {
            if (height_weight) {
              //height weight
              edit_profile_black_data++;
              height_weight = false;
            }
          }
          if (item.id === 25) {
            //ethnicity
            edit_profile_black_data++;
          }
          if (item.id === 26) {
            //family plan
            edit_profile_black_data++;
          }
          if (
            item.id === 28 ||
            item.id === 29 ||
            item.id === 30 ||
            item.id === 31
          ) {
            if (vices) {
              //vices
              edit_profile_black_data++;
              vices = false;
            }
          }
          if (item.id === 33) {
            //location
            edit_profile_black_data++;
          }
          if (item.id === 2) {
            what_are_you_looking_for_black_data++;
            //about gender
          }
          if (item.id === 54) {
            what_are_you_looking_for_black_data++;
            //about age
          }
          if (item.id === 55) {
            what_are_you_looking_for_black_data++;
            //about dog breed
          }
          if (item.id === 56) {
            what_are_you_looking_for_black_data++;
            //about job
          }
          if (item.id === 57) {
            what_are_you_looking_for_black_data++;
            //about education
          }
          if (item.id === 58) {
            what_are_you_looking_for_black_data++;
            //about hobbies
          }
          if (item.id === 59) {
            what_are_you_looking_for_black_data++;
            //about religion
          }
          if (item.id === 60) {
            what_are_you_looking_for_black_data++;
            //about politics
          }
          if (item.id === 61) {
            what_are_you_looking_for_black_data++;
            //about height weight
          }
          if (item.id === 62) {
            what_are_you_looking_for_black_data++;
            //about ethnicity
          }
          if (item.id === 63) {
            what_are_you_looking_for_black_data++;
            //about vices
          }
          if (item.id === 64) {
            what_are_you_looking_for_black_data++;
            //about location
          }
          return true;
        });
        setTimeout(() => {
          setWNow(edit_profile_black_data, what_are_you_looking_for_black_data);
          setLoader(false);
        }, 100);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const setWNow = async (
    edit_profile_blank_data,
    what_are_you_looking_for_black_data
  ) => {
    var _edit_profile_blank_data = (13 - edit_profile_blank_data) * 7.7;
    var _what_are_you_looking_for_black_data =
      (12 - what_are_you_looking_for_black_data) * 8.35;

    setWEditProfile(Math.floor(_edit_profile_blank_data));
    setWWhatAreYouLookingFor(Math.floor(_what_are_you_looking_for_black_data));
  };

  const checkForBlankData = async () => {
    try {
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;

      if (status === 200) {
        dispatch(clearSvgData());
        data?.questions.map((item) => {
          if (location?.state === 'looking-for') {
            if (breakIt === false) {
              if (item.id === 2) {
                breakIt = true; //about gender
                navigate('/profile-looking-for', {
                  state: '1',
                });
              } else if (item.id === 54) {
                breakIt = true; //about age
                navigate('/profile-looking-for', {
                  state: '2',
                });
              } else if (profileData?.isPaid) {
                if (item.id === 55) {
                  breakIt = true; //about dog breed
                  navigate('/profile-looking-for', {
                    state: '3',
                  });
                } else if (item.id === 56) {
                  breakIt = true; //about job
                  navigate('/profile-looking-for', {
                    state: '4',
                  });
                } else if (item.id === 57) {
                  breakIt = true; //about education
                  navigate('/profile-looking-for', {
                    state: '5',
                  });
                } else if (item.id === 58) {
                  breakIt = true; //about hobbies
                  navigate('/profile-looking-for', {
                    state: '6',
                  });
                } else if (item.id === 59) {
                  breakIt = true; //about religion
                  navigate('/profile-looking-for', {
                    state: '7',
                  });
                } else if (item.id === 60) {
                  breakIt = true; //about politics
                  navigate('/profile-looking-for', {
                    state: '8',
                  });
                } else if (item.id === 61) {
                  breakIt = true; //about height weight
                  navigate('/profile-looking-for', {
                    state: '9',
                  });
                } else if (item.id === 62) {
                  breakIt = true; //about ethnicity
                  navigate('/profile-looking-for', {
                    state: '10',
                  });
                } else if (item.id === 63) {
                  breakIt = true; //about vices
                  navigate('/profile-looking-for', {
                    state: '11',
                  });
                } else if (item.id === 64) {
                  breakIt = true; //about location
                  navigate('/profile-looking-for', {
                    state: '12',
                  });
                }
              } else {
                setToogleDialog(!toogleDialog);
              }
            }
          } else {
            if (breakIt === false) {
              // if (item.id === 8) {
              //   breakIt = true; //looking for
              //   // navigate('/profile-completion');
              //   // dispatch(handleNext(6));
              //   navigate('/edit-description');
              // } else
              if (
                item.id === 9 ||
                item.id === 10 ||
                item.id === 11 ||
                item.id === 12
              ) {
                breakIt = true; //job
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(1));
              } else if (item.id === 13 || item.id === 14 || item.id === 15) {
                breakIt = true; //education
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(2));
              } else if (item.id === 16) {
                breakIt = true; //hobbies
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(3));
              } else if (item.id === 17) {
                breakIt = true; //religion
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(4));
              } else if (item.id === 18) {
                breakIt = true; //politics
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(5));
              } else if (item.id === 19) {
                breakIt = true; ///children
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(6));
              } else if (item.id === 20) {
                breakIt = true; //how many kids
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext('otherKids'));
              } else if (item.id === 23 || item.id === 24) {
                breakIt = true; //height weight
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(7));
              } else if (item.id === 25) {
                breakIt = true; //ethnicity
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(8));
              } else if (item.id === 26) {
                breakIt = true; //family plan
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(9));
              } else if (
                item.id === 28 ||
                item.id === 29 ||
                item.id === 30 ||
                item.id === 31
              ) {
                breakIt = true; //vices
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(10));
              } else if (item.id === 33) {
                breakIt = true; //location
                navigate('/profile-completion-advance', { state: 'isEdit' });
                dispatch(handleNext(11));
              }
            }
          }
          return true;
        });
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    checkForBlankDataOnLoad();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className='main-page-content-wrap'>
      {loader && <PreLoader />}
      <UpgradePlan
        title={
          location?.state === 'looking-for'
            ? localesData?.profile?.WHAT_ARE_YOU_LOOKING_FOR
            : localesData?.edit_profile?.edit_your_profile
        }
      />

      <Box className='edit-profile-card'>
        <Box className='t-center'>
          <Box
            className={`mb-4 b-top mt-2 ${
              (location?.state === 'looking-for' &&
                wWhatAreYouLookingFor === 100) ||
              (location?.state === 'profile-edit' && wEditProfile === 100)
                ? ''
                : 'b-bottom'
            }`}
          >
            <Typography variant='h4' className='pb-3 mt-2'>
              {localesData?.userwork?.profile_completion}{' '}
              <span className='color-pink s-bold'>
                {location?.state === 'looking-for'
                  ? wWhatAreYouLookingFor
                  : wEditProfile}
                %{/* {reduxProgress}% */}
              </span>
            </Typography>
            <ProgressBar
              processData={
                location?.state === 'looking-for'
                  ? wWhatAreYouLookingFor
                  : wEditProfile
              }
            />
            <Box className='continue-btn-sec'>
              {(location?.state === 'looking-for' &&
                wWhatAreYouLookingFor === 100) ||
              (location?.state === 'profile-edit' &&
                wEditProfile === 100) ? null : (
                <CustomButton
                  variant='contained'
                  title={localesData?.CONTINUE}
                  // onClick={handleNext}
                  onClick={() => checkForBlankData()}
                  background='#F54D71'
                  fontWeight='600'
                  classname='mt-2 continue-btn s-bold capitalize pointer'
                />
              )}
            </Box>
          </Box>

          {question?.map((item) => (
            <Box
              key={item.id}
              className={`d-flex space-between align-center pb-4 pointer ${
                item?.isPaid ? '' : 'disable-color'
              }`}
              onClick={() =>
                item?.isPaid
                  ? location?.state === 'looking-for'
                    ? navigate('/profile-looking-for', {
                        state: item?.q_order?.toString(),
                      })
                    : navigate('/edit-your-profile', {
                        state: item?.q_order?.toString(),
                      })
                  : setToogleDialog(!toogleDialog)
              }
            >
              <Typography variant='h5'>{item.title}</Typography>
              <RightArrowIcon className='pointer' />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className='mt-2 pb-4'>
        <BottomFooter />
      </Box>
      <Box className='looking-for'>
        <SubDialogbox
          open={toogleDialog}
          handleClose={() => setToogleDialog(!toogleDialog)}
          title=''
          content={
            <SubscriptionPopup
              handleClose={() => setToogleDialog(!toogleDialog)}
              navigate={navigate}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default EditProfile;
