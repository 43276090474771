import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { CustomTextField } from '../../../UI/textfield';
import { ReactComponent as PlusIcon } from 'src/assets/images/icons/plus.svg';
import { ReactComponent as CheckIcon } from 'src/assets/images/icons/check.svg';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import IOSSwitch from 'src/views/component/UI/IOSSwitch';
import { ReactComponent as LockIcon } from 'src/assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from 'src/assets/images/icons/unlock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestionAnswer } from 'src/helpers/store/actions/questionAnswerSlice';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const AboutManuallyDogBreed = ({
  localesData,
  answers,
  setLoader,
  navigate,
}) => {
  const disatch = useDispatch();
  const reduxQuesAns = useSelector((state) => state?.questionAnswer);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [manuallyData, setManuallyData] = useState(['']);
  const [numFields, setNumFields] = useState(1);
  const [manuallyId, setManuallyId] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    if (answers?.length !== 0) {
      setManuallyId(
        answers?.find((o) => o?.id === 75)?.Option?.map((item) => item)?.[0]?.id
      );
      const reduxDogData = reduxQuesAns?.manullDog
        ?.filter((o) => o !== '')
        ?.map((obj) => ({
          title: obj?.title,
          selected: obj?.selected,
        }));

      const manuallyBreed = reduxDogData
        ? reduxDogData?.map((obj) => obj?.title) || []
        : JSON.parse(
            answers?.find((o) => o?.id === 75)?.Option?.[0]?.answer
          )?.map((obj) => obj?.title) || [];

      const manuallyWithBlank = [...manuallyBreed, ''];

      setIsPrivate(
        answers?.find((o) => o?.id === 75)?.Option?.[0]?.selected === 0
          ? false
          : true
      );
      setManuallyData(manuallyWithBlank);
      setNumFields(manuallyWithBlank?.length);
    }
  }, [answers, reduxQuesAns?.manullDog]);

  const handleBlur = (index, value) => {
    if (numFields === index + 1 && value !== '') {
      setManuallyData([...manuallyData, '']);
      setNumFields(numFields + 1);
    } else {
      setErrors(localesData?.validation?.ITS_CANT_BE_BLANK);
    }
  };

  const handleHobbyChange = (index, value) => {
    const newDog = [...manuallyData];
    newDog[index] = value;
    setManuallyData(newDog);
    setErrors('');
  };

  const handleOnSubmit = async () => {
    var opt = [];
    var test = [];
    manuallyData?.map((item) => {
      if (item !== '') {
        test.push({ title: item, selected: true });
      }
      return true;
    });
    opt.push({
      optionId: manuallyId,
      selected: false,
      answer: JSON.stringify(test),
      private: isPrivate,
      lookingFor: true,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        // navigate('/edit-profile', {
        //   state: 'looking-for',
        // });
        navigate('/profile-looking-for', { state: '3' });
        disatch(addQuestionAnswer({ key: 'manullDog', value: test }));
        disatch(
          addQuestionAnswer({ key: 'manullDogPrivate', value: isPrivate })
        );
        disatch(addQuestionAnswer({ key: 'manuallyDogId', value: manuallyId }));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { checked } = e.target;
    setIsPrivate(checked);
  };

  return (
    <>
      <Box className='manuall-containter mt-2 mb-10'>
        <Typography variant='h6' className='s-font-20 color-pink mb-6'>
          {localesData?.profile?.DOG_BREED}
        </Typography>

        {manuallyData?.map((hobby, index) => (
          <CustomTextField
            key={index}
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name={`hobby_${index}`}
            InputProps={{
              endAdornment:
                index === numFields - 1 ? (
                  <PlusIcon
                    className='pointer'
                    onClick={() => handleBlur(index, manuallyData[index])}
                  />
                ) : (
                  <CheckIcon />
                ),
            }}
            value={hobby}
            onChange={(event) => handleHobbyChange(index, event.target.value)}
            // onBlur={(event) => handleBlur(index, event.target.value)}
          />
        ))}
        {errors && (
          <FormHelperText className='color-error'>{errors}</FormHelperText>
        )}
      </Box>
      <Box sx={{ justifyContent: 'space-around' }} className='d-flex pb-4'>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch className='ios-switch' />}
            name='isPrivate'
            checked={isPrivate}
            onChange={(e) => {
              profileData?.isPaid === false
                ? setToogleDialog(true)
                : handleChange(e);
            }}
          />
        </FormGroup>
        <Typography variant='h5'>
          {localesData?.userwork?.thats_private_dont_show_this}
        </Typography>
        {isPrivate ? <LockIcon /> : <UnLockIcon />}
      </Box>
      <Box className=''>
        <Box className='t-center m-auto' sx={{ maxWidth: '134px' }}>
          <CustomButton
            classname='uppercase'
            variant='contained'
            title={localesData?.DONE}
            background='#59D09D'
            fontWeight='600'
            // onClick={() => navigate('/profile-looking-for', { state: '3' })}
            onClick={handleOnSubmit}
          />
        </Box>
      </Box>
      <DialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <PremiumPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </>
  );
};

export default AboutManuallyDogBreed;
