import React, { useState } from 'react';
import { Box, Dialog, DialogContent, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/CancelSharp';
import ReactPlayer from 'react-player';

const AudioMessage = ({ audiourl, message, closeAudio }) => {
  const [isDialogPopupOpen, setIsDialogPopupOpen] = useState(true);

  return (
    <>
      <Dialog
        open={isDialogPopupOpen}
        className='audio-msg-dialog dialog_box'
        onClose={() => {
          setIsDialogPopupOpen(false);
          closeAudio();
        }}
      >
        <CloseIcon
          className={`close pointer`}
          onClick={() => {
            setIsDialogPopupOpen(false);
            closeAudio();
          }}
        />
        <DialogContent>
          <Box className='d-flex align-center pb-2 pt-2'>
            {/* <Typography className='pb-2'>{'krushli'}</Typography> */}
            <Box className=''>
              {/* <BackArrow className='pointer' onClick={() => handleBack()} /> */}
              <Avatar
                src={message && message?.user?.avatar}
                style={{ margin: '0 10px', height: '40px', width: '40px' }}
              />
              {/* <Box>
                <Typography
                  variant='h5'
                  className='font-r-12'
                  style={{ fontWeight: '600', lineHeight: '12px' }}
                >
                  {userData && userData?.firstname + ' ' + userData?.lastname}
                </Typography>
                {userData &&
                userData?.dogs &&
                userData?.dogs?.length > 0 &&
                userData?.dogs[0] &&
                userData?.dogs[0]?.name ? (
                  <Typography
                    variant='h5'
                    className='font-r-12'
                    style={{ fontWeight: '600' }}
                  >
                    {'& ' + userData?.dogs?.[0]?.name}
                  </Typography>
                ) : (
                  <></>
                )}
              </Box> */}
            </Box>
            <ReactPlayer
              className='react-player-audio'
              url={audiourl}
              playing={false}
              // width='100%'
              // height='100%'
              controls={true}
              config={{
                file: { attributes: { controlsList: 'nodownload' } },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AudioMessage;
