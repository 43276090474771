import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IntlContext } from 'src/App';
import CustomButton from 'src/views/component/UI/Button/CustomButton';
// import dogImage from 'src/assets/images/dogprofile.png';
import dogImage from 'src/assets/images/logo-black.png';
import BottomFooter from 'src/views/component/UI/BottomFooter';
import UpgradePlan from '../UpgradePlan';
import EditDogProfile from './EditDogProfile';
// import DogProfileDetails from './DogProfileDetails';
// import './dogProfile.scss';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';

const DogProfile = () => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const isPaid = profileData?.isPaid;
  const [loader, setLoader] = useState(false);
  const [dogDetails, setDogDetails] = useState('');
  const getDogDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.DOG);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setDogDetails(data?.dogs);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getDogDetails();
  }, []);

  return (
    <Box>
      {loader && <PreLoader />}
      <UpgradePlan title={localesData?.dog_profile?.YOUR_DOG} />
      {/* <Box className='b-bottom mt-4 mb-4' /> */}
      <Box className='edit-upgrade-button'>
        {dogDetails && dogDetails?.length === 0 ? (
          <>
            <Box
              className={`d-flex justify-center pb-10 mt-14`}
              style={{ opacity: '0.5' }}
            >
              <img alt='not found' src={dogImage} />
            </Box>
            <Box className={`t-center m-auto pb-16`} width='230px'>
              <Typography
                variant='h3'
                dangerouslySetInnerHTML={{
                  __html: localesData?.dog_profile?.DONT_HAVE_FUR,
                }}
              />
            </Box>
          </>
        ) : (
          <EditDogProfile
            dogDetails={dogDetails}
            isPaid={isPaid}
            localesData={localesData}
          />
        )}
      </Box>
      {/* p-absolute w-100 bottom-0 */}
      <Box className={dogDetails?.length !== 0 ? 'mt-16   ' : ''}>
        {dogDetails && dogDetails?.length !== 3 && (
          <CustomButton
            variant='contained'
            title={
              dogDetails?.length === 0
                ? localesData?.dog_profile?.ADD_FUR_BTN
                : localesData?.edit_dog_profile?.FUR_BABY_BTN
            }
            background='#F54D71'
            fontWeight='600'
            onClick={() => navigate('/dog-profile/add')}
          />
        )}
        {!isPaid && <BottomFooter />}
      </Box>
    </Box>
  );
};

export default DogProfile;
