import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../UI/IOSSwitch';
import { useNavigate } from 'react-router-dom';
import { CustomSelect } from 'src/views/component/UI/select';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import { identifiers } from 'src/helpers/constants/identifier';
import UpgradePlan from '../UpgradePlan';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import { fetchFromStorage } from 'src/helpers/context';

const YourPhysicalAttributes = ({
  localesData,
  handleNext,
  questions,
  setLoader,
  dispatch,
  answars,
}) => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [youHaveKidsData, setYouHaveKidsData] = useState('');
  const [oldChildData, setOldChildData] = useState('');
  const [tall, setTall] = useState('');
  const [weightIsPrivate, setWeightIsPrivate] = useState(false);
  const [tallIsPrivate, setTallIsPrivate] = useState(false);
  const [weight, setWeight] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    answars?.map((item) => {
      if (item.id === 23) {
        setYouHaveKidsData({
          title: item.title,
          answars: item.Option[0]?.answer,
          id: item.id,
          options: item.Option,
          private: item?.private,
        });
        setTall(item.Option[0]?.answer);
        setTallIsPrivate(
          answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private === '1' ||
            answars?.find?.((o) => o?.id === 23)?.Option?.[0]?.private === 1
            ? true
            : false
        );
      }
      if (item.id === 24) {
        setOldChildData({
          title: item.title,
          answars: item.Option[0]?.answer,
          id: item.id,
          options: item.Option,
          private: item?.private,
        });
        setWeight(item.Option[0]?.answer);
        setWeightIsPrivate(
          answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private === '1' ||
            answars?.find?.((o) => o?.id === 24)?.Option?.[0]?.private === 1
            ? true
            : false
        );
      }
      return true;
    });
  }, [answars]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'youHaveKidsData') {
      setYouHaveKidsData({ ...youHaveKidsData, answer: value });
    }
    if (name === 'achiveDataSwitch') {
      setYouHaveKidsData({ ...youHaveKidsData, private: checked });
    }
    if (name === 'oldChildData') {
      setOldChildData({ ...oldChildData, answer: value });
    }
    if (name === 'oldChildDataSwitch') {
      setOldChildData({ ...oldChildData, private: checked });
    }
    if (name === 'tall') {
      setTall(value);
    }
    if (name === 'weight') {
      setWeight(value);
    }
    if (name === 'tallPrivate') {
      setYouHaveKidsData({ ...youHaveKidsData, private: checked });
      setTallIsPrivate(checked);
    }
    if (name === 'weightPrivate') {
      setOldChildData({ ...oldChildData, private: checked });
      setWeightIsPrivate(checked);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];

    opt.push({
      optionId: youHaveKidsData?.options?.[0]?.id,
      answer: tall === null || tall === '' ? null : tall?.toString(),
      selected: true,
      private: youHaveKidsData?.private ? youHaveKidsData?.private : false,
      startRange: tall?.toString().replace(/cm/g, ''),
      endRange: tall?.toString().replace(/cm/g, ''),
    });

    opt.push({
      optionId: oldChildData?.options?.[0]?.id,
      answer: weight === null || weight === '' ? null : weight?.toString(),
      selected: true,
      private: oldChildData?.private ? oldChildData?.private : false,
      startRange: weight.toString().replace(/kg/g, ''),
      endRange: weight.toString().replace(/kg/g, ''),
    });
    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  return (
    <>
      <UpgradePlan title={localesData?.profile?.YOUR_PHYSICAL_ATTRIBUTES} />
      <Box className='t-center'>
        <Box className='pb-6'>
          <Divider className='divider' />
        </Box>

        <Box sx={{ textAlign: 'left' }} className='pb-6'>
          <Box className='field-content'>
            {/* <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='youHaveKidsData'
            onChange={(e) => handleChange(e)}
          /> */}
            <Select
              fullWidth
              variant='standard'
              className='custom-select'
              name='tall'
              input={<CustomSelect />}
              IconComponent={DropdownIcon}
              value={tall}
              onChange={(e) => handleChange(e, 'tall')}
              displayEmpty
            >
              {identifiers?.talldata?.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item?.label}
                  className='select-options'
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography variant='h5' className='s-bold pb-2'>
              {youHaveKidsData?.title}
            </Typography>
            {/* <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      onClick={() =>
                        setToogleDialog(
                          youHaveKidsData.private ? toogleDialog : !toogleDialog
                        )
                      }
                    />
                  }
                  name='achiveDataSwitch'
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {youHaveKidsData?.private ? <LockIcon /> : <UnLockIcon />}
            </Box> */}
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  name='tallPrivate'
                  checked={tallIsPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {tallIsPrivate?.private ? <LockIcon /> : <UnLockIcon />}
            </Box>
          </Box>
          <Box className='field-content'>
            {/* <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='oldChildData'
            onChange={(e) => handleChange(e)}
          /> */}
            <Select
              fullWidth
              variant='standard'
              className='custom-select'
              name='weight'
              input={<CustomSelect />}
              IconComponent={DropdownIcon}
              value={weight}
              onChange={(e) => handleChange(e, 'weight')}
              displayEmpty
            >
              {identifiers?.weight?.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item?.label}
                  className='select-options'
                >
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography variant='h5' className='s-bold pb-2'>
              {oldChildData?.title}
            </Typography>
            {/* <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      onClick={() =>
                        setToogleDialog(
                          oldChildData.private ? toogleDialog : !toogleDialog
                        )
                      }
                    />
                  }
                  name='oldChildDataSwitch'
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {oldChildData?.private ? <LockIcon /> : <UnLockIcon />}
            </Box> */}
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  name='weightPrivate'
                  checked={weightIsPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {weightIsPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>
          </Box>
        </Box>
        <Box className='keep-going-sec done-btn-sec'>
          <CustomButton
            variant='contained'
            title={localesData?.DONE}
            background={'#59D09D'}
            fontWeight='600'
            classname='done-btn s-bold'
            onClick={handleOnSubmit}
          />
        </Box>
        <DialogBox
          open={toogleDialog}
          handleClose={() => setToogleDialog(!toogleDialog)}
          title=''
          content={
            <PremiumPopup
              handleClose={() => setToogleDialog(!toogleDialog)}
              navigate={navigate}
            />
          }
        />
      </Box>
    </>
  );
};

export default YourPhysicalAttributes;
