import React from 'react';
import { Box, Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { MSG_TYPE } from '../../../chat/Messages/components/MsgType';
import { identifiers } from 'src/helpers/constants/identifier';
import _ from 'lodash';

const EmojiTabpnel = ({
  iconTab,
  setActiveTab,
  activeTab,
  recentList,
  bonesList,
  dogsList,
  heartsList,
  homesList,
  handleSend,
}) => {
  const localRecentList = fetchFromStorage(identifiers?.RECENT_EMOJI);

  const handleSaveInStorage = (obj) => {
    let newList = [...(localRecentList || [])];
    newList?.push(obj);
    let uniqeList = _.uniqBy(newList, 'id');
    saveToStorage(identifiers?.RECENT_EMOJI, uniqeList);
  };

  return (
    <Box className='emoji-tab-sec d-flex '>
      <TabContext value={activeTab}>
        <Box sx={{ height: '300px', overflow: 'scroll' }}>
          <TabPanel value='1' style={{ padding: '9px' }}>
            <Box className='emoji-tab-sec d-flex '>
              {localRecentList?.map((obj, index) => {
                return (
                  <Box
                    className='emoji-icon-box d-flex justify-center pointer'
                    key={index}
                    onClick={() => {
                      handleSend(obj?.url, MSG_TYPE.EMOJI, null, '');
                    }}
                  >
                    <img src={obj?.url} alt='' className='emoji-icons' />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value='2' style={{ padding: '9px' }}>
            <Box className='emoji-tab-sec d-flex '>
              {bonesList?.map((obj, index) => {
                return (
                  <Box
                    className='emoji-icon-box d-flex justify-center pointer'
                    key={index}
                    onClick={() => {
                      handleSend(obj?.url, MSG_TYPE.EMOJI, null, '');
                      handleSaveInStorage(obj);
                    }}
                  >
                    <img src={obj?.url} alt='' className='emoji-icons' />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value='3' style={{ padding: '9px' }}>
            <Box className='emoji-tab-sec d-flex '>
              {dogsList?.map((obj, index) => {
                return (
                  <Box
                    className='emoji-icon-box d-flex justify-center pointer'
                    key={index}
                    onClick={() => {
                      handleSend(obj?.url, MSG_TYPE.EMOJI, null, '');
                      handleSaveInStorage(obj);
                    }}
                  >
                    <img src={obj?.url} alt='' className='emoji-icons' />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value='4' style={{ padding: '9px' }}>
            <Box className='emoji-tab-sec d-flex '>
              {heartsList?.map((obj, index) => {
                return (
                  <Box
                    className='emoji-icon-box d-flex justify-center pointer'
                    key={index}
                    onClick={() => {
                      handleSend(obj?.url, MSG_TYPE.EMOJI, null, '');
                      handleSaveInStorage(obj);
                    }}
                  >
                    <img src={obj?.url} alt='' className='emoji-icons' />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
          <TabPanel value='5' style={{ padding: '9px' }}>
            <Box className='emoji-tab-sec d-flex '>
              {homesList?.map((obj, index) => {
                return (
                  <Box
                    className='emoji-icon-box d-flex justify-center pointer'
                    key={index}
                    onClick={() => {
                      handleSend(obj?.url, MSG_TYPE.EMOJI, null, '');
                      handleSaveInStorage(obj);
                    }}
                  >
                    <img src={obj?.url} alt='' className='emoji-icons' />
                  </Box>
                );
              })}
            </Box>
          </TabPanel>
        </Box>
        <Box className='emoji-tabs d-flex justify-center'>
          {iconTab?.map((tab, index) => {
            return (
              <React.Fragment key={index}>
                <Tab
                  key={index}
                  value={String(tab?.id)}
                  className='emoji-tab'
                  iconPosition='start'
                  icon={tab?.icon}
                  onClick={() => {
                    setActiveTab(tab?.id);
                  }}
                />
              </React.Fragment>
            );
          })}
        </Box>
      </TabContext>
    </Box>
  );
};

export default EmojiTabpnel;
