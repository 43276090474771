import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { IntlContext } from '../../../../../App';
import CustomButton from '../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnLockIcon } from '../../../../../assets/images/icons/unlock.svg';
import { setApiMessage } from '../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../helpers/axios/axiosInstance';
import { addProfileData } from '../../../../../helpers/store/actions/profileSetup';
import IOSSwitch from '../../../UI/IOSSwitch';
import { CustomTextField } from '../../../UI/textfield';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import UpgradePlan from '../UpgradePlan';
import { useNavigate } from 'react-router-dom';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import dayjs from 'dayjs';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const UserEducation = ({ handleNext, setLoader, dispatch, answars }) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [empData, setEmpData] = useState('');
  const [achiveData, setAchiveData] = useState('');
  const [doYouFinish, setDoYouFinish] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [toogleDialog, setToogleDialog] = useState(false);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [isPrivate, setIsPrivate] = useState(false);
  const [whereswitch, setWhereswitch] = useState(false);
  const [whatswitch, setWhatswitch] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(dayjs(date));
  };

  useEffect(() => {
    answars?.forEach((item) => {
      if (item.id === 13) {
        const selectedOption = item.Option.find((option) => option.selected);
        setSelectedChips(selectedOption ? [selectedOption] : []);
        setEmpData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });

        setIsPrivate(
          item?.private === '1' || item?.private === 1 ? true : false
        );
      }
      if (item.id === 14) {
        setAchiveData({
          title: item.title,
          id: item.id,
          options: item.Option,
          answer: item.answer,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });
        setIsPrivate(
          item?.private === '1' || item?.private === 1 ? true : false
        );
      }
      if (item.id === 15) {
        setDoYouFinish({
          title: item.title,
          id: item.id,
          options: item.Option,
          answer: item?.Option?.[0].answer,
          private: item?.private === '1' || item?.private === 1 ? true : false,
        });
        setIsPrivate(
          item?.private === '1' || item?.private === 1 ? true : false
        );
        // Set the selected date for the date picker
        item?.Option?.[0].answer
          ? setSelectedDate(dayjs(item?.Option?.[0].answer))
          : setSelectedDate('');
        // setSelectedDate(
        //   new Date(item?.Option?.[0].answer ? item?.Option?.[0].answer : null)
        // );
      }
    });
  }, [answars]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'empData') {
      setEmpData({ ...empData, private: checked });
    }
    if (name === 'achiveData') {
      // setAchiveData({ ...achiveData, answer: value });
      setAchiveData({
        ...achiveData,
        options: [
          {
            ...achiveData.options[0],
            answer: value,
          },
          ...achiveData.options.slice(1),
        ],
      });
    }
    if (name === 'achiveDataSwitch') {
      setAchiveData({ ...achiveData, private: checked });
      setWhereswitch(checked);
    }
    if (name === 'doYouFinish') {
      setDoYouFinish({ ...doYouFinish, answer: value });
    }
    if (name === 'doYouFinishSwitch') {
      setDoYouFinish({ ...doYouFinish, private: checked });
      setWhatswitch(checked);
    }

    if (name === 'isPrivate') {
      setIsPrivate(checked);
    }
  };

  const handleOnSubmit = async () => {
    var opt = [];
    opt.push({
      optionId: empData?.options[0]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[0]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[0]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[1]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[1]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[1]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[2]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[2]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[2]?.id)
        ? true
        : false,
      private: empData?.private,
    });
    opt.push({
      optionId: empData?.options[3]?.id,
      answer: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[3]?.id)
        ? '1'
        : '0',
      selected: selectedChips
        ?.map((obj) => obj?.id)
        ?.includes(empData?.options[3]?.id)
        ? true
        : false,
      private: empData?.private,
    });

    opt.push({
      optionId: achiveData?.options?.[0]?.id,
      answer: achiveData?.options?.[0]?.answer
        ? achiveData?.options?.[0]?.answer
        : null,
      selected: false,
      private: achiveData?.private ? achiveData?.private : false,
    });
    opt.push({
      optionId: doYouFinish?.options?.[0]?.id,
      answer: selectedDate ? dayjs(selectedDate).format('YYYY') : '',
      selected: false,
      private: doYouFinish?.private ? doYouFinish?.private : false,
    });

    let sendData = {
      private: false,
      optionIds: opt,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        dispatch(
          addProfileData({
            key: 'employed',
            value: opt,
          })
        );
        handleNext();
        setLoader(false);
        navigate('/edit-profile', {
          state: 'profile-edit',
        });
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChipClick = (chip) => {
    if (!selectedChips?.includes(chip)) {
      setSelectedChips([chip]);
    }
  };

  return (
    <>
      <Box style={{ overflowY: 'scroll', height: 'inherit' }}>
        <UpgradePlan title={localesData?.profile?.YOUR_EDUCATION} />
        <Box className='t-center'>
          {/* style={{ overflow: 'auto', height: '377px' }} */}
          <Box className='t-center'>
            <Box className='pb-6'>
              <Divider className='divider' />
            </Box>
            <Typography variant='h3' className='s-bold pb-4'>
              {empData?.title}
            </Typography>
          </Box>
          <Box className='d-flex chip-block mb-5'>
            {empData?.options?.map((item, i) => {
              return (
                <Chip
                  className={`chip-inner font-r-11 ${
                    selectedChips?.[0] === item ? 'chip-active' : ''
                  } `}
                  key={i}
                  label={item?.title}
                  onClick={() => handleChipClick(item)}
                />
              );
            })}
          </Box>
          <Box
            sx={{ justifyContent: 'space-around' }}
            className='d-flex pb-4 profile-hobby'
          >
            <FormGroup>
              <FormControlLabel
                control={<IOSSwitch className='ios-switch' />}
                name='isPrivate'
                checked={isPrivate}
                onChange={(e) => {
                  profileData?.isPaid === false
                    ? setToogleDialog(true)
                    : handleChange(e);
                }}
              />
            </FormGroup>
            <Typography variant='h5'>
              {localesData?.userwork?.thats_private_dont_show_this}
            </Typography>
            {empData?.private ? <LockIcon /> : <UnLockIcon />}
          </Box>

          <Box sx={{ textAlign: 'left' }} className='pb-6'>
            <Box className='field-content'>
              <CustomTextField
                fullWidth
                variant='standard'
                className='custom-textfield pb-2'
                value={achiveData?.options?.[0]?.answer}
                name='achiveData'
                onChange={(e) => handleChange(e)}
              />
            </Box>
            <Box>
              <Typography variant='h5' className='s-bold pb-2'>
                {achiveData?.title}
              </Typography>
              <Box
                sx={{ justifyContent: 'space-around' }}
                className='d-flex pb-4 profile-hobby'
              >
                <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch className='ios-switch' />}
                    name='achiveDataSwitch'
                    checked={whereswitch}
                    onChange={(e) => {
                      profileData?.isPaid === false
                        ? setToogleDialog(true)
                        : handleChange(e);
                    }}
                  />
                </FormGroup>
                <Typography variant='h5'>
                  {localesData?.userwork?.thats_private_dont_show_this}
                </Typography>
                {achiveData?.private ? <LockIcon /> : <UnLockIcon />}
              </Box>
            </Box>
            {/* <Box className='field-content custom-date-picker'> */}
            {/* <CustomTextField
            fullWidth
            variant='standard'
            className='custom-textfield pb-2'
            name='doYouFinish'
            onChange={(e) => handleChange(e)}
          /> */}
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                views={['year']}
                value={selectedDate}
                className='custom-textfield pb-2'
                onChange={handleDateChange}
                renderInput={(params) => (
                  <CustomTextField variant='standard' fullWidth {...params} />
                )}
                disableFuture
              />
            </LocalizationProvider>
          </Box> */}

            <Box className='custom-date-picker date-picker'>
              {/* <DatePicker
                selected={selectedDate}
                dateFormat='yyyy'
                onChange={handleDateChange}
                showYearPicker
                className='custom-date-picker education-font date-picker-input b-rad-left'
                value={selectedDate}
              /> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  // onBlur={handleBlur}
                  name='selectedDate'
                  value={selectedDate || null}
                  className='custom-textfield'
                  format='YYYY'
                  // maxDate={eighteenYearsAgo}
                  // disableFuture
                  views={['year']}
                  onChange={(date) => handleDateChange(date)}
                  renderInput={(params) => (
                    <CustomTextField variant='standard' fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box>
              <Typography variant='h5' className='s-bold pb-2'>
                {doYouFinish?.title}
              </Typography>
              <Box
                sx={{ justifyContent: 'space-around' }}
                className='d-flex pb-4 profile-hobby'
              >
                <FormGroup>
                  <FormControlLabel
                    control={<IOSSwitch className='ios-switch' />}
                    name='doYouFinishSwitch'
                    checked={whatswitch}
                    onChange={(e) => {
                      profileData?.isPaid === false
                        ? setToogleDialog(true)
                        : handleChange(e);
                    }}
                  />
                </FormGroup>
                <Typography variant='h5'>
                  {localesData?.userwork?.thats_private_dont_show_this}
                </Typography>
                {doYouFinish?.private ? <LockIcon /> : <UnLockIcon />}
              </Box>
            </Box>
          </Box>
          {/* <Box>
          <CustomButton
            variant='contained'
            title={localesData?.userwork?.keep_going}
            background='#6A42ED'
            fontWeight='400'
            // type='submit'
            onClick={() => handleOnSubmit()}
          />
        </Box> */}
          <Box className='done-btn-sec'>
            <CustomButton
              variant='contained'
              title={localesData?.DONE}
              background={'#59D09D'}
              fontWeight='600'
              classname='done-btn s-bold'
              onClick={() => handleOnSubmit()}
            />
          </Box>
          <DialogBox
            open={toogleDialog}
            handleClose={() => setToogleDialog(!toogleDialog)}
            title=''
            content={
              <PremiumPopup
                handleClose={() => setToogleDialog(!toogleDialog)}
                navigate={navigate}
              />
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default UserEducation;
