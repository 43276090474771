import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UpgradePlan from '../ManageProfile/UpgradePlan';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const HelpcenterCategory = ({ localesData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  useEffect(() => {
    if (!state) {
      navigate('/help-center/');
    }
  }, []);

  return (
    <Box>
      <Box className='help-center-upgrade-btn'>
        <UpgradePlan title={localesData?.help_center?.HELP_CENTER} />
      </Box>
      <Box className='accordian-box-sec'>
        {state?.articles?.map((content, id) => (
          <Box className='content-head-sec' key={id}>
            <Box className='content-sec'>
              <Box
                onClick={() => {
                  navigate('/help-center/category/details', {
                    state: content,
                  });
                }}
                className='tab-sec space-between'
              >
                <Box>
                  <Typography className='subject-title'>
                    {content.title}
                  </Typography>
                  {content?.description && (
                    <Typography
                      className='font-r-13 txt-elips-2'
                      dangerouslySetInnerHTML={{ __html: content.description }}
                    />
                  )}
                </Box>
                <Box className='content-errow'>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}

        {state?.articles?.length === 0 && (
          <Typography className='pb-2'>
            No help centers are available for now. We will get back to you soon.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default HelpcenterCategory;
