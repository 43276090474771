import React from 'react';
import Page from 'src/components/Page';
import ManageProfile from 'src/views/component/application/ManageProfile';

const YourProfileView = () => {
  return (
    <Page title='Profile'>
      <ManageProfile />
    </Page>
  );
};

export default YourProfileView;
