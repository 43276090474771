import React, { useEffect } from 'react';
import { Box, List, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import './bottomBar.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Benefits } from 'src/assets/images/icons/benefits.svg';
import { ReactComponent as Directory } from 'src/assets/images/icons/directory.svg';
import { ReactComponent as DirectoryActive } from 'src/assets/images/icons/directory-active.svg';
import { ReactComponent as Matching } from 'src/assets/images/icons/matching.svg';
import { ReactComponent as Favourites } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as Kennel } from 'src/assets/images/icons/kennel.svg';
import { ReactComponent as BenefitsActive } from 'src/assets/images/icons/benefits-active.svg';
import { ReactComponent as FavouritesActive } from 'src/assets/images/icons/favourites-active.svg';
import { ReactComponent as MatchesActive } from 'src/assets/images/icons/matches-active.svg';
import { ReactComponent as KennelActive } from 'src/assets/images/icons/kennel-active.svg';
import { useSelector } from 'react-redux';

const BottomBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reduxCount = useSelector((state) => state?.notificationCount?.count);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);
  const menuItem = [
    {
      id: 1,
      name: 'Matching',
      url: '/matching',
      icon: <Matching />,
      selectedIcon: <MatchesActive />,
    },
    {
      id: 2,
      name: 'Kennel',
      url: '/kennel',
      icon: <Kennel />,
      selectedIcon: <KennelActive />,
      count: reduxCount,
    },
    {
      id: 3,
      name: 'Directory',
      url: '/directory',
      icon: <Directory />,
      selectedIcon: <DirectoryActive />,
    },
    {
      id: 4,
      name: 'Benefits',
      url: '/benefits',
      icon: <Benefits />,
      selectedIcon: <BenefitsActive />,
    },
    {
      id: 5,
      name: 'Favourites',
      url: '/favourites',
      icon: <Favourites />,
      selectedIcon: <FavouritesActive />,
    },
  ];

  const handleTabClick = (url) => {
    if (location?.pathname === url && url === '/directory') {
      window.location.reload();
    } else {
      navigate(url);
    }
  };

  return (
    <>
      {/* <div className='google-ads'>
        <ins
          className='adsbygoogle'
          style={{ display: 'block', height: '65px' }}
          data-ad-client='ca-pub-6734155310587726'
          data-ad-slot='8992226449'
          data-ad-format='auto'
          data-full-width-responsive='true'
          google_adtest='on'
        ></ins>
      </div> */}

      <Box className='footer-tab'>
        {/* <Box className='border-wrapper' /> */}
        <Box className='bottom-footer-tab'>
          <List className='tab-sec'>
            {menuItem &&
              menuItem?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <ListItem
                      className='bottom-tab pointer'
                      key={item?.name}
                      onClick={() => handleTabClick(item?.url)}
                    >
                      {' '}
                      {item?.count !== 0 && item?.url === '/kennel' && (
                        <span
                          className={`noti-count-show ${
                            location?.pathname === '/kennel' ? 'hide' : ''
                          }`}
                        >
                          {location?.pathname === '/kennel' &&
                          item?.url === '/kennel'
                            ? ''
                            : item?.count}
                        </span>
                      )}
                      <ListItemIcon className='tab-icons'>
                        {location?.pathname?.includes(item?.url)
                          ? item?.selectedIcon
                          : item?.icon}
                      </ListItemIcon>
                      <ListItemText className='tab-name'>
                        {item?.name}
                      </ListItemText>
                    </ListItem>
                  </React.Fragment>
                );
              })}
          </List>
        </Box>
      </Box>
    </>
  );
};

export default BottomBar;
