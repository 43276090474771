import React from 'react';
import Page from '../../../components/Page';
// import ChatPage from 'src/views/component/application/chat1';
import WelcomePage from 'src/views/component/application/chat/WelcomePage';

const ChatView = () => {
  return (
    <Page title='Chat'>
      {/* <ChatPage /> */}
      <WelcomePage />
    </Page>
  );
};

export default ChatView;
