import React, { useContext, useEffect, useState, useRef } from 'react';
import { IntlContext } from 'src/App';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Typography,
  Grid,
  Select,
} from '@mui/material';
import CreatableSelect from 'react-select/creatable';
// import moment from 'moment';
// import {
//   LoginSocialFacebook,
//   LoginSocialGoogle,
//   LoginSocialApple,
// } from 'reactjs-social-login';
import CustomButton from '../../../UI/Button/CustomButton';
import IOSSwitch from '../../../UI/IOSSwitch';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CustomTextField } from '../../../UI/textfield';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DownloadMyData from './DownloadData';
import UpgradePlan from '../../ManageProfile/UpgradePlan';
import {
  clearStorage,
  fetchFromStorage,
  saveToStorage,
  // Config,
} from 'src/helpers/context';
import _ from 'lodash';
import { identifiers } from 'src/helpers/constants/identifier';
import { CustomSelect } from '../../../UI/select';
import { ReactComponent as DropdownIcon } from 'src/assets/images/icons/down-icon.svg';
import { setApiMessage } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import PreLoader from 'src/components/Loader';
import { ReactComponent as PlusIcon } from 'src/assets/images/icons/plus.svg';
import { ReactComponent as Editicon } from 'src/assets/images/editicon.svg';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as Info } from 'src/assets/images/icons/info.svg';
import SubDialogbox from 'src/views/component/UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from 'src/views/component/UI/Subscriptionpopup/SubscriptionDialog';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import ContactCard from './ContactCard';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SubscriptionAlert from 'src/views/component/UI/Subscriptionpopup/SubscriptionAlert';

const AccountDetails = () => {
  const location = useLocation();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef();
  const selectRef = useRef(null);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [subgender, setsubgender] = useState([]);
  const [subgenderAns, setsubgenderAns] = useState([]);
  const [subInitgenderAns, setInitsubgenderAns] = useState([]);
  const [subgenderList, setsubgenderList] = useState([]);
  const [subG, setSubG] = useState();
  const [gender, setGender] = useState(profileData?.gender);
  const country_code = profileData?.countryCode ? profileData?.countryCode : '';
  const phone = profileData?.username ? profileData?.username : '';
  const full_phone =
    country_code !== '' ? '+' + country_code + ' ' + phone : phone;
  const [toogleDialog, setToogleDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [charityList, setcharityList] = useState([]);
  const [charity, setCharity] = useState('');
  const [open, setOpen] = useState(false);
  const [unlinkOpen, setUnlinkedOpen] = useState(false);
  const [push_notifications, setpush_notifications] = useState(false);
  const [is_account_pause, setis_account_pause] = useState(false);
  const [startDate, setStartDate] = useState('');

  const [contactDialog, setContactDialog] = useState(false);
  const [contact, setContact] = useState();

  const [subToggle, setSubToggle] = useState(false);
  const [cancelPlanStatus, setCancelPlanStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [cardData, setCardData] = useState('');
  const [addMobile, setAddMobile] = useState(false);
  const [profile, setProfile] = useState();
  const [userName, setUserName] = useState();
  const [genderInputValue, setGenderInputValue] = useState();

  const genderValue = [
    { label: 'Male', value: 0 },
    { label: 'Female', value: 1 },
    { label: 'Non-binary', value: 2 },
  ];
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const handleUnlink = () => {
    setUnlinkedOpen(true);
  };
  const handleUnlinkClose = () => {
    setUnlinkedOpen(false);
  };
  const getProfile = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
        setProfile(data);
        const country_code1 =
          data && data?.countryCode ? data?.countryCode : '';
        const phone1 = data && data?.username ? data?.username : '';
        const full_phone1 =
          country_code1 !== '' ? '+' + country_code1 + ' ' + phone1 : phone1;
        formikRef.current.setFieldValue('phone_number', full_phone1);
        formikRef.current.setFieldValue(
          'email',
          data?.email ? data?.email : ''
        );
        setUserName(full_phone1);
        setLoader(false);
        setis_account_pause(data?.active ? false : true);
        setpush_notifications(data?.pushNotificationActive ? true : false);
        setContactDialog(false);
        setUnlinkedOpen(false);
        const filterDate = dayjs(data?.dob);
        setStartDate(filterDate);
        let genderselect = data?.subGender.split(',').map(Number);
        setsubgenderAns(genderselect);
        setInitsubgenderAns(genderselect);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    const country_code1 =
      profileData && profileData?.countryCode ? profileData?.countryCode : '';
    const phone1 =
      profileData && profileData?.username ? profileData?.username : '';
    const full_phone1 =
      country_code1 !== '' ? '+' + country_code1 + ' ' + phone1 : phone1;
    setUserName(full_phone1);
    formikRef.current.setFieldValue('phone_number', full_phone1);
  }, [profileData]);
  useEffect(() => {
    getProfile();
  }, []);

  //HANDLE DELETE
  const handledelete = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.delete(URLS.USER);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
        clearStorage();
        navigate('/');
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const AccountData = [
    {
      id: 1,
      label: localesData?.account_detail?.LEGAL,
      href: 'https://puppilovers.com/terms-conditions/',
    },
    {
      id: 2,
      label: localesData?.account_detail?.PRIVACY_POLICY,
      href: 'https://puppilovers.com/privacy-policy/',
    },
    {
      id: 3,
      label: localesData?.account_detail?.TERMS_AND_CONDITIONS,
      href: 'https://puppilovers.com/terms-conditions/',
    },
    {
      id: 4,
      label: localesData?.account_detail?.COOKIE_POLICY,
      href: 'https://puppilovers.com/privacy-policy/',
    },
    {
      id: 5,
      label: localesData?.account_detail?.SAFE_DATING_TIPS,
      href: 'https://puppilovers.com/safety-tips/',
    },
    {
      id: 6,
      label: localesData?.account_detail?.DOWNLOAD_MY_DATA,
      link: '/download-data',
    },
  ];

  // HANDLE PUSHNOTIFICATIONS
  const pushnotifications = async () => {
    const param_data = {
      pushNotificationActive: push_notifications ? false : true,
    };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        getProfile();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const handleChangenotifications = (e) => {
    pushnotifications();
  };

  // HANDLE ACCOUNT PAUSE
  const HandleAccountpause = async () => {
    const param_data = {
      active: is_account_pause ? true : false,
    };

    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        getProfile();
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };
  const handlePause = (e) => {
    setis_account_pause(!is_account_pause);
    HandleAccountpause();
  };

  // HANDLE GENDER CHANGE
  const handleGenderChange = async (e) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    const param_data = {
      gender: selectedGender,
      subGender: null,
    };

    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        setLoader(false);
        getProfile();
        setSubG('');
        setsubgenderAns([]);
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };

  //HANDLE CHANGE CHARITY
  const handleChngeCharity = async (e) => {
    const selectedCharity = e.target.value;
    setCharity(selectedCharity);
    const param_data = {
      charityId: selectedCharity,
    };

    try {
      setLoader(true);
      const { status, data } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
    getCharity();
  };

  const handleClick = (e, href, link) => {
    e.preventDefault();

    if (href) {
      window.open(href, '_blank');
    } else if (link) {
      navigate(link);
    }
  };

  // GET CHARITY
  const getCharity = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_CHARITY);
      if (status === 200) {
        setApiMessage('success', data?.message);
        // setQuestions(data?.types);
        const charity_data = data?.data;
        setcharityList(charity_data);
        setCharity(charity_data?.[0]?.id);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const userUpdate = async (updatedGender) => {
    const param_data = {
      gender: 2,
      subGender: updatedGender,
    };
    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        setLoader(false);
        getProfile();
        getAllAnswars();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  // SUBGENDER
  const handlesubGenderChange = async (sublist) => {
    const slist = sublist.map((S) => S.id);
    const filterlist = slist.filter((l) => l !== undefined);
    const slistlabel = sublist.filter((S) => S.id === undefined);
    const filterlabel = slistlabel.map((S) => {
      return {
        optionId: null,
        answer: S?.value,
        selected: true,
      };
    });
    const subgender = subgenderList.map((g) => {
      if (filterlist.includes(g?.value)) {
        return { optionId: g?.value, selected: true };
      } else if (subInitgenderAns.includes(g?.value)) {
        return { optionId: g?.value, selected: false };
      } else {
        return null;
      }
    });
    const subgenderfilter = subgender.filter((s) => s !== null);
    const param_data = subgenderfilter.concat(filterlabel);
    const sendData = {
      private: false,
      optionIds: param_data,
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.SET_ANSWERS,
        sendData
      );
      if (status === 200) {
        setApiMessage('success', data?.message);
        const slists = data?.optionsCreated?.map((S) => S.optionId);
        const filterlists = slists.filter((l) => l !== undefined);
        setsubgenderAns(filterlists);
        userUpdate(filterlists.toString());
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (
      profileData?.gender &&
      profileData?.gender !== 0 &&
      profileData?.gender !== 1
    ) {
      profileData?.gender !== 2 && setSubG(profileData?.gender);
      setGender(2);
    } else {
      setGender(profileData?.gender);
    }
  }, [profileData, profileData?.gender]);

  // useEffect(() => {
  //   if (profileData) {
  //     const filterDate = dayjs(profileData?.dob);
  //     setStartDate(filterDate);
  //   }
  // }, [profileData]);

  //GET ALL ANSWER
  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setsubgender(data?.questions?.[1]?.Option);
        const filteredData = data?.questions?.[1]?.Option?.map(
          (filteredItem, index) => {
            return {
              ...filteredItem,
              label: filteredItem?.title,
              name: filteredItem?.title,
              value: filteredItem?.id,
              // id: index,
            };
          }
        )?.filter((item) => {
          return (
            item?.title !== 'Male' &&
            item?.title !== 'Female' &&
            item?.title !== 'Nonbinary'
          );
        });

        setsubgenderList(filteredData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const accountLogout = async () => {
    const sendData = {};
    try {
      const { status } = await axiosInstance.post(URLS.LOGOUT, sendData);
      if (status === 200) {
        clearStorage();
        navigate('/');
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // LOGOUT
  const logoutUser = () => {
    accountLogout();
    // clearStorage();
    // navigate('/');
  };
  useEffect(() => {
    getCharity();
    getAllAnswars();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   fetchInitialDate();
  // }, []);

  // //DOB
  // const fetchInitialDate = async () => {
  //   try {
  //     const { data } = await axiosInstance.get(URLS.USER);
  //     const dob = data?.dob;
  //     if (dob) {
  //       const parsedDate = dayjs(dob).format('DD/MM/YYYY');
  //       setStartDate(parsedDate);
  //     }
  //   } catch (error) {}
  // };

  //HANDLE DOB CHANGE
  const handleStartDateChange = async (date) => {
    let dateFormate = dayjs(date);
    setStartDate(dateFormate);

    const param_data = {
      dob: dateFormate,
    };

    try {
      setLoader(true);
      const { status } = await axiosInstance.put(URLS.USER, param_data);
      if (status === 200) {
        setLoader(false);
        getProfile();
      }
    } catch (error) {
      setLoader(false);
    }
  };

  //FILTER SUBGENDER
  const linkRejectWithStatus = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.SIMPLE_UNLNK + URLS.UNLINK_LINK_CANCEL
      );
      if (status === 200) {
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const linkReject = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(URLS.SIMPLE_UNLNK);
      if (status === 200) {
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const linkAccept = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.LINK + profileData?.linkProfile?.id + URLS.ACCEPT_LINK_REQUEST
      );
      if (status === 200) {
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const handleOnUnlink = async () => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.get(
        URLS.UNLINK + '?linkStatus=' + URLS.REQUEST_UNLNK
      );
      if (status === 200) {
        getProfile();
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // GET CARD DETAILS
  const getCardDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_SUBSCRIBE_PLAN);
      if (status === 200) {
        // if (Object.keys(data?.data).length !== 0) {
        setCardData(Object.keys(data?.data).length !== 0 ? data?.data : '');
        setLoader(false);
        // } else {
        //   navigate('/');
        // }
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  // CANCEL PLAN
  const handleCancelPlan = async () => {
    if (profileData?.platform === 'web' || profileData?.platform === null) {
      const sendData = {
        planId: cardData?.planId,
      };
      try {
        setLoader(true);
        const { status, data } = await axiosInstance.delete(
          URLS.CANCEL_SUBSCRIBE_PLAN,
          {
            data: sendData,
          }
        );
        if (status === 200) {
          if (data?.status) {
            setApiMessage('success', data?.message);
            setSubToggle(!subToggle);
            setCancelPlanStatus(false);
            setDeleteStatus(false);
            getProfile();
          } else {
            setApiMessage('error', data?.message);
          }
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        setApiMessage('error', error?.response?.data?.message);
      }
    } else {
      setSubToggle(false);
      setCancelPlanStatus(false);
      setApiMessage(
        'error',
        localesData?.subscription_popup?.CANNOT_CANCEL_IN_WEB
      );
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      paddingRight: '0px',
    }),
    // indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      // color: state.isFocused ? '#000' : '#ccc',
      // '&:hover': {
      //   color: '#000', // Adjust hover color
      // },
    }),
  };
  useEffect(() => {
    getCardDetails();
    // eslint-disable-next-line
  }, [location]);

  const currentDate = dayjs();
  const eighteenYearsAgo = currentDate.subtract(18, 'year');
  const isSubscribtion = profileData?.subscription?.status === 'cancel';
  const handleKeyDown = (event) => {
    if (event.key === ',') {
      // Simulate Tab key behavior
      event.preventDefault();
      // selectRef.current.select.selectOption({
      //   label: event.target.value,
      //   value: event.target.value,
      // });
      if (genderInputValue !== '') {
        let newOption = {
          label: genderInputValue,
          value: genderInputValue,
        };
        const sublistdata = selectRef.current.state.selectValue;
        const checkedinList = subgenderList.find(
          (f) =>
            f?.label?.toLowerCase().includes(genderInputValue?.toLowerCase()) &&
            !subgenderAns.includes(f?.values)
        );
        // sublist.push(newOption);
        if (!checkedinList) {
          setsubgenderList([...subgenderList, newOption]);
          handlesubGenderChange([...sublistdata, newOption]);
        } else {
          handlesubGenderChange([...sublistdata, checkedinList]);
        }
        handleCloseOptions();
        setGenderInputValue('');
      }
      //
    }
    //
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseOptions = () => {
    setIsOpen(false);
  };

  const handleToggleOptions = () => {
    setIsOpen(!isOpen);
  };
  // const handleAppleLoginSuccess = async (response, type) => {
  //   const requestData = {
  //     authorization: {
  //       code: response?.authorization?.code,
  //       id_token: response?.authorization?.id_token,
  //     },
  //     ...(response &&
  //       response?.user &&
  //       response?.user?.name &&
  //       response?.user?.name?.firstName && {
  //         firstname: response?.user?.name?.firstName
  //           ? response?.user?.name?.firstName
  //           : '',
  //       }),
  //     ...(response &&
  //       response?.user &&
  //       response?.user?.name &&
  //       response?.user?.name?.lastName && {
  //         lastname: response?.user?.name?.lastName
  //           ? response?.user?.name?.lastName
  //           : '',
  //       }),
  //     provider: 'apple',
  //   };
  //   if (!response?.error) {
  //     try {
  //       setLoader(true);
  //       const { status, data } = await axiosInstance.put(
  //         URLS.USER + `?type=${type}`,
  //         requestData
  //       );
  //       if (status === 200) {
  //         setLoader(false);
  //         setTimeout(() => {
  //           getProfile();
  //         }, 400);
  //       }
  //     } catch (error) {
  //       setApiMessage('error', error?.response?.data?.message);
  //       setLoader(false);
  //     }
  //   }
  // };

  // const socialLoginHandler = async (response, type) => {
  //   const requestData = {
  //     provider: type === 'web' ? 'google' : type,
  //     access_token:
  //       type === 'facebook'
  //         ? response?.data?.accessToken
  //         : type === 'apple-web'
  //         ? response?.access_token
  //         : response?.data?.access_token,
  //   };
  //   if (requestData?.access_token) {
  //     try {
  //       setLoader(true);
  //       const { status, data } = await axiosInstance.put(
  //         URLS.USER + `?type=${type}`,
  //         requestData
  //       );

  //       if (status === 200) {
  //         setApiMessage('success', data?.message);
  //         setTimeout(() => {
  //           getProfile();
  //         }, 400);
  //         setLoader(false);
  //       }
  //     } catch (error) {
  //       setApiMessage('error', error?.response?.data?.message);
  //       setLoader(false);
  //     }
  //   }
  // };
  return (
    <Box className='account-details-sec'>
      {/* loader */}
      {loader && <PreLoader />}
      <Box>
        {id === 'DownloadMyData' ? (
          <DownloadMyData />
        ) : (
          <Box>
            <Box className='pause-box-sec'>
              <Box className='edit-upgrade-btns'>
                <UpgradePlan
                  title={localesData?.account_detail?.ACCOUNT_DETAILS}
                />
              </Box>
              <Box className='page-content-sec'>
                <Box className='pause-head-sec'>
                  <Box className='pause-profile-sec'>
                    <Typography
                      className='pause-profile-head '
                      dangerouslySetInnerHTML={{
                        __html: localesData?.account_detail?.PAUSE_PROFILE,
                      }}
                    />

                    <FormControl>
                      <FormControlLabel
                        control={
                          <IOSSwitch defaultChecked={is_account_pause} />
                        }
                        name='empData'
                        onChange={(e) => handlePause(e)}
                      />
                    </FormControl>
                  </Box>
                  <Typography
                    className='pause-profile-desc pb-9'
                    dangerouslySetInnerHTML={{
                      __html: localesData?.account_detail?.DESC,
                    }}
                  />

                  <Box className='link-head-sec pb-3'>
                    <Box className='link-profile-sec'>
                      <Typography
                        className='link-profile-head'
                        dangerouslySetInnerHTML={{
                          __html: localesData?.account_detail?.LINK_PROFILE,
                        }}
                      />
                      {/* {this.state.linkStatus === ScreenText.REQUEST_SEND
                            ? ScreenText.PENDING
                            : this.state.linkStatus === ''
                            ? ScreenText.FIND
                            : this.state.linkStatus ===
                              ScreenText.REQUEST_ACCEPT
                            ? ScreenText.PENDING
                            : this.state.linkStatus ===
                              ScreenText.REQUEST_UNLINK
                            ? ScreenText.UNLINK
                            : ''} */}
                      <CustomButton
                        variant='contained'
                        title={
                          profileData?.linkProfile &&
                          profileData?.linkProfile?.linkStatus === 'RequestSend'
                            ? localesData?.account_detail?.pending
                            : !profileData?.linkProfile ||
                              profileData?.linkProfile?.linkStatus === '' ||
                              profileData?.linkProfile?.linkStatus === undefined
                            ? localesData?.account_detail?.FIND_BTN
                            : profileData?.linkProfile &&
                              profileData?.linkProfile?.linkStatus ===
                                'RequestAccept'
                            ? localesData?.account_detail?.pending
                            : profileData?.linkProfile &&
                              profileData?.linkProfile?.linkStatus ===
                                'RequestUnlink'
                            ? localesData?.account_detail?.unlink
                            : ''
                        }
                        background='#F54D71'
                        fontWeight='600'
                        classname='find-btn'
                        onClick={() => {
                          var pdata = {
                            name:
                              profileData &&
                              profileData?.linkProfile &&
                              profileData?.linkProfile?.firstname &&
                              profileData?.linkProfile?.firstname.trim() +
                                ' ' +
                                profileData &&
                              profileData?.linkProfile &&
                              profileData?.linkProfile?.lastname &&
                              profileData?.linkProfile?.lastname.trim(),
                            avatar:
                              profileData &&
                              profileData?.linkProfile &&
                              profileData?.linkProfile?.userFirstImage &&
                              profileData?.linkProfile?.userFirstImage?.url
                                ? profileData?.linkProfile?.userFirstImage?.url
                                : '',
                          };
                          setContact(pdata);
                          if (
                            profileData?.linkProfile?.linkStatus &&
                            profileData?.linkProfile?.linkStatus ===
                              'RequestSend'
                          ) {
                            setContactDialog(true);
                            setContact(pdata);
                          } else if (
                            !profileData?.linkProfile ||
                            profileData?.linkProfile?.linkStatus === '' ||
                            profileData?.linkProfile?.linkStatus === undefined
                          ) {
                            navigate('/contact');
                          } else if (
                            profileData?.linkProfile?.linkStatus ===
                            'RequestAccept'
                          ) {
                            setContactDialog(true);
                            setContact(pdata);
                          } else if (
                            profileData?.linkProfile?.linkStatus ===
                            'RequestUnlink'
                          ) {
                            handleUnlink();
                          }
                        }}
                      />
                    </Box>
                    <Typography
                      className='link-profile-desc'
                      dangerouslySetInnerHTML={{
                        __html:
                          profileData?.linkProfile &&
                          profileData?.linkProfile?.linkStatus &&
                          profileData?.linkProfile?.linkStatus ===
                            'RequestUnlink'
                            ? localesData?.account_detail
                                ?.your_profile_is_linked_to +
                              profileData?.linkProfile?.firstname +
                              localesData?.account_detail
                                ?.you_can_continue_to_use +
                              profileData?.linkProfile?.firstname +
                              localesData?.account_detail
                                ?.will_no_longer_appear_in_matches
                            : profileData?.linkProfile &&
                              profileData?.linkProfile?.linkStatus &&
                              profileData?.linkProfile?.linkStatus ===
                                'RequestSend'
                            ? localesData?.account_detail?.you_have_req +
                              profileData?.linkProfile?.firstname +
                              localesData?.account_detail?.request_pending
                            : profileData?.linkProfile &&
                              profileData?.linkProfile?.linkStatus &&
                              profileData?.linkProfile?.linkStatus ===
                                'RequestAccept'
                            ? profileData?.linkProfile?.firstname +
                              localesData?.account_detail?.request_send
                            : localesData?.account_detail?.LINK_DESC,
                      }}
                    />
                  </Box>

                  <Formik
                    innerRef={formikRef}
                    initialValues={{
                      phone_number: userName ? userName : full_phone,
                      email:
                        profile && profile?.email
                          ? profile?.email
                          : profileData?.email,
                      gender: '',
                      subgender:
                        profileData?.gender &&
                        profileData?.gender !== 0 &&
                        profileData?.gender !== 1
                          ? profileData?.gender
                          : '',
                    }}
                    validationSchema={Yup.object().shape({
                      subgender: Yup.string().required(
                        localesData?.validation?.REQUIRED
                      ),
                    })}
                    onSubmit={(requestData) => {
                      // setSubmitting(false);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className='form-content pb-6 all-wrap'
                      >
                        {/* phone number */}
                        <CustomTextField
                          error={Boolean(
                            touched.phone_number && errors.phone_number
                          )}
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          fullWidth
                          variant='standard'
                          className='select-input-field text-bold'
                          name='phone_number'
                          value={values.phone_number}
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={localesData?.otp?.add_mobile}
                          InputProps={{
                            endAdornment: values.phone_number ? (
                              <Editicon
                                className='pointer edit-icon'
                                onClick={() => {
                                  navigate('/manage-account/mobile');
                                }}
                              />
                            ) : (
                              <PlusIcon
                                className='pointer add-icon'
                                onClick={() => {
                                  navigate('/manage-account/mobile');
                                }}
                              />
                            ),
                          }}
                        />
                        <Typography
                          variant='h5'
                          className='pb-2 d-flex select-helper-txt'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.account_detail?.PHONE_NUMBER,
                          }}
                        />

                        {/* Email */}
                        <CustomTextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          variant='standard'
                          className='select-input-field '
                          name='email'
                          placeholder={localesData?.otp?.add_an_email_address}
                          value={values.email}
                          disabled
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment:
                              // !full_phone && profileData?.provider !== 'basic' ?
                              //   <Editicon
                              //     className='pointer edit-icon'
                              //     onClick={() => { setAddMobile(true) }}
                              //   /> : profileData?.provider === 'google' ?
                              //     <LoginSocialGoogle
                              //       client_id={Config.GoogleAppID}
                              //       discoveryDocs='claims_supported'
                              //       access_type='offline'
                              //       redirect_uri={Config.redirectURL}
                              //       scope='openid profile email'
                              //       onResolve={(user) => {
                              //         if (user) {
                              //           socialLoginHandler(user, 'web');
                              //         }
                              //       }}
                              //       onReject={(err) => {
                              //         console.log(err);
                              //       }}
                              //     >
                              //       <Editicon
                              //         className='pointer edit-icon'
                              //       />
                              //     </LoginSocialGoogle> :
                              //     profileData?.provider === 'facebook' ?
                              //       <LoginSocialFacebook
                              //         appId={Config.FacebookAppID || ''}
                              //         fieldsProfile={
                              //           'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                              //         }
                              //         redirect_uri={Config.redirectURL}
                              //         onResolve={(user) => {
                              //           if (user) {
                              //             socialLoginHandler(user, 'facebook');
                              //           }
                              //         }}
                              //         onReject={(err) => {
                              //           console.log(err);
                              //         }}
                              //       >
                              //         <Editicon
                              //           className='pointer edit-icon'
                              //         />
                              //       </LoginSocialFacebook> :
                              //       profileData?.provider === 'apple' ?
                              //         <LoginSocialApple
                              //           client_id={Config?.AppleAppID || ''}
                              //           scope={'name email'}
                              //           redirect_uri={Config?.redirectURL}
                              //           onResolve={(data) => {
                              //             handleAppleLoginSuccess(data?.data, 'apple-web');
                              //           }}
                              //           onReject={(err) => {
                              //             console.log(err);
                              //           }}
                              //         >
                              //           <Editicon
                              //             className='pointer edit-icon'
                              //           />
                              //         </LoginSocialApple>
                              //         : profileData?.provider === 'basic' &&
                              values.email ? (
                                <Editicon
                                  className='pointer edit-icon'
                                  onClick={() => {
                                    navigate('/manage-account/email');
                                  }}
                                />
                              ) : (
                                <PlusIcon
                                  className='pointer add-icon'
                                  onClick={() => {
                                    navigate('/manage-account/email');
                                  }}
                                />
                              ),
                          }}
                        />
                        <Typography
                          variant='h5'
                          className='pb-2 d-flex select-helper-txt'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.account_detail?.EMAIL_ADDRESS,
                          }}
                        />

                        <Box className='custom-date-picker date-picker'>
                          {/* <DatePicker
                            selected={startDate}
                            dateFormat='dd/MM/yyyy'
                            onChange={(e) => handleStartDateChange(e)}
                            className='date-picker-input  b-rad-left'
                            value={startDate}
                          /> */}

                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              // onBlur={handleBlur}
                              name='DOB'
                              value={startDate || null}
                              className='custom-textfield'
                              format='DD/MM/YYYY'
                              maxDate={eighteenYearsAgo}
                              // disableFuture
                              onChange={(date) => handleStartDateChange(date)}
                              renderInput={(params) => (
                                <CustomTextField
                                  variant='standard'
                                  fullWidth
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Typography
                          variant='h5'
                          className='pb-2 d-flex select-helper-txt'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.account_detail?.DOB,
                          }}
                        />

                        {/* Gender */}

                        <FormControl className='menu-box'>
                          <Select
                            fullWidth
                            labelId='Gender-select-label'
                            id='Gender-select'
                            value={gender}
                            name='gender'
                            variant='standard'
                            input={<CustomSelect />}
                            className='custom-select'
                            IconComponent={DropdownIcon}
                            onChange={handleGenderChange}
                          >
                            {genderValue?.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <Typography
                          variant='h5'
                          className='pb-3 d-flex select-helper-txt'
                          dangerouslySetInnerHTML={{
                            __html: localesData?.account_detail?.GENDER,
                          }}
                        />

                        {gender === 2 && (
                          <FormControl className='menu-box pb-3'>
                            {/* <Select
                              fullWidth
                              labelId='Gender-select-label'
                              id='Gender-select'
                              value={subG}
                              error={!subG}
                              displayEmpty
                              name='subgender'
                              isMulti
                              variant='standard'
                              input={<CustomSelect />}
                              className='custom-select'
                              IconComponent={DropdownIcon}
                              onChange={handlesubGenderChange}
                            >
                              {filteredData?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>
                                  {item?.title}
                                </MenuItem>
                              ))}
                            </Select> */}
                            {/* <ReactSelect
                              isMulti
                              classNamePrefix='select'
                              menuPosition='fixed'
                              className='custom-select react-select'
                              placeholder=''
                              options={filteredData?.map((item) => ({
                                value: item?.id,
                                label: item?.title,
                              }))}
                              // isOptionDisabled={(option) =>
                              //   selectedValues[race?.raceId]?.includes(
                              //     option.value
                              //   ) ||
                              //   (selectedValues[race?.raceId]?.length || 0) >=
                              //     4
                              // }
                              // value={}
                              // onChange={(selectedOptions) =>
                              // const isExisting = options.some(
                              //                           option => inputValue === option.name
                              //                       );
                              //                       if (inputValue !== '' && !isExisting) {
                              //                           filtered.push({
                              //                               inputValue,
                              //                               name: `Add "${inputValue}"`
                              //                           });
                              //                       }
                              //                       return filtered;
                              // }
                            /> */}
                            <CreatableSelect
                              ref={selectRef}
                              components={{
                                DropdownIndicator: Editicon,
                              }}
                              styles={customStyles}
                              className='custom-select react-select '
                              classNamePrefix='select'
                              // defaultOptions={subgenderList}
                              options={_.chain(subgenderList)
                                ?.filter((option) =>
                                  option?.label
                                    ?.toLowerCase()
                                    ?.includes(genderInputValue?.toLowerCase())
                                )
                                .uniqBy('value')
                                .value()}
                              isMulti
                              onChange={(e, newValue) => {
                                if (genderInputValue !== '') {
                                  let newOption = {
                                    label: genderInputValue,
                                    value: genderInputValue,
                                  };
                                  // const sublist = subgenderList;
                                  // sublist.push(newOption);
                                  setsubgenderList([
                                    ...subgenderList,
                                    newOption,
                                  ]);
                                  setGenderInputValue('');
                                }
                                handlesubGenderChange(e);
                              }}
                              value={
                                subgenderAns &&
                                subgenderAns.length !== 0 &&
                                subgenderList?.filter((opt) => {
                                  return subgenderAns.includes(opt?.value);
                                })
                              }
                              inputValue={genderInputValue}
                              onInputChange={(value) => {
                                setGenderInputValue(value);
                              }}
                              menuIsOpen={isOpen}
                              onFocus={handleToggleOptions}
                              onBlur={handleCloseOptions}
                              onKeyDown={handleKeyDown}
                            />
                            {/* <Autocomplete
                              options={filteredData}
                              // value={fightCom.fight_method_name}
                              onChange={(e, newValue) => {
                                if (newValue) {
                                  // let combs = resultData[index]?.fightContestants;
                                  // let newComb = combs?.map((x, xindex) => {
                                  //     if (xindex === findex) {
                                  //         return {
                                  //             ...x, fight_method_id: newValue.id,
                                  //             fight_method_name: newValue.name
                                  //         };
                                  //     }
                                  //     return x;
                                  // });
                                  // let updateData = resultData.map((y, yindex) => {
                                  //     if (yindex === index) {
                                  //         return { ...y, fightContestants: newComb };
                                  //     }
                                  //     return y;
                                  // });
                                  // setResultData(updateData);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.name
                                );
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              // getOptionLabel={option => option.name}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return String(option.name);
                              }}
                              renderOption={(props, option) => {
                                return props.name;
                              }}
                              renderInput={(params) => (
                                <CustomTextField
                                  fullWidth
                                  // error={validCheck && fightCom?.fight_method_name === ''}
                                  // helperText={
                                  //     validCheck &&
                                  //     fightCom?.fight_method_name === '' &&
                                  //     'This field is required'
                                  // }
                                  {...params}
                                  error={Boolean(
                                    touched.subgender && errors.subgender
                                  )}
                                  helperText={
                                    touched.subgender && errors.subgender
                                  }
                                  variant='standard'
                                  value={values.subgender}
                                  // onChange={handleChange}
                                  // onBlur={handleBlur}
                                  placeholder={''}
                                />
                              )}
                            /> */}
                            <Typography
                              variant='h5'
                              className=' d-flex select-helper-txt'
                              dangerouslySetInnerHTML={{
                                __html: localesData?.account_detail?.SUBGENDER,
                              }}
                            />
                            {subgenderAns.length === 0 && (
                              <Typography
                                variant='h5'
                                className=' font-r-11 color-error  d-flex align-center '
                              >
                                <Info className='mr-4' />{' '}
                                {localesData?.SUBGENDERERROR}
                              </Typography>
                            )}
                          </FormControl>
                        )}
                        <FormControl className='menu-box'>
                          <Select
                            fullWidth
                            labelId='Charity-select-label'
                            id='Charity-select'
                            value={charity || ''}
                            name='Charity'
                            displayEmpty
                            variant='standard'
                            input={<CustomSelect />}
                            IconComponent={DropdownIcon}
                            className='custom-select'
                            onChange={handleChngeCharity}
                          >
                            <MenuItem value='' disabled>
                              Select an item
                            </MenuItem>
                            {charityList?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography
                            variant='h5'
                            className='pb-7 d-flex select-helper-txt'
                            dangerouslySetInnerHTML={{
                              __html: localesData?.account_detail?.CHARITY,
                            }}
                          />
                        </FormControl>

                        {/* Gender */}
                        <Box className='pb-7'>
                          <Box className='push-notification-sec '>
                            <Box className='d-flex align-center'>
                              <Typography
                                variant='h5'
                                className='d-flex push-notification-txt mr-4'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    localesData?.account_detail
                                      ?.PUSH_NOTIFICATION,
                                }}
                              />

                              {push_notifications === false && <Info />}
                            </Box>

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <IOSSwitch checked={push_notifications} />
                                }
                                name='empData'
                                onChange={(e) => handleChangenotifications(e)}
                              />
                            </FormControl>
                          </Box>
                          {push_notifications === false && (
                            <FormHelperText className='color-error'>
                              {localesData?.favourites?.PUSH_NOTIFICATION_ALERT}
                            </FormHelperText>
                          )}
                        </Box>

                        <Box className='account-type-sec pb-5'>
                          <Typography
                            variant='h5'
                            className=' d-flex account-type-txt'
                            // dangerouslySetInnerHTML={{
                            //   __html: localesData?.account_detail?.ACCOUNT_TYPE,
                            // }}
                          >
                            {localesData?.account_detail?.ACCOUNT_TYPE}
                            <span className='bold'>
                              {' '}
                              {/* {profileData?.isPaid === false ? 'Free' : 'Paid'} */}
                              {profileData?.isPaid === false
                                ? localesData?.FREE
                                : profileData?.subscription?.duration
                                ? `${profileData?.subscription?.duration} paid`
                                : localesData?.PAID}
                            </span>
                          </Typography>
                          {profileData?.isPaid === false ? (
                            <CustomButton
                              variant='contained'
                              title={localesData?.account_detail?.CHANGE}
                              background='#6A42ED'
                              fontWeight='600'
                              onClick={() => setToogleDialog(!toogleDialog)}
                              classname='change-btn'
                            />
                          ) : (
                            ''
                          )}
                        </Box>
                        {AccountData?.map((item) => {
                          return (
                            <>
                              <Box
                                onClick={(e) => {
                                  handleClick(e, item.href, item.link);
                                }}
                                className='terms-condition-tab-sec'
                              >
                                <Box className='d-flex tabs-sec'>
                                  <Typography
                                    variant='h5'
                                    className=' d-flex terms-condition-tabs'
                                    dangerouslySetInnerHTML={{
                                      __html: item?.label,
                                    }}
                                  />
                                  <ChevronRightIcon />
                                </Box>
                              </Box>
                            </>
                          );
                        })}

                        {/* logout btn */}
                        <Box className='btns-sec'>
                          <CustomButton
                            variant='contained'
                            title={localesData?.account_detail?.LOG_OUT}
                            background='#6A42ED'
                            fontWeight='600'
                            classname='log-out-btn'
                            onClick={logoutUser}
                          />

                          <CustomButton
                            variant='contained'
                            title={localesData?.account_detail?.DELETE_ACCOUNT}
                            background='#FF0000'
                            fontWeight='600'
                            // onClick={handleClickOpen}
                            onClick={() => {
                              if (profileData?.isPaid && !isSubscribtion) {
                                setCancelPlanStatus(false);
                                setSubToggle(!subToggle);
                              } else {
                                setDeleteStatus(true);
                                setSubToggle(!subToggle);
                              }
                            }}
                            classname='delete-account-btn'
                          />

                          {profileData?.isPaid && !isSubscribtion && (
                            <CustomButton
                              variant='contained'
                              title={
                                localesData?.subscription_popup
                                  ?.CANCEL_ACTIVE_SUBSCRIPTION
                              }
                              background='#FF0000'
                              fontWeight='600'
                              onClick={() => {
                                setCancelPlanStatus(true);
                                setSubToggle(!subToggle);
                              }}
                              classname='sub-account-btn'
                            />
                          )}
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* Subscription */}
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
      {/* delete alert */}
      <Dialog onClose={handleClose} open={open} className='dailog-box'>
        <Box className='dialog-wrap t-center'>
          <DialogTitle>
            <Typography variant='h6' className='font-r-12 t-center text-bold'>
              {localesData?.SURE}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='h6' className='font-r-11 l-height'>
              {localesData?.DELETE}
            </Typography>
            <Typography variant='h6' className='font-r-11 delete-text'>
              {localesData?.DELETEIT}
            </Typography>
            <Box className='d-flex'>
              <CustomButton
                variant='text'
                title={localesData?.profile?.CANCEL_BTN}
                // background='#FF0000'userwork?Yes
                fontWeight='600'
                onClick={handleClose}
                classname='mb-1 custom-btn cancel-btn'
              />
              <CustomButton
                variant='text'
                title={localesData?.userwork?.Yes}
                // background='#FF0000'userwork?Yes
                fontWeight='600'
                classname='mb-1  custom-btn yes-btn'
                onClick={handledelete}
                // classname='delete-account-btn'
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      {/* unlink alert */}
      <Dialog
        onClose={handleUnlinkClose}
        open={unlinkOpen}
        className='dailog-box'
      >
        <Box className='dialog-wrap t-center'>
          <DialogTitle>
            <Typography variant='h6' className='font-r-12 t-center text-bold'>
              {localesData?.favourites_delete?.ARE_YOU_SURE}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant='h6'
              className='font-r-11 l-height delete-text delete-padding'
            >
              {localesData?.account_detail?.if_you_unlink_your_account_from}
              <span>
                {profileData?.linkProfile?.firstname}
                {/* // ' ' +
                  // profileData?.linkProfile?.lastname */}
              </span>
              {
                localesData?.account_detail
                  ?.you_will_both_appear_in_matching_again
              }
            </Typography>
            <Box className='d-flex'>
              <CustomButton
                variant='text'
                title={localesData?.profile?.CANCEL_BTN}
                // background='#FF0000'userwork?Yes
                fontWeight='600'
                onClick={handleUnlinkClose}
                classname='mb-1 custom-btn cancel-btn'
              />
              <CustomButton
                variant='text'
                title={localesData?.userwork?.Yes}
                // background='#FF0000'userwork?Yes
                fontWeight='600'
                classname='mb-1  custom-btn yes-btn'
                onClick={handleOnUnlink}
                // classname='delete-account-btn'
              />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
      <DialogBox
        open={contactDialog}
        handleClose={() => setContactDialog(!contactDialog)}
        title=''
        className='card-dialog-box contact-card-box '
        setContactDialog={setContactDialog}
        content={
          <ContactCard
            handleClose={() => setContactDialog(!contactDialog)}
            cardInfoLeft={localesData?.contact_search?.CARD_SELECTED}
            cardInfoRight={localesData?.contact_search?.LINK_PROFILE}
            contact={contact}
            sendLinkReq={linkRejectWithStatus}
            linkReject={linkReject}
            linkAccept={linkAccept}
            isReqSend={
              profileData?.linkProfile?.linkStatus === 'RequestSend'
                ? true
                : false
            }
            // navigate={navigate}
          />
        }
      />
      {/* ALERT MODAL */}
      <DialogBox
        open={subToggle}
        handleClose={() => {
          setSubToggle(!subToggle);
          setDeleteStatus(false);
        }}
        title=''
        className='subscription-modal'
        content={
          <SubscriptionAlert
            alertText={
              deleteStatus
                ? localesData?.DELETE + ' ' + localesData?.DELETEIT
                : cancelPlanStatus
                ? localesData?.subscription_popup
                    ?.CANCEL_SUBSCRIPTION_CONFIRMATION
                : localesData?.subscription_popup?.DELETE_TO_FIRST_CANCEL
            }
            setSubToggle={setSubToggle}
            handleSubPlan={() =>
              deleteStatus
                ? handledelete()
                : !cancelPlanStatus
                ? setCancelPlanStatus(true)
                : handleCancelPlan()
            }
            acceptBtn={
              cancelPlanStatus
                ? localesData?.subscription_popup?.CANCEL_SUBSCRIPTION
                : deleteStatus
                ? localesData?.account_detail?.DELETE_ACCOUNT
                : localesData?.subscription_popup?.CANCEL_ACTIVE_SUBSCRIPTION
            }
            declineBtn={localesData?.subscription_popup?.NOT_NOW}
          />
        }
      />
      <Dialog
        open={addMobile}
        onClose={() => {
          setAddMobile(false);
        }}
        className='delete-dialog-box'
      >
        <DialogContent>
          <Box className='delete-head-sec'>
            <Box className='favourites-delete-sec'>
              <Typography className='dailog-head-text pb-1'>
                {' '}
                {localesData?.otp?.add_mobile_to_continue}
              </Typography>
              <Typography className='font-r-13 t-center pb-1'>
                {' '}
                {localesData?.otp?.add_mobile_to_continue_text}
              </Typography>
            </Box>
            <Box className='permission-btns'>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={6} className='cancel-btn'>
                  <CustomButton
                    variant='text'
                    title={localesData?.favourites_delete?.CANCEL}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-cancel-btn favourites-btn'
                    onClick={() => {
                      setAddMobile(false);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomButton
                    variant='text'
                    title={localesData?.otp?.add_mobile}
                    fontWeight='600'
                    type='submit'
                    classname='favourites-yes-btn favourites-btn'
                    onClick={() => {
                      navigate('/manage-account/mobile');
                      setAddMobile(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AccountDetails;
