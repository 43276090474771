import React, { useState } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import Dog from 'src/assets/images/subscription-dog.png';
import { ReactComponent as BackArrow } from 'src/assets/images/icons/back-arrow.svg';
import CustomButton from 'src/views/component//UI/Button/CustomButton';
import { ReactComponent as Payment1 } from 'src/assets/images/icons/payment-icon-1.svg';
import { ReactComponent as Payment2 } from 'src/assets/images/icons/payment-icon-2.svg';
import { ReactComponent as Payment3 } from 'src/assets/images/icons/payment-icon-3.svg';
import { ReactComponent as Cvvimage } from 'src/assets/images/icons/cvvimage.svg';
import { CustomTextField } from 'src/views/component/UI/textfield';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PreLoader from 'src/components/Loader';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { useNavigate } from 'react-router-dom';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import './paymentdetails.scss';

const PaymentDetails = ({
  localesData,
  handleClose,
  selectedPlan,
  handleCloseBothModal,
  isMostPaw,
}) => {
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  // GET PROFILE DETAILS
  const getProfileDetails = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const isSubscribtion = profileData?.subscription?.status === 'cancel';
  return (
    <Box className='subscription-head-sec payment-details-sec'>
      {loader && <PreLoader />}
      <Box className='subscription-popup-sec'>
        <img alt='not found' src={Dog} className='dog-img' />
      </Box>
      <Box className='subscription-back-arrow '>
        <BackArrow className='pointer' onClick={handleClose} />
      </Box>
      <Box className='payment-details '>
        <Box className='paw-pular-btn-sec mt-7 mb-8'>
          {selectedPlan?.id === isMostPaw && (
            <Typography variant='h5' className='paw-pular-btn'>
              {localesData?.subscription_popup?.MOST_PAW_PULAR}
            </Typography>
          )}
        </Box>
        <Typography className='per-month-text'>
          <Typography variant='span' className='per-month-text-amount'>
            ${selectedPlan?.cost}
          </Typography>
          {'/' + selectedPlan?.duration}
        </Typography>
        <Box className='card-details'>
          <Typography className='details-text font-bold'>
            {localesData?.subscription_popup?.payment_details}
          </Typography>
          <Box className='d-flex justify-center align-center'>
            <Typography className='details-text pr-10'>
              {localesData?.subscription_popup?.credit_or_debit_card}
            </Typography>
            <Box className='payment-card-image d-flex'>
              <Payment1 />
              <Payment2 />
              <Payment3 />
            </Box>
          </Box>
        </Box>
        <Box>
          <Formik
            initialValues={{
              cardname: '',
              cardNumber: '',
              expirationDate: '',
              cvv: '',
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              cardname: Yup.string().required(
                localesData?.validation?.REQUIRED
              ),
              cardNumber: Yup.string()
                .min(19, 'Card number must be at most 16 characters')
                .max(19, 'Card number must be at most 16 characters')
                .required(localesData?.validation?.error_card_number),
              expirationDate: Yup.string()
                .typeError('Not a valid expiration date. Example: MM/YY')
                .max(5, 'Not a valid expiration date. Example: MM/YY')
                .matches(
                  /([0-9]{2})\/([0-9]{2})/,
                  'Not a valid expiration date. Example: MM/YY'
                )
                .required(localesData?.validation?.error_expiration_date),
              cvv: Yup.string()
                .min(3)
                .max(3)
                .required(localesData?.validation?.error_ccv_number),
            })}
            onSubmit={async (requestData) => {
              const date = requestData?.expirationDate;

              const [month, year] = date.split('/');

              const centuryPrefix =
                new Date().getFullYear() -
                (new Date().getFullYear() % 100) +
                ''; // Get the current century prefix as a string
              const formattedDate = `${centuryPrefix.slice(
                0,
                2
              )}${year}-${month}`;

              if (profileData?.isPaid) {
                const sendData = {
                  planId: selectedPlan?.id,
                  card: {
                    card_holder_name: requestData?.cardname,
                    card_token: requestData?.cardNumber?.replace(/\s/g, ''),
                    card_exp: formattedDate,
                    cvv: requestData?.cvv,
                  },
                };

                try {
                  setLoader(true);
                  const { status, data } = await axiosInstance.put(
                    URLS.UPGRADE_SUBSCRIBE_PLAN,
                    sendData
                  );
                  if (status === 200) {
                    if (data?.status) {
                      setApiMessage('success', data?.message);
                      handleCloseBothModal();
                      navigate('/subscription-plan');
                      getProfileDetails();
                    } else {
                      setApiMessage('error', data?.message);
                      setApiMessage('error', data?.message?.raw?.message);
                    }
                    setLoader(false);
                  }
                } catch (error) {
                  setLoader(false);
                  setApiMessage('error', error?.response?.data?.message);
                }
              } else {
                const sendData = {
                  planId: selectedPlan?.id,
                  platform: 'web',
                  card: {
                    card_holder_name: requestData?.cardname,
                    card_token: requestData?.cardNumber?.replace(/\s/g, ''),
                    card_exp: formattedDate,
                    cvv: requestData?.cvv,
                  },
                  roleId: 2,
                };

                try {
                  setLoader(true);
                  const { status, data } = await axiosInstance.post(
                    URLS.SUBSCRIBE_PLAN,
                    sendData
                  );
                  if (status === 200) {
                    if (data?.status) {
                      setApiMessage('success', data?.message);
                      handleCloseBothModal();
                      navigate('/subscription-plan');
                      getProfileDetails();
                    } else {
                      setApiMessage('error', data?.message);
                      setApiMessage('error', data?.message?.raw?.message);
                    }
                    setLoader(false);
                  }
                } catch (error) {
                  setLoader(false);
                  setApiMessage('error', error?.response?.data?.message);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                className='form-content pb-6 all-wrap'
              >
                <Box className='card-form'>
                  <Box className='mb-5'>
                    <CustomTextField
                      error={Boolean(touched.cardNumber && errors.cardNumber)}
                      helperText={touched.cardNumber && errors.cardNumber}
                      fullWidth
                      variant='standard'
                      className='select-input-field '
                      name='cardNumber'
                      value={values.cardNumber}
                      // inputProps={{ maxLength: 16 }}
                      onChange={(e) => {
                        // const re = /^[0-9\b]+$/;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                        // handleChange(e);
                        setFieldValue(
                          'cardNumber',
                          e.target.value
                            .replace(/[^\dA-Z]/g, '')
                            .replace(/(.{4})/g, '$1 ')
                            .trim()
                        );
                        // }
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9 ]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 19 }}
                      onBlur={handleBlur}
                    />
                    <FormHelperText className='custom-field-helper'>
                      {localesData?.subscription_popup?.card_number}
                    </FormHelperText>
                    {/* <Typography
                      variant='h5'
                      className='d-flex text-field-text'
                      dangerouslySetInnerHTML={{
                        __html: localesData?.subscription_popup?.card_number,
                      }}
                    /> */}
                  </Box>
                  <Box className='mb-5'>
                    <CustomTextField
                      error={Boolean(
                        touched.expirationDate && errors.expirationDate
                      )}
                      helperText={
                        touched.expirationDate && errors.expirationDate
                      }
                      fullWidth
                      variant='standard'
                      className='select-input-field '
                      name='expirationDate'
                      value={values.expirationDate}
                      onChange={(e) => {
                        setFieldValue(
                          'expirationDate',
                          e.target.value
                            .replace(/[^0-9]/g, '') // To allow only numbers
                            .replace(/^([2-9])$/g, '0$1') // To handle 3 > 03
                            .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2') // 13 > 01/3
                            .replace(/^0{1,}/g, '0') // To handle 00 > 0
                            .replace(
                              /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                              '$1/$2'
                            ) // To handle 113 > 11/3
                        );
                      }}
                      inputProps={{ maxLength: 5 }}
                      onBlur={handleBlur}
                    />
                    <FormHelperText className='custom-field-helper'>
                      {localesData?.subscription_popup?.expiry_date}
                      <Typography
                        variant='span'
                        className='text-field-text-gray'
                      >
                        {localesData?.subscription_popup?.expiry_date_eg}
                      </Typography>
                    </FormHelperText>
                    {/* <Typography variant='h5' className='custom-field-helper'>
                      {localesData?.subscription_popup?.expiry_date}
                      <Typography
                        variant='span'
                        className='text-field-text-gray'
                      >
                        {localesData?.subscription_popup?.expiry_date_eg}
                      </Typography>
                    </Typography> */}
                  </Box>
                  <Box className='mb-5'>
                    <CustomTextField
                      error={Boolean(touched.cvv && errors.cvv)}
                      helperText={touched.cvv && errors.cvv}
                      fullWidth
                      variant='standard'
                      className='select-input-field'
                      name='cvv'
                      value={values.cvv}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      inputProps={{ maxLength: 3 }}
                      onBlur={handleBlur}
                    />
                    <Box className='d-flex '>
                      <Typography
                        variant='h5'
                        className='custom-field-helper'
                        dangerouslySetInnerHTML={{
                          __html: localesData?.subscription_popup?.CVV,
                        }}
                      />
                      <Cvvimage className='cvv-icon' />
                    </Box>
                  </Box>
                  <Box className=''>
                    <CustomTextField
                      error={Boolean(touched.cardname && errors.cardname)}
                      helperText={touched.cardname && errors.cardname}
                      fullWidth
                      variant='standard'
                      className='select-input-field '
                      name='cardname'
                      value={values.cardname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormHelperText className='custom-field-helper'>
                      {localesData?.subscription_popup?.name_on_card}
                    </FormHelperText>
                    {/* <Typography
                      variant='h5'
                      className='d-flex text-field-text'
                      dangerouslySetInnerHTML={{
                        __html: localesData?.subscription_popup?.name_on_card,
                      }}
                    /> */}
                  </Box>
                </Box>
                <CustomButton
                  variant='contained'
                  title={localesData?.subscription_popup?.change_plan}
                  background='#000000'
                  onClick={handleClose}
                />
                <CustomButton
                  variant='contained'
                  type='submit'
                  title={
                    profileData?.isPaid && !isSubscribtion
                      ? localesData?.subscription_popup?.UPDATE_YOUR_PLAN
                      : localesData?.subscription_popup?.TRIAL_BTN
                  }
                  background='#59D09D'
                />
                <Typography className='trial-text'>
                  {
                    localesData?.subscription_popup
                      ?.you_will_be_charged_after_your_free_trial_expires
                  }
                </Typography>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentDetails;
