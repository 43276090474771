import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { IntlContext } from '../../../../App';

import { handleNext } from '../../../../helpers/store/actions/navigateSlice';
import UserInformation from './UserInformation';
import UserWork from './UserWork';
import UserEducation from './UserEducation';
import Hobbies from './UserHobbies/Hobbies';
import Religion from './UserReligion/Religion';
import Politics from './UserPolitics/Politics';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { identifiers } from '../../../../helpers/constants/identifier';
import { fetchFromStorage, saveToStorage } from '../../../../helpers/context';
import PreLoader from '../../../../components/Loader';
import DNATest from './UserDNA/DNATest';
import UserKids from './UserKids';
import SomeOtherKids from './UserKids/SomeOtherKids';
import UserHeightWeight from './UserHeightWeight';
import UserFamilyPlan from './UserFamilyPlans/FamilyPlan';
import Vices from './UserFamilyPlans/Vices';
import UserLocation from './UserLocation';
import SubDialogbox from '../../UI/Subscriptionpopup/SubDialogbox';
import SubscriptionPopup from '../../UI/Subscriptionpopup/SubscriptionDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearSvgData } from 'src/helpers/store/actions/progressImage';

const ProfileCompletionAdvance = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reduxStep = useSelector((state) => state?.page_navigate?.value);
  const reduxProgress = useSelector((state) => state?.progressBarData);
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answars, setAnswars] = useState([]);
  const [toogleDialog, setToogleDialog] = useState(false);
  const [progressWeightage, setProgressWeightage] = useState(0);
  const [questionsBlankLoaded, setQuestionsBlankLoaded] = useState(false);
  const isEdit = location?.state === 'isEdit';

  const redirectHomePage = () => {
    if (profileData?.isPaid === false) {
      setToogleDialog(!toogleDialog);
    } else {
      dispatch(clearSvgData());
      dispatch(handleNext(0));
      navigate('/');
    }
  };

  useEffect(() => {
    if (reduxStep) {
      setActiveStep(reduxStep === 'otherKids1' ? 7 : reduxStep);
    }
    // else {
    //   setActiveStep(0);
    // }
  }, [reduxStep]);

  const getAllQuestions = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.QUESTIONS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setQuestions(data?.types);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getAllAnswars = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);

        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getProfile = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.GET_PROFILE);
      if (status === 200) {
        saveToStorage(identifiers?.USER_DATA, data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  const getAllQuestionsBlank = async () => {
    var black_data = 0;
    var job = true;
    var education = true;
    var height_weight = true;
    var vices = true;
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      if (status === 200) {
        data?.questions?.map((item) => {
          // if (item.id === 8) {
          //   black_data++; //looking for
          // }
          if (
            item.id === 9 ||
            item.id === 10 ||
            item.id === 11 ||
            item.id === 12
          ) {
            if (job) {
              black_data++; //job
              job = false;
            }
          }
          if (item.id === 13 || item.id === 14 || item.id === 15) {
            if (education) {
              black_data++; //education
              education = false;
            }
          }
          if (item.id === 16) {
            black_data++; //hobbies
          }
          if (item.id === 17) {
            black_data++; //religion
          }
          if (item.id === 18) {
            black_data++; //politics
          }
          if (item.id === 19) {
            black_data++; //children
          }
          if (item.id === 20) {
            black_data++; //how many kids
          }
          if (item.id === 23 || item.id === 24) {
            if (height_weight) {
              black_data++; //height weight
              height_weight = false;
            }
          }
          if (item.id === 25) {
            black_data++; //ethnicity
          }
          if (item.id === 26) {
            black_data++; //family plan
          }
          if (
            item.id === 28 ||
            item.id === 29 ||
            item.id === 30 ||
            item.id === 31
          ) {
            if (vices) {
              black_data++; //vices
              vices = false;
            }
          }
          if (item.id === 33) {
            black_data++; //location
          }
          if (item.id === 2) {
            black_data++; //about gender
          }
          if (item.id === 54) {
            black_data++; //about age
          }
          if (item.id === 55) {
            black_data++; //about dog breed
          }
          if (item.id === 56) {
            black_data++; //about job
          }
          if (item.id === 57) {
            black_data++; //about education
          }
          if (item.id === 58) {
            black_data++; //about hobbies
          }
          if (item.id === 59) {
            black_data++; //about religion
          }
          if (item.id === 60) {
            black_data++; //about politics
          }
          if (item.id === 61) {
            black_data++; //about height weight
          }
          if (item.id === 62) {
            black_data++; //about ethnicity
          }
          if (item.id === 63) {
            black_data++; //about vices
          }
          if (item.id === 64) {
            black_data++; //about location
          }
          return true;
        });

        setProgressWeightage((25 - black_data) * 4);
        // dispatch(addProgressBar((25 - black_data) * 4));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllQuestions();
    // getAllAnswars();
    getProfile();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getAllAnswars();
  }, [reduxStep]);
  useEffect(() => {
    if (!questionsBlankLoaded) {
      getAllQuestionsBlank();
      setQuestionsBlankLoaded(true);
    }
  }, [activeStep, questionsBlankLoaded]);

  const pageTopRef = useRef(null);

  const scrollToTop = () => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
    }
  };

  /*   useEffect(() => {
      if (pageTopRef.current) {
        pageTopRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      }
  }, [reduxStep]); */

  const handleNexts = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(handleNext(activeStep + 1));
    scrollToTop();
  };

  const checkForBlankData = async () => {
    // setLoader(true);
    try {
      const { data, status } = await axiosInstance.get(URLS.BLANK_QUESTIONS);
      var breakIt = false;

      // if (response.data != null) {
      if (status === 200) {
        // setLoading(false);
        dispatch(clearSvgData());
        data?.questions.map((item) => {
          if (breakIt === false) {
            // if (item.id === 8) {
            //   breakIt = true;
            //   // navigate('/profile-completion-advance', { state: 'isEdit' });
            //   // dispatch(handleNext(0));
            //   navigate('/edit-description');
            //   // navigate('/edit-description');
            //   // navigate('/profile-completion');
            //   // dispatch(handleNext(6));
            // } else
            if (
              item.id === 9 ||
              item.id === 10 ||
              item.id === 11 ||
              item.id === 12
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(1));
            } else if (item.id === 13 || item.id === 14 || item.id === 15) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(2));
            } else if (item.id === 16) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(3));
            } else if (item.id === 17) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(4));
            } else if (item.id === 18) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(5));
            } else if (item.id === 19) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(6));
            } else if (item.id === 20) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext('otherKids'));
            } else if (item.id === 23 || item.id === 24) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(7));
            } else if (item.id === 25) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(8));
            } else if (item.id === 26) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(9));
            } else if (
              item.id === 28 ||
              item.id === 29 ||
              item.id === 30 ||
              item.id === 31
            ) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(10));
            } else if (item.id === 33) {
              breakIt = true;
              navigate('/profile-completion-advance', { state: 'isEdit' });
              dispatch(handleNext(11));
            } else {
              navigate('/edit-profile', {
                state: 'profile-edit',
              });
            }
          }
          return true;
        });
      }
    } catch (error) {
      setLoader(false);
      // setApiMessage('error', error?.response?.data?.message);
    }
  };

  const renderComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <UserInformation
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
          />
        );
      case 1:
        return (
          <UserWork
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 2:
        return (
          <UserEducation
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 3:
        return (
          <Hobbies
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 4:
        return (
          <Religion
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 5:
        return (
          <Politics
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 6:
        return (
          <UserKids
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 'otherKids':
        return (
          <SomeOtherKids
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 7:
        return (
          <UserHeightWeight
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 8:
        return (
          <DNATest
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 9:
        return (
          <UserFamilyPlan
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 10:
        return (
          <Vices
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      case 11:
        return (
          <UserLocation
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            questions={questions}
            answars={answars}
            setLoader={setLoader}
            dispatch={dispatch}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
            isEdit={isEdit}
            checkForBlankData={checkForBlankData}
          />
        );
      default:
        return (
          <UserInformation
            localesData={localesData}
            handleNext={handleNexts}
            reduxProgress={reduxProgress}
            redirectHomePage={redirectHomePage}
            progressWeightage={progressWeightage}
          />
        );
    }
  };

  return (
    <Box className='page-content-wrap pb-3' ref={pageTopRef}>
      {loader && <PreLoader />}
      {renderComponent()}
      <SubDialogbox
        open={toogleDialog}
        handleClose={() => {
          setToogleDialog(!toogleDialog);
          navigate('/');
        }}
        title=''
        content={
          <SubscriptionPopup
            handleClose={() => setToogleDialog(!toogleDialog)}
            navigate={navigate}
          />
        }
      />
    </Box>
  );
};

export default ProfileCompletionAdvance;
