import React from 'react';
import Page from '../../../components/Page';
import Dashboard from 'src/views/component/application/Directory/DirecoryDashboard';

const DirectoryDashboardPage = () => {
  return (
    <Page title='Direcory'>
      <Dashboard />
    </Page>
  );
};

export default DirectoryDashboardPage;
