import React, { useContext, useEffect, useRef, useState } from 'react';
import './benefits.scss';
import { Box, Typography, Avatar } from '@mui/material';
import { IntlContext } from '../../../../App';
// import { ReactComponent as Star } from '../../../../assets/images/icons/star.svg';
import { ReactComponent as FavIcon } from 'src/assets/images/icons/favourites.svg';
import { ReactComponent as FavSelectedIcon } from 'src/assets/images/icons/favourites-active.svg';
import { ReactComponent as NextArrow } from '../../../../assets/images/icons/next-pink-arrow.svg';
// import { ReactComponent as PinkStar } from '../../../../assets/images/icons/pink-star.svg';
// import { ReactComponent as Petbarn } from '../../../../assets/images/icons/petbarn.svg';
// import { ReactComponent as PawsImg } from '../../../../assets/images/icons/paws-icon.svg';
// import { ReactComponent as LogoImg } from '../../../../assets/images/icons/logo-dummy.svg';
import Dummyimg from '../../../../assets/images/benefit-page-dummy-img.png';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { setApiMessage } from 'src/helpers/commonFunctions';
import DialogBox from '../../UI/PremiumPopup/PremiumDialogBox';
import PremiumPopup from '../../UI/PremiumPopup';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomTextField } from '../../UI/textfield';
const page_limit = 10;
const Benefits = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const location = useLocation();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [benefitsData, setBenefitsData] = useState([]);
  // const [selectedBenefit, setSelectedBenefit] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const navigate = useNavigate();
  // const { id } = useParams();

  // const benefits = [
  //   {
  //     id: 1,
  //     title: <Petbarn />,
  //     subTitleName: 'Petbarn',
  //     subtitleContnent: ' - Pet Store',
  //     star: <Star />,
  //     description: localesData?.benefits?.OFF_ON_PURCHASES,
  //     nextArrow: <NextArrow />,
  //     path: '/benefits/BenefitDetails',
  //   },
  //   {
  //     id: 2,
  //     title: <PawsImg />,
  //     subTitleName: 'Mad Paws',
  //     subtitleContnent: ' - Grooming',
  //     star: <PinkStar />,
  //     description: '[benefit provided by corporate sponsor to member]',
  //     nextArrow: <NextArrow />,
  //   },
  //   {
  //     id: 3,
  //     title: <LogoImg />,
  //     subTitleName: '[Company Name] ',
  //     subtitleContnent: '- [Category]',
  //     star: <Star />,
  //     description: '[benefit provided by corporate sponsor to member]',
  //     nextArrow: <NextArrow />,
  //   },
  // ];

  const pageTopRef = useRef(null);

  const scrollToTop = () => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getBenefitData = async (pages) => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.BENEFIT + `?page=${pages ? pages : page}&size=${page_limit}`
      );
      if (status === 200) {
        // setApiMessage('success', data?.message);
        setBenefitsData([...benefitsData, ...data?.benefits]);
        setPage(pages ? pages + 1 : page + 1);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const getTotalRecord = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.BENEFIT);
      if (status === 200) {
        setTotalRecord(data?.benefits?.length);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const makeFavouriteAPI = async (itemID, index, item) => {
    const sendData = {
      following: itemID.toString(),
      type: 'benefits',
    };
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.post(
        URLS.FAVOURITE,
        sendData
      );
      if (status === 200) {
        item[index].isLike = 1;
        item[index].fevouriteId = data?.data?.favouriteId;
        setBenefitsData(item);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const removeFavouriteAPI = async (itemID, index, item) => {
    try {
      setLoader(true);
      const { status } = await axiosInstance.delete(
        URLS.FAVOURITE + '/' + itemID
      );
      if (status === 200) {
        item[index].isLike = 0;
        item[index].fevouriteId = null;
        setBenefitsData(item);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  const fetchMoreData = () => {
    getBenefitData();
  };

  const filteredBenefits = benefitsData.filter((benefit) =>
    Object.values(benefit).some((value) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    )
  );

  useEffect(() => {
    getBenefitData(1);
    getTotalRecord();
    scrollToTop();
    setSearchInput('');
  }, [location]);

  return (
    <>
      <Box
        style={{
          height: '100%',
        }}
      >
        <Box
          style={{
            height: '100%',
          }}
        >
          <Box
            className='benefits-detail-sec'
            style={{
              height: '100%',
            }}
          >
            <Box
              style={{
                position: 'sticky',
                top: '0',
                background: '#fafafa',
                zIndex: '9',
              }}
            >
              <Typography
                className='benefits-header-txt pb-2'
                dangerouslySetInnerHTML={{
                  __html: localesData?.benefits?.BENEFIT_HEAD,
                }}
              />
              {profileData?.isPaid === true ? (
                <CustomTextField
                  variant='standard'
                  className='benefit-search mb-4'
                  placeholder='Search here....'
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              ) : null}
            </Box>
            {filteredBenefits?.length === 0 && !loader ? (
              <Box className='blank-page-benefit-sec '>
                <Box className='dummy-img-benefit-sec d-flex'>
                  <Avatar
                    alt='Remy Sharp'
                    variant='square'
                    className='dummy-img '
                    src={Dummyimg}
                  />
                </Box>
                <Typography
                  variant='h3'
                  className='t-center'
                  dangerouslySetInnerHTML={{
                    __html:
                      localesData?.benefit_blank_page?.ARE_NOT_ANY_BENEFIT,
                  }}
                />
              </Box>
            ) : (
              <Box
                ref={pageTopRef}
                style={{
                  height: 'calc(100% - 199px)',
                  overflow: 'auto',
                }}
              >
                {filteredBenefits && filteredBenefits?.length > 0 ? (
                  <>
                    <InfiniteScroll
                      style={{
                        // height: '100%',
                        overflow: 'hidden',
                      }}
                      className='pb-4'
                      dataLength={benefitsData?.length}
                      next={fetchMoreData}
                      hasMore={benefitsData?.length !== totalRecord}
                      scrollableTarget='scrollable_topic'
                    >
                      {filteredBenefits?.map((content, id) => {
                        return (
                          <>
                            <Box
                              className='benefits-box-sec pb-2'
                              onClick={() =>
                                profileData?.isPaid === false
                                  ? setToogleDialog(true)
                                  : navigate(`/benefits/` + content?.id)
                              }
                            >
                              <Box className='white-Petbarn-box'>
                                <Box className='Petbarn-box-head d-flex'>
                                  <Typography className='d-flex'>
                                    {content.image ? (
                                      <img
                                        src={content.image}
                                        alt={content.image}
                                        className='benefit-image'
                                      />
                                    ) : (
                                      <span className='blank-image-placeholder'>
                                        {' '}
                                      </span>
                                    )}
                                  </Typography>
                                  <Avatar
                                    alt='Remy Sharp'
                                    variant='square'
                                    className='start-icon'
                                    onClick={() =>
                                      profileData?.isPaid === false
                                        ? setToogleDialog(true)
                                        : ''
                                    }
                                  >
                                    {content?.isLike === 1 ||
                                    content?.isLike === '1' ||
                                    content?.isLike === true ? (
                                      <FavSelectedIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          removeFavouriteAPI(
                                            content?.fevouriteId,
                                            id,
                                            benefitsData
                                          );
                                        }}
                                        className='pointer'
                                        height={22}
                                        width={23}
                                      />
                                    ) : (
                                      <FavIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          profileData?.isPaid === false
                                            ? setToogleDialog(true)
                                            : makeFavouriteAPI(
                                                content?.id,
                                                id,
                                                benefitsData
                                              );
                                        }}
                                        className='pointer'
                                        height={22}
                                        width={23}
                                      />
                                    )}
                                  </Avatar>
                                </Box>
                              </Box>
                              <Box className='Petbarn-box d-flex'>
                                <Box className='store-off-content'>
                                  <Box className='d-flex'>
                                    <Typography className='pet-store-head-txt'>
                                      {content.petStore} {'-'}
                                    </Typography>
                                    <Typography className='pet-store-txt'>
                                      {content.petStoreType}
                                    </Typography>
                                  </Box>

                                  <Typography
                                    className='font-r-12'
                                    dangerouslySetInnerHTML={{
                                      __html: content.benefistName,
                                    }}
                                  />
                                </Box>
                                <Avatar
                                  alt='Remy Sharp'
                                  variant='square'
                                  className='next-arrow-icon'
                                >
                                  <NextArrow />
                                </Avatar>
                              </Box>
                            </Box>
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <DialogBox
          open={toogleDialog}
          handleClose={() => setToogleDialog(!toogleDialog)}
          title=''
          content={
            <PremiumPopup
              handleClose={() => setToogleDialog(!toogleDialog)}
              navigate={navigate}
            />
          }
        />
      </Box>
    </>
  );
};

export default Benefits;
