import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  FormHelperText,
  MenuItem,
  Typography,
  Select,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { IntlContext } from '../../../../App';
import { saveToStorage } from '../../../../helpers/context';
import axiosInstance from '../../../../helpers/axios/axiosInstance';
import { URLS } from '../../../../helpers/constants/urls';
import { setApiMessage } from '../../../../helpers/commonFunctions';
import { identifiers } from '../../../../helpers/constants/identifier';
import { addAuth } from '../../../../helpers/store/actions/auth';
import PreLoader from '../../../../components/Loader';
import { clearProfileData } from 'src/helpers/store/actions/profileSetup';
import { handleNext } from 'src/helpers/store/actions/navigateSlice';
import { clearSvgData } from 'src/helpers/store/actions/progressImage';
import { ReactComponent as AdPLLogo } from '../../../../assets/images/adpartner/adsPartner-pl-logo.svg';
import WollemiLogo from '../../../../assets/images/adpartner/wollemi-retreat-logo.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CustomTextField } from '../../UI/textfield';
import CustomButton from '../../UI/Button/CustomButton';
import { CustomSelect } from '../../UI/select';
import { ReactComponent as DropdownIcon } from '../../../../assets/images/icons/down-icon.svg';
import { ReactComponent as Visibility } from '../../../../assets/images/icons/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../../../assets/images/icons/visibility-off.svg';
import './landingscreen.scss';

const LandingScreen = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [showEnterPassword, setShowCurrentPassword] = useState(false);

  // GET COUNTRY LIST
  const getCountryList = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.COUNTRY_LIST);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setCountryOptions(data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getCountryList();
  }, []);

  return (
    <Box className='landing-main-container'>
      {loader && <PreLoader />}
      <Box className='landing-card-container'>
        <Box>
          <AdPLLogo />
        </Box>
        <Box className='landing-card-wrapper'>
          <Typography className='landing-card-title'>GIVEAWAY</Typography>
          <Typography className='landing-card-sub-title'>
            IN PARTNERSHIP WITH
          </Typography>
          <Box className='ads-partner-logo-conent'>
            <img src={WollemiLogo} alt='wollemi-logo' />
          </Box>
          <Box className='ads-partner-desc-content'>
            <Typography className='ads-partner-desc'>
              <span className='color-pink bold'> Sign up </span>
              for a chance to win a 2 night stay for 2 at a cabin of your choice
              at the luxurious Wollemi Retreat.
            </Typography>
            <Typography className='ads-partner-drawn-date'>
              Winners drawn 31st March 2024
            </Typography>
          </Box>
          <Box className='ads-partner-form-container'>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
                phone_country: '61',
                phone_number: '',
                enter_password: '',
              }}
              // enableReinitialize={true}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required(
                  localesData?.validation?.REQUIRED
                ),
                last_name: Yup.string().required(
                  localesData?.validation?.REQUIRED
                ),
                email: Yup.string()
                  .required(localesData?.validation?.REQUIRED)
                  .email(localesData?.validation?.VALID_EMAIL)
                  .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    localesData?.validation?.VALID_EMAIL
                  ),
                phone_country: Yup.string().required(
                  localesData?.validation?.REQUIRED
                ),
                phone_number: Yup.string()
                  .required(localesData?.validation?.REQUIRED)
                  .matches(/^[0-9]{8,15}$/, localesData?.validation?.NUMBER),
                enter_password: Yup.string()
                  .required(localesData?.validation?.REQUIRED)
                  .min(6, localesData?.validation?.PASSWORD_LENGTH),
              })}
              onSubmit={async (requestData, { resetForm }) => {
                console.log('requestData', requestData);
                let sendData = {
                  // countryCode: profileData?.phone_country,
                  // username: profileData?.phone_number,
                  // // countryCode: profileData.phone_country,
                  // password: requestData?.enter_password,
                  // platform: 'web',
                  username: requestData?.phone_number,
                  countryCode: requestData?.phone_country,
                  password: requestData?.enter_password,
                  platform: 'web',
                  firstname: requestData?.first_name,
                  lastname: requestData?.last_name,
                  email: requestData?.email,
                  adsPartner: 'wollemiRetreat',
                };
                try {
                  const { status, data } = await axiosInstance.post(
                    URLS.SIGN_UP,
                    sendData
                  );
                  if (status === 200) {
                    // setApiMessage('success', data?.message);
                    if (data?.status) {
                      setApiMessage(
                        'success',
                        'Thank you for signing up! Good luck!'
                      );
                      resetForm();
                    }
                  }
                } catch (error) {
                  setApiMessage('error', error?.response?.data?.message);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit} className='form-content'>
                  {/* FIRST NAME */}
                  <Box className='landing-grid-field'>
                    <Box className='field-content landing-field'>
                      <CustomTextField
                        fullWidth
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        variant='standard'
                        className='custom-textfield'
                        name='first_name'
                        value={values.first_name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='First Name'
                      />
                    </Box>
                    {/* LAST NAME */}
                    <Box className='field-content landing-field'>
                      <CustomTextField
                        fullWidth
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        variant='standard'
                        className='custom-textfield'
                        name='last_name'
                        value={values.last_name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='Last Name'
                      />
                    </Box>
                  </Box>
                  {/* EMAIL */}
                  <Box className='field-content landing-field'>
                    <CustomTextField
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      variant='standard'
                      className='custom-textfield'
                      name='email'
                      value={values.email || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Email'
                    />
                  </Box>
                  {/* PHONE NUMBER */}
                  <Box className='login-field-content landing-field'>
                    <Box className='field-content'>
                      <Select
                        fullWidth
                        variant='standard'
                        error={Boolean(
                          touched.phone_country && errors.phone_country
                        )}
                        className='custom-select'
                        name='phone_country'
                        input={<CustomSelect />}
                        IconComponent={DropdownIcon}
                        value={values.phone_country?.replace('+', '') || '61'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        displayEmpty
                      >
                        {countryOptions?.map((item, i) => (
                          <MenuItem
                            key={i}
                            value={item?.countryCode?.replace('+', '')}
                            className='select-options'
                          >
                            <img
                              src={item?.flagUrl}
                              alt=''
                              className='dropdown-flag-icon'
                            />
                            <span className='dropdown-flag-text'>
                              {item?.countryCode}
                            </span>
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.phone_country && (
                        <FormHelperText error={Boolean(errors.phone_country)}>
                          {touched.phone_country && errors.phone_country}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box className='field-content'>
                      <CustomTextField
                        fullWidth
                        error={Boolean(
                          touched.phone_number && errors.phone_number
                        )}
                        helperText={touched.phone_number && errors.phone_number}
                        variant='standard'
                        className='custom-textfield'
                        name='phone_number'
                        value={values.phone_number || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={(e) => {
                          if (/[^0-9]/g.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        type='number'
                        placeholder='Mobile number'
                      />
                    </Box>
                  </Box>
                  {/* PASSWORD */}
                  <Box className='field-content mb-5'>
                    <CustomTextField
                      fullWidth
                      error={Boolean(
                        touched.enter_password && errors.enter_password
                      )}
                      helperText={
                        touched.enter_password && errors.enter_password
                      }
                      variant='standard'
                      className='custom-textfield'
                      name='enter_password'
                      value={values.enter_password?.replace(/\s+/g, '') || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type={showEnterPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              disableRipple
                              onClick={() =>
                                setShowCurrentPassword(!showEnterPassword)
                              }
                            >
                              {showEnterPassword ? (
                                <Visibility height='19px' />
                              ) : (
                                <VisibilityOff height='19px' />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder='Password'
                    />
                  </Box>
                  <Box>
                    <CustomButton
                      variant='contained'
                      title='SIGN UP FOR A CHANCE TO WIN!'
                      background='#F54D71'
                      fontWeight='600'
                      type='submit'
                      classname='landing-form-submit-btn'
                    />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingScreen;
