import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  directoryData: '',
};

export const directorySlice = createSlice({
  name: 'directoryData',
  initialState,
  reducers: {
    directoryData: (state, action) => {
      // const { key, value } = action.payload;
      // state[key] = value;
      return action.payload;
    },
  },
});
export const { directoryData } = directorySlice.actions;

export default directorySlice.reducer;
