import React from 'react';
import Page from '../../../components/Page';
import Benefits from 'src/views/component/application/Benefits';

const BenefitsView = () => {
  return (
    <Page title='Benefits'>
      <Benefits />
    </Page>
  );
};

export default BenefitsView;
