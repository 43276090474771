import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox, // Import the Checkbox component
  Divider,
} from '@mui/material';
import { Formik } from 'formik';
import IOSSwitch from '../../../../UI/IOSSwitch';
import CustomButton from '../../../../UI/Button/CustomButton';
import { ReactComponent as LockIcon } from '../../../../../../assets/images/icons/lock.svg';
import { ReactComponent as UnChecked } from '../../../../../../assets/images/unchecked.svg';
import { ReactComponent as Checked } from 'src/assets/images/profile-checked.svg';
import { ReactComponent as UnLockIcon } from '../../../../../../assets/images/icons/unlock.svg';
import PremiumPopup from 'src/views/component/UI/PremiumPopup';
import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import { setApiMessage } from '../../../../../../helpers/commonFunctions';
import { URLS } from '../../../../../../helpers/constants/urls';
import axiosInstance from '../../../../../../helpers/axios/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UpgradePlan from '../../UpgradePlan';
import { fetchFromStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const SelectYourChildren = ({
  localesData,
  handleNext,
  questions,
  setLoader,
  dispatch,
  // answars,
}) => {
  const navigate = useNavigate();
  const profileData = fetchFromStorage(identifiers?.USER_DATA);
  const [empData, setEmpData] = useState('');
  const [toogleDialog, setToogleDialog] = useState(false);
  const [youHaveKidsData, setYouHaveKidsData] = useState('');
  const [oldChildData, setOldChildData] = useState('');
  const [otherData, setOtherData] = useState({
    totalKids: [],
  });
  const [answars, setAnswars] = useState([]);
  // const [selectedValue, setSelectedValue] = useState(0);

  const getAllAnswers = async () => {
    try {
      setLoader(true);
      const { status, data } = await axiosInstance.get(URLS.ALL_ANSWERS);
      if (status === 200) {
        setApiMessage('success', data?.message);
        setAnswars(data?.questions);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getAllAnswers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    answars?.map((item, i) => {
      if (item.id === 19) {
        setEmpData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
        });
      }
      if (item.id === 20) {
        setYouHaveKidsData({
          title: item?.title,
          id: item?.id,
          options: item?.Option,
          private: item?.Option?.[0]?.private === 1 ? true : false,
        });
      }
      if (item.id === 21) {
        setOldChildData({
          title: item.title,
          id: item.id,
          options: item.Option,
          private: item?.Option?.[0]?.private === 1 ? true : false,
        });
        setOtherData({
          totalKids: item?.Option?.[0]?.answer
            ? JSON.parse(item.Option[0].answer)
            : null,
        });
      }
      return true;
    });
  }, [answars]);

  const handleCheckboxChange = (optionId) => {
    setEmpData((prevEmpData) => ({
      ...prevEmpData,
      options: prevEmpData.options.map(
        (option) =>
          option.id === optionId
            ? { ...option, selected: !option.selected } // Toggle the selected state of the clicked option
            : { ...option, selected: false } // Set other options to selected: false
      ),
    }));
  };

  return (
    <>
      <Formik
        initialValues={{
          kidsPrivate:
            empData?.options?.[0]?.private === '1' ||
            empData?.options?.[0]?.private === 1
              ? true
              : false,
        }}
        enableReinitialize={true}
        onSubmit={async (requestData) => {
          var opt = [];
          empData?.options?.map((obj) => {
            opt.push({
              optionId: obj?.id,
              answer: obj?.selected ? '1' : '0',
              selected: obj?.selected,
              private: requestData?.kidsPrivate,
            });
            return true;
          });
          opt.push({
            optionId: youHaveKidsData?.options[0]?.id,
            answer:
              empData?.options[1]?.selected ||
              empData?.options[2]?.selected ||
              empData?.options[3]?.selected
                ? '0'
                : youHaveKidsData?.options[0]?.answer === ''
                ? null
                : youHaveKidsData?.options[0]?.answer,
            selected: false,
            private: youHaveKidsData?.private
              ? youHaveKidsData?.private
              : false,
          });

          opt.push({
            optionId: oldChildData?.options[0]?.id,
            answer:
              otherData?.totalKids === ''
                ? null
                : empData?.options[1]?.selected ||
                  empData?.options[2]?.selected ||
                  empData?.options[3]?.selected
                ? null
                : JSON.stringify(otherData?.totalKids),
            selected: false,
            private: oldChildData?.private ? oldChildData?.private : false,
          });
          let sendData = {
            private: false,
            optionIds: opt,
          };
          try {
            setLoader(true);
            const { status, data } = await axiosInstance.post(
              URLS.SET_ANSWERS,
              sendData
            );
            if (status === 200) {
              setApiMessage('success', data?.message);
              // handleNext();
              setLoader(false);
              if (empData?.options?.[0]?.selected) {
                navigate('/edit-your-profile', {
                  state: 'yourChildren',
                });
              } else {
                navigate('/edit-profile', {
                  state: 'profile-edit',
                });
              }

              // if (index === 0) {
              //   console.log('indexindexindex', index);
              //   // handleNext(() => {
              //   navigate('/edit-your-profile', {
              //     state: 'yourChildren',
              //     // });
              //   });
              // } else {
              //   navigate('/edit-profile', {
              //     state: 'profile-edit',
              //   });
              // }
            }
          } catch (error) {
            setLoader(false);
            setApiMessage('error', error?.response?.data?.message);
          }
        }}
      >
        {({ errors, handleChange, handleSubmit, handleBlur, values }) => (
          <form
            onSubmit={handleSubmit}
            className='form-content pb-6'
            style={{ overflowY: 'scroll', height: 'inherit' }}
          >
            <UpgradePlan title={localesData?.profile?.YOUR_CHILDREN} />
            <Box className='t-center'>
              <Box className='pb-6'>
                <Divider className='divider' />
              </Box>
              <Typography variant='h4' className='pb-4'>
                {
                  localesData?.user_kids
                    ?.do_you_have_any_children_aside_from_your_furry_ones
                }
              </Typography>
              <Typography variant='h4' className='pb-1 s-bold'>
                {localesData?.user_kids?.select_as_many_as_you_ike}
              </Typography>
            </Box>

            <Box className='field-content '>
              <FormControl className='custom-radio pb-4'>
                <FormGroup>
                  {empData?.options?.map((item, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          size='small'
                          icon={<UnChecked />}
                          checkedIcon={<Checked />}
                          checked={item?.selected}
                          onChange={() => {
                            handleCheckboxChange(item?.id);
                          }}
                          disableRipple
                        />
                      }
                      labelPlacement='start'
                      label={
                        <Typography variant='h5' className='mb-2'>
                          {item?.title.replace(/,/g, '')}
                        </Typography>
                      }
                      onClick={() => {
                        if (!item?.selected && i === 0) {
                          handleSubmit();
                        }
                      }}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
            <Box
              sx={{ justifyContent: 'space-around' }}
              className='d-flex pb-4 profile-hobby'
            >
              <FormGroup>
                <FormControlLabel
                  control={<IOSSwitch />}
                  name='kidsPrivate'
                  checked={values?.kidsPrivate}
                  onChange={(e) => {
                    profileData?.isPaid === false
                      ? setToogleDialog(true)
                      : handleChange(e);
                  }}
                />
              </FormGroup>
              <Typography variant='h5'>
                {localesData?.userwork?.thats_private_dont_show_this}
              </Typography>
              {values?.kidsPrivate ? <LockIcon /> : <UnLockIcon />}
            </Box>
            <Box className='done-btn-sec'>
              <CustomButton
                variant='contained'
                title={localesData?.DONE}
                background={'#59D09D'}
                fontWeight='600'
                classname='done-btn s-bold'
                onClick={() => handleSubmit()}
              />
            </Box>
            <DialogBox
              open={toogleDialog}
              handleClose={() => setToogleDialog(!toogleDialog)}
              title=''
              content={
                <PremiumPopup
                  handleClose={() => setToogleDialog(!toogleDialog)}
                  navigate={navigate}
                />
              }
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default SelectYourChildren;
