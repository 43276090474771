import React from 'react';
import Page from 'src/components/Page';
import AddEditDogDetails from 'src/views/component/application/ManageProfile/DogProfile/AddEditDogDetails';

const AddEditDogProfilePage = () => {
  return (
    <Page title='Dog Profile'>
      <AddEditDogDetails />
    </Page>
  );
};

export default AddEditDogProfilePage;
