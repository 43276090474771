import React from 'react';
import { Dialog, Grow, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/CancelSharp';
import './premiumPopup.scss';

const DialogBox = ({
  open,
  handleClose,
  title,
  content,
  closeIconShow,
  className,
  onCloseStatus,
}) => {
  return (
    <>
      <Dialog
        className={`dialog_box premium-popup ${className}`}
        open={open}
        onClose={onCloseStatus ? handleClose : null}
        TransitionComponent={Grow}
        scroll={'body'}
      >
        {!closeIconShow && (
          <CloseIcon
            className={`close pointer ${className}`}
            onClick={handleClose}
          />
        )}
        <DialogTitle className={`dialog_title ${className}`}>
          {title}
        </DialogTitle>
        <DialogContent className={`dialog_content ${className}`}>
          {content}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogBox;
