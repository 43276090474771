import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as DogHeand } from 'src/assets/images/dog-heand-icon.svg';
import { ReactComponent as DogPinkHeand } from 'src/assets/images/pink-heand-icon.svg';
import noImage from 'src/assets/images/no_image.png';
// import DummyImg from 'src/assets/images/matching-img.png';
// import { fetchFromStorage } from 'src/helpers/context';
// import { identifiers } from 'src/helpers/constants/identifier';
// import DialogBox from 'src/views/component/UI/PremiumPopup/PremiumDialogBox';
import MediaDialogBox from 'src/views/component/UI/MediaDialog/MediaDialogBox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../matching.scss';

const MatchingImage = ({
  userData,
  isRemoveImg,
  imgIndex,
  onPawIconClick,
  isActivePawIcon,
}) => {
  const [toogleDialog, setToogleDialog] = useState(false);
  const [modalMedia, setModalMedia] = useState('');
  const isActivePawIconRef = useRef(false);
  // const profileData = fetchFromStorage(identifiers?.USER_DATA);
  // const [isActive, setIsActive] = useState(false);

  // const handleClick = () => {
  //   if (profileData?.isPaid === false) {
  //     setIsActive(false);
  //   } else {
  //     setIsActive(!isActive);
  //   }
  // };

  const openMediaModal = (media) => {
    setModalMedia(media);
    setToogleDialog(!toogleDialog);
  };

  return (
    <Box className='matching-img-sec'>
      {/* <img
        alt='not found'
        src={userData?.images?.[imgIndex]?.url}
        className='matching-img'
      /> */}
      {userData?.images?.[imgIndex]?.url?.substr(
        userData?.images?.[imgIndex]?.url?.length - 3
      ) === 'mp4' ? (
        <video
          alt=''
          className='matching-img'
          src={userData?.images?.[imgIndex]?.url}
        />
      ) : (
        <LazyLoadImage
          src={!isRemoveImg ? userData?.images?.[imgIndex]?.url : noImage}
          alt=''
          className='matching-img pointer'
          effect='blur'
          width='100%'
          onClick={() =>
            openMediaModal(
              !isRemoveImg ? userData?.images?.[imgIndex]?.url : noImage
            )
          }
        />
      )}
      {/* <img
          alt=''
          className='matching-img pointer'
          loading='lazy'
          src={userData?.images?.[imgIndex]?.url}
          onClick={() => openMediaModal(userData?.images?.[imgIndex]?.url)}
        /> */}
      <Box
        className='pointer dog-paw-icon'
        onClick={() => {
          onPawIconClick();
          // handleClick();
          isActivePawIconRef.current = !isActivePawIconRef.current;
        }}
      >
        {isActivePawIcon && isActivePawIconRef.current ? (
          <DogPinkHeand />
        ) : (
          <DogHeand />
        )}
      </Box>
      {/* <Button
        onClick={handleClick}
        className='dog-heand-icon'
        startIcon={isActive ? <DogPinkHeand /> : <DogHeand />}
      /> */}
      <MediaDialogBox
        open={toogleDialog}
        handleClose={() => setToogleDialog(!toogleDialog)}
        media_type='image'
        media_url={modalMedia}
      />
    </Box>
  );
};

export default MatchingImage;
